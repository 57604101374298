import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "loading"
};
export default {
  __name: 'loading',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return __props.show ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("div", {
        class: "loading-img"
      }, [_createElementVNode("svg", {
        viewBox: "25 25 50 50"
      }, [_createElementVNode("circle", {
        r: "20",
        cy: "50",
        cx: "50"
      })]), _createElementVNode("div", {
        style: {
          "color": "#fff",
          "font-size": "13px",
          "margin-top": "5px"
        }
      }, "加载中...")], -1)]))) : _createCommentVNode("", true);
    };
  }
};