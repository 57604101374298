import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, isRef as _isRef, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import _imports_0 from '../../assets/image/errorr.png';
import _imports_1 from '../../assets/image/activity/fu.png';
import _imports_2 from '../../assets/image/icon/LRDASku7_400x400.jpg';
import _imports_3 from '../../assets/image/icon/hb_home@1x.png';
import _imports_4 from '../../assets/image/icon/ljlq_@1x.png';
const _hoisted_1 = {
  class: "prompt"
};
const _hoisted_2 = {
  class: "prompt_title"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "tk-botton"
};
const _hoisted_5 = {
  class: "tk-mian"
};
const _hoisted_6 = {
  class: "tk-swiper"
};
const _hoisted_7 = {
  class: "tk-notice"
};
const _hoisted_8 = {
  class: "tk-mian-s"
};
const _hoisted_9 = {
  class: "tk-home-tabs-active tk-home-tabs",
  style: {
    "background": "none"
  }
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  class: "icon"
};
const _hoisted_12 = ["src"];
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  class: "name"
};
const _hoisted_15 = {
  class: "tk-home-tabs _gai_home_tabs"
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  class: "name"
};
const _hoisted_18 = {
  class: "lotteryTime"
};
const _hoisted_19 = {
  class: "tk-lottery"
};
const _hoisted_20 = {
  class: "tk-ad"
};
const _hoisted_21 = ["onClick"];
const _hoisted_22 = {
  class: "tk-home"
};
const _hoisted_23 = ["onClick"];
const _hoisted_24 = {
  key: 0
};
const _hoisted_25 = {
  key: 0,
  class: "icon"
};
const _hoisted_26 = ["src"];
const _hoisted_27 = {
  key: 1,
  class: "icon goods"
};
const _hoisted_28 = {
  class: "name"
};
const _hoisted_29 = {
  class: "tk-home"
};
const _hoisted_30 = ["onClick"];
const _hoisted_31 = {
  class: "icon"
};
const _hoisted_32 = ["src"];
const _hoisted_33 = {
  class: "name"
};
const _hoisted_34 = {
  class: "tk-year"
};
const _hoisted_35 = ["onClick"];
const _hoisted_36 = {
  class: "name"
};
const _hoisted_37 = {
  class: "tk-image-type"
};
const _hoisted_38 = {
  class: "tk-image-tyep-box"
};
const _hoisted_39 = ["onClick"];
const _hoisted_40 = {
  key: 1,
  class: "tk-image"
};
const _hoisted_41 = {
  class: "j_wrapper"
};
const _hoisted_42 = ["onClick"];
const _hoisted_43 = ["src"];
const _hoisted_44 = {
  class: "name"
};
const _hoisted_45 = ["onClick"];
const _hoisted_46 = ["src"];
const _hoisted_47 = {
  class: "name"
};
const _hoisted_48 = {
  key: 0,
  class: "tk-major-bottons"
};
const _hoisted_49 = {
  key: 1,
  class: "appdow"
};
const _hoisted_50 = {
  class: "item a"
};
const _hoisted_51 = ["href"];
const _hoisted_52 = {
  class: "item b"
};
const _hoisted_53 = ["href"];
const _hoisted_54 = {
  key: 2,
  class: "tk-major-bottons"
};
const _hoisted_55 = {
  class: "abFoit"
};
const _hoisted_56 = {
  key: 1,
  class: "lq",
  src: _imports_4,
  alt: ""
};
import redbag from "../redbag/charBag.vue";
import record from "../redbag/record.vue";
import { packetReceive, indexRed } from '@/utils/https/wechar';
import shares from '@/components/page/share.vue';
import showPage from '../activity/page.vue';
import { useKeepPage } from '@/router/useKeepPage';
import { iMgetLaunchURLHTML } from "@/utils/https/page";
import loadingTips from '@/components/mode/loading';
import wecharList from '@/components/me/wecharList.vue';
import kt_header from '@/components/page/header.vue';
import lottery from '@/components/page/lottery.vue';
import notiveVue from '@/components/page/notive.vue';
// import { Waterfall } from 'vue-waterfall-plugin-next'
import { Waterfall } from '@/utils/Waterfall';
import { roomList } from '@/utils/https/wechar';
import Dialog from '@/components/mode/message.js';
import { getCurrentInstance, nextTick, ref, watch } from 'vue';
import { pictureCate, years, swiperImg, yearAll, getMessage, config, advertisementList, picSeriesList, lottery_years_color } from '@/utils/https/page';
import { nextLottery, getMessageBadge } from '@/utils/https/https';
import { useStore } from 'vuex';
import { onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { color } from 'echarts';


import { useRouter, useRoute } from 'vue-router';
import { toRefs, computed, onMounted, onActivated } from 'vue';
// let nextLotterys = ()=>{

// }

export default {
  __name: 'index',
  setup(__props) {
    //首页红包
    const handleTg = () => {
      window.location.href = "https://t.me/tk49kj";
    };
    const openLotter = ref(null);
    const chid = ref(null);
    const chidss = ref(null);
    const redBagMoney = ref(0);
    const time = ref(10000);
    const lq = ref(false);
    const redStatus = ref(0);
    const redData = ref(null);
    const handleIndexRed = async () => {
      let {
        data
      } = await indexRed();
      if (data.status == 20000) {
        redStatus.value = data?.data?.red?.status;
        redData.value = data.data.red;
        var timesNew = new Date(data.data.red?.least).getTime();
        var timestamp = new Date().getTime();
        time.value = timesNew - timestamp;
      }
    };
    const handleRedShow = () => {
      // if(lq.value){
      // chid.value.show = true;
      // }
      router.push({
        path: '/national'
      });
    };
    const handleTiemDown = () => {
      console.log(111111);
      lq.value = true;
    };
    //点击打开红包
    const handleOpenRed = () => {
      chid.value.redShow = true;
      chid.value.show = false;
      packetReceives(redData?.value?.id, redData?.value?.from);
    };
    //领取红包接口
    const packetReceives = async (id, from) => {
      console.log({
        id,
        from
      });
      let {
        data
      } = await packetReceive({
        id,
        from
      });
      if (data.status == 20000) {
        chids.value.recordShow = true;
        chid.value.redShow = false;
        chid.value.show = false;
        redBagMoney.value = data.data.money;
      } else {
        chid.value.redShow = false;
        chid.value.show = false;
        Dialog(data.message, '#07c160');
      }
    };
    onBeforeMount(() => {
      handleIndexRed();
    });
    const srarShow = ref(false);
    const seharshow = () => {
      srarShow.value = !srarShow.value;
    };
    const chids = ref(null);
    const fiveFu = ref(false);
    useKeepPage(['indexs']);
    let getImg = () => {
      new Waterfall({
        el: 'j_wrapper',
        //父div
        colmun: 2,
        //几列
        gap: 10 //间隙
      });
    };
    let errorload = () => {
      new Waterfall({
        el: 'j_wrapper',
        //父div
        colmun: 2,
        //几列
        gap: 10 //间隙
      });
    };
    let handleSwipe = index => {
      data.prompt_title = data.noticeData[index].title;
    };
    // 游戏跳转
    const gameTogo = (id, name, status) => {
      if (status) {
        if (id == 5) {
          router.push({
            path: "/guess/index"
          });
        } else if (id == 2) {
          getLaunchURLHTMLs();
        } else {
          router.push({
            path: "/game/list",
            query: {
              id: id,
              title: name
            }
          });
        }
      } else {
        Dialog('暂未开通,尽请期待', '#07c160');
      }

      // window.location.replace("https://m.pg-staging.com/1492288/index.html?ot=I-d2ad95e58fe14303a615b4b7a6d604a8&btt=1&ops=ee2ead6f4e2629c2");
    };
    let getLaunchURLHTMLs = async () => {
      let {
        data
      } = await iMgetLaunchURLHTML();
      if (data.status == 20000) {
        window.location.href = data.data.data;
      } else {
        Dialog(data.message, '#07c160');
      }
      // htmls.value = data.data.data;
    };
    let majorTab = item => {
      //是否显示聊天室
      if (item.name == '聊天室') {
        if (localStorage.getItem('room_info') != null) {
          data.list = JSON.parse(localStorage.getItem('room_info'));
          if (localStorage.getItem('chatToken') != null) {
            data.list.push({
              name: '旧版六合聊天室',
              id: '9'
            });
          }
          if (data.list.length == 1) {
            router.push({
              path: '/wechat',
              query: {
                title: datas[0].name,
                id: datas[0].id
              }
            });
          } else if (data.list.length == 0) {
            Dialog('暂无聊天列表');
          } else {
            gameShow.value = true;
          }
        } else {
          roomLists();
        }
      }
    };
    let handleJinCai = () => {
      router.push({
        path: "/guess/index"
      });
    };
    let handleRefreshs = () => {
      router.push({
        path: "/activity/index"
      });
      // data.transitions = true;
      // configs();
      // nextLotterys();
      // datas.acitveIndexLottery = localStorage.getItem('lotteryType');
      // sessionStorage.removeItem(`picSeriesLists_${datas.acitveIndexLottery}`)
      // picSeriesLists(datas.acitveIndexLottery);
      // data.refreshKey += 1;
      // let c =  setInterval(()=>{
      // clearTimeout(c)
      //     data.transitions = false
      // },1000)
    };
    let selecRoom = item => {
      //选择聊天室
      router.push({
        path: '/wechat',
        query: {
          title: item.name,
          id: item.id
        }
      });
    };
    const router = useRouter();
    let tabsHome = ref(null);
    let tabsHomeItem = ref(null);
    let tkGame = ref([{
      icon: require('../../assets/image/game/game5.png'),
      name: '金币竞猜',
      path: '/guess/index',
      id: 5,
      status: true
    }
    // {icon:require('../../assets/image/game/dz.png'),name:'PG电子',path:'/materials',id:1},
    // {icon:require('../../assets/image/game/qipai.png'),name:'开元棋牌',path:'/statistics',id:3},
    // {icon:require('../../assets/image/game/tiyu.png'),name:'IM体育',path:'/lottery/history',id:4},
    ]);
    let tkHome = reactive([{
      icon: require('../../assets/image/major/2057s.png'),
      name: '开奖直播',
      path: '/lottery'
    }, {
      icon: require('../../assets/image/major/2058s.png'),
      name: '资料大全',
      path: '/materials'
    }, {
      icon: require('../../assets/image/major/2059s.png'),
      name: '资讯统计',
      path: '/statistics'
    }, {
      icon: require('../../assets/image/major/2060s.png'),
      name: '开奖记录',
      path: '/lottery/history'
    }, {
      icon: require('../../assets/image/major/2062s.png'),
      name: '幽默猜测',
      path: '/guess'
    }, {
      icon: require('../../assets/image/major/2063s.png'),
      name: '六合图库',
      path: '/serach'
    }, {
      icon: require('../../assets/image/major/2064s.png'),
      name: '高手论坛',
      path: '/master'
    }, {
      icon: require('../../assets/image/major/2065s.png'),
      name: '工具宝箱',
      path: '/tool'
    }]);
    let tkHomeTabs = reactive([{
      icon: require('../../assets/image/major/provice/2031.png'),
      name: '新澳彩',
      id: '2',
      selectIcon: require('../../assets/image/major/provice/2015.png')
    }, {
      icon: require('../../assets/image/major/provice/2017.png'),
      name: '港彩',
      id: '1',
      selectIcon: require('../../assets/image/major/provice/2028.png')
    }, {
      icon: require('../../assets/image/major/provice/2017.png'),
      name: '快乐八',
      id: '6',
      selectIcon: require('../../assets/image/major/provice/2028.png')
    }, {
      icon: require('../../assets/image/major/provice/2018.png'),
      name: '老澳',
      id: '7',
      selectIcon: require('../../assets/image/major/provice/2029.png')
    }, {
      icon: require('../../assets/image/major/provice/2031.png'),
      name: '天澳彩',
      id: '5',
      selectIcon: require('../../assets/image/major/provice/2015.png')
    }
    // {icon:require('../../assets/image/major/provice/2018.png'),name:'台彩',id:'3',selectIcon:require('../../assets/image/major/provice/2029.png')},
    // {icon:require('../../assets/image/major/provice/2032.png'),name:'新彩',id:'4',selectIcon:require('../../assets/image/major/provice/2019.png')},
    ]);
    let tkHomeTab = reactive([{
      icon: require('../../assets/image/major/provice/2031.png'),
      name: '新澳彩',
      id: '2',
      selectIcon: require('../../assets/image/major/provice/2015.png'),
      color: '#07c160'
    }, {
      icon: require('../../assets/image/major/provice/2017.png'),
      name: '港彩',
      id: '1',
      selectIcon: require('../../assets/image/major/provice/2028.png'),
      color: '#c10707'
    }, {
      icon: require('../../assets/image/major/provice/2031.png'),
      name: '快乐八',
      id: '6',
      selectIcon: require('../../assets/image/major/provice/2015.png'),
      color: '#07c160'
    }, {
      icon: require('../../assets/image/major/provice/2018.png'),
      name: '老澳',
      id: '7',
      selectIcon: require('../../assets/image/major/provice/2029.png')
    }
    // {icon:require('../../assets/image/major/provice/2018.png'),name:'台彩',id:'3',selectIcon:require('../../assets/image/major/provice/2029.png'),color:'#0755c1'},
    // {icon:require('../../assets/image/major/provice/2032.png'),name:'新彩',id:'4',selectIcon:require('../../assets/image/major/provice/2019.png'),color:'#b907c1'},
    ]);
    let data = reactive({
      //请求处理交互数据
      yearList: [],
      pictureData: [],
      swiperImgsrc: [],
      page: 1,
      year: localStorage.getItem('yearone'),
      color: '1',
      gameShow: false,
      noticeData: [],
      noticeCont: '',
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {} : JSON.parse(localStorage.getItem('config')),
      refreshKey: '1',
      transitions: false,
      picturex: [],
      list: [],
      adlistb: [],
      seriesList: [],
      prompt_title: '重要通知',
      resh: true,
      lotteryTypeb: [
        // {icon:require('../../assets/image/major/provice/2017.png'),name:'港彩',id:'1',times:'2023年06月07日  21点30分'},
        // {icon:require('../../assets/image/major/provice/2031.png'),name:'新澳彩',id:'2',times:'2023年06月07日  21点30分'},
        // {icon:require('../../assets/image/major/provice/2018.png'),name:'台彩',id:'3',times:'2023年06月07日  21点30分'},
        // {icon:require('../../assets/image/major/provice/2032.png'),name:'新彩',id:'4',times:'2023年06月07日  21点30分'},
        // {icon:require('../../assets/image/major/provice/2031.png'),name:'澳彩',id:'5',times:'2023年06月07日  21点30分'},
      ]
    });
    let {
      yearList,
      pictureData,
      swiperImgsrc,
      gameShow,
      noticeData,
      noticeCont,
      websiteName,
      refreshKey,
      transitions,
      prompt_title
    } = toRefs(data);
    let datas = reactive({
      show: false,
      acitveIndexLottery: 2,
      //彩票选择
      showsajfajdkfjakldjkd: false
    });
    watch(() => datas.showsajfajdkfjakldjkd, (val, old) => {
      if (datas.showsajfajdkfjakldjkd) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }, {
      deep: true
    });
    let {
      show,
      acitveIndexLottery,
      showsajfajdkfjakldjkd
    } = toRefs(datas);
    //滚动置顶
    const scrollTabs = ref(null);
    const scrollYear = ref(null);
    const wrapper = ref(null);
    let scroolData = reactive({
      offsetTop: 0,
      scrollYear: false,
      yearTop: 0
    });
    let list = reactive([]);
    let roomLists = () => {
      roomList().then(res => {
        list.length = 0;
        let datas = res.data.data;
        data.list = datas;
        if (localStorage.getItem('chatToken') != null) {
          data.list.push({
            name: '旧版六合聊天室',
            id: '9'
          });
        }
        if (datas.length == 1) {
          router.push({
            path: '/wechat',
            query: {
              title: datas[0].name,
              id: datas[0].id
            }
          });
        } else if (datas.length == 0) {
          Dialog('暂无聊天列表');
        } else {
          gameShow.value = true;
        }
        datas.forEach((item, index) => {
          switch (item.id) {
            case 1:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2028.png')
              });
              break;
            case 2:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            case 3:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2029.png')
              });
              break;
            case 4:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2019.png')
              });
              break;
            case 5:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            default:
              break;
          }
        });
        localStorage.setItem('room_info', JSON.stringify(list));
      });
    };
    let getQueryString = name => {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      ;
      return null;
    };
    let offsetTop = ref('1.1rem');
    const {
      proxy
    } = getCurrentInstance();
    let route = useRoute();
    const configInfo = computed(() => {
      fiveFu.value = $store?.state?.config?.five;
      return $store?.state?.config;
    });
    onBeforeMount(() => {
      if (localStorage.getItem('config') == null) {
        configs();
      } else {
        tkGame.value = [{
          icon: require('../../assets/image/game/game5.png'),
          name: '金币竞猜',
          path: '/guess/index',
          id: 5,
          status: true
        }];
        let gameList = JSON.parse($store.state.config.game_platform_collection);
        gameList.forEach((item, index) => {
          tkGame.value.push(item);
        });
      }
      nextLotterys();
      if (localStorage.getItem('device') == null) {
        localStorage.setItem('device', JSON.stringify(route.query));
      }
      if (route.query.j_token) {
        localStorage.setItem('Authorization', 'bearer ' + route.query.j_token);
      }
      if (localStorage.getItem('lotteryTypes') != null) {
        localStorage.setItem('lotteryType', localStorage.getItem('lotteryTypes'));
      }
      proxy.$sayHello();
      if (route.query.download != null) {
        localStorage.setItem('downloadapp', false);
        localStorage.setItem('url', 1);
        tipsData.downloadapp = localStorage.getItem('downloadapp') == null ? 'false' : localStorage.getItem('downloadapp'); //判断是否显示
      } else {
        if (localStorage.getItem('url') == 1) {
          tipsData.downloadapp = 'false';
        } else {
          tipsData.downloadapp = true;
        }
      }
      var date = new Date();
      var yearss = date.getFullYear();
      // if(localStorage.getItem('lotteryType')==null || yearss=='2024'){
      //     year();
      // }else{

      //     data.yearList = JSON.parse(localStorage.getItem('yearColor'));
      // }
      if (localStorage.getItem('lotteryType') != null) {
        datas.acitveIndexLottery = localStorage.getItem('lotteryType');
        year(datas.acitveIndexLottery);
      } else {
        datas.acitveIndexLottery = 2;
        year(datas.acitveIndexLottery);
      }
      nextTick(() => {
        const styles = window.getComputedStyle(tabsHomeItem.value[0]);
        const marginRight = styles.getPropertyValue('margin-right');
        let str = marginRight.slice(0, -2);
        let num = tabsHomeItem.value[0].offsetWidth + parseFloat(str);
        if (datas.acitveIndexLottery == 4) {
          tabsHome.value.scrollLeft = num;
        } else {
          tabsHome.value.scrollLeft = 0;
        }
      });
      if (localStorage.getItem('Authorization') != null) {
        if (!isWithinTimeRange()) {
          getMessageBadge().then(res => {
            if (res.status == 500) {
              return false;
            }
            if (res.data.status != 40000) {
              let msg = res.data.data.msg;
              $store.commit('msgadd', msg);
            }
          });
        }
      }
      function isWithinTimeRange() {
        const currentTime = new Date();
        const hours = currentTime.getHours(); // 获取当前小时
        const minutes = currentTime.getMinutes(); // 获取当前分钟

        // 判断是否在 21:20 到 21:50 之间
        if (hours === 21 && minutes >= 20 || hours === 21 && minutes <= 50) {
          return true;
        }
        return false;
      }
      advertisementLists(datas.acitveIndexLottery);
      picSeriesLists(datas.acitveIndexLottery);
      let swiperImgss = JSON.parse(localStorage.getItem('swiperImg'));
      if (swiperImgss && swiperImgss.expires > Date.now()) {
        data.swiperImgsrc.push(...swiperImgss.value);
        if (localStorage.getItem('notice') != null) {
          data.noticeCont = localStorage.getItem('notice');
        }
      } else {
        swiperImgs();
      }
      // if(localStorage.getItem('announcement') !=null){
      //     let datas = JSON.parse(localStorage.getItem('announcement'))
      //     if (datas&&datas.expires > Date.now()) {
      //         getMessages(1,1)
      //     }
      // }
      // if(JSON.parse(localStorage.getItem('announcement')) == null){
      getMessages(1, 1);
      // }
    });
    onMounted(() => {
      let picture = JSON.parse(localStorage.getItem('pictureData')) == null ? [] : JSON.parse(localStorage.getItem('pictureData'));
      picture.forEach((item, index) => {
        if (item.expires < Date.now()) {
          data.pictureData.length = 0;
          picture = [];
          localStorage.removeItem('pictureData');
        }
        if (item.lottery == localStorage.getItem('lotteryType')) {
          data.pictureData.push(...item.data);
        } else {
          loading.value = false;
        }
      });

      // 获取 DOM 元素
      scroolData.yearTop = scrollYear.value?.offsetTop;
      // 监听滚动事件
      window.addEventListener('scroll', handleScroll);
      const colseTiem = setInterval(() => {
        let yearList = JSON.parse(sessionStorage.getItem('yearList'));
        if (yearList) {
          yearList.forEach((item, index) => {
            if (item.lotteryType == datas.acitveIndexLottery) {
              localStorage.setItem('yearone', item.years[0]);
              localStorage.setItem('year', JSON.stringify(item.years));
            }
          });
          clearInterval(colseTiem);
        }
      }, 1000);
    });
    let handleImageType = item => {
      router.push({
        path: `/tuku`,
        query: {
          id: item.id,
          name: item.name,
          lottery: datas.acitveIndexLottery
        }
      });
    };
    let handleImageMore = () => {
      router.push({
        path: `/xilie`,
        query: {
          lottery: datas.acitveIndexLottery
        }
      });
    };
    let nextLotterys = () => {
      nextLottery().then(res => {
        //获取下一期的开奖数据
        if (res.data.status == 20000) {
          let datas = res.data.data;
          data.lotteryTypeb = datas;
          let datab = {
            datas,
            tiems: Date.now() + 1000 * 60 * 30
          };
          sessionStorage.setItem('nextLottery', JSON.stringify(datab));
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const onLoad = () => {
      if (datas.acitveIndexLottery == 5) {
        finished.value = true;
      } else {
        picture(data.year, data.color, data.page, datas.acitveIndexLottery);
        data.page++;
      }
    };
    const onRefresh = () => {
      data.resh = false;
      proxy.$sayHello();
      datas.acitveIndexLottery = localStorage.getItem('lotteryType') == null ? 2 : localStorage.getItem('lotteryType');
      year(datas.acitveIndexLottery);
      nextLotterys();
      // 清空列表数据
      data.page = 1;
      // data.yearList.length = 0;
      data.pictureData.length = 0;
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      swiperImgs();
      onLoad();
      data.refreshKey += 1;
    };

    //接口请求区域

    let advertisementLists = lotteryType => {
      let articleData = JSON.parse(sessionStorage.getItem(`adId_${lotteryType}`));
      if (articleData == null) {
        advertisementList({
          type: 8,
          lotteryType
        }).then(res => {
          if (res.data.status == 20000) {
            let datas = res.data.data;
            data.adlistb = datas;
            sessionStorage.setItem(`adId_${lotteryType}`, JSON.stringify(datas));
          }
        });
      } else {
        data.adlistb = articleData;
      }
    };
    let picSeriesLists = lotteryType => {
      data.seriesList.length = 0;
      let articleData = JSON.parse(sessionStorage.getItem(`picSeriesLists_${lotteryType}`));
      if (articleData == null) {
        picSeriesList({
          lotteryType
        }).then(res => {
          if (res.data.status == 20000) {
            let datas = res.data.data;
            datas.forEach((item, index) => {
              if (index < 3) {
                data.seriesList.push(item);
              }
            });
            sessionStorage.setItem(`picSeriesLists_${lotteryType}`, JSON.stringify(datas));
          }
        });
      } else {
        articleData.forEach((item, index) => {
          if (index < 3) {
            data.seriesList.push(item);
          }
        });
      }
    };
    let configs = () => {
      //获取公共配置
      config().then(res => {
        if (res == undefined) return false;
        let datas = res.data.data;
        data.websiteName = datas;
        document.title = datas.name;
        var head = document.getElementsByTagName('head')[0];
        // 获取当前 favicon 元素
        var favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
        favicon.type = 'image/x-icon';
        favicon.rel = 'shortcut icon';

        // 设置新的 favicon 地址
        favicon.href = hasHttpOrHttps(datas.ad_img_url) ? datas.ad_img_url + datas.logo : "https://" + datas.ad_img_url + datas.logo;

        // 如果当前 head 标签中不存在 favicon 元素，则将新的 favicon 添加到 head 标签中
        if (!document.querySelector("link[rel*='icon']")) {
          head.appendChild(favicon);
        }
        let gameList = JSON.parse(datas.game_platform_collection);
        tkGame.value = [{
          icon: require('../../assets/image/game/game5.png'),
          name: '金币竞猜',
          path: '/guess/index',
          id: 5,
          status: true
        }];
        gameList.forEach((item, index) => {
          tkGame.value.push(item);
        });
        localStorage.setItem('config', JSON.stringify(datas));
      });
    };
    function hasHttpOrHttps(url) {
      const regex = /^https?:\/\//; // 匹配 http:// 或 https://
      return regex.test(url);
    }
    let picture = (year, color, page, lottery) => {
      //图片请求
      finished.value = false;
      if (page == 1 && data.resh) {
        loadingTips();
      }
      pictureCate({
        year: year,
        color: color,
        page: page,
        lotteryType: lottery
      }).then(res => {
        if (page == 1 && data.resh) {
          loadingTips('close');
        }
        if (res == undefined) return false;
        let datas = res.data.data.data;
        refreshing.value = false;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.pictureData.push(...datas);
          data.picturex.push({
            lottery,
            expires: Date.now() + 1000 * 60 * 1,
            data: [...data.pictureData]
          });
          let dd = arrayUnique(data.picturex, 'lottery');
          localStorage.setItem('pictureData', JSON.stringify(dd));
          new Waterfall({
            el: 'j_wrapper',
            //父div
            colmun: 2,
            //几列
            gap: 10 //间隙
          });
        }
      });
    };
    let arrayUnique = (arr, name) => {
      let hash = {};
      return arr.reduce((acc, cru, index) => {
        if (!hash[cru[name]]) {
          hash[cru[name]] = {
            index: acc.length
          };
          acc.push(cru);
        } else {
          acc.splice(hash[cru[name]]['index'], 1, cru);
        }
        return acc;
      }, []);
    };
    let year = async acitveIndexLottery => {
      //获取年份
      data.yearList.length = 0;
      // if ( sessionStorage.getItem('lotteryYears') == null || sessionStorage.getItem('lotteryYears')== 'undefined' ) {
      let ress = await lottery_years_color();
      let lotteryYears = ress.data.data;
      sessionStorage.setItem('lotteryYears', JSON.stringify(lotteryYears));
      lotteryYears.forEach(item => {
        if (item.lotteryType == acitveIndexLottery) {
          data.yearList.push(...item.list);
        }
      });
      // } else {
      //     let lotteryYears = JSON.parse(sessionStorage.getItem('lotteryYears'));
      //     lotteryYears.forEach(item=>{
      //         if(item.lotteryType == acitveIndexLottery) {
      //             data.yearList.push(...item.list);
      //         }
      //     })
      // }
    };
    let yearAlls = () => {
      yearAll().then(res => {
        if (res == undefined) return false;
        let data = res.data.data;
        let reversed = data.reverse();
        localStorage.setItem('year', JSON.stringify(reversed));
        localStorage.setItem('yearone', reversed[0]);
      });
    };
    let swiperImgs = () => {
      //轮播图
      swiperImg().then(res => {
        if (res.data.status == 20000) {
          let datas = res.data.data.ad_list;
          data.swiperImgsrc.push(...datas);
          let datac = {
            value: datas,
            expires: Date.now() + 1000 * 60 * 60 // 1 hour from now
          };
          localStorage.setItem('swiperImg', JSON.stringify(datac));
        }
      });
    };
    function stripHtmlAndEntities(html) {
      // 去除HTML标签
      const regex = /(<([^>]+)>)/ig;
      const plainText = html.replace(regex, '');

      // 去除HTML实体
      const entitiesRegex = /&([a-z]+|#\d+);/ig;
      const textWithoutEntities = plainText.replace(entitiesRegex, '');
      return textWithoutEntities;
    }
    let getMessages = (type, page) => {
      //首页公告

      getMessage({
        type: type,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datasc = res.data.data.list;
        if (datasc.length == 0) {
          datas.showsajfajdkfjakldjkd = false;
          data.noticeCont = ' ';
          // localStorage.setItem('notice', data.noticeCont)
        } else {
          datasc.forEach((item, index) => {
            if (index < 10) {
              item.created_at = replaceStr1(item.created_at);
              data.noticeCont += ' ' + stripHtmlAndEntities(item.content);
              data.noticeData.push(item);
            }
          });
          data.prompt_title = data.noticeData[0].title;
          if (localStorage.getItem('announcement') != null) {
            let datac = localStorage.getItem('announcement');
            var date = new Date();
            let day = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`;
            if (datac == day) {
              if (configInfo.value.five == 1) {
                chids.value.show = true;
              }
              datas.showsajfajdkfjakldjkd = false;
            } else {
              datas.showsajfajdkfjakldjkd = true;
            }
          } else {
            datas.showsajfajdkfjakldjkd = true;
          }
          // datas.shows = localStorage.getItem('announcement')==null?true:false//判断是否显示公告
          localStorage.setItem('notice', data.noticeCont);
        }
      });
    };
    const replaceStr1 = str => {
      const strAry = str.split('-');
      return strAry.join('/');
    };
    const handleScroll = () => {
      //页面滚动的时候用于置顶判断

      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop - scroolData.yearTop >= 0) {
        scroolData.scrollYear = true;
      } else {
        scroolData.scrollYear = false;
      }
    };
    //功能区域

    const $store = useStore(); //获取vuex

    let yearColor = item => {
      let lottery = {
        lottery: datas.acitveIndexLottery,
        ...item
      };
      router.push({
        path: '/serach',
        query: lottery
      });
    };
    let onSehar = () => {
      datas.show = true;
    };
    const lotteryConetn = index => {
      //元素距离屏幕左边的距离 - 屏幕宽度的一半 + 元素宽度的一半 表示点击元素时变化的距离
      let w = tabsHome.value.offsetWidth;
      let l = tabsHomeItem.value[index].offsetLeft;
      let cw = tabsHomeItem.value[index].offsetWidth;
      let val = l - w / 2 + cw / 2;
      if (val > 0) {
        tabsHome.value.scrollLeft = val;
      } else {
        tabsHome.value.scrollLeft = 0;
      }
    };
    let lotteryFunSelect = (index, id) => {
      //彩票选择
      let yearList = JSON.parse(sessionStorage.getItem('yearList'));
      yearList.forEach((item, index) => {
        if (item.lotteryType == id) {
          localStorage.setItem('yearone', item.years[0]);
          data.year = item.years[0];
          localStorage.setItem('year', JSON.stringify(item.years));
        }
      });
      const styles = window.getComputedStyle(tabsHomeItem.value[0]);
      const marginRight = styles.getPropertyValue('margin-right');
      let str = marginRight.slice(0, -2);
      let num = tabsHomeItem.value[0].offsetWidth + parseFloat(str);
      picSeriesLists(id);
      year(id);
      lotteryConetn(index);
      advertisementLists(id);
      localStorage.setItem('lotteryType', id);
      datas.acitveIndexLottery = id;
      data.pictureData.length = 0; //初始化
      data.page = 1;
      ; //初始化
      $store.commit('upLotteryCont', id);
      $store.commit('exidRelease', true);
      localStorage.setItem('discovery', true);
      let picture = JSON.parse(localStorage.getItem('pictureData')) == null ? [] : JSON.parse(localStorage.getItem('pictureData'));
      picture.forEach((item, index) => {
        if (item.expires < Date.now()) {
          data.pictureData.length = 0;
          picture = [];
          localStorage.removeItem('pictureData');
        }
      });
      let ress;
      if (picture.length == 0) {
        ress = -1;
      } else {
        ress = picture.findIndex(ev => {
          return ev.lottery == id;
        });
      }
      if (picture.length != 0 && ress != -1) {
        picture.forEach((item, index) => {
          if (item.lottery == id) {
            data.pictureData.push(...item.data);
          }
        });
      } else {
        finished.value = false;
        loading.value = true;
        onLoad();
      }
    };
    let tipsData = reactive({
      //弹窗提示
      downloadapp: true
    });
    let colse = () => {
      //关闭app下载
      // localStorage.setItem('downloadapp',false);
      tipsData.downloadapp = 'false';
      offsetTop.value = '1.1rem';
    };
    let promptColse = () => {
      //关闭公告提示
      datas.showsajfajdkfjakldjkd = false;
      if (configInfo.value.five == 1) {
        chids.value.show = true;
      }
    };
    let prompt = () => {
      //
      var date = new Date();
      let day = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`;
      localStorage.setItem('announcement', day);
      datas.showsajfajdkfjakldjkd = false;
      if (configInfo.value.five == 1) {
        chids.value.show = true;
      }
    };
    //跳转页面
    let principalJump = item => {
      if (datas.acitveIndexLottery == 5) {
        if (item == '/lottery/history' || item == '/tool' || item == "/lottery") {
          router.push({
            path: item,
            query: {
              lottery: datas.acitveIndexLottery
            }
          });
        } else {
          Dialog('暂无数据');
        }
      } else {
        router.push({
          path: item,
          query: {
            lottery: datas.acitveIndexLottery
          }
        });
      }
    };
    let serachColorImg = () => {
      router.push({
        path: 'serach',
        query: {
          lottery: datas.acitveIndexLottery
        }
      });
    };
    let pictureJump = item => {
      if (item.is_ad) {
        let device = JSON.parse(localStorage.getItem("device"));
        if (device && device.system) {
          Android.openWithBrowser(item.ad_url);
        } else {
          window.location.href = item.ad_url;
        }
      } else {
        localStorage.setItem('comment', true);
        router.push({
          path: `/gallery`,
          query: {
            color: item.color,
            year: item.year,
            id: item.pictureTypeId,
            title: item.pictureName,
            image: item.pictureUrl,
            pictureId: item.pictureId,
            lottery: datas.acitveIndexLottery
          }
        });
      }
    };
    let numSx = computed(() => item => {
      //号码的属性
      if (data.lotteryTypeb.length != 0) {
        let openLottery = data.lotteryTypeb;
        let str = '';
        openLottery.forEach(items => {
          if (items.lotteryType == item) {
            let arr = items.lotteryTime.split("-");
            str = `${arr[1]}月${arr[2]}日开奖`;
          }
        });
        return str;
      } else {
        return '';
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    onActivated(() => {
      $store.commit('handleSearch', true);
      window.scrollTo(0, 0);
      //   if($store.state.lotteryType!=null){
      //     datas.acitveIndexLottery = $store.state.lotteryType;
      //   }
    });
    const handleAdJump = item => {
      let device = JSON.parse(localStorage.getItem("device"));
      if (device && device.system) {
        Android.openWithBrowser(item.ad_url);
      } else {
        window.location.href = item.ad_url;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      const _component_swipers = _resolveComponent("swipers");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_sticky = _resolveComponent("van-sticky");
      const _component_empty = _resolveComponent("empty");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_share = _resolveComponent("share");
      const _component_van_count_down = _resolveComponent("van-count-down");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(kt_header, {
        onSehar: _unref(onSehar),
        headLogo: _unref(websiteName).wide_logo,
        config: _unref(websiteName),
        onColse: _unref(colse),
        onSerach: _unref(serachColorImg),
        onSeharshow: seharshow
      }, null, 8, ["onSehar", "headLogo", "config", "onColse", "onSerach"]), _createVNode(_component_van_overlay, {
        show: _unref(showsajfajdkfjakldjkd),
        "z-index": "999",
        onClick: _cache[1] || (_cache[1] = $event => _isRef(showsajfajdkfjakldjkd) ? showsajfajdkfjakldjkd.value = false : showsajfajdkfjakldjkd = false),
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrappersss",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_unref(prompt_title)), 1), _createVNode(_component_van_swipe, {
          class: "my-swipe",
          autoplay: 0,
          "indicator-color": "#07c160",
          onChange: _unref(handleSwipe)
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(noticeData), (item, index) => {
            return _openBlock(), _createBlock(_component_van_swipe_item, {
              key: index
            }, {
              default: _withCtx(() => [_createElementVNode("div", {
                class: "content",
                innerHTML: item.content
              }, null, 8, _hoisted_3)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }, 8, ["onChange"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_botton, {
          name: "关闭",
          color: "#999",
          bgcolor: "transparent",
          onClick: _withModifiers(_unref(promptColse), ["stop"])
        }, null, 8, ["onClick"]), _cache[21] || (_cache[21] = _createElementVNode("div", {
          class: "ccc"
        }, null, -1)), _createVNode(_component_botton, {
          bgcolor: "transparent",
          color: "#07c160",
          style: {
            "margin-left": "10px"
          },
          name: "不再提示",
          onClick: _withModifiers(_unref(prompt), ["stop"])
        }, null, 8, ["onClick"])])])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_pull_refresh, {
        modelValue: refreshing.value,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => refreshing.value = $event),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: loading.value,
          "onUpdate:loading": _cache[14] || (_cache[14] = $event => loading.value = $event),
          finished: finished.value,
          "finished-text": "",
          onLoad: onLoad
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_swipers, {
            swiperImg: _unref(swiperImgsrc)
          }, null, 8, ["swiperImg"])]), _createElementVNode("div", _hoisted_7, [_createVNode(notiveVue, {
            notiveInfo: _unref(noticeCont)
          }, null, 8, ["notiveInfo"])]), _createElementVNode("div", _hoisted_8, [_cache[23] || (_cache[23] = _createElementVNode("div", {
            class: "h3"
          }, null, -1)), _createElementVNode("div", _hoisted_9, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tkHomeTab), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              class: _normalizeClass(_unref(acitveIndexLottery) == item.id ? 'active' : ''),
              key: index,
              onClick: $event => _unref(lotteryFunSelect)(index, item.id),
              style: _normalizeStyle({
                'border': '1px solid ' + item.color,
                'background': _unref(acitveIndexLottery) == item.id ? item.color + '!important' : '#fff'
              })
            }, [_createElementVNode("div", _hoisted_11, [_unref(acitveIndexLottery) == item.id ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: item.selectIcon,
              alt: ""
            }, null, 8, _hoisted_12)) : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: item.icon,
              alt: ""
            }, null, 8, _hoisted_13))]), _createElementVNode("div", _hoisted_14, _toDisplayString(item.name), 1)], 14, _hoisted_10);
          }), 128))])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", {
            class: "home_tabs",
            ref_key: "tabsHome",
            ref: tabsHome
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tkHomeTabs), (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              ref_for: true,
              ref_key: "tabsHomeItem",
              ref: tabsHomeItem,
              class: _normalizeClass(_unref(acitveIndexLottery) == item.id ? 'item active' : 'item'),
              key: index,
              onClick: $event => _unref(lotteryFunSelect)(index, item.id)
            }, [_createElementVNode("div", _hoisted_17, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(numSx)(item.id)), 1)], 10, _hoisted_16);
          }), 128))], 512)]), _createElementVNode("div", _hoisted_19, [(_openBlock(), _createBlock(lottery, {
            lottery: _unref(acitveIndexLottery),
            key: _unref(refreshKey)
          }, null, 8, ["lottery"]))]), _createElementVNode("div", _hoisted_20, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).adlistb, (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              class: "",
              key: index
            }, [_createElementVNode("div", {
              onClick: $event => handleAdJump(item)
            }, _toDisplayString(item.title), 9, _hoisted_21)]);
          }), 128))])]), _cache[24] || (_cache[24] = _createElementVNode("div", {
            class: "h"
          }, null, -1)), _createElementVNode("div", _hoisted_22, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tkGame), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              key: index,
              class: "flex-between",
              onClick: $event => gameTogo(item.id, item.name, item.status)
            }, [item.status ? (_openBlock(), _createElementBlock("div", _hoisted_24, [index != 3 ? (_openBlock(), _createElementBlock("div", _hoisted_25, [_createElementVNode("img", {
              src: './game/game' + item.id + '.png',
              alt: ""
            }, null, 8, _hoisted_26)])) : (_openBlock(), _createElementBlock("div", _hoisted_27, _cache[22] || (_cache[22] = [_createElementVNode("div", {
              class: "goods_f"
            }, null, -1)]))), _createElementVNode("span", _hoisted_28, _toDisplayString(item.name), 1)])) : _createCommentVNode("", true)], 8, _hoisted_23);
          }), 128))])]), _cache[25] || (_cache[25] = _createElementVNode("div", {
            class: "h"
          }, null, -1)), _createElementVNode("div", _hoisted_29, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tkHome), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              key: index,
              class: "flex-between",
              onClick: $event => _unref(principalJump)(item.path)
            }, [_createElementVNode("div", _hoisted_31, [_createElementVNode("img", {
              src: item.icon,
              alt: ""
            }, null, 8, _hoisted_32)]), _createElementVNode("span", _hoisted_33, _toDisplayString(item.name), 1)], 8, _hoisted_30);
          }), 128))])]), _cache[26] || (_cache[26] = _createElementVNode("div", {
            class: "h"
          }, null, -1)), _createVNode(_component_van_sticky, {
            "offset-top": _unref(offsetTop),
            position: "top"
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              class: _normalizeClass('tk-image-year'),
              ref_key: "scrollYear",
              ref: scrollYear
            }, [_createElementVNode("div", _hoisted_34, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(yearList), (item, index) => {
              return _openBlock(), _createElementBlock("div", {
                class: _normalizeClass(item.color == 1 ? 'tk-year-item' : 'tk-year-item color'),
                key: index,
                onClick: $event => _unref(yearColor)(item)
              }, [_createElementVNode("div", {
                class: _normalizeClass(item.color == 1 ? 'photo' : 'photos photo')
              }, [_createVNode(_component_van_icon, {
                name: "photo-o"
              })], 2), _createElementVNode("div", _hoisted_36, _toDisplayString(item.name), 1)], 10, _hoisted_35);
            }), 128))])], 512), _createElementVNode("div", _hoisted_37, [_createElementVNode("div", _hoisted_38, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).seriesList, (item, index) => {
              return _openBlock(), _createElementBlock("div", {
                class: "tk-type-item",
                key: index,
                onClick: $event => _unref(handleImageType)(item)
              }, _toDisplayString(item.name), 9, _hoisted_39);
            }), 128))]), _unref(data).seriesList.length != 0 ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "tk-type-more",
              onClick: _cache[2] || (_cache[2] = (...args) => _unref(handleImageMore) && _unref(handleImageMore)(...args))
            }, "更多")) : _createCommentVNode("", true)])]),
            _: 1
          }, 8, ["offset-top"])])]), _unref(acitveIndexLottery) == 5 ? (_openBlock(), _createBlock(_component_empty, {
            key: 0,
            style: {
              "margin-top": "30px"
            }
          })) : (_openBlock(), _createElementBlock("div", _hoisted_40, [_createElementVNode("div", {
            class: "tk-img-wrapper",
            ref_key: "wrapper",
            ref: wrapper
          }, [_createElementVNode("div", _hoisted_41, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pictureData), (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "item",
              key: index
            }, [item.is_ad ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "card wrapper",
              onClick: $event => _unref(pictureJump)(item)
            }, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
              style: {},
              src: item.ad_image,
              onLoad: _cache[3] || (_cache[3] = $event => _unref(getImg)())
            }, {
              error: _withCtx(() => [_createElementVNode("img", {
                src: _ctx.$httpsUrl + _unref(websiteName).notfind_image,
                alt: ""
              }, null, 8, _hoisted_43)]),
              _: 2
            }, 1032, ["src"])), [[_directive_lazy, item.ad_image]]), _createElementVNode("div", _hoisted_44, _toDisplayString(item.title), 1), _cache[27] || (_cache[27] = _createElementVNode("div", {
              class: "advss"
            }, "广告", -1))], 8, _hoisted_42)) : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "card wrapper",
              onClick: $event => _unref(pictureJump)(item)
            }, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
              src: item.pictureUrl,
              onLoad: _cache[12] || (_cache[12] = $event => _unref(getImg)()),
              onError: _cache[13] || (_cache[13] = $event => _unref(errorload)())
            }, {
              error: _withCtx(() => [_unref(acitveIndexLottery) == 5 ? _withDirectives((_openBlock(), _createBlock(_component_van_image, {
                key: 0,
                src: item.pictureUrlOther,
                onLoad: _cache[8] || (_cache[8] = $event => _unref(getImg)()),
                onError: _cache[9] || (_cache[9] = $event => _unref(errorload)())
              }, {
                error: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
                  src: item.previousPictureUrl,
                  onLoad: _cache[6] || (_cache[6] = $event => _unref(getImg)()),
                  onError: _cache[7] || (_cache[7] = $event => _unref(errorload)())
                }, {
                  error: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
                    src: item.previousPictureUrlOther,
                    onLoad: _cache[4] || (_cache[4] = $event => _unref(getImg)()),
                    onError: _cache[5] || (_cache[5] = $event => _unref(errorload)())
                  }, {
                    error: _withCtx(() => _cache[28] || (_cache[28] = [_createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)])),
                    _: 2
                  }, 1032, ["src"])), [[_directive_lazy, item.previousPictureUrl]])]),
                  _: 2
                }, 1032, ["src"])), [[_directive_lazy, item.previousPictureUrl]])]),
                _: 2
              }, 1032, ["src"])), [[_directive_lazy, item.pictureUrlOther]]) : _withDirectives((_openBlock(), _createBlock(_component_van_image, {
                key: 1,
                src: item.previousPictureUrl,
                onLoad: _cache[10] || (_cache[10] = $event => _unref(getImg)()),
                onError: _cache[11] || (_cache[11] = $event => _unref(errorload)())
              }, {
                error: _withCtx(() => [_createElementVNode("img", {
                  src: _ctx.$httpsUrl + _unref(websiteName).notfind_image,
                  alt: ""
                }, null, 8, _hoisted_46)]),
                _: 2
              }, 1032, ["src"])), [[_directive_lazy, item.pictureUrlOther]])]),
              _: 2
            }, 1032, ["src"])), [[_directive_lazy, item.pictureUrl]]), _createElementVNode("div", _hoisted_47, _toDisplayString(item.pictureName), 1)], 8, _hoisted_45))]);
          }), 128))])], 512)]))]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(wecharList, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[16] || (_cache[16] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        onSelecRoom: _unref(selecRoom),
        list: _unref(data).list
      }, null, 8, ["show", "onSelecRoom", "list"]), _createVNode(_component_share, {
        shows: _unref(show),
        "onUpdate:shows": _cache[17] || (_cache[17] = $event => _isRef(show) ? show.value = $event : show = $event)
      }, null, 8, ["shows"]), fiveFu.value ? (_openBlock(), _createElementBlock("div", _hoisted_48, [_createElementVNode("div", {
        class: "tk-major-image",
        style: {
          "bottom": "35%",
          "width": "50px",
          "height": "57px"
        },
        onClick: _cache[18] || (_cache[18] = (...args) => _unref(handleRefreshs) && _unref(handleRefreshs)(...args))
      }, _cache[29] || (_cache[29] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)]))])) : _createCommentVNode("", true), _unref(tipsData).downloadapp != 'false' ? (_openBlock(), _createElementBlock("div", _hoisted_49, [_createElementVNode("div", _hoisted_50, [_createElementVNode("a", {
        href: _unref(websiteName).download_url
      }, "下载APP", 8, _hoisted_51)]), _createElementVNode("div", _hoisted_52, [_createElementVNode("a", {
        href: _unref(websiteName).download_url
      }, "看图更爽", 8, _hoisted_53)]), _createElementVNode("div", {
        class: "item c",
        onClick: _cache[19] || (_cache[19] = (...args) => _unref(colse) && _unref(colse)(...args))
      }, [_cache[30] || (_cache[30] = _createElementVNode("span", null, "更爽...", -1)), _createVNode(_component_van_icon, {
        name: "cross",
        color: "#fff"
      })])])) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "tk-major-bottons tk-major-bottonssss"
      }, [_createElementVNode("div", {
        class: "tk-major-image",
        style: {
          "width": "40px",
          "height": "40px",
          "border-radius": "100%",
          "overflow": "hidden",
          "bottom": "45%"
        },
        onClick: handleTg
      }, _cache[31] || (_cache[31] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)]))]), redStatus.value == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_54, [_createElementVNode("div", {
        class: "tk-major-image",
        onClick: handleRedShow
      }, [_cache[32] || (_cache[32] = _createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_55, [!lq.value ? (_openBlock(), _createBlock(_component_van_count_down, {
        key: 0,
        time: time.value,
        onFinish: handleTiemDown
      }, null, 8, ["time"])) : _createCommentVNode("", true), lq.value ? (_openBlock(), _createElementBlock("img", _hoisted_56)) : _createCommentVNode("", true)])])])) : _createCommentVNode("", true), _createVNode(redbag, {
        ref_key: "chid",
        ref: chid,
        onOpenRed: handleOpenRed
      }, null, 512), _createVNode(showPage, {
        ref_key: "chidss",
        ref: chidss
      }, null, 512), _createVNode(record, {
        moeny: redBagMoney.value,
        ref_key: "chids",
        ref: chids
      }, null, 8, ["moeny"]), _createVNode(shares, {
        shows: srarShow.value,
        "onUpdate:shows": _cache[20] || (_cache[20] = $event => srarShow.value = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};