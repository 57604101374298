import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue";
import _imports_0 from '../../assets/image/imagess.png';
import _imports_1 from '../../assets/image/zanons.png';
import _imports_2 from '../../assets/image/zans.png';
import _imports_3 from '../../assets/image/colleons.png';
import _imports_4 from '../../assets/image/colles.png';
import _imports_5 from '../../assets/image/comments.png';
import _imports_6 from '../../assets/image/fencomments.png';
import _imports_7 from '../../assets/image/dashang.png';
const _hoisted_1 = {
  class: "tk-box-comment flex-between"
};
const _hoisted_2 = {
  class: "tk-comment-info"
};
const _hoisted_3 = {
  key: 0,
  src: _imports_1,
  alt: ""
};
const _hoisted_4 = {
  key: 1,
  src: _imports_2,
  alt: ""
};
const _hoisted_5 = {
  key: 0,
  src: _imports_3,
  alt: ""
};
const _hoisted_6 = {
  key: 1,
  src: _imports_4,
  alt: ""
};
import Dialog from '@/components/mode/message';
import topForn from "@/components/footerEarninges.vue";
import { ref } from "vue";

import { useRouter } from 'vue-router';
import { reactive, toRefs, onMounted } from 'vue';
export default {
  __name: 'comment',
  props: {
    dataInfo: {
      type: Object,
      default: {}
    },
    typeId: {
      type: String,
      default: ''
    },
    corpusTypeId: {
      type: String,
      default: ''
    },
    userInfo: {
      type: Object,
      default: {}
    },
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['majoFun', 'hanladClick'],
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    const emits = __emit;
    const chid = ref(null);
    const props = __props;
    let scrollTabs = ref(null);
    let scroolData = reactive({
      offsetTop: 0
    });
    let {
      offsetTop
    } = toRefs(scroolData);
    onMounted(() => {
      // 获取 DOM 元素
      scroolData.offsetTop = scrollTabs.value?.clientHeight;
    });

    //功能区域
    let majoFun = index => {
      sessionStorage.setItem('collect', 'collect');
      emits('majoFun', index);
    };
    const hanldeShowDs = isIncome => {
      if (isIncome == 0) {
        Dialog('该用户未开通打赏功能', '#07c160');
      } else {
        chid.value.show = true;
      }
    };
    let commentOpne = () => {
      if (props.typeId == '') {
        emits('hanladClick');
      } else {
        router.push({
          path: '/materials/list/comment',
          query: {
            typeId: props.typeId,
            id: props.dataInfo.id,
            corpusTypeId: props.corpusTypeId
          }
        });
      }
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "tk-main-comment ccc",
        ref_key: "scrollTabs",
        ref: scrollTabs
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "tk-comment-splik",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(commentOpne) && _unref(commentOpne)(...args))
      }, "发布准确解料，抽送8888元彩金~"), _createElementVNode("div", _hoisted_2, [__props.typeId == 1 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-info-item",
        onClick: _cache[1] || (_cache[1] = $event => _unref(majoFun)(4))
      }, _cache[7] || (_cache[7] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "查看图解", -1)]))) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "tk-info-item",
        onClick: _cache[2] || (_cache[2] = $event => _unref(majoFun)(0))
      }, [__props.dataInfo.isFollow || __props.dataInfo.follow ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4)), _createElementVNode("span", null, _toDisplayString(__props.dataInfo.thumbUpCount), 1)]), __props.typeId == '11' || __props.typeId == '1' ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "tk-info-item",
        onClick: _cache[3] || (_cache[3] = $event => _unref(majoFun)(3))
      }, [__props.dataInfo.isCollect || __props.dataInfo.collect ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : (_openBlock(), _createElementBlock("img", _hoisted_6)), _createElementVNode("span", null, _toDisplayString(__props.dataInfo.collectCount), 1)])) : (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: "tk-info-item",
        onClick: _cache[4] || (_cache[4] = $event => _unref(majoFun)(1))
      }, [_cache[8] || (_cache[8] = _createElementVNode("img", {
        src: _imports_5,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(__props.dataInfo.commentCount), 1)])), __props.type == '' ? (_openBlock(), _createElementBlock("div", {
        key: 3,
        class: "tk-info-item",
        onClick: _cache[5] || (_cache[5] = $event => _unref(majoFun)(2))
      }, [_cache[9] || (_cache[9] = _createElementVNode("img", {
        src: _imports_6,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(__props.dataInfo.commentCount), 1)])) : (_openBlock(), _createElementBlock("div", {
        key: 4,
        class: "tk-info-item cccb",
        onClick: _cache[6] || (_cache[6] = $event => hanldeShowDs(__props.userInfo.isIncome))
      }, [_createElementVNode("img", {
        class: _normalizeClass(__props.userInfo.isIncome == 1 ? '' : 'Imagesf'),
        src: _imports_7,
        alt: ""
      }, null, 2)]))])]), _createVNode(topForn, {
        ref_key: "chid",
        ref: chid,
        data: __props.dataInfo,
        userInfo: __props.userInfo,
        type: "type"
      }, null, 8, ["data", "userInfo"])], 512);
    };
  }
};