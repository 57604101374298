import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/date.png';
const _hoisted_1 = {
  class: "tk-main-bagSearch"
};
import picker from '@/components/picker.vue';
import { mysteryHistory } from '@/utils/https/https';
import { onBeforeMount } from 'vue';

import { reactive, ref } from 'vue';
export default {
  __name: 'bagSearch',
  setup(__props) {
    let data = reactive({
      show: false,
      lotteryType: JSON.parse(localStorage.getItem('lotteryType')),
      year: JSON.parse(localStorage.getItem('yearone')),
      history: []
    });
    onBeforeMount(() => {
      mysteryHistorys(data.year, data.lotteryType);
    });
    let mysteryHistorys = (year, lotteryType) => {
      mysteryHistory({
        year: year,
        lotteryType: lotteryType
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data;
        data.history.push(...datas);
      });
    };
    let onConfirm = item => {
      data.history.length = 0;
      data.year = item;
      mysteryHistorys(data.year, data.lotteryType);
    };
    let showYeara = () => {
      data.show = true;
    };
    const activeNames = ref(['1']);
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_collapse_item = _resolveComponent("van-collapse-item");
      const _component_van_collapse = _resolveComponent("van-collapse");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "锦囊记录"
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(showYeara) && _unref(showYeara)(...args))
        }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_collapse, {
        modelValue: _unref(activeNames),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(activeNames) ? activeNames.value = $event : null)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).history, (item, index) => {
          return _openBlock(), _createBlock(_component_van_collapse_item, {
            title: item.title,
            name: index,
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("p", null, _toDisplayString(item.content[0]), 1), _createElementVNode("p", null, _toDisplayString(item.content[1]), 1)]),
            _: 2
          }, 1032, ["title", "name"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]), _createVNode(picker, {
        show: _unref(data).show,
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _unref(data).show = $event),
        onOnConfirm: _unref(onConfirm)
      }, null, 8, ["show", "onOnConfirm"])], 64);
    };
  }
};