import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/image/back.png';
import { reactive } from "vue";

import { useRouter } from 'vue-router';
import { ref } from 'vue';
export default {
  __name: 'majorBack',
  props: {
    x: {
      type: String,
      default: '10px'
    },
    y: {
      type: String,
      default: '50%'
    },
    r: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    let props = __props;
    let router = useRouter();
    let majorbotton = ref(null);
    let data = reactive({
      offsetTop: 0,
      offsetLeft: 0,
      xc: 0,
      yc: 0,
      y: '50%',
      x: '10px'
    });
    let majorReturn = () => {
      router.push({
        path: '/'
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "tk-return-back",
        ref_key: "majorbotton",
        ref: majorbotton,
        style: _normalizeStyle({
          'top': __props.y,
          'right': __props.r,
          'left': __props.x
        })
      }, [_createElementVNode("div", {
        class: "tk-back-button",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(majorReturn) && _unref(majorReturn)(...args)),
        onTouchstart: _cache[1] || (_cache[1] = (...args) => _ctx.mousedown && _ctx.mousedown(...args)),
        onTouchmove: _cache[2] || (_cache[2] = (...args) => _ctx.mousemove && _ctx.mousemove(...args)),
        onTouchend: _cache[3] || (_cache[3] = (...args) => _ctx.mouseup && _ctx.mouseup(...args))
      }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]), 32)], 4);
    };
  }
};