import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";

import { reactive, onBeforeMount } from 'vue';
const _hoisted_1 = {
  class: "tk-main-love"
};
const _hoisted_2 = {
  class: "tk-turntable-box"
};
const _hoisted_3 = {
  class: "tk-title-issue"
};
const _hoisted_4 = {
  class: "tk-box"
};
const _hoisted_5 = {
  id: "container"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "item item-13 start"
};
const _hoisted_8 = {
  key: 1,
  class: "randomCont"
};
const _hoisted_9 = {
  class: "sx"
};
const _hoisted_10 = {
  style: {
    "margin-top": "15px"
  }
};
export default {
  __name: 'turntable',
  setup(__props) {
    let data = reactive({
      sx: [{
        image: require('@/assets/image/sx/1.png'),
        select: '0',
        zhuan: '0',
        acitve: 0,
        name: '鼠'
      }, {
        image: require('@/assets/image/sx/2.png'),
        select: '0',
        zhuan: '1',
        acitve: 0,
        name: '牛'
      }, {
        image: require('@/assets/image/sx/3.png'),
        select: '0',
        zhuan: '2',
        acitve: 0,
        name: '虎'
      }, {
        image: require('@/assets/image/sx/4.png'),
        select: '0',
        zhuan: '3',
        acitve: 0,
        name: '兔'
      }, {
        image: require('@/assets/image/sx/5.png'),
        select: '0',
        zhuan: '5',
        acitve: 0,
        name: '猴'
      }, {
        image: require('@/assets/image/sx/6.png'),
        select: '0',
        zhuan: '7',
        acitve: 0,
        name: '鸡'
      }, {
        image: require('@/assets/image/sx/7.png'),
        select: '0',
        zhuan: '11',
        acitve: 0,
        name: '狗'
      }, {
        image: require('@/assets/image/sx/8.png'),
        select: '0',
        zhuan: '10',
        acitve: 0,
        name: '羊'
      }, {
        image: require('@/assets/image/sx/9.png'),
        select: '0',
        zhuan: '9',
        acitve: 0,
        name: '龙'
      }, {
        image: require('@/assets/image/sx/10.png'),
        select: '0',
        zhuan: '8',
        acitve: 0,
        name: '马'
      }, {
        image: require('@/assets/image/sx/11.png'),
        select: '0',
        zhuan: '6',
        acitve: 0,
        name: '蛇'
      }, {
        image: require('@/assets/image/sx/12.png'),
        select: '0',
        zhuan: '4',
        acitve: 0,
        name: '猪'
      }],
      selectSx: [],
      show: false,
      bose: '',
      boseList: ['红波', '蓝波', '绿波'],
      qi: localStorage.getItem('qi')
    });
    // 创建一个数组，用于存储已经生成的随机数
    let generatedNumbers = [];

    // 生成不重复的随机数
    let generateUniqueRandomNumber = (min, max) => {
      let randomNumber;
      do {
        // 生成随机数
        randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      } while (generatedNumbers.includes(randomNumber)); // 检查随机数是否已经存在于数组中

      generatedNumbers.push(randomNumber); // 将随机数添加到数组中
      return randomNumber;
    };
    let startUp = () => {
      data.show = true;
      let d = 1;
      data.selectSx.length = 0;
      let i = 0;
      let e = 0;
      let cl = setInterval(() => {
        let c = i;
        let b = data.sx[i].zhuan - i;
        data.sx.forEach((item, index) => {
          if (i + b == index) {
            item.select = 1;
            if (d % 36 == 0) {
              let random = generateUniqueRandomNumber(1, 11);
              data.selectSx.push(data.sx[random].name);
              data.sx[random].acitve = 1;
            }
          } else {
            item.select = 0;
          }
          if (d == 36 * 3) {
            item.select = 0;
            data.bose = data.boseList[Math.floor(Math.random() * 3)];
            clearInterval(cl);
          }
        });
        i++;
        d++;
        if (i == 12) {
          i = 0;
        }
      }, 50);
    };
    onBeforeMount(() => {});

    //功能区域

    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "波肖转盘"
      }), _createElementVNode("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "tk-love-description"
      }, [_createElementVNode("div", {
        class: "tk-love-title"
      }, "简介"), _createElementVNode("div", {
        class: "tk-desc"
      }, "转转转！转出本期的生肖和波色，激动不如行动，赶紧来转出心 目中的生肖和波色！"), _createElementVNode("div", {
        class: "tk-prompt-c"
      }, "温馨提示：每期只能进行一次摇一摇")], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(data).qi) + "六合彩 ", 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).sx, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(item.select == 1 || item.acitve ? 'item active' : 'item'),
          key: index
        }, [_createElementVNode("img", {
          src: item.image,
          alt: ""
        }, null, 8, _hoisted_6)], 2);
      }), 128)), _createElementVNode("div", _hoisted_7, [!_unref(data).show ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "s",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(startUp) && _unref(startUp)(...args))
      }, "点击开始")) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).selectSx, item => {
        return _openBlock(), _createElementBlock("span", {
          key: item
        }, _toDisplayString(' ' + item), 1);
      }), 128))]), _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(data).bose), 1)]))])])])])])], 64);
    };
  }
};