import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, vModelText as _vModelText } from "vue";
import _imports_0 from '../../assets/image/passeyesopn.png';
import _imports_1 from '../../assets/image/passeyes.png';
import _imports_2 from '../../assets/image/loading.gif';
import _imports_3 from '../../assets/image/close.png';
const _hoisted_1 = {
  class: "from"
};
const _hoisted_2 = {
  class: "icon"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_8 = {
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_9 = {
  id: "captcha",
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_10 = {
  id: "captchass",
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_11 = {
  style: {
    "margin": "16px 0"
  }
};
const _hoisted_12 = {
  class: "wrapper"
};
const _hoisted_13 = {
  class: "tk-main-QEcode"
};
const _hoisted_14 = {
  class: "codeEr"
};
const _hoisted_15 = ["src"];
const _hoisted_16 = {
  class: "tk-from-field flex-between"
};
const _hoisted_17 = {
  class: "tk-field-icon"
};
const _hoisted_18 = ["src"];
const _hoisted_19 = {
  class: "tk-field-input"
};
const _hoisted_20 = {
  class: "tk-botton"
};
import { onBeforeMount, reactive, ref, watch } from "vue";
import { getUserInfo } from '@/utils/https/me';
import { login, captcha, loginChat, mobileSend, mobileLogin } from '@/utils/https/register';
import { config } from '@/utils/https/page';
import Dialog from '@/components/mode/message.js';
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';

import { computed } from 'vue';
export default {
  __name: 'login',
  setup(__props) {
    const router = useRouter();
    const functionMoble = ref('手机验证码登陆');
    const show = ref(false); //显示弹窗
    const froms = ref({
      scene: 'login',
      password: ''
    }); //提交的表表单
    const yzm = ref("发送验证码");
    const checked = ref(false);
    const status = reactive({
      login_send_sms: false,
      //是否手机登陆
      wydun: null,
      //是否是验证状态
      code: '',
      //行为验证号码
      statusMoble: true //是否显示行为状态
    });
    const $store = useStore(); //获取vuex
    const codeImage = ref('');
    const tableForm = reactive([{
      type: "text",
      model: "account_name",
      name: "用户名",
      placeholder: "请输入用户名",
      message: "请填写用户名",
      required: true,
      Lefticon: require('@/assets/image/user.png'),
      status: true
    }, {
      type: "text",
      model: "mobile",
      name: "手机号",
      placeholder: "请输入手机号",
      message: "请填写手机号",
      required: true,
      Lefticon: require('@/assets/image/iphone.png'),
      status: false,
      max: 11
    }, {
      type: "password",
      model: "password",
      name: "密码",
      placeholder: "请输入密码",
      message: "请填写密码",
      required: true,
      Lefticon: require('@/assets/image/password.png'),
      status: true,
      look: true,
      looks: false
    }, {
      type: "text",
      model: "captcha",
      name: "图型验证码",
      placeholder: "请输入图型验证码",
      message: "请填写图型验证码",
      required: true,
      Lefticon: require('@/assets/image/code.png'),
      image: true,
      status: false
    }, {
      type: "text",
      model: "sms_code",
      name: "手机证码",
      placeholder: "请输入手机验证码",
      message: "请填写手机验证码",
      required: true,
      Lefticon: require('@/assets/image/anquan.png'),
      yzm: true,
      status: false
    }]);
    const handleLook = item => {
      tableForm.forEach(element => {
        if (element == item) {
          if (item.looks) {
            element.looks = false;
            element.type = 'password';
          } else {
            element.looks = true;
            element.type = 'text';
          }
        }
      });
    };
    const configInfo = computed(() => {
      return $store.state.config;
    });
    watch(() => configInfo.value, (val, old) => {
      if (JSON.stringify(val) != '{}') {
        let datas = configInfo.value;
        status.login_send_sms = datas.login_send_sms;
        status.wydun = datas.wydun;
        status.code = datas.wydun_captcha_id;
        wydunStatus(datas.wydun);
        if (status.wydun == 1) {
          initNECaptchas(datas.wydun_captcha_id);
          mobileIniteNeCaptcha(datas.wydun_captcha_id);
        } else {
          tableForm[3].state = true;
        }
      }
    });
    onBeforeMount(() => {
      if (localStorage.getItem('loginc') != null) {
        checked.value = true;
        froms.value.password = localStorage.getItem('loginc');
      }
      captchas();
      if (JSON.stringify(configInfo.value) != '{}') {
        let datas = configInfo.value;
        status.login_send_sms = datas.login_send_sms;
        status.wydun = datas.wydun;
        status.code = datas.wydun_captcha_id;
        wydunStatus(datas.wydun);
        if (status.wydun == 1) {
          initNECaptchas(datas.wydun_captcha_id);
          mobileIniteNeCaptcha(datas.wydun_captcha_id);
        } else {
          tableForm[3].state = true;
        }
      }
      //传人设备码
      let device = JSON.parse(localStorage.getItem('device'));
      if (device && device.system) {
        froms.value.device = device.system;
        froms.value.device_code = device.device;
      } else {
        froms.value.device = 'h5';
      }
    });
    //是否开启行为验证
    let wydunStatus = wydun => {
      tableForm.forEach((item, index) => {
        if (wydun == 0 && item.model == "captcha") {
          item.status = true;
        }
      });
    };
    // 手机验证码登陆
    let loginMoble = () => {
      tableForm.forEach((item, index) => {
        if (item.status) {
          item.status = false;
        } else {
          item.status = true;
        }
        if (status.wydun == 1 && item.model == "captcha") {
          item.status = false;
        }
      });

      //关闭行为验证
      if (functionMoble.value == '手机验证码登陆') {
        functionMoble.value = '密码登陆';
        status.statusMoble = false;
      } else {
        functionMoble.value = '手机验证码登陆';
        status.statusMoble = true;
      }
    };
    const handleEndCode = () => {
      //发送验证按钮
      if (yzm.value != "发送验证码") return false;
      var myreg = /^1[3-9]\d{9}$/;
      if (froms.value.mobile == '') {
        Dialog('手机号不能为空');
        return;
      } else if (!myreg.test(froms.value.mobile)) {
        Dialog('手机号格式有误');
        return;
      }
      if (status.wydun == 1) {
        captchaIns.value && captchaIns.value.verify();
      } else {
        show.value = true;
        // mobileSends();
      }
    };
    const handleSubmit = () => {
      if (froms.value.captcha && froms.value.captcha != '') {
        mobileSends();
      } else {
        Dialog('请输入图形验证码');
      }
    };
    // 发送手机验证验证码
    let mobileSends = async () => {
      let res = await mobileSend({
        mobile: froms.value.mobile,
        scene: 'login',
        captcha: froms.value.captcha,
        key: froms.value.key,
        NECaptchaValidate: froms.value.NECaptchaValidate
      });
      if (res.data.status == 20000) {
        show.value = false;
        Dialog(res.data.message);
        djs();
      } else {
        Dialog(res.data.message);
        captchas();
      }
      captchaIns.value.refresh();
    };
    //倒计时
    const djs = () => {
      let clear;
      let num = 60;
      clear = setInterval(() => {
        num -= 1;
        yzm.value = num;
        if (num == 0) {
          yzm.value = "发送验证码";
          clearInterval(clear);
        }
      }, 1000);
    };
    //手机号登陆
    let mobileLogins = async from => {
      let {
        data
      } = await mobileLogin(from);
      if (data.status == 20000) {
        let datas = data.data;
        Dialog(data.message);
        localStorage.setItem('Authorization', `${datas.token_type} ${datas.token}`);
        loginChats();
        getUserInfos();
        router.push({
          path: '/index'
        });
      } else {
        captchas();
        Dialog(data.message);
      }
    };
    const onSubmit = from => {
      //提交表单
      if (status.statusMoble) {
        if (status.wydun == 1) {
          if (froms.value.NECaptchaValidate) {
            logins(froms.value);
          } else {
            Dialog('请点击完成验证');
          }
        } else {
          if (status.wydun == null) {
            Dialog('验证加载中请稍后...');
          } else {
            logins(froms.value);
          }
        }
      } else {
        mobileLogins(froms.value);
      }
    };
    let logins = datas => {
      //请求登陆接口

      login(datas).then(res => {
        if (status.wydun == 1) {
          initNECaptchasValue.value.refresh();
        }
        if (res.data.status == 40000) {
          captchas();
          Dialog(res.data.message);
          return false;
        }
        if (res.status != '400') {
          let data = res.data.data;
          Dialog(res.data.message);
          localStorage.setItem('Authorization', `${data.token_type} ${data.token}`);
          loginChats();
          getUserInfos();
          router.push({
            path: '/index'
          });
          if (checked.value && localStorage.getItem('loginc') == null) {
            localStorage.setItem('loginc', datas.password);
          }
        } else {
          captchas();
          Dialog(res.data.message);
        }
      });
    };
    let loginChats = () => {
      //登陆聊天室
      loginChat().then(res => {
        if (res.data.status == 20000) {
          let chat = res.data.data.token;
          localStorage.setItem('chatToken', chat);
        }
      });
    };
    let captchas = () => {
      //请求验证码
      captcha().then(res => {
        let datas = res.data.data;
        froms.value.key = datas.key;
        codeImage.value = datas.img;
      });
    };
    let getUserInfos = () => {
      //获取用户信息
      getUserInfo().then(res => {
        if (res.status != 500) {
          let datas = res.data.data;
          localStorage.setItem('usetInfo', JSON.stringify(datas));
        } else {
          data.userInfo = '';
        }
      });
    };
    let initNECaptchasValue = ref(null);
    //行为验证
    const initNECaptchas = code => {
      initNECaptcha({
        captchaId: code,
        element: '#captcha',
        mode: 'popup',
        width: '18.75rem',
        onVerify: function (err, data) {
          if (data) {
            froms.value.NECaptchaValidate = data.validate;
          }
        }
      }, function onload(instance) {
        initNECaptchasValue.value = instance;
      }, function onerror(err) {
        console.log(err);
      });
    };
    //手机登陆行为验证
    const captchaIns = ref(null);
    const mobileIniteNeCaptcha = code => {
      // 若使用降级方案接入
      // initNECaptcha 替换成 initNECaptchaWithFallback
      initNECaptcha({
        captchaId: code,
        element: '#captchass',
        mode: 'popup',
        width: '320px',
        apiVersion: 2,
        onVerify: (err, data) => {
          // 当验证失败时, 内部会自动 refresh 方法, 无需手动再调用一次
          if (err) return;
          // 以下为业务侧逻辑
          if (data) {
            froms.value.NECaptchaValidate = data.validate;
            mobileSends();
          }
        }
      }, function onload(instance) {
        captchaIns.value = instance;
      }, function onerror(err) {
        // 初始化失败后触发该函数, err对象描述当前错误信息
        console.log(err);
      });
    };
    // 记住密码
    const handelPassword = () => {
      if (!captcha.value && localStorage.getItem('loginc') != null) {
        console.log(111);
        localStorage.removeItem('loginc');
      }
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableForm, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: index
            }, [item.status ? (_openBlock(), _createBlock(_component_van_field, {
              key: 0,
              modelValue: froms.value[item.model],
              "onUpdate:modelValue": $event => froms.value[item.model] = $event,
              type: item.type,
              name: item.name,
              label: "",
              maxlength: item.max,
              placeholder: item.placeholder,
              "label-width": "0px",
              rules: [{
                required: item.required,
                message: item.message
              }]
            }, {
              "left-icon": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
                src: item.Lefticon,
                alt: ""
              }, null, 8, _hoisted_3)])]),
              "right-icon": _withCtx(() => [_withDirectives(_createElementVNode("div", {
                class: "icon j-login",
                onClick: handleEndCode
              }, _toDisplayString(yzm.value), 513), [[_vShow, item.yzm]]), item.image ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "image",
                onClick: _cache[0] || (_cache[0] = (...args) => _unref(captchas) && _unref(captchas)(...args))
              }, [_createElementVNode("img", {
                src: codeImage.value,
                alt: ""
              }, null, 8, _hoisted_4)])) : _createCommentVNode("", true), item.look ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "look",
                onClick: $event => handleLook(item, index)
              }, [item.looks ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : (_openBlock(), _createElementBlock("img", _hoisted_7))], 8, _hoisted_5)) : _createCommentVNode("", true)]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "type", "name", "maxlength", "placeholder", "rules"])) : _createCommentVNode("", true)]);
          }), 128))]),
          _: 1
        }), _createElementVNode("div", _hoisted_8, [_createVNode(_component_van_checkbox, {
          modelValue: checked.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => checked.value = $event),
          "checked-color": "#07c160",
          onClick: handelPassword
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("记住密码")])),
          _: 1
        }, 8, ["modelValue"])]), _withDirectives(_createElementVNode("div", _hoisted_9, _cache[7] || (_cache[7] = [_createElementVNode("div", {
          style: {
            "display": "flex",
            "align-items": "center",
            "justify-content": "center",
            "width": "100%"
          }
        }, [_createElementVNode("img", {
          style: {
            "width": "20px",
            "height": "20px"
          },
          src: _imports_2,
          alt: ""
        })], -1)]), 512), [[_vShow, status.wydun == 1 && status.statusMoble]]), _withDirectives(_createElementVNode("div", _hoisted_10, null, 512), [[_vShow, !status.statusMoble]]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_van_button, {
          color: "#07c160",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode(" 登陆 ")])),
          _: 1
        })])]),
        _: 1
      }), status.login_send_sms ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "moble",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(loginMoble) && _unref(loginMoble)(...args))
      }, [_createTextVNode(_toDisplayString(functionMoble.value), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })])) : _createCommentVNode("", true)]), _createVNode(_component_van_overlay, {
        show: show.value,
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[3] || (_cache[3] = _withModifiers($event => show.value = false, ["stop"]))
        }, _cache[9] || (_cache[9] = [_createElementVNode("img", {
          src: _imports_3,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_14, [_cache[10] || (_cache[10] = _createElementVNode("div", {
          class: "text"
        }, "验证码", -1)), _createElementVNode("img", {
          onClick: _cache[4] || (_cache[4] = _withModifiers((...args) => _unref(captchas) && _unref(captchas)(...args), ["stop"])),
          src: codeImage.value,
          alt: ""
        }, null, 8, _hoisted_15)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("img", {
          src: require('@/assets/image/anquan.png'),
          alt: ""
        }, null, 8, _hoisted_18)]), _createElementVNode("div", _hoisted_19, [_withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => froms.value.captcha = $event),
          placeholder: "请输入验证码"
        }, null, 512), [[_vModelText, froms.value.captcha]])])]), _cache[11] || (_cache[11] = _createElementVNode("div", {
          class: ""
        }, null, -1)), _createElementVNode("div", _hoisted_20, [_createVNode(_component_botton, {
          name: "确定",
          onClick: handleSubmit
        })])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};