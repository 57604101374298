import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, isRef as _isRef, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../assets/image/huibottom.png';
const _hoisted_1 = {
  class: "tk-exit-passwrod"
};
const _hoisted_2 = {
  class: "tk-from"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "tk-from-field flex-between"
};
const _hoisted_5 = {
  class: "tk-field-input"
};
const _hoisted_6 = ["type", "onUpdate:modelValue", "placeholder", "onClick"];
const _hoisted_7 = ["type", "onUpdate:modelValue", "placeholder"];
const _hoisted_8 = {
  class: "tk-prompts"
};
const _hoisted_9 = {
  key: 0,
  style: {
    "margin-top": "10px",
    "text-align": "center"
  }
};
const _hoisted_10 = ["href"];
import Dialog from '@/components/mode/message.js';
import { platList, platBind } from '@/utils/https/me';
import { onBeforeMount } from 'vue';

import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'BindingAccount',
  setup(__props) {
    let data = reactive({
      from: [{
        type: 'text',
        name: 'username',
        placeholder: '请选择额度转换平台',
        value: '',
        icon: '平台',
        type_c: 'password',
        select: false
      }, {
        type: 'text',
        name: 'password',
        placeholder: '请输入本台绑定姓名',
        value: '',
        icon: '姓名'
      }, {
        type: 'text',
        name: 'code',
        placeholder: '请输入平台账号',
        value: '',
        icon: '账号'
      }],
      froms: {
        account_name: '',
        password: '',
        captcha: '',
        key: ''
      },
      lookeyes: true,
      show: false,
      codeImg: '',
      message: '',
      listplatform: [],
      activeIndex: '',
      showBottom: false,
      columns: [],
      navgetUrl: ''
    });
    let {
      show,
      from,
      codeImg,
      message,
      froms,
      listplatform,
      activeIndex,
      showBottom,
      columns
    } = toRefs(data);
    let router = useRouter();
    onBeforeMount(() => {
      platLists();
    });

    //接口请求
    let platLists = () => {
      platList().then(res => {
        if (res.data.status == 40000) {} else {
          let datas = res.data.data;
          datas.forEach((item, index) => {
            data.columns.push({
              text: item.name,
              value: item.id,
              url: item.website
            });
          });
          data.listplatform.push(...datas);
        }
      });
    };
    let platBinds = from => {
      platBind(from).then(res => {
        if (res.data.status == 40000) {
          Dialog(res.data.message);
        } else {
          Dialog(res.data.message);
          router.push({
            path: '/me/setup/account'
          });
        }
      });
    };
    let lookPassword = index => {
      data.showBottom = true;
    };
    const onConfirm = ({
      selectedOptions
    }) => {
      let item = selectedOptions[0];
      data.activeIndex = item.value;
      data.from[0].value = item.text;
      data.navgetUrl = item.url;
      data.showBottom = false;
    };
    let onCancel = () => {
      data.showBottom = false;
    };
    let handleSelect = item => {};
    let onSubmit = e => {
      e.preventDefault();
      let from = {
        plat_id: '',
        plat_user_name: '',
        plat_user_account: ''
      };
      from.plat_id = data.activeIndex;
      from.plat_user_name = data.from[1].value;
      from.plat_user_account = data.from[2].value;
      if (from.plat_id == '') {
        Dialog('请选择平台');
      } else if (from.plat_user_name == '') {
        Dialog('请输入平台绑定姓名');
      } else if (from.plat_user_account == '') {
        Dialog('请输入平台账号');
      } else {
        platBinds(from);
      }
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_picker = _resolveComponent("van-picker");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "绑定平台账号"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = (...args) => _unref(onSubmit) && _unref(onSubmit)(...args))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(from), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "labe",
          key: index
        }, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.icon), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [item.type_c == 'password' ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          type: item.type,
          readonly: "readonly",
          "onUpdate:modelValue": $event => item.value = $event,
          placeholder: item.placeholder,
          onClick: $event => _unref(lookPassword)(index)
        }, null, 8, _hoisted_6)), [[_vModelDynamic, item.value]]) : _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          type: item.type,
          "onUpdate:modelValue": $event => item.value = $event,
          placeholder: item.placeholder
        }, null, 8, _hoisted_7)), [[_vModelDynamic, item.value]])]), item.type_c == 'password' ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-field-tips",
          onClick: _cache[0] || (_cache[0] = $event => _isRef(showBottom) ? showBottom.value = true : showBottom = true)
        }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))) : _createCommentVNode("", true)])]);
      }), 128)), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-from-field bottom"
      }, [_createElementVNode("button", {
        type: "submit"
      }, "绑定")], -1))], 32), _createElementVNode("div", _hoisted_8, [_cache[6] || (_cache[6] = _createElementVNode("span", null, "温馨提示：由于安全原因，请使用本人属下平台帐号", -1)), _unref(from)[0].value != '' ? (_openBlock(), _createElementBlock("p", _hoisted_9, [_cache[5] || (_cache[5] = _createTextVNode("没有账号 ？ ")), _createElementVNode("a", {
        href: _unref(data).navgetUrl,
        style: {
          "color": "red",
          "text-decoration": "underline"
        }
      }, "前往注册", 8, _hoisted_10)])) : _createCommentVNode("", true)])])]), _createVNode(_component_van_popup, {
        show: _unref(showBottom),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(showBottom) ? showBottom.value = $event : showBottom = $event),
        round: "",
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          title: "请选择要绑定的平台",
          columns: _unref(columns),
          onConfirm: onConfirm,
          onCancel: _unref(onCancel),
          "default-index": 2
        }, null, 8, ["columns", "onCancel"])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};