import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "tk-main-guess"
};
const _hoisted_2 = {
  class: "tk-lootery"
};
const _hoisted_3 = {
  class: "tk-artivles"
};
const _hoisted_4 = {
  class: "tk-artivles-image"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "tk-guess-recommend"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "tk-guess-img"
};
const _hoisted_11 = ["src"];
const _hoisted_12 = ["src"];
const _hoisted_13 = {
  class: "tk-guess-name"
};
const _hoisted_14 = {
  class: "tk-swiper",
  style: {
    "margin-top": "10px"
  }
};
const _hoisted_15 = ["src"];
import lottery from "@/components/page/lottery.vue";
import year from "@/components/page/guess/year.vue";
import articlesvote from "@/components/page/guess/articlesvote.vue";
import comment from "@/components/page/comments.vue";
import comment_list from "@/components/page/comment_list.vue";
import Dialog from "@/components/mode/message.js";
import { showImagePreview } from "vant"; //图片预览
import { useStore } from "vuex";
import { config } from "@/utils/https/page";
import { picture, pictureVote, pictureFollow, pictureCollect, pictureIssues, pictureRecommend } from "@/utils/https/gallery";
import { numbers } from "@/utils/https/lottery";
import { ref } from "vue";

import { useRoute, useRouter } from 'vue-router';
import { reactive, toRefs, onBeforeMount, onBeforeUnmount, onDeactivated, onActivated } from 'vue';
// let pictureIssuess = (issues,year)=>{
//     pictureIssues({pictureTypeId:issues,year:year}).then(res=>{
//         if(res==undefined) return false;
//         let datas =  res.data.data;
//         data.issue = datas;
//     })
// }

export default {
  __name: 'index',
  setup(__props) {
    const ImageDow = ref(false);
    const imagedows = ref(false);
    const images = ref([]);
    let lookImage = img => {
      ImageDow.value = true;
      images.value = [img];
    };
    const onOffsetChange = () => {
      router.push({
        path: "/guess/index"
      });
    };
    const route = useRoute();
    const router = useRouter();
    let data = reactive({
      title: "",
      year: "",
      id: "",
      color: "",
      page: 1,
      galleryData: [],
      //页面详情数据
      voteList: [],
      //生肖投票数据
      shows: false,
      show: false,
      yearss: 1,
      issue: [],
      //期数
      scrollTop: false,
      numarray: {
        year: "2023",
        issue: "000",
        lotteryType: 1,
        lotteryTime: "2020-12-29",
        number_attr: [{
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }]
      },
      //开奖号码
      oneissue: "",
      //第一次进来的期数
      lotteryCode: false,
      lottery: localStorage.getItem("lotteryType"),
      galleryRecommend: [],
      page: 1,
      reacommendShow: true,
      reacommentcc: 1,
      adList: [],
      loading: "",
      pictureId: "",
      daDeac: []
    });
    let {
      title,
      galleryData,
      voteList,
      show,
      msg,
      shows,
      issue,
      numarray,
      oneissue,
      lotteryCode,
      galleryRecommend,
      reacommendShow,
      reacommentcc
    } = toRefs(data);
    let configImg = ref(null);
    const $store = useStore(); //获取vuex
    onBeforeMount(() => {
      let device = JSON.parse(localStorage.getItem("device"));
      if (device && device.system) {
        imagedows.value = true;
      }
      data.adList = JSON.parse(localStorage.getItem("avList")).adlist;
      data.daDeac = JSON.parse(localStorage.getItem("avDesc"));
      window.scrollTo(0, 0);
      data.id = route.query.id;
      if (localStorage.getItem("config") == null) {
        configs();
      } else {
        let datas = JSON.parse(localStorage.getItem("config"));
        configImg.value = datas;
      }
      window.addEventListener("scroll", handleScroll);
    });
    const dowImage = () => {
      Android.downloadImage(images.value[0]);
      ImageDow.value = false;
    };
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    let configs = () => {
      config().then(res => {
        //获取公共配置
        if (res == undefined) return false;
        let datas = res.data.data;
        configImg.value = datas;
        localStorage.setItem("config", JSON.stringify(datas));
      });
    };
    //接口请求区域
    let pictureDesc = (id, year, pictureId = "", lottery, isIndexFrom = 1) => {
      //请求数据
      if (sessionStorage.getItem(`${id}/${pictureId}/${lottery}/${year}`)) {
        let datas = JSON.parse(sessionStorage.getItem(`${id}/${pictureId}/${lottery}/${year}`)).PicDetailData;
        if (data.issue.length == 0 || data.yearss != 1) {
          data.issue = datas.issues;
        }
        datas.isFollow = datas.follow;
        datas.isCollect = datas.collect;
        data.galleryData = datas;
        data.loading = datas.largePictureUrl;
        data.pictureId = datas.pictureId;
        data.voteList = JSON.parse(sessionStorage.getItem(`${id}/${pictureId}/${lottery}/${year}`)).voteList;
        data.reacommentcc += 1;
        number(data.galleryData.year, data.galleryData.lotteryType, data.galleryData.issue); //详情获取每一期的期数
      } else {
        picture({
          pictureTypeId: id,
          year: year,
          pictureId: pictureId,
          lotteryType: lottery,
          isIndexFrom
        }).then(res => {
          if (res == undefined) return false;
          sessionStorage.setItem(`${id}/${pictureId}/${lottery}/${year}`, JSON.stringify(res.data.data));
          let datas = res.data.data.PicDetailData;
          if (data.issue.length == 0 || data.yearss != 1) {
            data.issue = datas.issues;
          }
          datas.isFollow = datas.follow;
          datas.isCollect = datas.collect;
          data.galleryData = datas;
          data.loading = datas.largePictureUrl;
          data.pictureId = datas.pictureId;
          data.voteList = res.data.data.voteList;
          data.reacommentcc += 1;
          number(data.galleryData.year, data.galleryData.lotteryType, data.galleryData.issue); //详情获取每一期的期数
        });
      }
    };
    let pictureRecommends = (id, year, pictureId = "", page, lottery) => {
      pictureRecommend({
        pictureTypeId: id,
        year: year,
        pictureId: pictureId,
        page: page,
        lotteryType: lottery
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data;
        if (datas.length == 0) {
          data.reacommendShow = false;
        } else {
          data.reacommendShow = true;
          data.galleryRecommend.push(...datas);
        }
      });
    };
    let vote = sx => {
      //投票
      data.shows = false;
      pictureVote({
        pictureId: data.galleryData.pictureId,
        vote_zodiac: sx
      }).then(res => {
        if (res == undefined) return false;
        Dialog(res.data.message, "#07c160");
        if (res.data.message != "您已经投票了") {
          data.voteList.forEach(item => {
            if (item.name == sx) {
              item.vote_num += 1;
            }
            let c = sum(data.voteList);
            if (c == 0) {
              item.percentage = 0;
            } else {
              item.percentage = 100 / c * item.vote_num;
            }
          });
        }
      });
    };
    function sum(arr) {
      //获得投票总数
      var s = 0;
      arr.forEach(function (val) {
        s += val.vote_num;
      }, 0);
      return s;
    }
    let follow = id => {
      //点赞
      pictureFollow({
        pictureId: id
      }).then(res => {
        if (res.data.status == "40000") {
          Dialog(res.data.message, "#07c160");
        }
      });
    };
    let collect = id => {
      //收藏
      pictureCollect({
        pictureId: id
      }).then(res => {
        if (res.data.status == "40000") {
          Dialog(res.data.message, "#07c160");
        }
      });
    };
    let number = (year, lotteryType, issue) => {
      //每一期开奖结果
      if (sessionStorage.getItem(`${year}/${lotteryType}/${issue}`)) {
        data.lotteryCode = true;
        let datas = JSON.parse(sessionStorage.getItem(`${year}/${lotteryType}/${issue}`));
        data.numarray = datas;
      } else {
        numbers({
          year: year,
          lotteryType: lotteryType,
          issue: issue
        }).then(res => {
          if (res == undefined) return false;
          if (res.data.status == 40000) {
            data.lotteryCode = false;
          } else {
            data.lotteryCode = true;
            let datas = res.data.data;
            data.numarray = datas;
            sessionStorage.setItem(`${year}/${lotteryType}/${issue}`, JSON.stringify(datas));
          }
        });
      }
    };
    //功能区域

    const handleScroll = () => {
      //页面滚动的时候用于置顶判断

      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 200) {
        data.scrollTop = true;
      } else {
        data.scrollTop = false;
      }
    };
    let handleMore = () => {
      //点击加载更多图片
      data.page++;
      pictureRecommends(data.id, data.year, "", data.page, data.lottery);
    };
    let handleReacommend = item => {
      //推荐图片跳转
      data.galleryRecommend.length = 0;
      // router.push({
      //         path: `/gallery`,
      //         query:{
      //             color:item.color,
      //             year:item.year,
      //             id:item.pictureTypeId,
      //             title:item.pictureName
      //         }
      //     })

      data.title = item.pictureName;
      data.id = item.pictureTypeId;
      data.pictureId = item.pictureId;
      data.color = item.color;
      data.year = item.year;
      pictureRecommends(data.id, data.year, "", 1, data.lottery);
      pictureDesc(data.id, data.year, "", data.lottery);
      data.yearss += 1;
      // pictureIssuess(data.id,data.year)
    };
    const handleThumbUpCount = () => {
      if (!data.galleryData.isFollow) {
        //判断是否点赞
        data.galleryData.isFollow = 1;
        Dialog("点赞成功", "#07c160");
        data.galleryData.thumbUpCount += 1;
      } else {
        data.galleryData.isFollow = 0;
        data.galleryData.thumbUpCount -= 1;
        Dialog("已取消点赞", "#07c160");
      }
      follow(data.galleryData.pictureId);
    };
    const handleCollect = () => {
      if (!data.galleryData.isCollect) {
        //判断是收藏
        data.galleryData.isCollect = true;
        Dialog("收藏成功", "#07c160");
        data.galleryData.collectCount += 1;
      } else {
        data.galleryData.isCollect = false;
        data.galleryData.collectCount -= 1;
        Dialog("已取消收藏", "#07c160");
      }
      collect(data.galleryData.pictureId);
    };
    let majoFun = index => {
      // comment组件点击 0 是点赞 3是收藏 2是分享
      if (index == 3) {
        if (!data.galleryData.isCollect) {
          //判断是收藏
          data.galleryData.isCollect = true;
          Dialog("收藏成功", "#07c160");
          data.galleryData.collectCount += 1;
        } else {
          data.galleryData.isCollect = false;
          data.galleryData.collectCount -= 1;
          Dialog("已取消收藏", "#07c160");
        }
        collect(data.galleryData.pictureId);
      } else if (index == 0) {
        if (!data.galleryData.isFollow) {
          //判断是否点赞
          data.galleryData.isFollow = 1;
          Dialog("点赞成功", "#07c160");
          data.galleryData.thumbUpCount += 1;
        } else {
          data.galleryData.isFollow = 0;
          data.galleryData.thumbUpCount -= 1;
          Dialog("已取消点赞", "#07c160");
        }
        follow(data.galleryData.pictureId);
      } else if (index == 2) {
        data.show = true;
      } else if (index == 4) {
        router.push({
          path: "/gallery/analysis",
          query: {
            title: data.title,
            id: data.galleryData.id,
            issue: data.galleryData.issue
          }
        });
      } else if (index == 10) {
        data.reacommentcc += data.reacommentcc;
      } else if (index == 11) {
        router.push({
          path: "/guess/index"
        });
        // onOffsetChange();
      }
    };
    let issueFun = item => {
      //选择期数
      // data.galleryData = []
      data.yearss = 1;
      pictureDesc(data.id, data.year, item.pictureId, data.lottery, 0);
    };
    let majoYear = item => {
      // data.galleryData = [];
      data.year = item;
      data.yearss += 1;
      pictureDesc(data.id, item, "", data.lottery, 0);
      // pictureIssuess(data.id,item)
    };
    let start = ref(true);
    onDeactivated(() => {
      start.value = false;
    });
    onActivated(() => {
      window.scrollTo(0, 0);
      data.page = 1;
      let id = route.query.id;
      let img = route.query.pictureUrl;
      let pictureId = route.query.pictureId;
      data.lottery = localStorage.getItem("lotteryType");
      if (start.value || pictureId != data.pictureId) {
        data.yearss += 1;
        data.title = route.query.title;
        data.id = route.query.id;
        data.color = route.query.color;
        data.year = route.query.year;
        data.pictureId = route.query.pictureId;
        // if(route.query.issue != undefined){
        //     data.oneissue = route.query.issue;
        //     data.year =  route.query.year;
        // }
        if (route.query.image != undefined) {
          data.galleryData.largePictureUrl = route.query.image;
          data.loading = route.query.image;
        }
        data.galleryRecommend.length = 0;
        pictureRecommends(data.id, data.year, "", 1, data.lottery);
        pictureDesc(data.id, data.year, data.pictureId, data.lottery);
        // pictureIssuess(data.id,data.year)
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_image = _resolveComponent("van-image");
      const _component_swipers = _resolveComponent("swipers");
      const _component_advertisement = _resolveComponent("advertisement");
      const _component_back = _resolveComponent("back");
      const _component_share = _resolveComponent("share");
      const _component_van_image_preview = _resolveComponent("van-image-preview");
      const _component_majorBack = _resolveComponent("majorBack");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(title)
      }, {
        iocn: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("div", {
          class: "icon-tk"
        }, [_createElementVNode("a", {
          href: "/lottery/history"
        }, "开奖记录")], -1)])),
        tkCont: _withCtx(() => [_createVNode(year, {
          issue: _unref(issue),
          onIssueFun: _unref(issueFun),
          onMajoYear: _unref(majoYear),
          onissue: _unref(oneissue),
          year: _unref(data).year
        }, null, 8, ["issue", "onIssueFun", "onMajoYear", "onissue", "year"])]),
        _: 1
      }, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_withDirectives(_createElementVNode("div", _hoisted_2, [_createVNode(lottery, {
        types: false,
        data: _unref(numarray)
      }, null, 8, ["data"])], 512), [[_vShow, _unref(lotteryCode)]]), _createVNode(articlesvote, {
        onHandleThumbUpCount: handleThumbUpCount,
        onHandleCollect: handleCollect,
        galleryData: _unref(galleryData),
        onVote: _unref(vote),
        voteList: _unref(voteList),
        show: _unref(shows),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(shows) ? shows.value = $event : shows = $event)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_image, {
          src: _unref(galleryData).largePictureUrl,
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => _unref(lookImage)(_unref(galleryData).largePictureUrl), ["stop"]))
        }, {
          error: _withCtx(() => [_unref(galleryData).largePictureUrlOther != '' ? (_openBlock(), _createBlock(_component_van_image, {
            key: 0,
            src: _unref(galleryData).largePictureUrlOther,
            onClick: _cache[0] || (_cache[0] = _withModifiers($event => _unref(lookImage)(_unref(galleryData).largePictureUrlOther), ["stop"]))
          }, {
            error: _withCtx(() => [_createElementVNode("img", {
              src: _ctx.$httpsUrl + _unref(configImg).notfind_image,
              alt: ""
            }, null, 8, _hoisted_5)]),
            _: 1
          }, 8, ["src"])) : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.$httpsUrl + _unref(configImg).notfind_image,
            alt: ""
          }, null, 8, _hoisted_6))]),
          loading: _withCtx(() => [_createElementVNode("img", {
            src: _unref(data).loading,
            alt: ""
          }, null, 8, _hoisted_7)]),
          _: 1
        }, 8, ["src"])])])]),
        _: 1
      }, 8, ["galleryData", "onVote", "voteList", "show"]), _createElementVNode("div", _hoisted_8, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "tk-guess-title"
      }, "为您推荐类似图纸", -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(galleryRecommend), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(handleReacommend)(item)
        }, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_van_image, {
          src: item.largePictureUrl
        }, {
          error: _withCtx(() => [_unref(data).lottery == 5 ? (_openBlock(), _createBlock(_component_van_image, {
            key: 0,
            src: item.largePictureUrlOther
          }, {
            error: _withCtx(() => [_createVNode(_component_van_image, {
              src: item.previousPictureUrl
            }, {
              error: _withCtx(() => [_createVNode(_component_van_image, {
                src: item.previousPictureUrlOther
              }, {
                error: _withCtx(() => [_createElementVNode("img", {
                  src: _ctx.$httpsUrl + _unref(configImg).notfind_image,
                  alt: ""
                }, null, 8, _hoisted_11)]),
                _: 2
              }, 1032, ["src"])]),
              _: 2
            }, 1032, ["src"])]),
            _: 2
          }, 1032, ["src"])) : (_openBlock(), _createBlock(_component_van_image, {
            key: 1,
            src: item.previousPictureUrl
          }, {
            error: _withCtx(() => [_createElementVNode("img", {
              src: _ctx.$httpsUrl + _unref(configImg).notfind_image,
              alt: ""
            }, null, 8, _hoisted_12)]),
            _: 2
          }, 1032, ["src"]))]),
          _: 2
        }, 1032, ["src"])]), _createElementVNode("div", _hoisted_13, _toDisplayString(item.pictureName), 1)], 8, _hoisted_9);
      }), 128)), _unref(galleryRecommend).length != 0 && _unref(reacommendShow) ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        class: "more",
        onClick: _cache[3] || (_cache[3] = (...args) => _unref(handleMore) && _unref(handleMore)(...args))
      }, " 点 击 加 载 更 多 ")) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_swipers, {
        swiperImg: _unref(data).adList,
        listImg: _unref(data).daDeac
      }, null, 8, ["swiperImg", "listImg"])])]), _createVNode(_component_advertisement), _unref(galleryData).length != 0 ? (_openBlock(), _createBlock(comment_list, {
        type: "1",
        id: _unref(galleryData).id,
        key: _unref(reacommentcc)
      }, null, 8, ["id"])) : _createCommentVNode("", true), _createVNode(comment, {
        dataInfo: _unref(galleryData),
        typeId: "1",
        onMajoFun: _unref(majoFun),
        scrollTop: _unref(data).scrollTop,
        scrollTops: true
      }, null, 8, ["dataInfo", "onMajoFun", "scrollTop"]), _createVNode(_component_back), _createVNode(_component_share, {
        shows: _unref(show),
        "onUpdate:shows": _cache[4] || (_cache[4] = $event => _isRef(show) ? show.value = $event : show = $event)
      }, null, 8, ["shows"]), _createElementVNode("div", {
        class: "guess",
        onClick: onOffsetChange
      }, [_createElementVNode("img", {
        src: require('@/assets/image/icon/mony.png'),
        alt: ""
      }, null, 8, _hoisted_15)]), ImageDow.value && imagedows.value ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "ccImage",
        onClick: dowImage
      }, " 保存图片 ")) : _createCommentVNode("", true), _createVNode(_component_van_image_preview, {
        show: ImageDow.value,
        "onUpdate:show": _cache[5] || (_cache[5] = $event => ImageDow.value = $event),
        images: images.value,
        onChange: _ctx.onChange
      }, null, 8, ["show", "images", "onChange"]), _createVNode(_component_majorBack)], 64);
    };
  }
};