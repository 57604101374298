import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import _imports_0 from '../assets/image/icon/nolottery.png';
import _imports_1 from '../assets/image/icon/yeslottery.png';
import _imports_2 from '../assets/image/icon/ding.png';
import _imports_3 from '../assets/image/icon/jing.png';
import _imports_4 from '../assets/image/guanzhu.png';
import _imports_5 from '../assets/image/error.png';
import _imports_6 from '../assets/image/zan.png';
import _imports_7 from '../assets/image/comment.png';
import _imports_8 from '../assets/image/look.png';
import _imports_9 from '../assets/image/major/provice/2031.png';
import _imports_10 from '../assets/image/major/provice/2017.png';
import _imports_11 from '../assets/image/major/provice/2018.png';
import _imports_12 from '../assets/image/major/provice/2032.png';
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "tk-article flex-between"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "tk-user"
};
const _hoisted_6 = {
  class: "name",
  style: {
    "color": "var(--main-colors)"
  }
};
const _hoisted_7 = {
  class: "times"
};
const _hoisted_8 = {
  class: "tk-tips"
};
const _hoisted_9 = {
  key: 0,
  class: "lottery"
};
const _hoisted_10 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_11 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_12 = {
  key: 1,
  src: _imports_2,
  alt: ""
};
const _hoisted_13 = {
  key: 2,
  src: _imports_3,
  alt: ""
};
const _hoisted_14 = ["onClick"];
const _hoisted_15 = {
  key: 0,
  src: _imports_4,
  alt: ""
};
const _hoisted_16 = {
  key: 0,
  class: "tk-article-title"
};
const _hoisted_17 = {
  key: 0,
  class: "tk-c"
};
const _hoisted_18 = {
  key: 1,
  src: _imports_2,
  alt: ""
};
const _hoisted_19 = {
  key: 2,
  src: _imports_3,
  alt: ""
};
const _hoisted_20 = {
  style: {
    "margin-left": "5px"
  }
};
const _hoisted_21 = {
  key: 1,
  class: "tk-article-title"
};
const _hoisted_22 = {
  style: {
    "color": "#FF1A00 !important"
  }
};
const _hoisted_23 = {
  class: "tk-article-cont"
};
const _hoisted_24 = ["innerHTML"];
const _hoisted_25 = {
  class: "tk-article-img"
};
const _hoisted_26 = {
  key: 0,
  class: "tk-article-item"
};
const _hoisted_27 = {
  key: 2,
  class: "tk-article-info flex-between"
};
const _hoisted_28 = {
  class: "tk-info"
};
const _hoisted_29 = {
  class: "flex-between"
};
const _hoisted_30 = {
  key: 0,
  class: "tk-type"
};
const _hoisted_31 = {
  key: 1,
  class: "tk-type"
};
const _hoisted_32 = {
  key: 2,
  class: "tk-type"
};
const _hoisted_33 = {
  key: 3,
  class: "tk-type"
};
const _hoisted_34 = {
  key: 4,
  class: "tk-type"
};
const _hoisted_35 = {
  key: 5,
  class: "tk-type"
};
import { useStore } from 'vuex';
import { showImagePreview } from 'vant'; 
import { useRouter } from 'vue-router';
import { computed } from 'vue';
//图片预览

export default {
  __name: 'articles',
  props: {
    articlesArray: {
      type: Array,
      default: []
    },
    guess: {
      type: String,
      default: ''
    }
  },
  emits: ['articleDes', 'focusFun'],
  setup(__props, {
    emit: __emit
  }) {
    let router = useRouter();
    let props = __props;
    const $store = useStore(); //获取vuex
    const configInfo = computed(() => {
      return $store.state.config;
    });
    let emits = __emit;
    let articleDes = item => {
      emits('articleDes', item);
    };
    const contetnComputed = computed(() => item => {
      if (item) {
        return item.replace(/\n/g, '<br/>');
      }
    });
    let focusFun = (id, index) => {
      emits('focusFun', id, index);
    };
    let majoruser = id => {
      //跳转用户中心
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    let preview = (img, index) => {
      //预览图片
      let arr = [];
      img.forEach(item => {
        arr.push(item.img_url);
      });
      showImagePreview({
        images: arr,
        startPosition: index
      });
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.articlesArray, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tk-main-article",
          key: index,
          onClick: $event => _unref(articleDes)(item)
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: "article-head",
          onClick: _withModifiers($event => _unref(majoruser)(item.user.id), ["stop"])
        }, [_createElementVNode("img", {
          src: item.user.avatar == '' ? require('@/assets/image/logo.png') : _unref(configInfo).host2 + '/' + item.user.avatar,
          alt: ""
        }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(item.user.nickname), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(item.created_at), 1)])], 8, _hoisted_3), _createElementVNode("div", _hoisted_8, [__props.guess == '竞猜' || __props.guess == '5' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [item.success == 0 ? (_openBlock(), _createElementBlock("img", _hoisted_10)) : _createCommentVNode("", true), item.success == 1 ? (_openBlock(), _createElementBlock("img", _hoisted_11)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), item.is_top && __props.guess == '1' ? (_openBlock(), _createElementBlock("img", _hoisted_12)) : _createCommentVNode("", true), item.is_essence && __props.guess == '1' ? (_openBlock(), _createElementBlock("img", _hoisted_13)) : _createCommentVNode("", true)]), __props.guess == '高手' ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(item.user.focus ? 'tk-follow focus' : 'tk-follow'),
          onClick: _withModifiers($event => _unref(focusFun)(item.user.id, index), ["stop"])
        }, [!item.user.focus ? (_openBlock(), _createElementBlock("img", _hoisted_15)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(item.user.focus ? '已关注' : '关注'), 1)], 10, _hoisted_14)) : _createCommentVNode("", true)]), __props.guess == '高手' || __props.guess == '3' || __props.guess == '1' || __props.guess == '2' ? (_openBlock(), _createElementBlock("div", _hoisted_16, [__props.guess != '3' ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(item.issue), 1)) : _createCommentVNode("", true), item.is_top ? (_openBlock(), _createElementBlock("img", _hoisted_18)) : _createCommentVNode("", true), item.is_essence ? (_openBlock(), _createElementBlock("img", _hoisted_19)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_20, _toDisplayString(item.title), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("span", _hoisted_22, _toDisplayString(item.title), 1)])), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", {
          class: "tk-article-cont_s",
          style: _normalizeStyle({
            'color': item.word_color
          }),
          innerHTML: _unref(contetnComputed)(item.content)
        }, null, 12, _hoisted_24), _cache[0] || (_cache[0] = _createElementVNode("span", null, "点击查看详情...", -1))]), _createElementVNode("div", _hoisted_25, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.images, (items, indexs) => {
          return _openBlock(), _createElementBlock("div", {
            key: indexs
          }, [indexs < 3 ? (_openBlock(), _createElementBlock("div", _hoisted_26, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
            src: items.img_url,
            fit: "cover",
            onClick: _withModifiers($event => _unref(preview)(item.images, indexs), ["stop"])
          }, {
            error: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("img", {
              src: _imports_5,
              alt: ""
            }, null, -1)])),
            _: 2
          }, 1032, ["src", "onClick"])), [[_directive_lazy, items.img_url]])])) : _createCommentVNode("", true)]);
        }), 128))]), __props.guess == '' || __props.guess == '高手' || __props.guess == '3' || __props.guess == '2' || __props.guess == '1' ? (_openBlock(), _createElementBlock("div", _hoisted_27, [_createElementVNode("div", _hoisted_28, [_createElementVNode("ul", _hoisted_29, [_createElementVNode("li", null, [_cache[2] || (_cache[2] = _createElementVNode("img", {
          src: _imports_6,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(item.thumbUpCount), 1)]), _createElementVNode("li", null, [_cache[3] || (_cache[3] = _createElementVNode("img", {
          src: _imports_7,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(item.commentCount), 1)]), _createElementVNode("li", null, [_cache[4] || (_cache[4] = _createElementVNode("img", {
          src: _imports_8,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$filters.format(item.views)), 1)])])]), item.lotteryType == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[5] || (_cache[5] = [_createElementVNode("img", {
          src: _imports_9,
          alt: ""
        }, null, -1), _createElementVNode("span", null, "澳门", -1)]))) : _createCommentVNode("", true), item.lotteryType == 6 ? (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[6] || (_cache[6] = [_createElementVNode("img", {
          src: _imports_9,
          alt: ""
        }, null, -1), _createElementVNode("span", null, "快乐八", -1)]))) : _createCommentVNode("", true), item.lotteryType == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[7] || (_cache[7] = [_createElementVNode("img", {
          src: _imports_9,
          alt: ""
        }, null, -1), _createElementVNode("span", null, "新澳门", -1)]))) : _createCommentVNode("", true), item.lotteryType == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[8] || (_cache[8] = [_createElementVNode("img", {
          src: _imports_10,
          alt: ""
        }, null, -1), _createElementVNode("span", null, "香港", -1)]))) : _createCommentVNode("", true), item.lotteryType == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_34, _cache[9] || (_cache[9] = [_createElementVNode("img", {
          src: _imports_11,
          alt: ""
        }, null, -1), _createElementVNode("span", null, "台湾", -1)]))) : _createCommentVNode("", true), item.lotteryType == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[10] || (_cache[10] = [_createElementVNode("img", {
          src: _imports_12,
          alt: ""
        }, null, -1), _createElementVNode("span", null, "新加坡", -1)]))) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 8, _hoisted_1);
      }), 128);
    };
  }
};