import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";

import { reactive, toRefs } from 'vue';
const _hoisted_1 = {
  class: "tk-main-more"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  key: 0,
  class: "tk-main-title"
};
export default {
  __name: 'moreType',
  setup(__props) {
    let data = reactive({
      codeType: [{
        name: '特肖'
      }, {
        name: '特码'
      }, {
        name: '单双'
      }, {
        name: '一肖'
      }, {
        name: '大小'
      }, {
        name: '波色'
      }, {
        name: '连肖'
      }, {
        name: '杀特肖'
      }, {
        name: '一尾'
      }, {
        name: '杀肖'
      }, {
        name: '杀特尾'
      }, {
        name: '杀尾'
      }, {
        name: '不中'
      }, {
        name: '天地生肖'
      }, {
        name: '家禽野兽'
      }, {
        name: '左右生肖'
      }, {
        name: '杀一头'
      }, {
        name: '稳禁一段'
      }, {
        name: '合数单双'
      }, {
        name: '杀一和'
      }, {
        name: '三行中特'
      }],
      child: [{
        name: '正一'
      }, {
        name: '正二'
      }, {
        name: '正三'
      }, {
        name: '正四'
      }, {
        name: '正五'
      }, {
        name: '正六'
      }, {
        name: '正特码'
      }],
      select: false,
      activeIndex: 0
    });
    let {
      codeType,
      child,
      select,
      activeIndex
    } = toRefs(data);
    let codeSelect = (item, index) => {
      data.activeIndex = index;
      if (item == '大小' || item == '波色') {
        data.select = true;
      } else {
        data.select = false;
      }
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "幽默猜测"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeType), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(activeIndex) == index ? 'active' : ''),
          key: index,
          onClick: $event => _unref(codeSelect)(item.name, index)
        }, _toDisplayString(item.name), 11, _hoisted_2);
      }), 128))]), _unref(select) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "tk-title"
      }, "位置", -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(child), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, _toDisplayString(item.name), 1);
      }), 128))])])) : _createCommentVNode("", true)])], 64);
    };
  }
};