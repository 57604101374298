import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "tk-main-fans"
};
const _hoisted_2 = {
  class: "tk-fans"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tk-fans-picture"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "fans-name"
};
import search from '@/components/page/search.vue';
import { useStore } from 'vuex';
import { getFans } from '@/utils/https/me';
import { onBeforeMount } from 'vue';

import { reactive, computed, toRefs, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'fans',
  setup(__props) {
    onBeforeMount(() => {
      getFansx(data.page, data.keywords);
    });
    let data = reactive({
      fansInfo: [],
      page: 1,
      keywords: ''
    });
    const $store = useStore(); //获取vuex
    const configInfo = computed(() => {
      return $store.state.config;
    });
    const router = useRouter();
    let {
      fansInfo,
      http
    } = toRefs(data);
    let getFansx = (page, keywords = '') => {
      getFans({
        page: page,
        keywords: keywords
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        datas.forEach((element, index) => {
          if (element.fromuser == null) {
            datas.splice(index, 1);
          }
        });
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.fansInfo.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      data.page++;
      getFansx(data.page, data.keywords);
    };
    let onSubmit = item => {
      //搜索
      data.page = 1;
      data.keywords = item;
      data.fansInfo.length = 0;
      getFansx(data.page, data.keywords);
    };
    let userInfo = item => {
      router.push({
        path: '/userInfo',
        query: {
          id: item.user_id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的粉丝"
      }, {
        tkCont: _withCtx(() => [_createVNode(search, {
          returns: false,
          onOnSubmit: _unref(onSubmit)
        }, null, 8, ["onOnSubmit"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        "loading-text": " ",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_unref(fansInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fansInfo), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(userInfo)(item)
          }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
            src: item.fromuser.avatar == '' ? require('@/assets/image/logo.png') : _unref(configInfo).host2 + item.fromuser.avatar,
            alt: ""
          }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.fromuser.nickname), 1)], 8, _hoisted_4);
        }), 128))]))])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};