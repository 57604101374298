import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../assets/image/onreturn.png';
import _imports_1 from '../../../assets/image/weigui.png';
const _hoisted_1 = {
  class: "tk-main-head-nav",
  ref: "scrollTabs"
};
const _hoisted_2 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_3 = {
  class: "tk-main-room"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "tk-user-head"
};
const _hoisted_6 = {
  class: "tk-user-avater"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "tk-user-name"
};
const _hoisted_9 = {
  class: "name"
};
const _hoisted_10 = {
  class: "tiems"
};
const _hoisted_11 = {
  class: "tk-user-records"
};
const _hoisted_12 = {
  class: "tk-weigui"
};
const _hoisted_13 = {
  class: "tk-weigui-l"
};
const _hoisted_14 = {
  key: 0
};
import { getBlackHouse, getUserBlackHouse } from '@/utils/https/me';
import { onBeforeMount, reactive, toRefs } from 'vue';

import { useRouter } from 'vue-router';
import { ref } from 'vue';
export default {
  __name: 'search',
  setup(__props) {
    const router = useRouter();
    let data = reactive({
      blackRoom: [],
      page: 1,
      getUserInfo: {},
      keywords: ''
    });
    let {
      blackRoom,
      getUserInfo
    } = toRefs(data);

    //接口请求
    let getBlackHouses = (page, keywords) => {
      getBlackHouse({
        page: page,
        keywords: keywords
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.blackRoom.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      if (data.keywords == '') {
        finished.value = true;
      } else {
        data.page++;
        getBlackHouses(data.page, data.keywords);
      }
    };
    let onSubmit = item => {
      //搜索
      finished.value = false;
      data.page = 1;
      data.keywords = item;
      data.blackRoom.length = 0;
      getBlackHouses(data.page, data.keywords);
    };
    let returnUp = () => {
      router.back();
    };
    return (_ctx, _cache) => {
      const _component_search = _resolveComponent("search", true);
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "小黑屋", -1)), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-head-info"
      }, null, -1))]), _createVNode(_component_search, {
        returns: false,
        onOnSubmit: _unref(onSubmit)
      }, null, 8, ["onOnSubmit"])], 512), _cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "h"
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(blackRoom).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blackRoom), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
            src: item.user.avatar == '' ? require('@/assets/image/logo.png') : item.user.avatar,
            alt: ""
          }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(item.user.nickname), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, "共" + _toDisplayString(item.count) + "次违规", 1)])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("p", null, [_cache[5] || (_cache[5] = _createTextVNode("违规理由： ")), _createElementVNode("span", null, _toDisplayString(item.mushin.name), 1)]), _cache[7] || (_cache[7] = _createElementVNode("p", null, [_createTextVNode("处理结果： "), _createElementVNode("span", null, "23")], -1)), !item.is_forever ? (_openBlock(), _createElementBlock("p", _hoisted_14, [_cache[6] || (_cache[6] = _createTextVNode("解封时间： ")), _createElementVNode("span", null, _toDisplayString(item.mushin_end_date), 1)])) : _createCommentVNode("", true)]), _cache[8] || (_cache[8] = _createElementVNode("div", {
            class: "tk-weigui-r"
          }, [_createElementVNode("img", {
            src: _imports_1,
            alt: ""
          })], -1))])]);
        }), 128))]))]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};