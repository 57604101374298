import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, isRef as _isRef, Fragment as _Fragment } from "vue";
import _imports_0 from '../../../assets/image/img.png';
import _imports_1 from '../../../assets/image/tiems.png';
const _hoisted_1 = {
  key: 0,
  class: "tk-article"
};
const _hoisted_2 = {
  key: 1,
  class: "tk-article"
};
const _hoisted_3 = {
  class: "tk-main-comment flex-between"
};
import articles from '@/components/articles.vue';
import { onBeforeMount, toRefs } from 'vue';
import { diagramList, forecastList } from '@/utils/https/gallery';

import { useRoute, useRouter } from 'vue-router';
import { reactive, ref } from 'vue';
export default {
  __name: 'analysis',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    let data = reactive({
      activeIndex: 0,
      title: '',
      id: '',
      issue: '',
      page: 1,
      articlesInfo: [],
      guessInfo: []
    });
    onBeforeMount(() => {
      data.title = route.query.title;
      data.id = route.query.id;
      data.issue = route.query.issue;
    });
    let {
      activeIndex,
      title,
      articlesInfo,
      guessInfo
    } = toRefs(data);
    //获取接口
    let diagramLists = (id, page) => {
      diagramList({
        id: id,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        if (res.data.status == 40000) {
          finished.value = true;
        } else {
          let datas = res.data.data.data;
          loading.value = false;
          data.articlesInfo.push(...datas);
        }
      });
    };
    let forecastLists = (id, page) => {
      forecastList({
        pic_detail_id: id,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.data;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          datas.forEach(item => {
            let success = '0';
            let c = '';
            item.content.forEach(items => {
              if (items.success == 1) {
                success = items.success;
              } else if (items.success == '-1') {
                success = -1;
              }
              c += items.value;
            });
            item.content = item.body + ' ' + c;
            item.success = success;
          });
          data.guessInfo.push(...datas);
        }
      });
    };

    //功能区域
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      if (data.activeIndex == 0) {
        diagramLists(data.id, data.page);
      } else {
        forecastLists(data.id, data.page);
      }
      data.page++;
    };
    let principalTans = index => {
      //切换
      data.page = 1;
      data.guessInfo.length = 0;
      data.articlesInfo.length = 0;
      loading.value = false;
      finished.value = false;
      data.activeIndex = index;
      onLoad();
    };
    let comment = () => {
      //评论
      router.push({
        path: '/gallery/analysis/comment',
        query: {
          title: data.title,
          id: data.id,
          issue: data.issue
        }
      });
    };
    let articleDes = item => {
      if (data.activeIndex == 0) {
        router.push({
          path: '/gallery/analysis/details',
          query: {
            id: item.id
          }
        });
      } else {
        router.push({
          path: '/gallery/guess/details',
          query: {
            id: item.id
          }
        });
      }
    };
    let guessing = () => {
      router.push({
        path: '/gallery/analysis/guessing',
        query: {
          id: data.id
        }
      });
    };
    let handleAnalysisMe = () => {
      if (localStorage.getItem('usetInfo') == null) {
        router.push({
          path: '/login'
        });
      } else {
        let id = JSON.parse(localStorage.getItem('usetInfo')).id;
        router.push({
          path: '/userInfo',
          query: {
            id: id
          }
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(title)
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(guessing) && _unref(guessing)(...args))
        }, _cache[4] || (_cache[4] = [_createElementVNode("span", {
          style: {
            "font-size": "12px !important",
            "color": "var(--main-color) !important"
          }
        }, "参与竞猜", -1)]))]),
        tkCont: _withCtx(() => [_createVNode(_component_navTabsVue, {
          nameLeft: "图解小组",
          onPrincipalTans: _unref(principalTans),
          nameRight: "竞猜小组"
        }, null, 8, ["onPrincipalTans"])]),
        _: 1
      }, 8, ["title"]), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": " ",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(activeIndex) == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_unref(articlesInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : _createCommentVNode("", true), _unref(articlesInfo).length != 0 ? (_openBlock(), _createBlock(articles, {
          key: 1,
          onArticleDes: _unref(articleDes),
          guess: "图解",
          articlesArray: _unref(articlesInfo)
        }, null, 8, ["onArticleDes", "articlesArray"])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_unref(guessInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : _createCommentVNode("", true), _unref(guessInfo).length != 0 ? (_openBlock(), _createBlock(articles, {
          key: 1,
          onArticleDes: _unref(articleDes),
          guess: "竞猜",
          articlesArray: _unref(guessInfo)
        }, null, 8, ["onArticleDes", "articlesArray"])) : _createCommentVNode("", true)]))]),
        _: 1
      }, 8, ["loading", "finished"]), _cache[7] || (_cache[7] = _createElementVNode("div", {
        style: {
          "height": "55px"
        }
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "tk-comment",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(comment) && _unref(comment)(...args))
      }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "发布准确解料，抽送8888元彩金~", -1)])), _unref(activeIndex) != 0 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-guessing",
        onClick: _cache[3] || (_cache[3] = (...args) => _unref(handleAnalysisMe) && _unref(handleAnalysisMe)(...args))
      }, _cache[6] || (_cache[6] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "我的竞猜记录", -1)]))) : _createCommentVNode("", true)])], 64);
    };
  }
};