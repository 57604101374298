import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '../../../assets/image/onreturn.png';
import _imports_1 from '../../../assets/image/sehar.png';
const _hoisted_1 = {
  class: "tk-main-user"
};
const _hoisted_2 = {
  class: "tk-user-head"
};
const _hoisted_3 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_4 = {
  class: "tk-head-user-data"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "tk-head-user-info"
};
const _hoisted_7 = {
  class: "flex-between"
};
const _hoisted_8 = {
  class: "tk-info-num"
};
const _hoisted_9 = {
  class: "tk-info-num"
};
const _hoisted_10 = {
  class: "tk-info-num"
};
const _hoisted_11 = {
  class: "tk-info-num"
};
const _hoisted_12 = {
  key: 0,
  class: "tk-head-user-botton"
};
const _hoisted_13 = {
  class: "tk-l"
};
const _hoisted_14 = {
  class: "tk-r"
};
const _hoisted_15 = {
  class: "tk-user-major"
};
const _hoisted_16 = {
  class: "tk-major-nav"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = {
  class: "tk-link"
};
const _hoisted_19 = {
  class: "kt-user-serach"
};
const _hoisted_20 = {
  class: "tk-article"
};
import search from "@/components/page/search.vue";
import articles from "@/components/articles.vue";
import Dialog from "@/components/mode/message";
import { getUserIndex } from "@/utils/https/materials";
import { setFocus } from "@/utils/https/https";
import { release, setBlacklist } from "@/utils/https/user.js";

import { reactive, toRefs, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    let data = reactive({
      dataNav: [{
        name: "高手论坛"
      }, {
        name: "发现"
      }, {
        name: "资料大全"
      }, {
        name: "图解小组"
      }, {
        name: "竞猜小组"
      }],
      province: [{
        name: "全部",
        lottery: 0
      }, {
        name: "新澳彩",
        lottery: 2
      }, {
        name: "港彩",
        lottery: 1
      }, {
        name: "快乐八",
        lottery: 6
      }, {
        name: "老澳",
        lottery: 7
      }
      // { name: "台彩", lottery: 3 },
      // { name: "新彩", lottery: 4 },
      ],
      left: 0,
      guanzhuicno: true,
      //关注
      guanzhuicolor: "#FF1A00",
      //关注
      guanzhuname: "关注",
      laheicon: true,
      //拉黑
      lahecolor: "#07c160",
      //拉黑
      lahename: "拉黑",
      content: "",
      show: false,
      showShare: false,
      userid: "",
      //用户id
      getUserInfo: {},
      type: 1,
      //高手论坛=1，发现=2，资料大全=3，图解小组=4，竞猜小组=5
      lotteryType: 0,
      //全部=0，港彩=1，澳彩=2，台彩=3，新彩=4s
      keyword: "",
      //搜索
      discussInfo: [],
      discussVideo: [],
      //视频
      discussImg: [],
      //图片
      page: 1,
      meId: "",
      //自己的ID判断个人中心是否是自己
      checked: false
    });
    let article = reactive({
      articleData: []
    });
    let {
      checked,
      dataNav,
      province,
      left,
      guanzhuicno,
      guanzhuicolor,
      laheicon,
      lahecolor,
      guanzhuname,
      lahename,
      content,
      show,
      showShare,
      getUserInfo,
      discussInfo,
      type,
      meId,
      userid
    } = toRefs(data);
    const route = useRoute();
    const router = useRouter();
    onBeforeMount(() => {
      data.userid = route.query.id;
      data.meId = localStorage.getItem("usetInfo") == null ? "" : JSON.parse(localStorage.getItem("usetInfo")).id;
      getUser(data.userid);
    });
    let getUser = id => {
      //用户数据
      getUserIndex({
        id: id
      }).then(res => {
        if (res.status != "400") {
          let datas = res.data.data;
          data.getUserInfo = datas;
          if (!datas.isFocuson) {
            data.guanzhuicolor = "#FF1A00";
            data.guanzhuname = "关注";
          } else {
            data.guanzhuicolor = "#A1B1C8";
            data.guanzhuname = "已关注";
            data.guanzhuicno = false;
          }
          if (!datas.isShield) {
            data.lahecolor = "#07c160";
            data.lahename = "拉黑";
          } else {
            data.lahecolor = "#A1B1C8";
            data.lahename = "已拉黑";
            data.laheicon = false;
          }
        } else {
          Dialog(res.data.message, "#07c160");
        }
      });
    };
    let focus = id => {
      //关注
      setFocus({
        id: id
      }).then(res => {
        if (res.status != "400") {} else {
          Dialog(res.data.message, "#07c160");
        }
      });
    };
    let setBlacklists = id => {
      //拉黑
      setBlacklist({
        id: id
      }).then(res => {
        if (res.status != "400") {} else {
          Dialog(res.data.message, "#07c160");
        }
      });
    };
    let releases = (type, lotteryType, id, page, keyword) => {
      release({
        type: type,
        lotteryType: lotteryType,
        id: id,
        page: page,
        keyword: keyword
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          if (data.type == 3) {
            datas.forEach(element => {
              element.user = {};
              element.views = element.clickCount;
              element.user.avatar = element.avatar;
              element.user.nickname = element.nickname;
            });
          }
          if (data.type == 2) {
            datas.forEach(element => {
              if (element.type == 2) {
                data.discussVideo.push(element);
              } else {
                data.discussImg.push(element);
              }
            });
            datas = data.discussImg;
          }
          if (data.type == 5) {
            //判断是否是竞猜是的就改变数据
            let c = "";
            let success = "";
            datas.forEach(item => {
              item.content.forEach(items => {
                success = items.success;
                c += items.value + ",";
              });
              item.content = item.body + " " + c;
              item.success = success;
            });
          }
          loading.value = false;
          data.discussInfo.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      releases(data.type, data.lotteryType, data.userid, data.page, data.keyword);
      data.page++;
    };

    //功能区域

    let onSubmit = item => {
      //搜索
      data.keyword = item;
      data.page = 1;
      data.discussInfo.length = 0;
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let handleClick = () => {
      //视频
      data.discussInfo.length = 0;
      if (data.checked) {
        data.discussInfo.push(...data.discussVideo);
      } else {
        data.discussInfo.push(...data.discussImg);
      }
      //     let ccc = []
      //    if(data.checked){
      //         bbb.forEach((item,index)=>{
      //             if(item.type==2){
      //                 ccc.push(item)
      //             }
      //         })
      //         data.discussInfo = ccc;
      //    }else{
      //          data.discussInfo = bbb;
      //    };
    };
    const majornav = ref(null);
    let majoNav = index => {
      //切换文章类型 获取dom做了一个移动动画
      data.keyword = "";
      data.type = index + 1;
      data.page = 1;
      data.discussInfo.length = 0;
      loading.value = true;
      finished.value = false;
      onLoad();
      // releases(data.type,data.lotteryType,data.userid,data.page,data.keyword)
      let items = Array.from(majornav.value?.children);
      if (index == 1) {
        data.left = items[index].offsetLeft - 10 - items[index].clientWidth / 2;
      } else {
        data.left = items[index].offsetLeft - 10;
      }
    };
    let guanzhu = () => {
      //关注

      focus(data.userid);
      if (data.guanzhuname == "关注") {
        data.guanzhuicno = false;
        data.guanzhuicolor = "#A1B1C8";
        data.guanzhuname = "已关注";
        data.getUserInfo.follows += 1;
        Dialog("已关注", "#07c160");
      } else {
        data.guanzhuicno = true;
        data.guanzhuicolor = "#FF1A00";
        data.getUserInfo.follows -= 1;
        data.guanzhuname = "关注";
        Dialog("已取消关注", "#07c160");
      }
    };
    let lahe = () => {
      //拉黑
      setBlacklists(data.userid);
      if (data.lahename == "拉黑") {
        data.laheicon = false;
        data.lahecolor = "#A1B1C8";
        data.lahename = "已拉黑";
        Dialog("已拉黑", "#07c160");
      } else {
        data.laheicon = true;
        data.lahecolor = "#07c160";
        data.lahename = "拉黑";
        Dialog("已取消拉黑", "#07c160");
      }
    };
    let shareShow = () => {
      data.showShare = !data.shareShow;
    };
    let navGetToDesc = index => {
      switch (index) {
        case 1:
          router.push({
            path: "/user/fans",
            query: {
              id: data.userid
            }
          });
          break;
        case 2:
          router.push({
            path: "/user/like",
            query: {
              id: data.userid
            }
          });
          break;
        default:
          break;
      }
    };
    let returnUp = () => {
      //返回上一页
      router.back();
    };
    let majorHistory = () => {};
    let tabLottery = item => {
      //切换彩种

      data.lotteryType = item;
      data.page = 1;
      data.discussInfo.length = 0;
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let articleDes = item => {
      //进入详情
      if (data.type == 1) {
        router.push({
          path: "/master/details",
          query: {
            id: item.id,
            lottery: item.lotteryType
          }
        });
      }
      if (data.type == 2) {
        if (item.type == 2) {
          router.push({
            path: "/discover/video",
            query: {
              data: item.id,
              page: item.id
            }
          });
        } else {
          router.push({
            path: "/discover/details",
            query: {
              id: item.id,
              lottery: item.lotteryType
            }
          });
        }
      }
      if (data.type == 3) {
        router.push({
          path: "/materials/list/details",
          query: {
            id: item.id,
            corpusTypeId: item.corpusTypeId,
            lottery: item.lotteryType
          }
        });
      }
      if (data.type == 4) {
        router.push({
          path: "/gallery/analysis/details",
          query: {
            id: item.id,
            lottery: item.lotteryType
          }
        });
      }
      if (data.type == 5) {
        router.push({
          path: "/gallery/guess/details",
          query: {
            id: item.id,
            lottery: item.lotteryType
          }
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_lotteryTabs = _resolveComponent("lotteryTabs");
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      const _component_prompt = _resolveComponent("prompt");
      const _component_share = _resolveComponent("share");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[9] || (_cache[9] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[11] || (_cache[11] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "个人中心", -1)), _createElementVNode("div", {
        class: "tk-head-info",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(shareShow) && _unref(shareShow)(...args))
      }, _cache[10] || (_cache[10] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)]))]), _createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        src: _unref(getUserInfo).avatar == '' ? require('../../../assets/image/logo.png') : _ctx.$httpsUrl + _unref(getUserInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_5), _createElementVNode("span", null, _toDisplayString(_unref(getUserInfo).nickname), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("ul", _hoisted_7, [_createElementVNode("li", {
        onClick: _cache[2] || (_cache[2] = $event => _unref(navGetToDesc)(0))
      }, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(getUserInfo).likes), 1), _cache[12] || (_cache[12] = _createElementVNode("div", {
        class: "tk-info-text"
      }, "获赞", -1))]), _createElementVNode("li", {
        onClick: _cache[3] || (_cache[3] = $event => _unref(navGetToDesc)(1))
      }, [_createElementVNode("div", _hoisted_9, _toDisplayString(_unref(getUserInfo).fans), 1), _cache[13] || (_cache[13] = _createElementVNode("div", {
        class: "tk-info-text"
      }, "粉丝", -1))]), _createElementVNode("li", {
        onClick: _cache[4] || (_cache[4] = $event => _unref(navGetToDesc)(2))
      }, [_createElementVNode("div", _hoisted_10, _toDisplayString(_unref(getUserInfo).follows), 1), _cache[14] || (_cache[14] = _createElementVNode("div", {
        class: "tk-info-text"
      }, "关注", -1))]), _createElementVNode("li", {
        onClick: _cache[5] || (_cache[5] = $event => _unref(navGetToDesc)(3))
      }, [_createElementVNode("div", _hoisted_11, _toDisplayString(_unref(getUserInfo).releases), 1), _cache[15] || (_cache[15] = _createElementVNode("div", {
        class: "tk-info-text"
      }, "发布", -1))])])]), _unref(meId) != _unref(userid) ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createVNode(_component_botton, {
        onSubmits: _unref(guanzhu),
        name: _unref(guanzhuname),
        bgcolor: _unref(guanzhuicolor),
        guanzhu: _unref(guanzhuicno)
      }, null, 8, ["onSubmits", "name", "bgcolor", "guanzhu"])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_botton, {
        onSubmits: _unref(lahe),
        name: _unref(lahename),
        bgcolor: _unref(lahecolor),
        lahe: _unref(laheicon)
      }, null, 8, ["onSubmits", "name", "bgcolor", "lahe"])])])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("ul", {
        class: "flex-between",
        ref_key: "majornav",
        ref: majornav
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataNav), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(majoNav)(index)
        }, _toDisplayString(item.name), 9, _hoisted_17);
      }), 128))], 512), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", {
        class: "link",
        style: _normalizeStyle({
          left: _unref(left) + 'px'
        })
      }, null, 4)])]), _createVNode(_component_lotteryTabs, {
        province: _unref(province),
        onTabLottery: _unref(tabLottery),
        id: _unref(data).lotteryType
      }, null, 8, ["province", "onTabLottery", "id"]), _unref(type) == 2 ? (_openBlock(), _createBlock(_component_van_checkbox, {
        key: 0,
        onClick: _unref(handleClick),
        modelValue: _unref(checked),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(checked) ? checked.value = $event : checked = $event),
        "checked-color": "#07c160",
        shape: "square",
        "icon-size": "16px"
      }, {
        default: _withCtx(() => [_createElementVNode("span", {
          class: _normalizeClass({
            checkedColor: !_unref(checked)
          })
        }, "图片", 2), _cache[16] || (_cache[16] = _createTextVNode("/")), _createElementVNode("span", {
          class: _normalizeClass({
            checkedColor: _unref(checked)
          })
        }, "视频", 2)]),
        _: 1
      }, 8, ["onClick", "modelValue"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_19, [_createVNode(search, {
        returns: false,
        onOnSubmit: _unref(onSubmit)
      }, null, 8, ["onOnSubmit"])]), _createElementVNode("div", _hoisted_20, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[7] || (_cache[7] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(discussInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : _createCommentVNode("", true), _unref(type) == 1 ? (_openBlock(), _createBlock(articles, {
          key: 1,
          articlesArray: _unref(discussInfo),
          onArticleDes: _unref(articleDes),
          guess: "1"
        }, null, 8, ["articlesArray", "onArticleDes"])) : _createCommentVNode("", true), _unref(type) == 2 ? (_openBlock(), _createBlock(articles, {
          key: 2,
          articlesArray: _unref(discussInfo),
          onArticleDes: _unref(articleDes),
          guess: "2"
        }, null, 8, ["articlesArray", "onArticleDes"])) : _createCommentVNode("", true), _unref(type) == 3 ? (_openBlock(), _createBlock(articles, {
          key: 3,
          articlesArray: _unref(discussInfo),
          onArticleDes: _unref(articleDes),
          guess: "3"
        }, null, 8, ["articlesArray", "onArticleDes"])) : _createCommentVNode("", true), _unref(type) == 4 ? (_openBlock(), _createBlock(articles, {
          key: 4,
          articlesArray: _unref(discussInfo),
          onArticleDes: _unref(articleDes),
          guess: "4"
        }, null, 8, ["articlesArray", "onArticleDes"])) : _createCommentVNode("", true), _unref(type) == 5 ? (_openBlock(), _createBlock(articles, {
          key: 5,
          articlesArray: _unref(discussInfo),
          onArticleDes: _unref(articleDes),
          guess: "5"
        }, null, 8, ["articlesArray", "onArticleDes"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["loading", "finished"])])])]), _createVNode(_component_prompt, {
        tail: _unref(content),
        show: _unref(show)
      }, null, 8, ["tail", "show"]), _createVNode(_component_share, {
        shows: _unref(showShare),
        "onUpdate:shows": _cache[8] || (_cache[8] = $event => _isRef(showShare) ? showShare.value = $event : showShare = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};