import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, isRef as _isRef } from "vue";
import _imports_0 from '../../assets//image/me/add.png';
import _imports_1 from '../../assets/image/me/moneys.png';
import _imports_2 from '../../assets/image/me/copy.png';
const _hoisted_1 = {
  class: "tk-head"
};
const _hoisted_2 = {
  class: "tk-item"
};
const _hoisted_3 = {
  class: "num"
};
const _hoisted_4 = {
  class: "tk-item"
};
const _hoisted_5 = {
  class: "num"
};
const _hoisted_6 = {
  class: "tk-bonus"
};
const _hoisted_7 = {
  class: "tk-bonus-list"
};
const _hoisted_8 = {
  class: "title"
};
const _hoisted_9 = {
  class: "name"
};
const _hoisted_10 = {
  key: 0,
  class: "money"
};
const _hoisted_11 = {
  key: 1,
  class: "money"
};
const _hoisted_12 = {
  key: 2,
  class: "money"
};
const _hoisted_13 = {
  class: "tk-item-cont"
};
const _hoisted_14 = {
  class: "tk-item-cont-left"
};
const _hoisted_15 = {
  class: "order"
};
const _hoisted_16 = {
  class: "text"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = {
  class: "items"
};
const _hoisted_19 = ["onClick"];
const _hoisted_20 = {
  key: 1,
  class: "tk-item-cont-rigth sdc"
};
const _hoisted_21 = {
  key: 2,
  class: "tk-item-cont-rigth sdc"
};
import Dialog from '@/components/mode/message';
import { user_welfare, user_welfareCount, user_welfareReceive } from '@/utils/https/me';
import { onBeforeMount } from 'vue';

import { reactive, toRefs, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'bonus',
  setup(__props) {
    let datas = reactive({
      bonusInfo: [],
      page: 1,
      total: '0',
      sum: '0'
    });
    const router = useRouter();
    let {
      bonusInfo,
      http,
      total,
      sum
    } = toRefs(datas);
    onBeforeMount(() => {
      user_welfareCounts();
    });
    let getFansx = async page => {
      let {
        data
      } = await user_welfare({
        page,
        limit: 20
      });
      if (data.status == 20000) {
        if (data.data.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          datas.bonusInfo.push(...data.data);
        }
      } else {
        Dialog(data.message, '#07c160');
      }
    };
    let user_welfareCounts = async () => {
      let {
        data
      } = await user_welfareCount();
      if (data.status == 20000) {
        datas.total = data.data.total;
        datas.sum = data.data.sum;
      } else {
        Dialog(data.message, '#07c160');
      }
    };
    let user_welfareReceives = async id => {
      let {
        data
      } = await user_welfareReceive({
        id
      });
      if (data.status == 20000) {
        datas.bonusInfo.forEach(element => {
          if (element.id == id) {
            element.status = 1;
            element.really_money = data.data.really_money;
            datas.sum = (Math.floor(data.data.really_money) + Math.floor(datas.sum)).toFixed(2);
          }
        });
        Dialog("领取成功", '#07c160');
      } else {
        Dialog(data.message, '#07c160');
      }
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getFansx(datas.page);
      datas.page++;
    };
    const copyText = async val => {
      if (navigator.clipboard && navigator.permissions) {
        await navigator.clipboard.writeText(val);
        Dialog("复制成功！", '#07c160');
      } else {
        const textArea = document.createElement("textArea");
        textArea.value = val;
        textArea.style.width = 0;
        textArea.style.position = "fixed";
        textArea.style.left = "-999px";
        textArea.style.top = "10px";
        textArea.setAttribute("readonly", "readonly");
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        Dialog("复制成功！", '#07c160');
      }
    };
    let userInfo = item => {
      router.push({
        path: '/userInfo',
        query: {
          id: item.user_id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "福利中心"
      }, {
        tkCont: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("div", {
          class: "cont"
        }, [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }), _createElementVNode("div", {
          class: "name"
        }, "总笔数")], -1)), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(total)), 1)]), _createElementVNode("div", _hoisted_4, [_cache[2] || (_cache[2] = _createElementVNode("div", {
          class: "cont"
        }, [_createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }), _createElementVNode("div", {
          class: "name"
        }, "总金额")], -1)), _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(sum)), 1)])])]),
        _: 1
      }), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "bg"
      }, null, -1)), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        "loading-text": " ",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_unref(bonusInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bonusInfo), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(item.name), 1), item.status == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(item.really_money), 1)) : item.random_money.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_cache[3] || (_cache[3] = _createElementVNode("span", null, "¥", -1)), _createTextVNode(" " + _toDisplayString(item.really_money), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_12, [_cache[4] || (_cache[4] = _createElementVNode("span", null, "¥", -1)), _createTextVNode(" " + _toDisplayString(item.random_money[0]) + " ~ " + _toDisplayString(item.random_money[1]), 1)]))]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, _toDisplayString(item.order_num), 1), _createElementVNode("img", {
            onClick: $event => copyText(item.order_num),
            src: _imports_2,
            alt: ""
          }, null, 8, _hoisted_17)]), _createElementVNode("div", _hoisted_18, _toDisplayString(item.created_at), 1)]), item.status == 0 ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "tk-item-cont-rigth",
            onClick: $event => _unref(user_welfareReceives)(item.id)
          }, "领取", 8, _hoisted_19)) : _createCommentVNode("", true), item.status == -1 ? (_openBlock(), _createElementBlock("div", _hoisted_20, "已失效")) : _createCommentVNode("", true), item.status == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_21, "已领取")) : _createCommentVNode("", true)])]);
        }), 128))])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};