import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, withModifiers as _withModifiers, isRef as _isRef, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '../../assets/image/activity/showPage.png';
import _imports_1 from '../../assets/image/activity/clos.png';
import Dialog from '@/components/mode/message';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'page',
  setup(__props, {
    expose: __expose
  }) {
    let show = ref(false);
    const router = useRouter();
    __expose({
      show
    });
    const copyContent = () => {
      router.push({
        path: "/activity/index"
      });
    };
    return (_ctx, _cache) => {
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: _unref(show),
        "z-index": "1000",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => _isRef(show) ? show.value = false : show = false, ["stop"]))
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => _isRef(show) ? show.value = false : show = false, ["stop"]))
        }, [_createElementVNode("div", {
          class: "image",
          onClick: _withModifiers(copyContent, ["stop"])
        }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)])), _cache[3] || (_cache[3] = _createElementVNode("div", {
          class: "cols"
        }, [_createElementVNode("img", {
          src: _imports_1,
          alt: ""
        })], -1))])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};