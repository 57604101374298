import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withModifiers as _withModifiers } from "vue";
import _imports_0 from '../../assets/image/uploads.png';
const _hoisted_1 = {
  class: "tk-share-content"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["href"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "name"
};
const _hoisted_7 = {
  class: "tk-share-cancle"
};
import { onBeforeMount } from 'vue';
import tk_botton from '../botton.vue';
import Dialog from '@/components/mode/message';
import { forward } from '@/utils/https/https';

import { ref } from 'vue';
export default {
  __name: 'share',
  props: {
    shows: {
      type: Boolean,
      default: false
    },
    video: {
      type: String,
      default: ''
    }
  },
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let emits = __emit;
    let dataImg = ref([{
      image: require('../../assets/image/weixin.png'),
      name: '微信'
    }, {
      image: require('../../assets/image/pengyouquan.png'),
      name: '朋友圈'
    }, {
      image: require('../../assets/image/duanxin.png'),
      name: '短信'
    }]);
    onBeforeMount(() => {

      // console.log(url);
    });
    let forwards = () => {
      forward().then(res => {});
    };
    let cancle = () => {
      emits('update:shows', false);
    };
    let copy = r => {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', r);
      input.setAttribute('readonly', 'readonly');
      input.select();
      input.setSelectionRange(0, 999);
      if (document.execCommand('copy')) {
        Dialog('复制成功,快去分享吧');
      }
      document.body.removeChild(input);
    };
    let smsLink = ref('');
    let handleShare = async index => {
      let url = window.location.href;
      let tel = '';
      var token = localStorage.getItem('Authorization');
      if (index == 0) {
        copy(url);
        if (token != null) {
          forwards();
        }
      }
      if (index == 1) {
        copy(url);
        if (token != null) {
          forwards();
        }
      }
      if (index == 2) {
        if (token != null) {
          forwards();
        }
        var u = navigator.userAgent;
        //判断是否为android终端
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
        //判断是否为ios终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        //ios终端
        if (isiOS) {
          smsLink.value = "sms:" + tel + "&body=" + url;
          // document.getElementsByTagName('a')[0].href=
          //安卓终端
        } else {
          smsLink.value = "sms:" + tel + "&body=" + url;
          // document.getElementsByTagName('a')[0].href="sms:"+tel+"?body="+url;
        }
      }
      if (index == 3) {
        if (token != null) {
          forwards();
        }
        try {
          handleload();
        } catch (err) {
          Dialog('下载视频失败');
        }
      }
    };
    let handleload = () => {
      const a = document.createElement("a");
      a.href = props.video;
      a.download = 'test_video' + Date.now();
      a.click();
      a.remove();
    };
    return (_ctx, _cache) => {
      return __props.shows ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-mian-share",
        onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => _unref(cancle) && _unref(cancle)(...args), ["stop"]))
      }, [_createElementVNode("div", {
        class: "tk-share",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
      }, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-share-title"
      }, "温馨提示", -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataImg), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(handleShare)(index)
        }, [index == 2 ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _unref(smsLink),
          ref_for: true,
          ref: "ac"
        }, [_createElementVNode("img", {
          src: item.image,
          alt: ""
        }, null, 8, _hoisted_4)], 8, _hoisted_3)) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: item.image,
          alt: ""
        }, null, 8, _hoisted_5)), _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1)], 8, _hoisted_2);
      }), 128)), __props.video != '' ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = $event => _unref(handleShare)(3))
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("div", {
        class: "name"
      }, "下载", -1)]))) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_7, [_createVNode(tk_botton, {
        onSubmits: _unref(cancle)
      }, null, 8, ["onSubmits"])])])])) : _createCommentVNode("", true);
    };
  }
};