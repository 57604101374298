import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, isRef as _isRef, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../../../assets/image/date.png';
import _imports_1 from '../../../assets/image/right-t.png';
const _hoisted_1 = {
  key: 0,
  class: "tk-lottery-history"
};
const _hoisted_2 = {
  class: "tk-history-title flex-between"
};
const _hoisted_3 = {
  class: "tk-title"
};
const _hoisted_4 = {
  class: "tk-screening"
};
const _hoisted_5 = {
  class: "tk-screening-ul"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0,
  class: "tk-select"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  class: "tk-code-title flex-between"
};
const _hoisted_10 = {
  class: "code-title"
};
const _hoisted_11 = {
  class: "code-tiems"
};
const _hoisted_12 = {
  class: "tk-lottery-box"
};
const _hoisted_13 = {
  class: "sx"
};
const _hoisted_14 = {
  key: 1,
  class: "tk-tiems-box"
};
const _hoisted_15 = {
  class: "tk-tiems-calendar"
};
const _hoisted_16 = {
  class: "tk-calendar-header"
};
const _hoisted_17 = ["data-index"];
const _hoisted_18 = {
  class: "tk-calendar-date"
};
const _hoisted_19 = ["onClick"];
import { newYear } from '@/utils/dateTiem';
import { history, open_date } from '@/utils/https/lottery';

import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, ref, reactive, toRefs, computed } from 'vue';
export default {
  __name: 'history',
  setup(__props) {
    let route = useRoute();
    onBeforeMount(() => {
      let type = route.query.lottery;
      if (type == undefined) {
        data.type = localStorage.getItem('lotteryType');
      } else {
        data.type = type;
      }
      open_dates(data.type);
    });
    let numarray = ref({
      Day: "12",
      Moon: "05",
      Nq: "132",
      Qi: "131",
      Time: "21点33分",
      Week: "周五",
      Year: "2023",
      data: [{
        "nim": "水",
        "number": "28",
        "style": "green",
        "sx": "鼠"
      }, {
        "nim": "木",
        "number": "05",
        "style": "green",
        "sx": "猪"
      }, {
        "nim": "火",
        "number": "38",
        "style": "green",
        "sx": "虎"
      }, {
        "nim": "金",
        "number": "23",
        "style": "red",
        "sx": "蛇"
      }, {
        "nim": "火",
        "number": "29",
        "style": "red",
        "sx": "猪"
      }, {
        "nim": "火",
        "number": "08",
        "style": "red",
        "sx": "猴"
      }, {
        "nim": "木",
        "number": "43",
        "style": "green",
        "sx": "鸡"
      }]
    });
    let tiems = reactive({
      //开奖日期
      year: '',
      month: '',
      dates: '',
      days: [],
      opendate: []
    });
    let data = reactive({
      show: 0,
      page: 1,
      //分页
      year: localStorage.getItem('yearone'),
      //年份
      type: localStorage.getItem('lotteryType'),
      //彩票类型
      sort: 'desc',
      //升序
      historyData: [],
      //历史列表
      screeningList: [{
        name: '升序',
        select: 0
      }, {
        name: '五行',
        select: 1
      }, {
        name: '默认',
        select: 0
      }],
      activeIndex: 1,
      shows: false,
      sortIndex: 0,
      //状态0是默认1是升序2是将序，默认0
      sxWx: true,
      //是否显示五行
      showyears: false,
      openLottery: 0
    });
    let {
      year,
      month,
      dates,
      days,
      opendate
    } = toRefs(tiems);
    let {
      show,
      historyData,
      screeningList,
      activeIndex,
      shows,
      sortIndex,
      sxWx,
      showyears
    } = toRefs(data);
    let datas = () => {
      var date = new Date();
      tiems.year = date.getFullYear(); //获取完整的年份(4位)
      tiems.month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      tiems.dates = date.getDate(); //获取当前日(1-31)
      var d = new Date(tiems.year, tiems.month, 0);
      var days = d.getDate();
      for (let i = 1; i <= days; i++) {
        tiems.days.push({
          num: i,
          select: 0
        });
      }
    };
    let showYear = () => {
      data.showyears = true;
    };
    let handleTiem = item => {
      //选择日期
      tiems.dates = item.num;
      // fullName(tiems.days,item.num)
    };
    let onConfirm = item => {
      //获取prompt选择的值
      data.historyData.length = 0;
      data.year = item;
      historyInfo(data.year, data.type, data.sort, data.page);
    };
    let principalTans = index => {
      data.show = index;
    };

    //分页请求
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const onLoad = () => {
      historyInfo(data.year, data.type, data.sort, data.page);
      data.page++;
    };
    const onRefresh = () => {
      // 清空列表数据
      data.page = 1;
      data.historyData.length = 0;
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    //接口请求区域
    let historyInfo = (year, type, sort = 'asc', page) => {
      history({
        year: year,
        lotteryType: type,
        sort: sort,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.data;
        let lotteryTime = [];
        datas.forEach(item => {
          //循环改变数组里面的内容变成自己想要的数据，可优化 代码稀撇。
          lotteryTime = item.lotteryTime.split('-');
          item.lotteryTime = `${lotteryTime[0]}年${lotteryTime[1]}月${lotteryTime[2]}日`;
          if (data.sortIndex == 1) {
            //判断平码升序降序
            let pingcode = item.number_attr.pop();
            let res = item.number_attr.sort(function (a, b) {
              return a.number - b.number; //升序
            });
            res.push(pingcode);
          } else if (data.sortIndex == 2) {
            let pingcode = item.number_attr.pop();
            let res = item.number_attr.sort(function (a, b) {
              return b.number - a.number; //降序
            });
            res.push(pingcode);
          }
          item.number_attr.forEach(items => {
            if (items.color == '1') {
              items.color = 'red';
            } else if (items.color == '2') {
              items.color = 'blue';
            } else {
              items.color = 'greed';
            }
          });
        });
        refreshing.value = false;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.historyData.push(...datas);
        }
      });
    };
    let open_dates = lotteryType => {
      open_date({
        lotteryType: lotteryType
      }).then(res => {
        if (res == undefined) return false;
        datas();
        let datass = res.data.data.dayList;
        tiems.days.forEach(element => {
          datass.forEach(item => {
            if (element < 10) {
              element = '0' + element;
            }
            if (item.split('-')[2] == element.num) {
              element.select = 1;
            }
          });
        });
        tiems.opendate.push(...datass);
      });
    };
    //功能区域
    let fullName = computed(() => (item, c) => {
      //计算属性传递参数
      let lottery = 0;
      item.forEach(element => {
        if (element.select == 1 && element.num == c) {
          lottery = 1;
        }
      });
      return lottery;
    });
    let screening = index => {
      //选择五行，升序，默认
      data.activeIndex = index;
      if (index == 1) {
        data.sxWx = !data.sxWx;
        if (data.screeningList[index].select == 0) {
          //是否显示五行
          data.screeningList[index].select = 1;
        } else {
          data.screeningList[index].select = 0;
        }
      }
      if (index == 2) {
        data.shows = !data.shows;
      }
      if (index == 0) {
        data.page = 1;
        data.historyData.length = 0;
        if (data.screeningList[index].name == '升序') {
          data.screeningList[index].name = '降序';
          data.sort = 'asc';
          //  historyInfo(data.year,data.type,data.sort,data.page)
          data.historyData.length = 0;
          finished.value = false;
          loading.value = true;
          onLoad();
        } else {
          data.screeningList[index].name = '升序';
          data.sort = 'desc';
          //  historyInfo(data.year,data.type,data.sort,data.page)
          finished.value = false;
          loading.value = true;
          onLoad();
        }
      }
    };
    let selectSort = index => {
      //选择平码升序降序 0 是默认 1 是升序 2是降序
      data.sortIndex = index;
      let info = data.historyData;
      if (index == 0) {
        data.screeningList[2].name = '默认';
        data.page = 1;
        data.historyData.length = 0;
        historyInfo(data.year, data.type, data.sort, data.page); //请求历史接口
      } else if (index == 1) {
        data.screeningList[2].name = '平码升序';
        historyFun(info);
      } else {
        data.screeningList[2].name = '平码降序';
        historyFun(info);
      }
    };
    let historyFun = datas => {
      //升序降序函数
      datas.forEach(item => {
        //循环改变数组里面的内容变成自己想要的数据，可优化 代码稀撇。
        if (data.sortIndex == 1) {
          //判断平码升序降序
          let pingcode = item.number_attr.pop();
          let res = item.number_attr.sort(function (a, b) {
            return a.number - b.number; //升序
          });
          res.push(pingcode);
        } else if (data.sortIndex == 2) {
          let pingcode = item.number_attr.pop();
          let res = item.number_attr.sort(function (a, b) {
            return b.number - a.number; //降序
          });
          res.push(pingcode);
        }
      });
      return datas;
    };
    let router = useRouter();
    let handleDesc = item => {
      router.push({
        path: '/lottery/history/details',
        query: {
          id: item.id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_majorBack = _resolveComponent("majorBack");
      const _component_picker = _resolveComponent("picker");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "开奖历史"
      }, {
        tkCont: _withCtx(() => [_createVNode(_component_navTabsVue, {
          nameLeft: "开奖记录",
          onPrincipalTans: _unref(principalTans),
          nameRight: "开奖日期"
        }, null, 8, ["onPrincipalTans"])]),
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(showYear) && _unref(showYear)(...args))
        }, _cache[7] || (_cache[7] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }), !_unref(show) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_pull_refresh, {
        modelValue: _unref(refreshing),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(refreshing) ? refreshing.value = $event : null),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: _unref(loading),
          "onUpdate:loading": _cache[4] || (_cache[4] = $event => _isRef(loading) ? loading.value = $event : null),
          finished: _unref(finished),
          "finished-text": "没有更多了",
          onLoad: onLoad
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(data).year) + "年历史开奖记录", 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(screeningList), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              class: _normalizeClass(item.select == 1 ? 'tk-screening-li t-active' : 'tk-screening-li t-activ'),
              onClick: $event => _unref(screening)(index),
              key: index
            }, [_createTextVNode(_toDisplayString(item.name) + " ", 1), _unref(activeIndex) == 2 && index == 2 && _unref(shows) ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("ul", null, [_createElementVNode("li", {
              class: _normalizeClass(_unref(sortIndex) == 0 ? 'flex-between tc-active' : 'flex-between'),
              onClick: _cache[1] || (_cache[1] = $event => _unref(selectSort)(0))
            }, _cache[8] || (_cache[8] = [_createElementVNode("div", null, "默认", -1), _createElementVNode("div", {
              class: "select"
            }, [_createElementVNode("div")], -1)]), 2), _createElementVNode("li", {
              class: _normalizeClass(_unref(sortIndex) == 1 ? 'flex-between tc-active' : 'flex-between'),
              onClick: _cache[2] || (_cache[2] = $event => _unref(selectSort)(1))
            }, _cache[9] || (_cache[9] = [_createElementVNode("div", null, "平码升序", -1), _createElementVNode("div", {
              class: "select"
            }, [_createElementVNode("div")], -1)]), 2), _createElementVNode("li", {
              class: _normalizeClass(_unref(sortIndex) == 2 ? 'flex-between tc-active' : 'flex-between'),
              onClick: _cache[3] || (_cache[3] = $event => _unref(selectSort)(2))
            }, _cache[10] || (_cache[10] = [_createElementVNode("div", null, "平码降序", -1), _createElementVNode("div", {
              class: "select"
            }, [_createElementVNode("div")], -1)]), 2)])])) : _createCommentVNode("", true)], 10, _hoisted_6);
          }), 128))])])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(historyData), (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tk-lottery-code",
              key: index,
              onClick: $event => _unref(handleDesc)(item)
            }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, "第" + _toDisplayString(item.issue) + "期最新开奖结果", 1), _createElementVNode("div", _hoisted_11, _toDisplayString(item.lotteryTime), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.number_attr, (items, indexs) => {
              return _openBlock(), _createElementBlock("li", {
                key: indexs
              }, [_createElementVNode("div", {
                class: _normalizeClass(items.color + ' num')
              }, [_createElementVNode("span", null, _toDisplayString(items.number), 1)], 2), _createElementVNode("div", _hoisted_13, _toDisplayString(items.shengXiao) + _toDisplayString(_unref(sxWx) ? '/' + items.wuXing : ''), 1)]);
            }), 128))])])], 8, _hoisted_8);
          }), 128))]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"])])) : (_openBlock(), _createElementBlock("div", _hoisted_14, [_cache[12] || (_cache[12] = _createElementVNode("div", {
        class: "tk-prompt-cont"
      }, [_createElementVNode("div", {
        class: "tk-prompt-title"
      }, "温馨提示"), _createElementVNode("div", {
        class: "tk-prompt-titles"
      }, "搅珠日期对照表，可查看当月及下一个月的搅珠开奖日期")], -1)), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("span", null, _toDisplayString(_unref(year)) + "-" + _toDisplayString(_unref(month)), 1), _cache[11] || (_cache[11] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1))]), _createElementVNode("div", {
        class: "tk-calendar-day",
        "data-index": _unref(fullName)(_unref(days), _unref(dates))
      }, "今天：" + _toDisplayString(_unref(year)) + "-" + _toDisplayString(_unref(month)) + "-" + _toDisplayString(_unref(dates)) + " " + _toDisplayString(_unref(fullName)(_unref(days), _unref(dates)) ? '是' : '不是') + "开奖日期", 9, _hoisted_17), _createElementVNode("div", _hoisted_18, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(days), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          onClick: $event => _unref(handleTiem)(item),
          class: _normalizeClass(item.select ? 'active' : ''),
          style: _normalizeStyle({
            'background': _unref(dates) == item.num ? '#A1B1C8 !important' : ''
          }),
          key: index
        }, _toDisplayString(item.num), 15, _hoisted_19);
      }), 128))])])])])), _createVNode(_component_majorBack), _createVNode(_component_picker, {
        show: _unref(showyears),
        "onUpdate:show": _cache[6] || (_cache[6] = $event => _isRef(showyears) ? showyears.value = $event : showyears = $event),
        onOnConfirm: _unref(onConfirm)
      }, null, 8, ["show", "onOnConfirm"])], 64);
    };
  }
};