import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/major/provice/2031.png';
import _imports_1 from '../../../assets/image/major/provice/2017.png';
import _imports_2 from '../../../assets/image/major/provice/2018.png';
import _imports_3 from '../../../assets/image/major/provice/2032.png';
import _imports_4 from '../../../assets/image/sright.png';
const _hoisted_1 = {
  class: "tk-main-materals-list"
};
const _hoisted_2 = {
  class: "tk-main-collect"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tk-img"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "tk-title"
};
const _hoisted_8 = {
  class: "info"
};
const _hoisted_9 = {
  key: 0,
  class: "tk-lottery"
};
const _hoisted_10 = {
  key: 1,
  class: "tk-lottery"
};
const _hoisted_11 = {
  key: 2,
  class: "tk-lottery"
};
const _hoisted_12 = {
  key: 3,
  class: "tk-lottery"
};
const _hoisted_13 = {
  key: 4,
  class: "tk-lottery"
};
const _hoisted_14 = {
  key: 5,
  class: "tk-lottery"
};
const _hoisted_15 = {
  key: 6,
  class: "tk-lottery"
};
import search from '@/components/page/search.vue';
import { onBeforeMount } from 'vue';
import { getCollect } from '@/utils/https/me';

import { useRoute, useRouter } from 'vue-router';
import { reactive, toRefs, ref } from 'vue';
export default {
  __name: 'search',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    let data = reactive({
      type: '1',
      lotteryType: '2',
      content: '',
      page: 1,
      collectData: []
    });
    let {
      type,
      collectData
    } = toRefs(data);
    onBeforeMount(() => {
      data.type = route.query.type;
      data.lotteryType = route.query.lotteryType;
    });
    let getCollects = (type, lotteryType, name, page) => {
      getCollect({
        type: type,
        lotteryType: lotteryType,
        pictureName: name,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (type == 2) {
          datas.forEach(element => {
            element.pic_detail = element.humorou;
            element.pic_detail.picurl = element.humorou.imageUrl;
            element.pic_detail.pictureName = element.humorou.title;
          });
        }
        if (type == 3) {
          datas.forEach(element => {
            element.pic_detail = element.user_discovery;
            element.pic_detail.picurl = element.user_discovery.images[0].img_url;
            element.pic_detail.pictureName = element.user_discovery.title;
          });
        }
        if (datas == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.collectData.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getCollects(data.type, data.lotteryType, data.content, data.page);
      data.page++;
    };
    let onSubmit = item => {
      //搜索
      data.page = 1;
      data.collectData.length = 0;
      getCollects(data.type, data.lotteryType, item, data.page);
    };
    let majorDesc = item => {
      if (data.type == 1) {
        router.push({
          path: `/gallery`,
          query: {
            color: item.pic_detail.color,
            year: item.pic_detail.year,
            id: item.pic_detail.pictureTypeId,
            title: item.pic_detail.pictureName,
            issue: item.pic_detail.issue,
            lottery: item.pic_detail.lotteryType
          }
        });
      }
      if (data.type == 2) {
        router.push({
          path: `/guess`,
          query: {
            id: item.humorou.id,
            year: item.humorou.year,
            lottery: item.humorou.lotteryType,
            issue: item.humorou.issue
          }
        });
      }
      if (data.type == 3) {
        router.push({
          path: '/discover/video',
          query: {
            data: item.user_discovery.id,
            page: item.id
          }
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_di = _resolveComponent("di");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的收藏"
      }, {
        tkCont: _withCtx(() => [_createVNode(search, {
          returns: false,
          onOnSubmit: _unref(onSubmit)
        }, null, 8, ["onOnSubmit"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_di, {
          class: "tk-collect-item"
        }, {
          default: _withCtx(() => [_unref(collectData).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
            key: 0
          })) : (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collectData), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              key: index,
              onClick: $event => _unref(majorDesc)(item)
            }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
              src: item.pic_detail.picurl,
              alt: ""
            }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.pic_detail.pictureName), 1), _createElementVNode("div", _hoisted_8, [item.pic_detail.lotteryType == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[1] || (_cache[1] = [_createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "澳门", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[2] || (_cache[2] = [_createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "新澳门", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[3] || (_cache[3] = [_createElementVNode("img", {
              src: _imports_1,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "香港", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 6 ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[4] || (_cache[4] = [_createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "快乐八", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 7 ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[5] || (_cache[5] = [_createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "老澳", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[6] || (_cache[6] = [_createElementVNode("img", {
              src: _imports_2,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "台湾", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[7] || (_cache[7] = [_createElementVNode("img", {
              src: _imports_3,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "新加坡", -1)]))) : _createCommentVNode("", true), _cache[8] || (_cache[8] = _createElementVNode("div", {
              class: "tk-desc"
            }, [_createElementVNode("img", {
              src: _imports_4,
              alt: ""
            })], -1))])], 8, _hoisted_4);
          }), 128))]))]),
          _: 1
        })])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};