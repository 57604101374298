import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../../assets/image/about.png';
const _hoisted_1 = {
  class: "tk-main-about"
};
const _hoisted_2 = ["innerHTML"];
import { onBeforeMount } from "vue";

import { ref } from 'vue';
export default {
  __name: 'about',
  setup(__props) {
    let about = ref('');
    onBeforeMount(() => {
      window.scrollTo(0, 0);
      about.value = JSON.parse(localStorage.getItem('config')).about_us;
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "关于我们"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "link-url",
        innerHTML: _unref(about)
      }, null, 8, _hoisted_2), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "tk-about-img"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1))])], 64);
    };
  }
};