import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, isRef as _isRef, Fragment as _Fragment } from "vue";
import _imports_0 from '../../../assets/image/more.png';
import _imports_1 from '../../../assets/image/tisp.png';
const _hoisted_1 = {
  class: "tk-main-details"
};
const _hoisted_2 = {
  class: "tk-details-content"
};
const _hoisted_3 = {
  class: "tk-details-info"
};
const _hoisted_4 = {
  class: "tk-picture"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "tk-user"
};
const _hoisted_7 = {
  class: "tk-name"
};
const _hoisted_8 = {
  class: "tk-tiems"
};
const _hoisted_9 = {
  class: "tk-info-like"
};
const _hoisted_10 = {
  class: "num"
};
const _hoisted_11 = {
  class: "num"
};
const _hoisted_12 = {
  class: "num"
};
const _hoisted_13 = {
  class: "num"
};
const _hoisted_14 = {
  class: "tk-info-button"
};
const _hoisted_15 = {
  class: "tk-l",
  style: {
    "width": "100%"
  }
};
const _hoisted_16 = {
  class: "tk-tips"
};
const _hoisted_17 = {
  class: "tk-read"
};
const _hoisted_18 = {
  class: "name"
};
const _hoisted_19 = {
  class: "tk-deails-title"
};
const _hoisted_20 = ["innerHTML"];
const _hoisted_21 = {
  class: "tk-deails-img"
};
const _hoisted_22 = ["src"];
const _hoisted_23 = ["src"];
import lottery from '@/components/page/lottery.vue';
import comment from '@/components/page/comment.vue';
import comment_list from '@/components/page/comment_list.vue';
import more from '@/components/page/more.vue';
import share from '@/components/page/share.vue';
import Dialog from '@/components/mode/message';
import { newYear } from '@/utils/dateTiem';
import { numbers } from '@/utils/https/lottery';
// import {discoveryDetail,discoveryFollow} from '@/utils/https/discover'
import { diagramDetail, diagramFollow } from '@/utils/https/gallery';
import { getUserIndex } from '@/utils/https/materials';
import { setFocus } from '@/utils/https/https';

import { reactive, toRefs, ref, onBeforeMount, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'details',
  setup(__props) {
    let data = reactive({
      show: false,
      id: '',
      //文章id
      articleInfo: {},
      getUserInfo: {},
      guanzhuicno: true,
      //关注
      guanzhuicolor: '#FF1A00',
      //关注
      guanzhuname: '关注',
      shows: false,
      //显示分享页面
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {} : JSON.parse(localStorage.getItem('config')),
      numarray: {
        year: newYear,
        issue: "000",
        lotteryType: 1,
        lotteryTime: "2020-12-29",
        number_attr: [{
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }]
      },
      //开奖号码
      lotteryCode: true
    });
    let {
      show,
      shows,
      articleInfo,
      user,
      guanzhuicno,
      guanzhuicolor,
      guanzhuname,
      getUserInfo,
      id,
      websiteName,
      https,
      numarray,
      lotteryCode
    } = toRefs(data);
    let router = useRouter();
    let route = useRoute();
    let configImg = ref(null);
    onBeforeMount(() => {
      data.id = route.query.id;
      infoData(data.id);
      window.scrollTo(0, 0);
      if (localStorage.getItem('config') != null) {
        let datas = JSON.parse(localStorage.getItem('config'));
        configImg.value = datas;
      }
    });
    //数据请求区域
    let infoData = id => {
      diagramDetail({
        id: id
      }).then(res => {
        //帖子详情
        if (res.status != '400') {
          let datas = res.data.data;
          datas.isFollow = datas.follow;
          datas.isCollect = datas.collect;
          data.articleInfo = datas;
          getUser(datas.user_id);
          number(datas.picture.year, datas.lotteryType, datas.issue);
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let getUser = id => {
      //用户数据
      getUserIndex({
        id: id
      }).then(res => {
        if (res.status != '400') {
          let datas = res.data.data;
          data.getUserInfo = datas;
          if (!datas.isFocuson) {
            data.guanzhuicolor = '#FF1A00';
            data.guanzhuname = '关注';
          } else {
            data.guanzhuicolor = '#A1B1C8';
            data.guanzhuname = '已关注';
            data.guanzhuicno = false;
          }
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let focus = id => {
      //关注
      setFocus({
        id: id
      }).then(res => {
        if (res.status != '400') {} else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let onefollow = id => {
      //点赞
      diagramFollow({
        diagrams_id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let number = (year, lotteryType, issue) => {
      //每一期开奖结果
      numbers({
        year: year,
        lotteryType: lotteryType,
        issue: issue
      }).then(res => {
        if (res == undefined) return false;
        if (res.data.status == 40000) {
          data.lotteryCode = false;
        } else {
          data.lotteryCode = true;
          let datas = res.data.data;
          data.numarray = datas;
        }
      });
    };
    //功能区域
    let majoFun = index => {
      //comment组件的信息数据
      if (index == 0) {
        //点赞
        if (!data.articleInfo.isFollow) {
          //判断是否点赞
          data.articleInfo.isFollow = 1;
          Dialog('点赞成功', '#07c160');
          data.articleInfo.thumbUpCount += 1;
          data.getUserInfo.likes += 1;
        } else {
          data.articleInfo.isFollow = 0;
          data.articleInfo.thumbUpCount -= 1;
          data.getUserInfo.likes -= 1;
          Dialog('已取消点赞', '#07c160');
        }
        onefollow(data.articleInfo.id);
      } else if (index == 2) {
        data.shows = true;
      }
    };
    let guanzhu = () => {
      //关注
      focus(data.articleInfo.user_id);
      if (data.guanzhuname == '关注') {
        data.guanzhuicno = false;
        data.guanzhuicolor = '#A1B1C8';
        data.guanzhuname = '已关注';
        data.getUserInfo.follows += 1;
        Dialog('已关注', '#07c160');
      } else {
        data.guanzhuicno = true;
        data.guanzhuicolor = '#FF1A00';
        data.getUserInfo.follows -= 1;
        data.guanzhuname = '关注';
        Dialog('已取消关注', '#07c160');
      }
    };
    let lahe = () => {
      //跳转历史记录
      router.push({
        path: `/materials/list/history`,
        query: {
          id: data.articleInfo.id,
          title: data.getUserInfo.nickname,
          userId: data.articleInfo.user_id
        }
      });
    };
    let majorMore = () => {
      //显示更多
      data.show = true;
    };
    let funcMore = index => {
      //更多弹窗里面的信息 0 是用户主页，1是历史记录  2是举报
      if (index == '') {
        router.push({
          path: `/materials/list/history`,
          query: {
            id: data.articleInfo.id,
            title: data.getUserInfo.nickname,
            userId: data.articleInfo.user_id
          }
        });
      } else if (index == 0) {
        router.push({
          path: '/userInfo'
        });
      } else if (index == 1) {
        router.push({
          path: '/user/report',
          query: {
            typeId: 9,
            id: data.articleInfo.id,
            corpusTypeId: ''
          }
        });
      }
    };
    let majoruser = id => {
      //跳转用户中心
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    let contentbox = computed(() => item => {
      //替换
      if (item != undefined) {
        return item.replace(/\n/g, "<br>");
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      const _component_van_image = _resolveComponent("van-image");
      const _component_advertisement = _resolveComponent("advertisement");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(articleInfo).title
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(majorMore) && _unref(majorMore)(...args))
        }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_unref(lotteryCode) ? (_openBlock(), _createBlock(lottery, {
        key: 0,
        types: false,
        data: _unref(numarray)
      }, null, 8, ["data"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: "tk-info-head",
        onClick: _cache[1] || (_cache[1] = $event => _unref(majoruser)(_unref(getUserInfo).id))
      }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        src: _ctx.$httpsUrl + _unref(getUserInfo).avatar == '' ? _ctx.$httpsUrl + _unref(websiteName).logo : _ctx.$httpsUrl + _unref(getUserInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_unref(getUserInfo).nickname), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(articleInfo).created_at), 1)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("ul", null, [_createElementVNode("li", null, [_createElementVNode("span", _hoisted_10, _toDisplayString(_unref(getUserInfo).likes), 1), _cache[5] || (_cache[5] = _createElementVNode("span", {
        class: "name"
      }, "获赞", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_11, _toDisplayString(_unref(getUserInfo).fans), 1), _cache[6] || (_cache[6] = _createElementVNode("span", {
        class: "name"
      }, "粉丝", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_12, _toDisplayString(_unref(getUserInfo).follows), 1), _cache[7] || (_cache[7] = _createElementVNode("span", {
        class: "name"
      }, "关注", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_13, _toDisplayString(_unref(getUserInfo).growth_score), 1), _cache[8] || (_cache[8] = _createElementVNode("span", {
        class: "name"
      }, "成长值", -1))]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createVNode(_component_botton, {
        onSubmits: _unref(guanzhu),
        name: _unref(guanzhuname),
        bgcolor: _unref(guanzhuicolor),
        guanzhu: _unref(guanzhuicno)
      }, null, 8, ["onSubmits", "name", "bgcolor", "guanzhu"])])])])]), _createElementVNode("div", _hoisted_16, [_cache[10] || (_cache[10] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_17, [_createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$filters.format(_unref(articleInfo).views)), 1), _cache[9] || (_cache[9] = _createElementVNode("p", {
        class: "name"
      }, "阅读数", -1))])])]), _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(articleInfo).title), 1), _createElementVNode("div", {
        class: "tk-deails-content",
        innerHTML: _unref(contentbox)(_unref(articleInfo).content)
      }, null, 8, _hoisted_20), _createElementVNode("div", _hoisted_21, [_createVNode(_component_van_image, {
        src: _unref(articleInfo).largePictureUrl
      }, {
        error: _withCtx(() => [_unref(articleInfo).largePictureUrlOther != '' ? (_openBlock(), _createBlock(_component_van_image, {
          key: 0,
          src: _unref(articleInfo).largePictureUrlOther
        }, {
          error: _withCtx(() => [_createElementVNode("img", {
            src: _ctx.$httpsUrl + _unref(configImg).notfind_image,
            alt: ""
          }, null, 8, _hoisted_22)]),
          _: 1
        }, 8, ["src"])) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.$httpsUrl + _unref(configImg).notfind_image,
          alt: ""
        }, null, 8, _hoisted_23))]),
        _: 1
      }, 8, ["src"])])])]), _createVNode(_component_advertisement), _createVNode(comment_list, {
        type: "2",
        id: _unref(id)
      }, null, 8, ["id"]), _createVNode(comment, {
        dataInfo: _unref(articleInfo),
        typeId: "2",
        onMajoFun: _unref(majoFun),
        type: "3",
        userInfo: _unref(getUserInfo)
      }, null, 8, ["dataInfo", "onMajoFun", "userInfo"]), _createVNode(more, {
        show: _unref(show),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(show) ? show.value = $event : show = $event),
        onMajorMore: _unref(funcMore)
      }, null, 8, ["show", "onMajorMore"]), _createVNode(share, {
        shows: _unref(shows),
        "onUpdate:shows": _cache[3] || (_cache[3] = $event => _isRef(shows) ? shows.value = $event : shows = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};