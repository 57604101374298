import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "tabbar"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "icon"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "selectIcon"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "name"
};
const _hoisted_8 = {
  key: 0,
  class: "tips"
};
import { computed, onActivated, onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';
import { roomList } from '@/utils/https/wechar';
import Dialog from '@/components/mode/message.js';

import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'tabbar',
  setup(__props) {
    let data = reactive({
      list: [],
      msg: computed(() => {
        return store.state.msg;
      })
    });
    const store = useStore(); //获取vuex

    const tabbar = ref([{
      icon: require('@/assets/image/tabbar/pages.png'),
      name: '49图库',
      selectIcon: require('@/assets/image/tabbar/pageselects.png'),
      path: '/index'
    }, {
      icon: require('@/assets/image/tabbar/fxs.png'),
      name: '发现',
      selectIcon: require('@/assets/image/tabbar/fsselects.png'),
      path: '/discover'
    }, {
      icon: require('@/assets/image/wechar.png'),
      name: '聊天室',
      selectIcon: require('@/assets/image/wechar.png'),
      path: ''
    }, {
      icon: require('@/assets/image/tabbar/xbs.png'),
      name: '平台',
      selectIcon: require('@/assets/image/tabbar/xbselects.png'),
      path: '/platform'
    }, {
      icon: require('@/assets/image/tabbar/mes.png'),
      name: '我的',
      selectIcon: require('@/assets/image/tabbar/meselects.png'),
      path: '/me'
    }]);
    let gameShow = ref(false);
    let wechar = item => {
      msg.value = store.state.msg;
      if (item.name == '聊天室') {
        if (localStorage.getItem('room_info') != null) {
          data.list = JSON.parse(localStorage.getItem('room_info'));
          if (localStorage.getItem('chatToken') != null) {
            data.list.push({
              name: '旧版六合聊天室',
              id: '9'
            });
          }
          if (data.list.length == 0) {
            router.push({
              path: '/wechat',
              query: {
                title: data.list[0].name,
                id: data.list[0].id
              }
            });
          } else if (data.list.length == 0) {
            Dialog('暂无聊天列表');
          } else {
            gameShow.value = true;
          }
        } else {
          roomLists();
        }
      }
    };
    let list = reactive([]);
    let roomLists = () => {
      roomList().then(res => {
        list.length = 0;
        let datas = res.data.data;
        data.list = datas;
        if (localStorage.getItem('chatToken') != null) {
          data.list.push({
            name: '旧版六合聊天室',
            id: '9'
          });
        }
        if (datas.length == 1) {
          router.push({
            path: '/wechat',
            query: {
              title: datas[0].name,
              id: datas[0].id
            }
          });
        } else if (datas.length == 0) {
          Dialog('暂无聊天列表');
        } else {
          gameShow.value = true;
        }
        datas.forEach((item, index) => {
          switch (item.id) {
            case 1:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2028.png')
              });
              break;
            case 2:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            case 3:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2029.png')
              });
              break;
            case 4:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2019.png')
              });
              break;
            case 5:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            default:
              break;
          }
        });
        localStorage.setItem('room_info', JSON.stringify(list));
      });
    };
    let fullName = computed(() => () => {
      //计算属性传递参数
      return store.state.msg;
    });
    let msg = ref(null);
    onActivated(() => {
      console.log(store.state.msg);
      msg.value = store.state.msg;
    });
    const router = useRouter();
    let selecRoom = item => {
      //选择聊天室
      router.push({
        path: '/wechat',
        query: {
          title: item.name,
          id: item.id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_wecharList = _resolveComponent("wecharList");
      return _openBlock(), _createElementBlock(_Fragment, null, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "h"
      }, null, -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabbar), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "item",
          key: index,
          onClick: $event => _unref(wechar)(item)
        }, [_createVNode(_component_router_link, {
          "active-class": "activeIndex",
          to: item.path
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
            src: item.icon,
            alt: ""
          }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
            src: item.selectIcon,
            alt: ""
          }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["to"]), item.name == '我的' && _unref(data).msg > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("p", null, _toDisplayString(_unref(data).msg), 1)])) : _createCommentVNode("", true)], 8, _hoisted_2);
      }), 128))])]), _createVNode(_component_wecharList, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[0] || (_cache[0] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        onSelecRoom: _unref(selecRoom),
        list: _unref(data).list
      }, null, 8, ["show", "onSelecRoom", "list"])], 64);
    };
  }
};