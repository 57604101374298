import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
import _imports_0 from '../../assets/image/kf.png';
import _imports_1 from '../../assets/image/name.png';
const _hoisted_1 = {
  class: "tk-kf-box"
};
const _hoisted_2 = {
  key: 0,
  class: "tk-kf-title"
};
const _hoisted_3 = {
  key: 1,
  class: "tk-img"
};
const _hoisted_4 = {
  key: 2,
  class: "tk-bottom"
};
const _hoisted_5 = ["src"];
import { onBeforeMount, reactive } from "vue";
export default {
  __name: 'kf',
  setup(__props) {
    let data = reactive({
      show: true,
      url: 'https://msg.pinglun.site/msg'
    });
    onBeforeMount(() => {
      window.scrollTo(0, 0);
      let config = JSON.parse(localStorage.getItem('config'));
      data.url = config.server_url;
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "联系客服"
      }), _createElementVNode("div", _hoisted_1, [_unref(data).show ? (_openBlock(), _createElementBlock("div", _hoisted_2, "7*24小时在线服务")) : _createCommentVNode("", true), _unref(data).show ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]))) : _createCommentVNode("", true), _unref(data).show ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", {
        class: "bottom",
        onClick: _cache[0] || (_cache[0] = $event => _unref(data).show = false)
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)]))])) : (_openBlock(), _createElementBlock("iframe", {
        key: 3,
        src: _unref(data).url,
        frameborder: "0"
      }, null, 8, _hoisted_5))])], 64);
    };
  }
};