import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../../assets/image/date.png';
const _hoisted_1 = {
  class: "page"
};
const _hoisted_2 = {
  class: "calendar"
};
const _hoisted_3 = {
  class: "calendar-header"
};
const _hoisted_4 = {
  class: "month"
};
const _hoisted_5 = {
  class: "year"
};
const _hoisted_6 = {
  class: "next-day"
};
const _hoisted_7 = {
  class: "day"
};
const _hoisted_8 = {
  class: "calendar-body"
};
const _hoisted_9 = {
  class: "calendar-week"
};
const _hoisted_10 = {
  class: "week"
};
const _hoisted_11 = {
  class: "week"
};
const _hoisted_12 = {
  class: "calendar-week zh"
};
const _hoisted_13 = {
  class: "week"
};
const _hoisted_14 = {
  class: "week"
};
const _hoisted_15 = {
  class: "calendar-box"
};
const _hoisted_16 = {
  class: "calendar-box-header"
};
const _hoisted_17 = {
  class: "calendar-box-header-title"
};
const _hoisted_18 = {
  style: {
    "padding": "0 12px"
  }
};
const _hoisted_19 = {
  class: "calendar-box-header-date"
};
const _hoisted_20 = {
  class: "calendar-box-bodys"
};
const _hoisted_21 = {
  class: "calendar-box-bodys"
};
import axios from "axios";
import { ref, computed, onBeforeMount } from "vue";
export default {
  __name: 'calendar',
  setup(__props) {
    const currentDate = ref(['2021', '01', '01']);
    const minDate = ref(new Date(1900, 0, 1));
    const maxDate = ref(new Date(2070, 0, 1));
    const month = ref([]);
    const currentDateIndex = ref(0);
    const showBottom = ref(false);
    const NumberSize = computed(() => item => {
      let big = [1, 3, 5, 7, 8, 10, 12];
      if (item) {
        return big.includes(item) ? '大' : '小';
      }
    });
    const week = computed(() => item => {
      let big = {
        '一': 'Monday',
        '二': 'Tuesday',
        '三': 'Wednesday',
        '四': 'Thursday',
        '五': 'Friday',
        '六': 'Saturday',
        '日': 'Sunday'
      };
      if (item) {
        return big[item];
      }
    });
    const getPost = data => {
      axios.get("https://kj.3666kjkj.com/wnl?ym=" + data).then(res => {
        month.value = res.data.data;
        currentDateIndex.value = findCurrentDateIndex();
        off.value = false;
      }).catch(err => {});
    };
    function findCurrentDateIndex() {
      let currentDates;
      if (off) {
        currentDates = currentDate.value.join("-");
      } else {
        currentDates = getCurrentDate();
      }
      console.log(currentDates);
      return month.value.findIndex(item => item.date === currentDates);
    }
    function getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    const dateItem = computed(() => {
      if (month.value.length > 0) {
        return month.value[currentDateIndex.value];
      } else {
        return null;
      }
    });
    const nextDay = () => {
      if (currentDateIndex.value < month.value.length - 1) {
        currentDateIndex.value++;
      }
    };
    const previousDay = () => {
      if (currentDateIndex.value > 0) {
        currentDateIndex.value--;
      }
    };
    const off = ref(false);
    const handleConfirm = e => {
      off.value = true;
      getPost(currentDate.value[0] + currentDate.value[1]);
      showBottom.value = false;
    };
    onBeforeMount(() => {
      getPost(202501);
      currentDate.value = getCurrentDate().split("-");
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_col = _resolveComponent("van-col");
      const _component_van_row = _resolveComponent("van-row");
      const _component_van_date_picker = _resolveComponent("van-date-picker");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_headNav, {
        title: "老黄历"
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[1] || (_cache[1] = (...args) => _ctx.showYear && _ctx.showYear(...args))
        }, [_createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = $event => showBottom.value = true),
          src: _imports_0,
          alt: ""
        })])]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(Number(dateItem.value?.date.split("-")[1])) + "月" + _toDisplayString(NumberSize.value(Number(dateItem.value?.date.split("-")[1]))), 1), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "title"
      }, null, -1)), _createElementVNode("div", _hoisted_5, _toDisplayString(dateItem.value?.date.split("-")[0]) + "年", 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
        class: "next up",
        onClick: previousDay
      }, [_createVNode(_component_van_icon, {
        name: "arrow-left"
      })]), _createElementVNode("div", _hoisted_7, _toDisplayString(dateItem.value?.date.split("-")[2]), 1), _createElementVNode("div", {
        class: "next",
        onClick: nextDay
      }, [_createVNode(_component_van_icon, {
        name: "arrow"
      })])]), _createElementVNode("div", _hoisted_8, [_cache[5] || (_cache[5] = _createElementVNode("img", {
        src: "https://6gdaa.c60031.com/calendar/yellow_header.png",
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(week.value(dateItem.value?.week)), 1), _createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(dateItem.value?.nianganzhi) + "年", 1), _createElementVNode("span", null, _toDisplayString(dateItem.value?.nongliyue), 1)])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, "星期" + _toDisplayString(dateItem.value?.week), 1), _createElementVNode("div", _hoisted_14, _toDisplayString(dateItem.value?.nongliri), 1)])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_van_row, null, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          style: {
            "border-right": "1.5px solid #f77e04"
          },
          span: "5"
        }, {
          default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("p", {
            class: "title"
          }, "宜", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.yi), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          class: "van-col-one",
          style: {
            "border-right": "1.5px solid #f77e04"
          },
          span: "14"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_17, [_createVNode(_component_van_row, null, {
            default: _withCtx(() => [_createVNode(_component_van_col, {
              style: {
                "border-right": "1.5px solid #f77e04"
              },
              span: "8"
            }, {
              default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("p", {
                class: "title"
              }, "吉神宜趋", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.jishen), 1)]),
              _: 1
            }), _createVNode(_component_van_col, {
              style: {
                "border-right": "1.5px solid #f77e04"
              },
              span: "8"
            }, {
              default: _withCtx(() => [_cache[8] || (_cache[8] = _createElementVNode("p", {
                class: "title"
              }, "六合吉数", -1)), _createElementVNode("p", _hoisted_18, _toDisplayString(dateItem.value?.jishu), 1)]),
              _: 1
            }), _createVNode(_component_van_col, {
              span: "8"
            }, {
              default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("p", {
                class: "title"
              }, "凶神宜趋", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.xiongshen), 1)]),
              _: 1
            })]),
            _: 1
          })]), _createElementVNode("div", _hoisted_19, [_cache[10] || (_cache[10] = _createElementVNode("p", {
            class: "title"
          }, "时辰吉凶", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.jixiong), 1)])]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "5"
        }, {
          default: _withCtx(() => [_cache[11] || (_cache[11] = _createElementVNode("p", {
            class: "title"
          }, "忌", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.ji), 1)]),
          _: 1
        })]),
        _: 1
      })]), _createElementVNode("div", _hoisted_20, [_createVNode(_component_van_row, null, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          style: {
            "border-right": "1.5px solid #f77e04"
          },
          span: "8"
        }, {
          default: _withCtx(() => [_cache[12] || (_cache[12] = _createElementVNode("p", {
            class: "title"
          }, "冲煞", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.chongsha), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          style: {
            "border-right": "1.5px solid #f77e04"
          },
          span: "8"
        }, {
          default: _withCtx(() => [_cache[13] || (_cache[13] = _createElementVNode("p", {
            class: "title"
          }, "干支", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.yueganzhi) + " " + _toDisplayString(dateItem.value?.nianganzhi) + " " + _toDisplayString(dateItem.value?.riganzhi), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "8"
        }, {
          default: _withCtx(() => [_cache[14] || (_cache[14] = _createElementVNode("p", {
            class: "title"
          }, "数九数伏", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.shujiushufu), 1)]),
          _: 1
        })]),
        _: 1
      })]), _createElementVNode("div", _hoisted_21, [_createVNode(_component_van_row, null, {
        default: _withCtx(() => [_createVNode(_component_van_col, {
          style: {
            "border-right": "1.5px solid #f77e04"
          },
          span: "20"
        }, {
          default: _withCtx(() => [_cache[15] || (_cache[15] = _createElementVNode("p", {
            class: "title"
          }, "彭祖百忌", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.baiji), 1)]),
          _: 1
        }), _createVNode(_component_van_col, {
          span: "4"
        }, {
          default: _withCtx(() => [_cache[16] || (_cache[16] = _createElementVNode("p", {
            class: "title"
          }, "五行", -1)), _createElementVNode("p", null, _toDisplayString(dateItem.value?.wuxing), 1)]),
          _: 1
        })]),
        _: 1
      })])])])]), _createVNode(_component_van_popup, {
        show: showBottom.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => showBottom.value = $event),
        position: "bottom",
        style: {
          height: '40%'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_date_picker, {
          modelValue: currentDate.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => currentDate.value = $event),
          title: "选择日期",
          "min-date": minDate.value,
          "max-date": maxDate.value,
          onConfirm: handleConfirm
        }, null, 8, ["modelValue", "min-date", "max-date"])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};