import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, isRef as _isRef, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../../assets/image/god.png';
const _hoisted_1 = {
  class: "tk-main-love"
};
const _hoisted_2 = {
  class: "tk-turntable-box"
};
const _hoisted_3 = {
  class: "tk-box"
};
const _hoisted_4 = {
  class: "item"
};
const _hoisted_5 = {
  class: "item"
};
const _hoisted_6 = {
  class: "messge"
};
const _hoisted_7 = {
  class: "cont"
};
const _hoisted_8 = {
  class: "block"
};
import { onBeforeMount, toRefs } from "vue";

import { reactive } from 'vue';
export default {
  __name: 'secret',
  setup(__props) {
    let data = reactive({
      sx: '开始',
      ws: '开始',
      show: false,
      textList: [],
      textLista: [],
      textListb: [],
      sxList: ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '猪', '狗'],
      wxList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      showHead: true
    });
    onBeforeMount(() => {
      cc();
    });
    let cc = () => {
      if (localStorage.getItem('Device') != null) {
        data.showHead = false;
      } else {
        let c = setTimeout(() => {
          if (localStorage.getItem('Device') != null) {
            data.showHead = false;
          }
          clearTimeout(c);
        }, 100);
      }
    };
    let {
      sx,
      ws,
      show,
      textList,
      textLista,
      textListb
    } = toRefs(data);
    //功能区域

    let sxRandom = () => {
      if (data.sx != '开始') return false;
      data.show = true;
      data.sxList.forEach((item, index) => {
        data.textList[index] = data.sxList[Math.floor(Math.random() * 12)] + data.sxList[Math.floor(Math.random() * 12)] + data.sxList[Math.floor(Math.random() * 12)];
        data.textLista[index] = data.sxList[Math.floor(Math.random() * 12)] + data.sxList[Math.floor(Math.random() * 12)] + data.sxList[Math.floor(Math.random() * 12)];
        data.textListb[index] = data.sxList[Math.floor(Math.random() * 12)] + data.sxList[Math.floor(Math.random() * 12)] + data.sxList[Math.floor(Math.random() * 12)];
      });
      let c = setTimeout(() => {
        data.show = false;
        data.sx = data.sxList[Math.floor(Math.random() * 12)] + ' ' + data.sxList[Math.floor(Math.random() * 12)] + ' ' + data.sxList[Math.floor(Math.random() * 12)];
      }, 2000);
    };
    let wsRandom = () => {
      data.textList.length = 0;
      data.textLista.length = 0;
      data.textListb.length = 0;
      if (data.ws != '开始') return false;
      data.show = true;
      data.wxList.forEach((item, index) => {
        data.textList[index] = data.wxList[Math.floor(Math.random() * 9)] + data.wxList[Math.floor(Math.random() * 9)] + data.wxList[Math.floor(Math.random() * 9)];
        data.textLista[index] = data.wxList[Math.floor(Math.random() * 9)] + data.wxList[Math.floor(Math.random() * 9)] + data.wxList[Math.floor(Math.random() * 9)];
        data.textListb[index] = data.wxList[Math.floor(Math.random() * 9)] + data.wxList[Math.floor(Math.random() * 9)] + data.wxList[Math.floor(Math.random() * 9)];
      });
      let c = setTimeout(() => {
        data.show = false;
        data.ws = data.wxList[Math.floor(Math.random() * 9)] + ' ' + data.wxList[Math.floor(Math.random() * 9)] + ' ' + data.wxList[Math.floor(Math.random() * 9)];
      }, 2000);
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_rolling_text = _resolveComponent("van-rolling-text");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(data).showHead ? (_openBlock(), _createBlock(_component_headNav, {
        key: 0,
        title: "天机测算"
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "tk-love-description"
      }, [_createElementVNode("div", {
        class: "tk-love-title"
      }, "简介"), _createElementVNode("div", {
        class: "tk-desc"
      }, "天机泄露啦!所求何事,让诸葛丞相为您卜一卦,祝您六合彩 顺风顺水。")], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "title"
      }, "求生肖", -1)), _createElementVNode("div", {
        class: _normalizeClass(_unref(sx) == '开始' ? 'botton' : 'active botton'),
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(sxRandom) && _unref(sxRandom)(...args))
      }, _toDisplayString(_unref(sx)), 3)]), _createElementVNode("div", _hoisted_5, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "title"
      }, "求尾数", -1)), _createElementVNode("div", {
        class: _normalizeClass(_unref(ws) == '开始' ? 'botton' : 'active botton'),
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(wsRandom) && _unref(wsRandom)(...args))
      }, _toDisplayString(_unref(ws)), 3)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("p", null, _toDisplayString(_unref(sx) == '开始' ? '? ? ?' : _unref(sx)), 1), _createElementVNode("p", null, _toDisplayString(_unref(ws) == '开始' ? '? ? ?' : _unref(ws)), 1)])]), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "img_bg"
      }, [_createElementVNode("div", {
        class: "other"
      }), _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1))])]), _createVNode(_component_van_overlay, {
        show: _unref(show),
        onClick: _cache[3] || (_cache[3] = $event => _isRef(show) ? show.value = false : show = false)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_van_rolling_text, {
          class: "my-rolling-text",
          "text-list": _unref(textList),
          duration: 2,
          "auto-start": _unref(show),
          direction: "up"
        }, null, 8, ["text-list", "auto-start"]), _createVNode(_component_van_rolling_text, {
          class: "my-rolling-text",
          "text-list": _unref(textLista),
          duration: 2,
          "auto-start": _unref(show),
          direction: "down"
        }, null, 8, ["text-list", "auto-start"]), _createVNode(_component_van_rolling_text, {
          class: "my-rolling-text",
          "text-list": _unref(textListb),
          duration: 2,
          "auto-start": _unref(show),
          direction: "up"
        }, null, 8, ["text-list", "auto-start"])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};