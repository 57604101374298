import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '../../assets/image/error.png';
const _hoisted_1 = {
  key: 0,
  class: "vant-swiper-body-mini"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  class: "lable"
};
const _hoisted_4 = {
  key: 1,
  class: "vant-swiper-body-mini",
  style: {
    "background-color": "#ff5c70"
  }
};
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = {
  class: "lable"
};
import { h } from "vue";
import { onBeforeMount } from "vue";
export default {
  __name: 'swipers',
  props: {
    swiperImg: {
      type: Array,
      default: []
    },
    listImg: {
      type: Array,
      default: []
    }
  },
  setup(__props) {
    let props = __props;
    // let swiperImg = ref([
    //     {cover:'https://picsum.photos/360/460?radom='+Math.ceil(Math.random()*100),url:'www.baidu.com'},
    //     {cover:'https://picsum.photos/360/460?radom='+Math.ceil(Math.random()*100),url:'www.baidu.com'},
    //     {cover:'https://picsum.photos/360/460?radom='+Math.ceil(Math.random()*100),url:'www.baidu.com'},
    //     {cover:'https://picsum.photos/360/460?radom='+Math.ceil(Math.random()*100),url:'www.baidu.com'},
    //     {cover:'https://picsum.photos/360/460?radom='+Math.ceil(Math.random()*100),url:'www.baidu.com'},
    // ])
    // 在modules加入要使用的模块

    const handleAdJump = item => {
      let device = JSON.parse(localStorage.getItem("device"));
      if (device && device.system) {
        Android.openWithBrowser(item);
      } else {
        window.location.href = item;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      return _openBlock(), _createElementBlock(_Fragment, null, [__props.listImg.length != 0 && __props.listImg[0] ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "title",
        innerHTML: __props.listImg[0].title
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
        style: {
          "color": "#07c160"
        },
        onClick: _cache[0] || (_cache[0] = (...args) => __props.listImg[0].ad_url && __props.listImg[0].ad_url(...args))
      }, "点击立即投注")])])) : _createCommentVNode("", true), _createVNode(_component_van_swipe, {
        class: "my-swipe",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.swiperImg, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index,
            onClick: $event => handleAdJump(item.ad_url)
          }, {
            default: _withCtx(() => [_createVNode(_component_van_image, {
              src: item.ad_image
            }, {
              error: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }, null, -1)])),
              _: 2
            }, 1032, ["src"])]),
            _: 2
          }, 1032, ["onClick"]);
        }), 128))]),
        _: 1
      }), __props.listImg.length != 0 && __props.listImg[1] ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", {
        class: "title",
        innerHTML: __props.listImg[1].title
      }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, [_createElementVNode("a", {
        style: {
          "color": "#ff5c70"
        },
        onClick: _cache[1] || (_cache[1] = (...args) => __props.listImg[1].ad_url && __props.listImg[1].ad_url(...args))
      }, " 点击立即投注 ")])])) : _createCommentVNode("", true)], 64);
    };
  }
};