import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "tk-container-wrap"
};
const _hoisted_2 = {
  class: "tk-top-wrap"
};
const _hoisted_3 = {
  class: "tk-wrap-title"
};
const _hoisted_4 = {
  class: "tk-wrap"
};
import { onBeforeMount } from "vue";

import { reactive, toRefs } from 'vue';
export default {
  __name: 'jiaqin',
  setup(__props) {
    let data = reactive({
      codeData: [{
        name: '家禽',
        list: ['牛、', '马、', '羊、', '鸡、', '狗、', '猪']
      }, {
        name: '野兽',
        list: ['鼠、', '虎、', '兔、', '龙、', '蛇、', '猴']
      }]
    });
    onBeforeMount(() => {
      if (localStorage.getItem("number_attr2") != null) {
        data.codeData = JSON.parse(localStorage.getItem("number_attr2")).animalTypeList;
      }
    });
    let {
      codeData
    } = toRefs(data);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeData), (item, index) => {
        return _openBlock(), _createElementBlock("ul", {
          key: index
        }, [_createElementVNode("li", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (items, indexs) => {
          return _openBlock(), _createElementBlock("p", {
            key: indexs
          }, _toDisplayString(items), 1);
        }), 128))])])]);
      }), 128))]);
    };
  }
};