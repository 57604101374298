import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, isRef as _isRef, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../../assets/image/searchblack.png';
import _imports_1 from '../../../assets/image/img.png';
const _hoisted_1 = {
  class: "tk-notice"
};
const _hoisted_2 = {
  class: "tk-master-tabs"
};
const _hoisted_3 = {
  class: "tk-master-lotteryTabs"
};
const _hoisted_4 = {
  class: "tk-master-genre"
};
const _hoisted_5 = {
  class: "tk-main-master"
};
const _hoisted_6 = {
  class: "tk-master-content"
};
const _hoisted_7 = {
  class: "tk-main-comment tk-main-comment-s flex-between"
};
import notive from "@/components/page/notive.vue";
import genre from "@/components/page/genre.vue";
import articles from "@/components/articles.vue";
import Dialog from "@/components/mode/message";
import { roomList } from "@/utils/https/wechar";
import { discussList } from "@/utils/https/master";
import { setFocus } from "@/utils/https/https";
import { onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";

import { toRefs, ref, onActivated, onDeactivated } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    let data = reactive({
      lotteryTab: [{
        name: "新澳彩",
        lottery: 2
      }, {
        name: "港彩",
        lottery: 1
      }, {
        name: "快乐八",
        lottery: 6
      }, {
        name: "老澳",
        lottery: 7
      }
      // { name: "台彩", lottery: 3 },
      // { name: "新彩", lottery: 4 },
      ],
      genreData: [{
        name: "综合",
        id: 4
      }, {
        name: "精华",
        id: 1
      }, {
        name: "最多点赞",
        id: 2
      }, {
        name: "最新",
        id: 3
      }],
      //    ,{name:'规则'}
      discussInfo: [],
      page: "1",
      tabbar: [{
        name: "发表",
        icon: require("../../../assets/image/major/2131.png"),
        path: "/master/release"
      }, {
        name: "六合图库",
        icon: require("../../../assets/image/major/2063.png"),
        path: "/serach"
      }, {
        name: "幽默猜测",
        icon: require("../../../assets/image/major/2062.png"),
        path: "/guess"
      }, {
        name: "聊天室",
        icon: require("../../../assets/image/major/2129.png"),
        path: "1"
      }, {
        name: "挑码助手",
        icon: require("../../../assets/image/major/2108.png"),
        path: "/tool/assistant"
      }],
      lottery: "0",
      //彩种
      type: "1",
      //类型 1是全部主题，2是图解小组
      sort: "4",
      //排序
      activeIndex: 0,
      gameShow: false,
      lotteryNum: 2,
      list: []
    });
    let {
      genreData,
      discussInfo,
      tabbar,
      lotteryTab,
      gameShow,
      lotteryNum
    } = toRefs(data);
    const router = useRouter();
    const route = useRoute();
    let list = reactive([{
      name: "香港六合聊天室",
      id: "1",
      selectIcon: require("@/assets/image/major/provice/2028.png")
    }, {
      name: "新澳门六合聊天室",
      id: "2",
      selectIcon: require("@/assets/image/major/provice/2015.png")
    }, {
      name: "台湾六合聊天室",
      id: "3",
      selectIcon: require("@/assets/image/major/provice/2029.png")
    }, {
      name: "新加坡六合聊天室",
      id: "4",
      selectIcon: require("@/assets/image/major/provice/2019.png")
    }, {
      name: "澳门六合聊天室",
      id: "5",
      selectIcon: require("@/assets/image/major/provice/2015.png")
    }]);
    onBeforeMount(() => {
      if (localStorage.getItem("lotteryType") != null) {
        data.lotteryNum = localStorage.getItem("lotteryType");
        data.lottery = localStorage.getItem("lotteryType");
      } else {
        data.lotteryNum = 2;
      }
    });
    //接口请求
    let discuss = (lottery, type, sort = "1", page) => {
      discussList({
        lotteryType: lottery,
        type: type,
        sort: sort,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        if (res.data.status == 40000) {
          finished.value = true;
        } else {
          let datas = res.data.data.data;
          datas.forEach(item => {
            item.content = item.content.replace(/\n/g, "<br>");
          });
          loading.value = false;
          data.discussInfo.push(...datas);
        }
      });
    };
    let focusFun = (id, index) => {
      //关注
      setFocus({
        id: id
      }).then(res => {
        if (res.data.status == "20000") {
          data.discussInfo.forEach((item, i) => {
            if (index == i) {
              item.user.focus = !item.user.focus;
            }
          });
          Dialog(res.data.message, "#07c160");
        } else {
          Dialog(res.data.message, "#07c160");
        }
      });
    };

    //功能区域；

    // let focusFun = (id,index)=>{
    //     focus(id);

    // }
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      discuss(data.lottery, data.type, data.sort, data.page);
      data.page++;
    };
    let principalTans = item => {
      //切换类型
      data.activeIndex = item;
      data.page = 1;
      data.discussInfo.length = 0;
      data.type = item + 1;
      loading.value = true;
      finished.value = false;
      onLoad();
      // discuss(data.lottery,data.type,data.sort,data.page);
    };
    let tabLottery = item => {
      //切换彩种
      data.page = 1;
      data.discussInfo.length = 0;
      data.lottery = item;
      loading.value = true;
      finished.value = false;
      onLoad();
      // discuss(data.lottery,data.type,data.sort,data.page);
    };
    let majorNav = item => {
      //切换分类
      data.sort = item;
      data.page = 1;
      data.discussInfo.length = 0;
      loading.value = true;
      finished.value = false;
      onLoad();
      // discuss(data.lottery,data.type,data.sort,data.page);
    };
    let search = () => {
      //前往搜索
      router.push({
        path: "/master/search",
        query: {
          lottery: data.lottery,
          type: data.type,
          sort: data.sort,
          activeIndex: data.activeIndex
        }
      });
    };
    let articleDes = item => {
      //进入详情
      if (data.activeIndex == 0) {
        router.push({
          path: "/master/details",
          query: {
            id: item.id
          }
        });
      } else {
        router.push({
          path: "/gallery/analysis/details",
          query: {
            id: item.id
          }
        });
      }
    };
    let release = () => {
      router.push({
        path: "/master/release"
      });
    };
    let majorTab = item => {
      if (item.name == "聊天室") {
        if (localStorage.getItem("room_info") != null) {
          list = JSON.parse(localStorage.getItem("room_info"));
          data.list = JSON.parse(localStorage.getItem("room_info"));
          if (localStorage.getItem("chatToken") != null) {
            data.list.push({
              name: "旧版六合聊天室",
              id: "9"
            });
          }
          if (data.list.length == 1) {
            router.push({
              path: "/wechat",
              query: {
                title: data.list[0].name,
                id: data.list[0].id
              }
            });
          } else if (data.list.length == 0) {
            Dialog("暂无聊天列表");
          } else {
            gameShow.value = true;
          }
        } else {
          roomLists();
        }
      }
    };
    let selecRoom = item => {
      //选择聊天室
      router.push({
        path: "/wechat",
        query: {
          title: item.name,
          id: item.id
        }
      });
    };
    let roomLists = () => {
      roomList().then(res => {
        list.length = 0;
        let datas = res.data.data;
        data.list = datas;
        if (localStorage.getItem("chatToken") != null) {
          data.list.push({
            name: "旧版六合聊天室",
            id: "9"
          });
        }
        if (datas.length == 1) {
          router.push({
            path: "/wechat",
            query: {
              title: datas[0].name,
              id: datas[0].id
            }
          });
        } else if (datas.length == 0) {
          Dialog("暂无聊天列表");
        } else {
          data.gameShow = true;
        }
        datas.forEach((item, index) => {
          switch (item.id) {
            case 1:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require("@/assets/image/major/provice/2028.png")
              });
              break;
            case 2:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require("@/assets/image/major/provice/2015.png")
              });
              break;
            case 3:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require("@/assets/image/major/provice/2029.png")
              });
              break;
            case 4:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require("@/assets/image/major/provice/2019.png")
              });
              break;
            case 5:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require("@/assets/image/major/provice/2015.png")
              });
              break;
            default:
              break;
          }
        });
        localStorage.setItem("room_info", JSON.stringify(list));
      });
    };
    const $store = useStore(); //获取vuex

    onActivated(() => {
      if ($store.state.release) {
        data.lotteryNum = route.query.lottery;
        data.lottery = route.query.lottery;
        data.page = 1;
        data.discussInfo.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
    });
    onDeactivated(() => {
      $store.commit("exidRelease", false);
    });
    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_lotteryTabs = _resolveComponent("lotteryTabs");
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      const _component_major = _resolveComponent("major");
      const _component_wecharList = _resolveComponent("wecharList");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "高手论坛"
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(search) && _unref(search)(...args))
        }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        tkCont: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(notive)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_navTabsVue, {
          nameLeft: "全部主题",
          onPrincipalTans: _unref(principalTans),
          nameRight: "图解小组"
        }, null, 8, ["onPrincipalTans"])]), _createElementVNode("div", _hoisted_3, [(_openBlock(), _createBlock(_component_lotteryTabs, {
          province: _unref(lotteryTab),
          onTabLottery: _unref(tabLottery),
          id: _unref(lotteryNum),
          key: _unref(lotteryNum)
        }, null, 8, ["province", "onTabLottery", "id"]))]), _createElementVNode("div", _hoisted_4, [_createVNode(genre, {
          dataNav: _unref(genreData),
          onMajorNav: _unref(majorNav)
        }, null, 8, ["dataNav", "onMajorNav"])])]),
        _: 1
      }), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(discussInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createBlock(articles, {
          key: 1,
          articlesArray: _unref(discussInfo),
          onArticleDes: _unref(articleDes),
          onFocusFun: _unref(focusFun),
          guess: "高手"
        }, null, 8, ["articlesArray", "onArticleDes", "onFocusFun"]))]),
        _: 1
      }, 8, ["loading", "finished"])]), _cache[6] || (_cache[6] = _createElementVNode("div", {
        style: {
          "height": "55px"
        }
      }, null, -1)), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
        class: "tk-comment",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(release) && _unref(release)(...args))
      }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "发布论坛贴", -1)]))])]), _createVNode(_component_major, {
        onMajorTab: _unref(majorTab),
        listData: _unref(tabbar)
      }, null, 8, ["onMajorTab", "listData"]), _createVNode(_component_wecharList, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[3] || (_cache[3] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        onSelecRoom: _unref(selecRoom),
        list: _unref(data).list
      }, null, 8, ["show", "onSelecRoom", "list"])], 64);
    };
  }
};