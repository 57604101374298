import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "tk-advertisement"
};
const _hoisted_2 = {
  class: "tk-tab"
};
const _hoisted_3 = {
  class: "tk-advertisement-cont"
};
const _hoisted_4 = ["onClick"];
import { treasure } from "@/utils/https/https";
import { onBeforeMount } from "vue";

import { reactive, ref } from 'vue';
export default {
  __name: 'advertisement',
  setup(__props) {
    let data = reactive({
      platforms: [],
      page: 12,
      title: "点击查看更多网址"
    });
    onBeforeMount(() => {
      treasures(4, "", 5);
    });
    let handleMore = () => {
      data.page += 12;
      if (data.title == "收起") {
        data.title = "点击查看更多网址";
        data.page = 12;
      }
      if (data.platforms.length < data.page) {
        data.title = "收起";
      }
    };
    let tabLottery = id => {
      treasures(4, "", id);
    };
    const lotteryTab = ref([
    // {name:'天澳彩',lottery:5},
    {
      name: "新澳彩",
      lottery: 2
    }, {
      name: "港彩",
      lottery: 1
    }, {
      name: "快乐八",
      lottery: 6
    }, {
      name: "老澳",
      lottery: 7
    }
    // {name:'台彩',lottery:3},
    // {name:'新彩',lottery:4}
    ]);
    const handleNavGetTo = item => {
      let device = JSON.parse(localStorage.getItem("device"));
      if (device && device.system) {
        Android.openWithBrowser(item.ad_url);
      } else {
        window.location.href = item.ad_url;
      }
    };
    //接口请求区域
    let treasures = (type, keyword, lotteryType) => {
      let treasures = JSON.parse(localStorage.getItem(`platform_${type}_${lotteryType}_type3`));
      if (treasures == null || keyword != "") {
        data.platforms.length = 0;
        treasure({
          type,
          lotteryType,
          keyword
        }).then(res => {
          let datas = res.data.data;
          if (res.status == 200) {
            if (type == 4) {
              data.platforms.push(...datas.haveImg, ...datas.withoutImg);
              localStorage.setItem(`platform_${type}_${lotteryType}_type3`, JSON.stringify(data.platforms));
            }
          }
        });
      } else {
        if (type == 4) {
          data.platforms = treasures;
        }
      }
    };
    return (_ctx, _cache) => {
      const _component_lotteryTabs = _resolveComponent("lotteryTabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "tk-adve-title"
      }, "网址大全：", -1)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_lotteryTabs, {
        province: _unref(lotteryTab),
        onTabLottery: _unref(tabLottery)
      }, null, 8, ["province", "onTabLottery"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).platforms, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [index < _unref(data).page ? (_openBlock(), _createElementBlock("li", {
          key: 0,
          onClick: $event => handleNavGetTo(item)
        }, _toDisplayString(item.title), 9, _hoisted_4)) : _createCommentVNode("", true)]);
      }), 128))]), _createElementVNode("div", {
        class: "more",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(handleMore) && _unref(handleMore)(...args))
      }, _toDisplayString(_unref(data).title), 1)])]);
    };
  }
};