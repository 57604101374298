import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../../assets/image/errorr.png';
const _hoisted_1 = {
  class: "tk-mian"
};
const _hoisted_2 = {
  class: "tk-image"
};
const _hoisted_3 = {
  class: "tk-img-wrapper",
  ref: "wrapper"
};
const _hoisted_4 = {
  class: "j_wrapper"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "name"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "name"
};
import search from '@/components/page/search.vue';
import { onBeforeMount, reactive } from "vue";
import { years, pictureCate, picSeriesDetail } from '@/utils/https/page';
import loadingTips from '@/components/mode/loading';
import { Waterfall } from '@/utils/Waterfall';
import { useStore } from 'vuex';

import { useRouter, useRoute } from 'vue-router';
import { toRefs, onDeactivated, onActivated } from 'vue';
export default {
  __name: 'image',
  setup(__props) {
    const $store = useStore(); //获取vuex
    const router = useRouter();
    const route = useRoute();
    let getImg = () => {
      new Waterfall({
        el: 'j_wrapper',
        //父div
        colmun: 2,
        //几列
        gap: 10 //间隙
      });
    };
    let errorload = () => {
      new Waterfall({
        el: 'j_wrapper',
        //父div
        colmun: 2,
        //几列
        gap: 10 //间隙
      });
    };
    let data = reactive({
      yearList: [],
      page: 1,
      year: 2023,
      color: 1,
      pictureData: [],
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {} : JSON.parse(localStorage.getItem('config')),
      acitveIndexLottery: 5,
      hei: [],
      bai: [],
      activeIndex: 1,
      picturex: [],
      title: '11',
      id: 0
    });
    let {
      yearList,
      pictureData,
      websiteName,
      acitveIndexLottery,
      activeIndex
    } = toRefs(data);
    onBeforeMount(() => {
      data.acitveIndexLottery = route.query.lottery == undefined ? 2 : route.query.lottery;
      var date = new Date();
      var yearss = date.getFullYear();
      if (localStorage.getItem('yearColor') == null || yearss == '2024') {
        year();
      } else {
        let year = JSON.parse(localStorage.getItem('yearColor'));
        year.forEach(element => {
          if (element.color == 2) {
            data.hei.push(element);
          }
          if (element.color == 1) {
            data.bai.push(element);
          }
        });
        data.yearList = data.bai;
      }
      let id = route.query.id;
      data.title = route.query.name;
      data.id = id;
      picture(id, '', data.acitveIndexLottery);
    });
    let year = async () => {
      //获取年份
      let res = await years();
      let c = res.data.data;
      localStorage.setItem('yearColor', JSON.stringify(c));
      let year = JSON.parse(localStorage.getItem('yearColor'));
      c.forEach(element => {
        if (element.color == 2) {
          data.hei.push(element);
        }
        if (element.color == 1) {
          data.bai.push(element);
        }
      });
      data.yearList = data.bai;
    };
    let onSubmit = e => {
      data.pictureData.length = 0;
      picture(data.id, e, data.acitveIndexLottery);
    };
    const onRefresh = () => {
      localStorage.removeItem('lotteryNumber');

      // 清空列表数据
      data.page = 1;
      data.pictureData.length = 0;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
    };
    let picture = (id, keyword, lotteryType) => {
      //图片请求

      picSeriesDetail({
        id,
        keyword,
        lotteryType
      }).then(res => {
        if (res.data.status == 20000) {
          let datas = res.data.data.data;
          data.pictureData.push(...datas);
          new Waterfall({
            el: 'j_wrapper',
            //父div
            colmun: 2,
            //几列
            gap: 10 //间隙
          });
        }
      });
    };
    let pictureJump = item => {
      if (item.is_ad) {
        window.location.href = item.ad_url;
      } else {
        localStorage.setItem('comment', true);
        router.push({
          path: `/gallery`,
          query: {
            color: item.color,
            year: item.year,
            id: item.pictureTypeId,
            title: item.pictureName,
            image: item.pictureUrl
          }
        });
      }
    };
    let handleTabColor = index => {
      data.activeIndex = index;
      data.page = 1;
      data.color = index;
      data.pictureData.length = 0;
      picture(data.year, index, data.page, data.acitveIndexLottery);
      if (index == 1) {
        data.yearList = data.bai;
      } else {
        data.yearList = data.hei;
      }
    };
    let yearColor = item => {
      router.push({
        path: '/serach',
        query: item
      });
    };
    let majorMore = () => {
      router.push({
        path: `/serach`
      });
    };
    onDeactivated(() => {
      $store.commit('handleImgs', false);
    });
    onActivated(() => {
      let discoverys = $store.state.imagesb;
      if (discoverys) {
        data.page = 1;
        data.acitveIndexLottery = localStorage.getItem('lotteryType') == null ? '5' : localStorage.getItem('lotteryType');
        data.pictureData.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_image = _resolveComponent("van-image");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(data).title
      }, {
        tkCont: _withCtx(() => [_createVNode(search, {
          tuku: false,
          onColors: _ctx.colors,
          onOnSubmit: _unref(onSubmit),
          placeholder: "请输入要搜索的图片标题"
        }, null, 8, ["onColors", "onOnSubmit"])]),
        _: 1
      }, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pictureData), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [item.is_ad ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "card wrapper",
          onClick: $event => _unref(pictureJump)(item)
        }, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
          style: {},
          src: item.ad_image,
          onLoad: _cache[0] || (_cache[0] = $event => _unref(getImg)())
        }, {
          error: _withCtx(() => [_createElementVNode("img", {
            src: _ctx.$httpsUrl + _unref(websiteName).notfind_image,
            alt: ""
          }, null, 8, _hoisted_6)]),
          _: 2
        }, 1032, ["src"])), [[_directive_lazy, item.ad_image]]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1)], 8, _hoisted_5)) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "card wrapper",
          onClick: $event => _unref(pictureJump)(item)
        }, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
          src: item.pictureUrl,
          onLoad: _cache[9] || (_cache[9] = $event => _unref(getImg)()),
          onError: _cache[10] || (_cache[10] = $event => _unref(errorload)())
        }, {
          error: _withCtx(() => [_unref(acitveIndexLottery) == 5 ? _withDirectives((_openBlock(), _createBlock(_component_van_image, {
            key: 0,
            src: item.pictureUrlOther,
            onLoad: _cache[5] || (_cache[5] = $event => _unref(getImg)()),
            onError: _cache[6] || (_cache[6] = $event => _unref(errorload)())
          }, {
            error: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
              src: item.previousPictureUrl,
              onLoad: _cache[3] || (_cache[3] = $event => _unref(getImg)()),
              onError: _cache[4] || (_cache[4] = $event => _unref(errorload)())
            }, {
              error: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
                src: item.previousPictureUrlOther,
                onLoad: _cache[1] || (_cache[1] = $event => _unref(getImg)()),
                onError: _cache[2] || (_cache[2] = $event => _unref(errorload)())
              }, {
                error: _withCtx(() => _cache[11] || (_cache[11] = [_createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)])),
                _: 2
              }, 1032, ["src"])), [[_directive_lazy, item.previousPictureUrl]])]),
              _: 2
            }, 1032, ["src"])), [[_directive_lazy, item.previousPictureUrl]])]),
            _: 2
          }, 1032, ["src"])), [[_directive_lazy, item.pictureUrlOther]]) : _withDirectives((_openBlock(), _createBlock(_component_van_image, {
            key: 1,
            src: item.previousPictureUrl,
            onLoad: _cache[7] || (_cache[7] = $event => _unref(getImg)()),
            onError: _cache[8] || (_cache[8] = $event => _unref(errorload)())
          }, {
            error: _withCtx(() => [_createElementVNode("img", {
              src: _ctx.$httpsUrl + _unref(websiteName).notfind_image,
              alt: ""
            }, null, 8, _hoisted_9)]),
            _: 2
          }, 1032, ["src"])), [[_directive_lazy, item.pictureUrlOther]])]),
          _: 2
        }, 1032, ["src"])), [[_directive_lazy, item.pictureUrl]]), _createElementVNode("div", _hoisted_10, _toDisplayString(item.pictureName), 1)], 8, _hoisted_8))]);
      }), 128))])], 512)])])], 64);
    };
  }
};