import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, withModifiers as _withModifiers, vModelText as _vModelText } from "vue";
import _imports_0 from '../../../assets/image/onreturn.png';
import _imports_1 from '../../../assets/image/gold.png';
import _imports_2 from '../../../assets/image/sright.png';
import _imports_3 from '../../../assets/image/convertselect.png';
import _imports_4 from '../../../assets/image/passeyesopn.png';
import _imports_5 from '../../../assets/image/passeyes.png';
import _imports_6 from '@/assets/image/close.png';
const _hoisted_1 = {
  class: "tk-user-head"
};
const _hoisted_2 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_3 = {
  class: "tk-head-money"
};
const _hoisted_4 = {
  class: "tk-num"
};
const _hoisted_5 = {
  class: "tk-main-purse-withdraw"
};
const _hoisted_6 = {
  class: "tk-exchange-select-userlottey"
};
const _hoisted_7 = {
  class: "tk-platform"
};
const _hoisted_8 = {
  class: "platform"
};
const _hoisted_9 = {
  class: "info"
};
const _hoisted_10 = {
  class: "platform"
};
const _hoisted_11 = {
  class: "info"
};
const _hoisted_12 = {
  class: "platform"
};
const _hoisted_13 = {
  class: "info"
};
const _hoisted_14 = {
  class: "tk-exit-passwrod"
};
const _hoisted_15 = {
  class: "tk-from"
};
const _hoisted_16 = {
  class: "title"
};
const _hoisted_17 = {
  class: "tk-from-field flex-between"
};
const _hoisted_18 = {
  key: 0
};
const _hoisted_19 = {
  class: "tk-field-input"
};
const _hoisted_20 = ["type", "onUpdate:modelValue", "onBlur", "placeholder"];
const _hoisted_21 = ["onClick"];
const _hoisted_22 = {
  key: 0,
  src: _imports_4,
  alt: ""
};
const _hoisted_23 = {
  key: 1,
  src: _imports_5,
  alt: ""
};
const _hoisted_24 = ["onClick"];
const _hoisted_25 = {
  key: 0,
  class: "forget"
};
const _hoisted_26 = {
  style: {
    "text-align": "left",
    "color": "#039F4C"
  }
};
const _hoisted_27 = {
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_28 = {
  class: "default-table"
};
const _hoisted_29 = {
  class: "required-turnover"
};
const _hoisted_30 = {
  class: "required-turnover"
};
const _hoisted_31 = {
  class: "tk-toup-tips"
};
const _hoisted_32 = {
  class: "bottomSelect"
};
const _hoisted_33 = {
  class: "tk-platform"
};
const _hoisted_34 = ["onClick"];
const _hoisted_35 = {
  class: "platform"
};
const _hoisted_36 = {
  class: "info"
};
const _hoisted_37 = {
  class: "select"
};
const _hoisted_38 = {
  key: 0,
  src: _imports_3,
  alt: ""
};
const _hoisted_39 = {
  class: "wrapper"
};
const _hoisted_40 = {
  class: "tk-main-QEcode"
};
const _hoisted_41 = {
  class: "codeEr"
};
const _hoisted_42 = ["src"];
const _hoisted_43 = {
  class: "tk-from-field flex-between"
};
const _hoisted_44 = {
  class: "tk-field-icon"
};
const _hoisted_45 = ["src"];
const _hoisted_46 = {
  class: "tk-field-input"
};
const _hoisted_47 = {
  class: "tk-botton"
};
import { platUserPlat, platWithdraw, platWithdraw_page } from '@/utils/https/me';
import { mobileSend, captchaTow, captcha, mobileVerify } from '@/utils/https/register';
import { config } from '@/utils/https/page';
import { showDialog } from 'vant';
import contactUser from '@/components/me/contactUser.vue';
import { onBeforeMount, ref, watch } from 'vue';
import { showConfirmDialog } from 'vant';
import 'vant/es/dialog/style';
import Dialogs from '@/components/mode/message.js';
import { useStore } from 'vuex';

import { useRouter } from 'vue-router';
import { reactive, toRefs, computed } from 'vue';
export default {
  __name: 'withdraw',
  setup(__props) {
    const router = useRouter();
    const shows = ref(false);
    const codeNum = ref('');
    let yzm = ref("发送验证码");
    let data = reactive({
      from: [{
        type: 'number',
        name: 'username',
        placeholder: '请输入提现金额',
        value: '',
        icon: '提现金额',
        money: true,
        type_c: false
      }, {
        type: 'password',
        name: 'password',
        placeholder: '请输入密码',
        value: '',
        icon: '资金密码',
        lookeyes: false,
        money: false,
        x: 'c',
        type_c: 'password'
      }],
      from1: [{
        type: 'number',
        name: 'username',
        placeholder: '请输入提现金额',
        value: '',
        icon: '提现金额',
        money: true,
        type_c: false
      }, {
        type: 'password',
        name: 'password',
        placeholder: '请输入密码',
        value: '',
        icon: '资金密码',
        lookeyes: false,
        money: false,
        x: 'c',
        type_c: 'password'
      }],
      from2: [{
        type: 'number',
        name: 'username',
        placeholder: '请输入提现金额',
        value: '',
        icon: '提现金额',
        money: true,
        type_c: false
      }, {
        type: 'number',
        name: 'sms_code',
        placeholder: '请输入手机验证码',
        value: '',
        icon: '验证码',
        money: false,
        type_c: 'yzm'
      }, {
        type: 'password',
        name: 'password',
        placeholder: '请输入密码',
        value: '',
        icon: '资金密码',
        lookeyes: false,
        money: false,
        x: 'c',
        type_c: 'password'
      }],
      selectplt: {
        plats: {
          name: '图库'
        },
        plat_user_account: '*****',
        plat_user_name: '*****'
      },
      purseList: [],
      id: '',
      showBottom: false,
      info: [],
      withdraw: {
        account_balance: '*'
      },
      gameShow: false,
      is_balance_freeze: JSON.parse(localStorage.getItem('usetInfo')).is_balance_freeze,
      balance: '请联系平台客服，找回资金密码',
      codeImg: '',
      codeCode: ''
    });
    const $store = useStore(); //获取vuex
    let {
      from,
      money,
      showBottom,
      purseList,
      selectplt,
      withdraw,
      gameShow,
      balance,
      codeImg
    } = toRefs(data);
    let found_send_sms = ref(false);
    const wydun = ref(null);
    const configInfo = computed(() => {
      return $store.state.config;
    });
    watch(() => configInfo.value, (val, old) => {
      configs();
      data.from.length = 0;
      found_send_sms.value = configInfo.value.found_send_sms;
      if (found_send_sms.value) {
        data.from.push(...data.from2);
      } else {
        data.from.push(...data.from1);
      }
    });
    let configs = () => {
      //获取公共配置
      let datas = configInfo.value;
      data.websiteName = datas;
      wydun.value = datas.wydun;
      if (wydun.value == 1) {
        mobileIniteNeCaptcha(datas.wydun_captcha_id);
      }
    };
    onBeforeMount(() => {
      configs();
      platUserPlats();
      platWithdraw_pages();
      data.from.length = 0;
      found_send_sms.value = configInfo.value.found_send_sms;
      if (found_send_sms.value) {
        data.from.push(...data.from2);
      } else {
        data.from.push(...data.from1);
      }
    });
    let platWithdraw_pages = () => {
      platWithdraw_page().then(res => {
        if (res.data.status == 20000) {
          let datas = res.data.data;
          datas.withdraw_limit = datas.withdraw_limit.toFixed(2);
          data.withdraw = datas;
        } else if (res.data.status == 40002) {
          showConfirmDialog({
            title: '温馨提示',
            message: '您尚未绑定手机号是否前去绑定'
          }).then(() => {
            router.push({
              path: "/me/mobile"
            });
          }).catch(() => {
            // on cancel
          });
        } else {
          Dialogs(res.data.message);
        }
      });
    };
    let platUserPlats = () => {
      //获取列表
      platUserPlat().then(res => {
        if (res.data.status != 20000) {
          Dialogs(res.data.message);
        } else {
          let datas = res.data.data;
          data.selectplt = datas[0];
          data.id = datas[0].id;
          datas.forEach((element, index) => {
            moeny_up.value += Number(element.plat_withdraw_limit);
            if (index == 0) {
              element.select = true;
            } else {
              element.select = false;
            }
          });
          data.purseList.push(...datas);
        }
      });
    };
    let captchas = () => {
      //请求验证码
      captcha().then(res => {
        let datas = res.data.data;
        data.codeCode = datas.key;
        data.codeImg = datas.img;
      });
    };
    const djs = () => {
      let clear;
      let num = 60;
      clear = setInterval(() => {
        num -= 1;
        yzm.value = num;
        if (num == 0) {
          yzm.value = "发送验证码";
          clearInterval(clear);
        }
      }, 1000);
    };
    let moeny_up = ref(0);
    let selectLottry = index => {
      //选择平台
      data.purseList.forEach(element => {
        if (element.id == index) {
          element.select = true;
          data.id = '';
          data.selectplt = element;
        } else {
          element.select = false;
        }
        if (element.select == true) {
          data.id = element.id;
        }
      });
      data.showBottom = false;
    };
    const handleUestion = () => {
      showDialog({
        title: "温馨提示",
        message: `前往绑定平台充值，解锁更多提现额度\n额度规则:充值100元即可获得${100 * Number(data.withdraw.incr_withdraw_limit_multiple)}提现额度,充值200元即可获得${200 * Number(data.withdraw.incr_withdraw_limit_multiple)}提现额度。\n以此类推`
      }).then(() => {
        // on close
      });
    };
    let lookPassword = index => {
      if (data.from[index].lookeyes) {
        data.from[index].type = 'password';
        data.from[index].lookeyes = false;
      } else {
        data.from[index].type = 'number';
        data.from[index].lookeyes = true;
      }
    };
    let platWithdraws = from => {
      platWithdraw(from).then(res => {
        if (res.data.status == 20000) {
          Dialogs(res.data.message);
          router.back();
          data.from[0].value = '';
          data.from[1].value = '';
        } else if (res.data.status == 40002) {
          showConfirmDialog({
            title: '温馨提示',
            message: '您尚未绑定手机号是否前去绑定'
          }).then(() => {
            router.push({
              path: "/me/mobile"
            });
          }).catch(() => {
            // on cancel
          });
        } else {
          Dialogs(res.data.message);
        }
      });
    };
    let hanldSubmit = () => {
      if (wydun.value == null) {
        Dialogs('配置加载中...');
        return false;
      }
      if (data.is_balance_freeze == 1) {
        data.balance = '您的资金已经被冻结，请联系客服';
        data.gameShow = true;
        return false;
      }
      let mobile = JSON.parse(localStorage.getItem('usetInfo')).mobile;
      let from = {
        user_plat_id: '',
        quota: '',
        fund_password: '',
        sms_code: '',
        mobile
      };
      from.user_plat_id = data.id;
      from.quota = data.from[0].value;
      if (found_send_sms.value) {
        from.sms_code = data.from[1].value;
        from.fund_password = data.from[2].value;
      } else {
        from.fund_password = data.from[1].value;
      }
      if (from.user_plat_id == '') {
        Dialogs('请选择充值平台');
      } else if (from.quota == '') {
        Dialogs('请输入充值金额');
      } else if (from.fund_password == '') {
        Dialogs('请输入资金密码');
      } else if (from.found_send_sms == '' && found_send_sms.value) {
        Dialogs('请输入手机验证码');
      } else {
        platWithdraws(from);
      }
    };
    let returnUp = () => {
      router.back();
    };
    let handleMore = () => {
      //显示弹窗
      data.showBottom = true;
    };
    let handleWjPssword = () => {
      data.gameShow = true;
    };
    let shareShow = () => {
      router.push({
        path: '/me/setup/account'
      });
    };
    let handleBlur = (e, name) => {
      if (name == 'username') {
        if (e > Number(data.withdraw.withdraw_limit) + Number(moeny_up.value)) {
          Dialogs('提现金额不足，快去绑定平台充值吧');
        }
      }
    };
    //发送验证码
    const handleYzm = () => {
      if (wydun.value == 1) {
        captchaIns.value && captchaIns.value.verify();
      } else {
        shows.value = true;
        captchas();
      }
    };
    const handleSubmit = () => {
      let mobile = JSON.parse(localStorage.getItem('usetInfo')).mobile;
      mobileSends('withdraw', codeNum.value, data.codeCode, mobile, '');
    };
    const mobileSends = async (scene, captcha, key, mobile, NECaptchaValidate) => {
      let froms = {
        scene,
        captcha,
        key,
        mobile,
        NECaptchaValidate
      };
      let {
        data
      } = await mobileSend(froms);
      if (data.status == 20000) {
        djs();
        shows.value = false;
        if (wydun.value == 1) {
          captchaIns.value.refresh();
        }
      } else {
        if (wydun.value == 1) {
          captchaIns.value.refresh();
        }
        Dialogs(data.message);
      }
    };
    //手机登陆行为验证
    const captchaIns = ref(null);
    const mobileIniteNeCaptcha = code => {
      // 若使用降级方案接入
      // initNECaptcha 替换成 initNECaptchaWithFallback
      initNECaptcha({
        captchaId: code,
        element: '#captchass',
        mode: 'popup',
        width: '320px',
        apiVersion: 2,
        onVerify: (err, data) => {
          // 当验证失败时, 内部会自动 refresh 方法, 无需手动再调用一次
          if (err) return;
          // 以下为业务侧逻辑
          if (data) {
            let mobile = JSON.parse(localStorage.getItem('usetInfo')).mobile;
            mobileSends('withdraw', codeNum.value, data.codeCode, mobile, data.validate);
          }
        }
      }, function onload(instance) {
        captchaIns.value = instance;
      }, function onerror(err) {
        // 初始化失败后触发该函数, err对象描述当前错误信息
        console.log(err);
      });
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_botton = _resolveComponent("botton");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[12] || (_cache[12] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[14] || (_cache[14] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "额度兑换", -1)), _createElementVNode("div", {
        class: "tk-head-info",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(shareShow) && _unref(shareShow)(...args))
      }, _cache[13] || (_cache[13] = [_createElementVNode("span", null, "已绑定账号", -1)]))]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(withdraw).account_balance), 1), _cache[15] || (_cache[15] = _createElementVNode("div", {
        class: "tk-exchange-num"
      }, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }), _createElementVNode("span", null, "可兑换金币")], -1))])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
        class: "tk-select-title",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(handleMore) && _unref(handleMore)(...args))
      }, _cache[16] || (_cache[16] = [_createElementVNode("p", null, " 请选择扣除额度的彩票余额账号", -1), _createElementVNode("p", null, [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      })], -1)])), _createElementVNode("div", _hoisted_7, [_createElementVNode("ul", null, [_createElementVNode("li", {
        onClick: _cache[3] || (_cache[3] = $event => _unref(selectLottry)(0))
      }, [_createElementVNode("div", _hoisted_8, [_cache[17] || (_cache[17] = _createElementVNode("span", {
        class: "name"
      }, "平台：", -1)), _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(selectplt).plats.name), 1)]), _createElementVNode("div", _hoisted_10, [_cache[18] || (_cache[18] = _createElementVNode("span", {
        class: "name"
      }, "账号：", -1)), _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(selectplt).plat_user_account), 1)]), _createElementVNode("div", _hoisted_12, [_cache[19] || (_cache[19] = _createElementVNode("span", {
        class: "name"
      }, "姓名：", -1)), _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(selectplt).plat_user_name), 1)]), _cache[20] || (_cache[20] = _createElementVNode("div", {
        class: "select"
      }, [_createElementVNode("img", {
        src: _imports_3,
        alt: ""
      })], -1))])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("form", {
        onSubmit: _cache[5] || (_cache[5] = (...args) => _ctx.onSubmit && _ctx.onSubmit(...args))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(from), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "labe",
          key: index
        }, [_createElementVNode("div", _hoisted_16, _toDisplayString(item.icon), 1), _createElementVNode("div", _hoisted_17, [item.money ? (_openBlock(), _createElementBlock("span", _hoisted_18, "¥")) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_19, [_withDirectives(_createElementVNode("input", {
          type: item.type,
          "onUpdate:modelValue": $event => item.value = $event,
          onBlur: $event => _unref(handleBlur)(item.value, item.name),
          placeholder: item.placeholder
        }, null, 40, _hoisted_20), [[_vModelDynamic, item.value]])]), item.type_c == 'password' ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tk-field-tips",
          onClick: $event => _unref(lookPassword)(index)
        }, [item.lookeyes ? (_openBlock(), _createElementBlock("img", _hoisted_22)) : (_openBlock(), _createElementBlock("img", _hoisted_23))], 8, _hoisted_21)) : _createCommentVNode("", true), item.type_c == 'yzm' ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "tk-field-tips-s",
          style: {
            "width": "100px",
            "color": "#039F4C",
            "text-align": "center"
          },
          onClick: $event => handleYzm(index)
        }, _toDisplayString(_unref(yzm)), 9, _hoisted_24)) : _createCommentVNode("", true)]), item.x == 'c' ? (_openBlock(), _createElementBlock("div", _hoisted_25, [_createElementVNode("span", _hoisted_26, [_createTextVNode(" 剩余提现额度" + _toDisplayString((Number(_unref(moeny_up)) + Number(_unref(withdraw).withdraw_limit)).toFixed(2)) + " ", 1), _createVNode(_component_van_icon, {
          color: "red",
          name: "question-o",
          onClick: handleUestion
        })]), _createElementVNode("span", {
          onClick: _cache[4] || (_cache[4] = (...args) => _unref(handleWjPssword) && _unref(handleWjPssword)(...args))
        }, "忘记密码")])) : _createCommentVNode("", true)]);
      }), 128)), _createElementVNode("div", _hoisted_27, [_createElementVNode("table", _hoisted_28, [_cache[22] || (_cache[22] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("td", null, "类型"), _createElementVNode("td", null, "提现额度")])], -1)), _createElementVNode("tbody", null, [_createElementVNode("tr", null, [_cache[21] || (_cache[21] = _createElementVNode("td", null, "通用额度", -1)), _createElementVNode("td", _hoisted_29, _toDisplayString(Number(_unref(withdraw).withdraw_limit).toFixed(2)), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(purseList), (item, index) => {
        return _openBlock(), _createElementBlock("tr", {
          key: index
        }, [_createElementVNode("td", null, _toDisplayString(item.plats?.name), 1), _createElementVNode("td", _hoisted_30, _toDisplayString(item.plat_withdraw_limit), 1)]);
      }), 128))])])])], 32)]), _cache[23] || (_cache[23] = _createElementVNode("div", {
        id: "captchass"
      }, null, -1))]), _createElementVNode("div", {
        class: "tk-botton",
        onClick: _cache[6] || (_cache[6] = (...args) => _unref(hanldSubmit) && _unref(hanldSubmit)(...args))
      }, [_createVNode(_component_botton, {
        name: "提现",
        bgcolor: _unref(data).from[1].value == '' ? 'rgba(3, 159, 76, 0.4)' : '#07c160'
      }, null, 8, ["bgcolor"])])]), _createElementVNode("div", _hoisted_31, [_createElementVNode("p", null, " 温馨提示：1.用户一天可提现" + _toDisplayString(_unref(withdraw).withdraw_num) + "次,一次提现最低金额" + _toDisplayString(_unref(withdraw).withdraw_min_each) + "元,最高" + _toDisplayString(_unref(withdraw).withdraw_max) + "元一天累计提现不超过" + _toDisplayString(_unref(withdraw).withdraw_max_each) + "元;2.用户提现时需要支付" + _toDisplayString(_unref(withdraw).withdraw_rate) + "%手续费。 ", 1)])]), _createVNode(_component_van_popup, {
        show: _unref(showBottom),
        "onUpdate:show": _cache[7] || (_cache[7] = $event => _isRef(showBottom) ? showBottom.value = $event : showBottom = $event),
        round: "",
        position: "bottom",
        style: {
          height: '30%'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_32, [_cache[24] || (_cache[24] = _createElementVNode("div", {
          class: "title"
        }, "选择平台账号", -1)), _createElementVNode("div", _hoisted_33, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(purseList), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(selectLottry)(item.id)
          }, [_createElementVNode("div", _hoisted_35, [_createElementVNode("span", _hoisted_36, _toDisplayString(item.plats.name), 1)]), _createElementVNode("div", _hoisted_37, [item.select ? (_openBlock(), _createElementBlock("img", _hoisted_38)) : _createCommentVNode("", true)])], 8, _hoisted_34);
        }), 128))])])])]),
        _: 1
      }, 8, ["show"]), _createVNode(contactUser, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[8] || (_cache[8] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        title: _unref(balance)
      }, null, 8, ["show", "title"]), _createVNode(_component_van_overlay, {
        show: shows.value,
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_39, [_createElementVNode("div", _hoisted_40, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[9] || (_cache[9] = _withModifiers($event => shows.value = false, ["stop"]))
        }, _cache[25] || (_cache[25] = [_createElementVNode("img", {
          src: _imports_6,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_41, [_cache[26] || (_cache[26] = _createElementVNode("div", {
          class: "text"
        }, "验证码", -1)), _createElementVNode("img", {
          onClick: _cache[10] || (_cache[10] = _withModifiers((...args) => _unref(captchas) && _unref(captchas)(...args), ["stop"])),
          src: _unref(codeImg),
          alt: ""
        }, null, 8, _hoisted_42)]), _createElementVNode("div", _hoisted_43, [_createElementVNode("div", _hoisted_44, [_createElementVNode("img", {
          src: require('@/assets/image/anquan.png'),
          alt: ""
        }, null, 8, _hoisted_45)]), _createElementVNode("div", _hoisted_46, [_withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => codeNum.value = $event),
          placeholder: "请输入验证码"
        }, null, 512), [[_vModelText, codeNum.value]])])]), _cache[27] || (_cache[27] = _createElementVNode("div", {
          class: ""
        }, null, -1)), _createElementVNode("div", _hoisted_47, [_createVNode(_component_botton, {
          name: "确定",
          onClick: handleSubmit
        })])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};