import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '../../assets/image/close.png';
import _imports_1 from '../../assets/image/me/copy.png';
const _hoisted_1 = {
  class: "tk-QEcode"
};
const _hoisted_2 = {
  class: "tk-QEcode-title"
};
const _hoisted_3 = {
  class: "img",
  id: "qrcode"
};
const _hoisted_4 = {
  class: "tk-button-info"
};
const _hoisted_5 = {
  class: "tk-button"
};
import { onBeforeMount, reactive, toRefs } from "vue";
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import Dialog from '@/components/mode/message';
export default {
  __name: 'QRcode',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let data = reactive({
      logoSrc: require('@/assets/image/logo.png'),
      appSrc: 'http://www.baidu.com',
      code: '1902788'
    });
    onBeforeMount(() => {
      let domain = window.location.host;
      let code;
      if (localStorage.getItem('usetInfo') != null) {
        code = JSON.parse(localStorage.getItem('usetInfo')).invite_code;
      }
      data.code = code;
      data.appSrc = `http://${domain}/register?invite=${code}`;
    });
    let {
      logoSrc,
      appSrc,
      code
    } = toRefs(data);
    let emits = __emit;
    let colse = () => {
      emits('update:show', false);
    };
    let overlayPop = () => {
      emits('update:show', false);
    };
    let copyContent = async url => {
      try {
        await navigator.clipboard.writeText(url);
        Dialog('复制成功，赶快去给好友分享吧！');
      } catch (err) {
        Dialog('复制失败！！');
        console.error('Failed to copy: ', err);
      }
    };
    let downs = () => {
      const fileName = '邀请好友.png';
      let img = document.getElementById('qrcode').getElementsByTagName('img')[0].src;
      let aLink = document.createElement('a');
      let blob = base64ToBlob(img);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      // aLink.dispatchEvent(evt);
      aLink.click();
      Dialog('保存二维码成功，赶快去给好友分享吧！');
    };
    //base64转blob                                                                                                                                                                                        
    let base64ToBlob = code => {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {
        type: contentType
      });
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: __props.show
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[4] || (_cache[4] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createElementVNode("div", {
          class: "tk-main-QEcode",
          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => _unref(colse) && _unref(colse)(...args), ["stop"]))
        }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, "我的邀请码：" + _toDisplayString(_unref(code)), 1), _cache[6] || (_cache[6] = _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1))]), _createElementVNode("div", _hoisted_3, [_createVNode(vueQr, {
          margin: 0,
          logoScale: 0.3,
          correctLevel: 3,
          "auto-color": true,
          "dot-scale": 1,
          text: _unref(appSrc),
          colorDark: "#000"
        }, null, 8, ["text"])])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
          class: "tk-button",
          onClick: _cache[1] || (_cache[1] = (...args) => _unref(downs) && _unref(downs)(...args))
        }, [_createVNode(_component_botton, {
          name: "保存本地"
        })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_botton, {
          name: "复制链接",
          onClick: _cache[2] || (_cache[2] = $event => _unref(copyContent)(_unref(appSrc)))
        })])])])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};