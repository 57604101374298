import "core-js/modules/es.array.push.js";
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../assets/image/major/provice/2031.png';
import _imports_1 from '../../assets/image/major/provice/2017.png';
import _imports_2 from '../../assets/image/major/provice/2018.png';
import _imports_3 from '../../assets/image/major/provice/2032.png';
const _hoisted_1 = {
  class: "tk-main-comment"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-comment-item-head"
};
const _hoisted_5 = {
  class: "tk-head-avater"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = ["src"];
const _hoisted_8 = ["src"];
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "tk-head-user"
};
const _hoisted_11 = {
  key: 0,
  class: "username"
};
const _hoisted_12 = {
  key: 1,
  class: "username"
};
const _hoisted_13 = {
  key: 2,
  class: "username"
};
const _hoisted_14 = {
  key: 3,
  class: "username"
};
const _hoisted_15 = {
  class: "tiems"
};
const _hoisted_16 = {
  key: 0,
  class: "tk-lottery"
};
const _hoisted_17 = {
  key: 1,
  class: "tk-lottery"
};
const _hoisted_18 = {
  key: 2,
  class: "tk-lottery"
};
const _hoisted_19 = {
  key: 3,
  class: "tk-lottery"
};
const _hoisted_20 = {
  key: 4,
  class: "tk-lottery"
};
const _hoisted_21 = {
  key: 5,
  class: "tk-lottery"
};
const _hoisted_22 = {
  key: 6,
  class: "tk-lottery"
};
const _hoisted_23 = {
  key: 0,
  class: "tk-comment-title"
};
const _hoisted_24 = {
  key: 1,
  class: "tk-comment-title"
};
const _hoisted_25 = {
  class: "tk-comment-conente"
};
const _hoisted_26 = {
  class: "tk-head-avater"
};
const _hoisted_27 = ["src"];
const _hoisted_28 = {
  class: "tk-conente-head"
};
const _hoisted_29 = {
  class: "tk-head-user"
};
const _hoisted_30 = {
  class: "username"
};
const _hoisted_31 = {
  class: "tiems"
};
const _hoisted_32 = {
  class: "tk-comment"
};
import genre from '@/components/page/genre.vue';
import router from '@/router';
import { useStore } from 'vuex';
import { getComment } from '@/utils/https/me';
import { onBeforeMount } from 'vue';

import { reactive, computed, toRefs, ref, onActivated } from 'vue';
export default {
  __name: 'comment',
  setup(__props) {
    let data = reactive({
      dataNav: [{
        name: '发现',
        id: 1
      }, {
        name: '幽默猜测',
        id: 2
      }, {
        name: '高手论坛',
        id: 3
      }, {
        name: '资料大全',
        id: 4
      }, {
        name: '六合图库',
        id: 5
      }],
      commentData: [],
      page: 1,
      type: 1,
      //类型 1是发现 2是幽默猜测，3是高手论坛，4是资料大全，5是六合图库
      refs: 1
    });
    const $store = useStore(); //获取vuex
    const configInfo = computed(() => {
      return $store.state.config;
    });
    let {
      dataNav,
      commentData,
      type,
      http
    } = toRefs(data);
    onBeforeMount(() => {});
    //接口请求区域
    let getComments = (type, page) => {
      getComment({
        type: type,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.commentData.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getComments(data.type, data.page);
      data.page++;
    };
    let majorNav = item => {
      data.type = item;
      data.commentData.length = 0;
      data.page = 1;
      // getComments(data.type,data.page);
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let returnUp = () => {
      sessionStorage.setItem("collect", true);
    };
    let majorDesc = item => {
      sessionStorage.setItem("collect", false);
      if (data.type == 1) {
        if (item.type == 2) {
          router.push({
            path: '/discover/video',
            query: {
              data: item.id,
              page: item.id
            }
          });
        } else {
          router.push({
            path: '/discover/details',
            query: {
              id: item.id
            }
          });
        }
      }
      if (data.type == 2) {
        router.push({
          path: `/guess`,
          query: {
            id: item.id,
            year: item.year,
            lottery: item.lotteryType,
            issue: item.issue
          }
        });
      }
      if (data.type == 4) {
        router.push({
          path: `/materials/list/details`,
          query: {
            id: item.id,
            corpusTypeId: item.corpusTypeId
          }
        });
      }
      if (data.type == 5) {
        router.push({
          path: `/gallery`,
          query: {
            color: item.color,
            year: item.year,
            id: item.pictureTypeId,
            pictureId: item.pictureId,
            title: item.pictureName,
            issue: item.issue,
            lottery: item.lotteryType
          }
        });
      }
    };
    onActivated(() => {
      let collect = sessionStorage.getItem('collect');
      if (collect == 'collect') {
        data.page = 1;
        data.commentData.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
      if (collect == 'true') {
        data.refs += 1;
        data.type = 1;
        data.commentData.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的评论",
        onReturnUp: _unref(returnUp)
      }, {
        tkCont: _withCtx(() => [(_openBlock(), _createBlock(genre, {
          dataNav: _unref(dataNav),
          onMajorNav: _unref(majorNav),
          key: _unref(data).refs
        }, null, 8, ["dataNav", "onMajorNav"]))]),
        _: 1
      }, 8, ["onReturnUp"]), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(commentData).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(commentData), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(majorDesc)(item)
          }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_unref(type) == 1 || _unref(type) == 3 ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: item.user.avatar == '' ? require('@/assets/image/logo.jpg') : _unref(configInfo).host2 + '/' + item.user.avatar,
            alt: ""
          }, null, 8, _hoisted_6)) : _createCommentVNode("", true), _unref(type) == 2 ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: item.imageUrl == '' ? require('@/assets/image/logo.jpg') : item.imageUrl,
            alt: ""
          }, null, 8, _hoisted_7)) : _createCommentVNode("", true), _unref(type) == 4 ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            src: require('@/assets/image/logo.jpg'),
            alt: ""
          }, null, 8, _hoisted_8)) : _createCommentVNode("", true), _unref(type) == 5 ? (_openBlock(), _createElementBlock("img", {
            key: 3,
            src: item.picurl == '' ? require('@/assets/image/logo.jpg') : item.picurl,
            alt: ""
          }, null, 8, _hoisted_9)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_10, [_unref(type) == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.title), 1)) : _createCommentVNode("", true), _unref(type) == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(item.nickname), 1)) : _createCommentVNode("", true), _unref(type) == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(item.pictureName), 1)) : _createCommentVNode("", true), _unref(type) == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(item.user.nickname), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, _toDisplayString(item.created_at), 1)]), item.lotteryType == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[1] || (_cache[1] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "澳门", -1)]))) : _createCommentVNode("", true), item.lotteryType == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[2] || (_cache[2] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "新澳门", -1)]))) : _createCommentVNode("", true), item.lotteryType == 6 ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[3] || (_cache[3] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "快乐八", -1)]))) : _createCommentVNode("", true), item.lotteryType == 7 ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[4] || (_cache[4] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "老澳", -1)]))) : _createCommentVNode("", true), item.lotteryType == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[5] || (_cache[5] = [_createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "香港", -1)]))) : _createCommentVNode("", true), item.lotteryType == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[6] || (_cache[6] = [_createElementVNode("img", {
            src: _imports_2,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "台湾", -1)]))) : _createCommentVNode("", true), item.lotteryType == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[7] || (_cache[7] = [_createElementVNode("img", {
            src: _imports_3,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "新加坡", -1)]))) : _createCommentVNode("", true)]), _unref(type) == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(item.year + '年' + item.issue + '期'), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(item.title == null ? item.pictureName : item.title), 1)), _createElementVNode("div", _hoisted_25, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.comment, (items, indexs) => {
            return _openBlock(), _createElementBlock("li", {
              key: indexs
            }, [_createElementVNode("div", _hoisted_26, [_createElementVNode("img", {
              src: items.user.avatar == '' ? require('@/assets/image/logo.png') : _unref(configInfo).host2 + '/' + items.user.avatar,
              alt: ""
            }, null, 8, _hoisted_27)]), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, _toDisplayString(items.user.nickname), 1), _createElementVNode("div", _hoisted_31, _toDisplayString(items.created_at), 1)]), _createElementVNode("div", _hoisted_32, _toDisplayString(items.content), 1)])]);
          }), 128))])])], 8, _hoisted_3);
        }), 128))]))])]),
        _: 1
      }, 8, ["loading", "finished"])], 64);
    };
  }
};