import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "tk-main-comment"
};
const _hoisted_2 = {
  class: "tk-comment"
};
const _hoisted_3 = {
  class: "tk-comment"
};
const _hoisted_4 = {
  class: "tk-textarea"
};
const _hoisted_5 = {
  class: "tk-file"
};
const _hoisted_6 = {
  class: "file-shape"
};
const _hoisted_7 = {
  class: "file-upload"
};
const _hoisted_8 = {
  class: "link"
};
const _hoisted_9 = {
  class: "tk-comment-botton"
};
import { reactive, ref, toRefs } from 'vue';
import { createArticle, temp_cred, video } from '@/utils/https/discover';
import { uploadFile } from '@/utils/https/https';
import Dialog from '@/components/mode/message';

import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
export default {
  __name: 'article',
  setup(__props) {
    let route = useRoute();
    let router = useRouter();
    let data = reactive({
      fileList: [],
      lotteryData: [
      // {name:'天澳彩',lottery:5},
      {
        name: '新澳彩',
        lottery: 2
      }, {
        name: '港彩',
        lottery: 1
      }, {
        name: '快乐八',
        lottery: 6
      }, {
        name: '老澳',
        lottery: 7
      }
      // {name:'台彩',lottery:3},
      // {name:'新彩',lottery:4},
      ],
      type: 0,
      videoUrl: '',
      videoLoaded: false,
      progressShow: false,
      progress: 0,
      file: '',
      videoShow: false,
      videoList: []
    });
    const currentRate = ref(0);
    const text = computed(() => currentRate.value.toFixed(0) + '%');
    let {
      lotteryData,
      type,
      videoUrl,
      videoLoaded,
      progressShow,
      progress,
      videoShow,
      videoList
    } = toRefs(data);
    let form = reactive({
      lotteryType: '2',
      title: '',
      content: '',
      images: [],
      type: '1',
      videos: ''
    });
    let {
      title,
      content
    } = toRefs(form);
    let uploadFun = array => {
      data.fileList = array;
    };
    let createArticles = from => {
      createArticle(from).then(res => {
        if (res.status != '400') {
          router.back();
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let tabLottery = item => {
      form.lotteryType = item;
    };
    let onsubmit = async () => {
      if (form.content.length == 0) return false;
      if (form.type == 2) {
        await upload(data.file);
      }
      if (data.fileList.length != 0) {
        let res = uploadFile(data.fileList); //图片上传接口 先上传 在提交
        await res.then(datac => {
          if (datac.data.status == 40000) {
            Dialog(datac.data.message, '#07c160');
            return false;
          }
          let datas = datac.data.data.url;
          form.images = datas;
        });
        createArticles(form);
        localStorage.setItem('discovery', true);
      } else {
        Dialog('请上传图片', '#07c160');
      }
    };
    let principalTans = item => {

      // form.title = '';
      // form.content = '';
      // form.videos='';
      // form.images.length = 0;

      // data.type = item
      // form.type = item+1
    };

    //获取上传凭证

    //视频上传
    // let afterRead = async (e)=>{
    //      data.file = e.file;
    //     data.videoUrl = URL.createObjectURL( e.file);
    //     data.videoList = [URL.createObjectURL( e.file)]

    // }
    // let videos = ref(null)
    // let canvass = ref(null)
    // let  handleVideoLoad = ()=>{//获取截图
    //       let video = videos.value;
    //       let canvas = canvass.value;

    //       canvas.height = video.clientHeight/1.5
    //       const context = canvas.getContext('2d');

    //       // 在 canvas 上绘制视频帧
    //       context.drawImage(video, 0, 0, canvas.width, canvas.height);
    //       // 从 canvas 中获取数据并生成封面图片
    //       const dataUrl = canvas.toDataURL();

    //     //   form.images = [base64toFile(dataUrl)]

    //       data.fileList = [{file:base64toFile(dataUrl)}]
    //       // 清理资源
    //       URL.revokeObjectURL(data.videoUrl);
    //       data.videoLoaded = true;
    // }
    // let base64toFile = (dataurl, filename = 'file')=>{

    //     let arr = dataurl.split(',')
    //     let mime = arr[0].match(/:(.*?);/)[1]
    //     let suffix = mime.split('/')[1]
    //     let bstr = atob(arr[1])
    //     let n = bstr.length
    //     let u8arr = new Uint8Array(n)
    //     while (n--) {
    //       u8arr[n] = bstr.charCodeAt(n)
    //     }
    //     return new File([u8arr], `${filename}.${suffix}`, {
    //       type: mime
    //     })
    // }
    // let yearDay = ()=>{
    //     var date = new Date();
    //     let year =  date.getFullYear(); //获取完整的年份(4位)
    //     let month = date.getMonth()+1; //获取当前月份(0-11,0代表1月)
    //     let day =  date.getDate(); //获取当前日(1-31)

    //     if(month<10){
    //         month ='0'+month
    //     }
    //     if(day<10){
    //         day ='0'+day
    //     }

    //     return `${year}_${month}_${day}`
    // }

    // const timestamp = (new Date()).valueOf(); //1610075969354 
    // // 开始上传
    // let upload= async (file)=> {

    //     let file_names = file.name
    //     file_names.substr(file_names.length-4);

    //   let file_name = `video/${yearDay()}/${timestamp+file.name}`
    //     // file.name =
    //       //初始化AWS S3对象
    //       const s3 = new AWS.S3({
    //         accessKeyId: 'mXe3MZUYquuYBMEGWH5R',
    //         secretAccessKey: '8g4R2QLMZoWHCwKwdaxchLlknupAKM2yJVJm41xC',
    //         region: 'us-east-1',
    //         endpoint:'https://000558.com/tuku',
    //         use_path_style_endpoint:true
    //       });
    //       // 创建上传任务
    //       const { UploadId } = await s3.createMultipartUpload({
    //         Bucket: 'abc',
    //         Key: file_name,
    //         StorageClass: 'REDUCED_REDUNDANCY',
    //       }).promise();

    //       var uploadId = UploadId;

    //       // 计算分片数量和每个分片的大小
    //       const fileSize = file.size;
    //       const chunkSize = 5 * 1024 * 1024; // 10MB
    //       const numChunks = Math.ceil(fileSize / chunkSize);
    //         data.progressShow = true
    //       const parts = [];

    //       // 分片上传
    //       for (let i = 0; i <numChunks; i++) {
    //          const start = i * chunkSize;
    //         const end = Math.min(fileSize, start + chunkSize);
    //         const { PartNumber, ETag } = await s3.uploadPart({
    //           Bucket: 'abc',
    //           Key:file_name   ,
    //           UploadId:uploadId,
    //           PartNumber: i + 1,
    //           Body: file.slice(start, end),
    //           ContentLength:file.slice(start, end).size
    //         }).promise();

    //         parts.push({
    //           PartNumber:i + 1,
    //           ETag,
    //         });
    //          // 更新上传进度
    //          data.progress = Math.floor(((i + 1) / numChunks) * 100);

    //       }

    //       var partss = parts;

    //       // 合并分片
    //      let res =  await s3.completeMultipartUpload({
    //         Bucket: 'abc',
    //         Key:file_name,
    //         UploadId: uploadId,
    //         MultipartUpload: {
    //           Parts: partss,
    //         },
    //       }).promise();

    //       form.videos ='/'+res.Bucket+'/'+res.Key;
    //       // 完成上传
    //       data.progress = 100;
    //       data.progressShow = false
    //       handleVideoLoad()

    //     }

    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_headNav = _resolveComponent("headNav");
      const _component_lotteyTabs = _resolveComponent("lotteyTabs");
      const _component_uploadImage = _resolveComponent("uploadImage");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "发布发现贴"
      }, {
        tkCont: _withCtx(() => [_createVNode(_component_navTabsVue, {
          nameLeft: "发布图文资料",
          onPrincipalTans: _unref(principalTans),
          nameRight: "发布图文资料"
        }, null, 8, ["onPrincipalTans"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-title"
      }, "选择平台", -1)), _createVNode(_component_lotteyTabs, {
        province: _unref(lotteryData),
        onTabLottery: _unref(tabLottery)
      }, null, 8, ["province", "onTabLottery"]), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-title"
      }, "标题", -1)), _createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(title) ? title.value = $event : title = $event),
        type: "text",
        placeholder: "请输入标题"
      }, null, 512), [[_vModelText, _unref(title)]])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(content) ? content.value = $event : content = $event),
        placeholder: "想说点什么...",
        rows: "10"
      }, null, 512), [[_vModelText, _unref(content)]])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_uploadImage, {
        onUploadFun: _unref(uploadFun)
      }, null, 8, ["onUploadFun"])])]), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(content).length) + "/2000", 1)])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_botton, {
        name: "发布",
        onClick: _cache[2] || (_cache[2] = $event => _unref(onsubmit)()),
        bgcolor: _unref(content).length == 0 ? 'rgba(3, 159, 76, 0.4' : '#07c160'
      }, null, 8, ["bgcolor"])])])], 64);
    };
  }
};