import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "tk-main-comment"
};
const _hoisted_2 = {
  key: 0,
  class: "tk-comment"
};
const _hoisted_3 = {
  class: "tk-comment-botton"
};
import { activityFilling } from '@/utils/https/me';
import { reactive, toRefs } from "vue";
import Dialog from '@/components/mode/message';
export default {
  __name: 'addinvite',
  setup(__props) {
    let data = reactive({
      content: ''
    });
    let {
      content,
      title
    } = toRefs(data);
    let activityFillings = code => {
      activityFilling({
        invite_code: code
      }).then(res => {
        if (res.status != '400') {
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let onsubmit = () => {
      activityFillings(data.content);
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "填写邀请码"
      }), _createElementVNode("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-title"
      }, "邀请码", -1)), !_ctx.type ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(content) ? content.value = $event : content = $event),
        type: "text",
        placeholder: "请输入邀请码"
      }, null, 512), [[_vModelText, _unref(content)]])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createVNode(_component_botton, {
        name: "提交",
        onClick: _cache[1] || (_cache[1] = $event => _unref(onsubmit)()),
        bgcolor: _unref(content).length == 0 ? 'rgba(3, 159, 76, 0.4' : '#07c160'
      }, null, 8, ["bgcolor"])]), _cache[3] || (_cache[3] = _createElementVNode("p", null, "温馨提示：您只有一次填写邀请码的机会，一旦填写错误，无法修改，请仔细核实无误后在填写。", -1))])], 64);
    };
  }
};