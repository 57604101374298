import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../../assets/image/convert.png';
import _imports_1 from '../../../assets/image/right-t.png';
const _hoisted_1 = {
  class: "tk-main-purse-toup"
};
const _hoisted_2 = {
  class: "tk-toup-box"
};
const _hoisted_3 = {
  class: "tk-toup-item"
};
import { useRouter } from "vue-router";
export default {
  __name: 'topup',
  setup(__props) {
    let route = useRouter();
    let handleExchange = () => {
      route.push({
        path: '/me/purse/exchange'
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "充值中心"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createStaticVNode("<div class=\"tk-icon\" data-v-ad5e5598><img src=\"" + _imports_0 + "\" alt=\"\" data-v-ad5e5598></div><div class=\"tk-info\" data-v-ad5e5598><div class=\"title\" data-v-ad5e5598>额度转换</div><div class=\"desc\" data-v-ad5e5598>跟已绑定的彩票平台额度转换</div></div>", 2)), _createElementVNode("div", {
        class: "tk-button",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(handleExchange) && _unref(handleExchange)(...args))
      }, _cache[1] || (_cache[1] = [_createElementVNode("span", null, "前往转换", -1), _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)]))])]), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-toup-tips"
      }, [_createElementVNode("p", null, " 温馨提示:请选择您想要充值平台的银行卡帐号,选择后按照各自的信息进行操作.如在充值过程中遇到任何问题,都可联系在线客服 ")], -1))])], 64);
    };
  }
};