import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, Fragment as _Fragment } from "vue";
import _imports_0 from '../../../assets/image/more.png';
import _imports_1 from '../../../assets/image/tisp.png';
const _hoisted_1 = {
  class: "tk-main-details"
};
const _hoisted_2 = {
  class: "tk-swiper",
  style: {
    "margin-bottom": "10px"
  }
};
const _hoisted_3 = {
  class: "tk-details-content"
};
const _hoisted_4 = {
  class: "tk-details-info"
};
const _hoisted_5 = {
  class: "tk-picture"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "tk-user"
};
const _hoisted_8 = {
  class: "tk-name"
};
const _hoisted_9 = {
  class: "tk-tiems"
};
const _hoisted_10 = {
  class: "tk-info-like"
};
const _hoisted_11 = {
  class: "num"
};
const _hoisted_12 = {
  class: "num"
};
const _hoisted_13 = {
  class: "num"
};
const _hoisted_14 = {
  class: "num"
};
const _hoisted_15 = {
  class: "tk-info-button"
};
const _hoisted_16 = {
  class: "tk-l",
  style: {
    "width": "70%"
  }
};
const _hoisted_17 = {
  class: "tk-r",
  style: {
    "width": "30%"
  }
};
const _hoisted_18 = {
  class: "tk-tips"
};
const _hoisted_19 = {
  class: "tk-read"
};
const _hoisted_20 = {
  class: "name"
};
const _hoisted_21 = {
  class: "tk-deails-title"
};
const _hoisted_22 = ["innerHTML"];
import lottery from '@/components/page/lottery.vue';
import comment from '@/components/page/comment.vue';
import comment_list from '@/components/page/comment_list.vue';
import more from '@/components/page/more.vue';
import share from '@/components/page/share.vue';
import Dialog from '@/components/mode/message';
import { useStore } from 'vuex';
import { infoArticle, getUserIndex, follow } from '@/utils/https/materials';
import { setFocus } from '@/utils/https/https';
import { ref } from 'vue';

import { reactive, toRefs, onBeforeMount, onDeactivated, onActivated, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'details',
  setup(__props) {
    let data = reactive({
      show: false,
      id: '',
      //文章id
      articleInfo: {},
      getUserInfo: {},
      guanzhuicno: true,
      //关注
      guanzhuicolor: '#FF1A00',
      //关注
      guanzhuname: '关注',
      shows: false,
      //显示分享页面
      corpusTypeId: '',
      listData: [{
        name: '发表',
        icon: require('../../../assets/image/major/2131.png'),
        path: '/master/release'
      }, {
        name: '六合图库',
        icon: require('../../../assets/image/major/2063.png'),
        path: '/search'
      }, {
        name: '幽默猜测',
        icon: require('../../../assets/image/major/2062.png'),
        path: '/guess'
      }, {
        name: '聊天室',
        icon: require('../../../assets/image/major/2129.png'),
        path: '1'
      }, {
        name: '挑码助手',
        icon: require('../../../assets/image/major/2108.png'),
        path: '/tool/assistant'
      }],
      clientWidth: '',
      datalist: [{
        name: '用户主页',
        icon: require('../../../assets/image/meuser.png')
      }, {
        name: '历史帖子',
        icon: require('../../../assets/image/history.png')
      }, {
        name: '举报',
        icon: require('../../../assets/image/warning.png')
      }],
      gameShow: false,
      type: 0,
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {
        name: '图库中心',
        login: require('../../../assets/image/logo.png')
      } : JSON.parse(localStorage.getItem('config')),
      https: process.env.VUE_APP_BASE_API,
      refreshKey: 1,
      refreshKeys: 1,
      acitveIndexLottery: 5,
      adList: []
    });
    let {
      show,
      shows,
      articleInfo,
      user,
      guanzhuicno,
      guanzhuicolor,
      guanzhuname,
      getUserInfo,
      id,
      listData,
      clientWidth,
      datalist,
      gameShow,
      websiteName,
      https,
      refreshKey,
      refreshKeys,
      acitveIndexLottery
    } = toRefs(data);
    let router = useRouter();
    let route = useRoute();
    const $store = useStore(); //获取vuex
    onBeforeMount(() => {
      data.adList = JSON.parse(localStorage.getItem('avList')).adlist;
      // data.id = route.query.id;
      // data.corpusTypeId = route.query.corpusTypeId
      // infoData(data.id,data.corpusTypeId);
      // data.type = route.query.id;
    });
    //数据请求区域
    let infoData = (id, corpusTypeId) => {
      let articleData = JSON.parse(sessionStorage.getItem(`profile_${data.acitveIndexLottery}_id${id}_corpusTypeId_${corpusTypeId}`));
      if (articleData == null) {
        infoArticle({
          id: id,
          corpusTypeId: corpusTypeId
        }).then(res => {
          //帖子详情
          if (res.status != '400') {
            let datas = res.data.data;
            datas.content.replace('【', '');
            datas.content.replace('】', '');
            data.articleInfo = datas;
            sessionStorage.setItem(`profile_${data.acitveIndexLottery}_id${id}_corpusTypeId_${corpusTypeId}`, JSON.stringify(datas));
            getUser(datas.user_id);
          } else {
            Dialog(res.data.message, '#07c160');
          }
        });
      } else {
        data.articleInfo = articleData;
        getUser(articleData.user_id);
      }
    };
    let getUser = id => {
      //用户数据
      getUserIndex({
        id: id
      }).then(res => {
        if (res.status != '400') {
          let datas = res.data.data;
          data.getUserInfo = datas;
          if (!datas.isFocuson) {
            data.guanzhuicolor = '#FF1A00';
            data.guanzhuname = '关注';
          } else {
            data.guanzhuicolor = '#A1B1C8';
            data.guanzhuname = '已关注';
            data.guanzhuicno = false;
          }
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let focus = id => {
      //关注
      setFocus({
        id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let onefollow = id => {
      //点赞
      follow({
        id: id,
        corpusTypeId: data.corpusTypeId
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let handleNext = id => {
      window.scrollTo(0, 0);
      data.refreshKeys += 1;
      data.id = id;
      infoData(data.id, data.corpusTypeId);
    };

    //功能区域
    let majoFun = index => {
      //comment组件的信息数据
      if (index == 0) {
        //点赞
        if (data.articleInfo.isFollow == 0) {
          //判断是否点赞
          data.articleInfo.isFollow = 1;
          Dialog('点赞成功', '#07c160');
          data.articleInfo.thumbUpCount += 1;
          data.getUserInfo.likes += 1;
        } else {
          data.articleInfo.isFollow = 0;
          data.articleInfo.thumbUpCount -= 1;
          data.getUserInfo.likes -= 1;
          Dialog('已取消点赞', '#07c160');
        }
        onefollow(data.articleInfo.id);
      } else if (index == 2) {
        data.shows = true;
      }
    };
    let guanzhu = () => {
      //关注
      focus(data.articleInfo.user_id);
      if (data.guanzhuname == '关注') {
        data.guanzhuicno = false;
        data.guanzhuicolor = '#A1B1C8';
        data.guanzhuname = '已关注';
        Dialog('已关注', '#07c160');
      } else {
        data.guanzhuicno = true;
        data.guanzhuicolor = '#FF1A00';
        data.guanzhuname = '关注';
        Dialog('已取消关注', '#07c160');
      }
    };
    let lahe = () => {
      //跳转历史记录
      router.push({
        path: `/materials/list/history`,
        query: {
          id: data.articleInfo.id,
          title: data.getUserInfo.nickname,
          userId: data.articleInfo.user_id,
          corpusTypeId: data.corpusTypeId
        }
      });
    };
    let majorMore = () => {
      //显示更多
      data.show = true;
    };
    let funcMore = index => {
      //更多弹窗里面的信息 0 是用户主页，1是历史记录  2是举报
      if (index == 1) {
        router.push({
          path: `/materials/list/history`,
          query: {
            id: data.articleInfo.id,
            title: data.getUserInfo.nickname,
            userId: data.articleInfo.user_id,
            corpusTypeId: data.corpusTypeId
          }
        });
      } else if (index == 0) {
        router.push({
          path: '/userInfo',
          query: {
            id: data.getUserInfo.id
          }
        });
      } else if (index == 2) {
        router.push({
          path: '/user/report',
          query: {
            typeId: 4,
            id: data.articleInfo.id,
            corpusTypeId: data.corpusTypeId
          }
        });
      }
    };
    let majoruser = id => {
      //跳转用户中心
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    let majorTab = item => {
      if (item.name == '聊天室') {
        data.gameShow = true;
      }
    };
    let selecRoom = item => {
      //选择聊天室
      router.push({
        path: '/wechat',
        query: {
          title: item.name,
          id: item.id
        }
      });
    };
    let start = ref(true);
    onDeactivated(() => {
      data.refreshKey += 1;
      start.value = false;
    });
    onActivated(() => {
      data.refreshKey += 1;
      if (route.query.lottery != undefined) {
        data.acitveIndexLottery = route.query.lottery;
      } else {
        data.acitveIndexLottery = localStorage.getItem('lotteryType');
      }
      window.scrollTo(0, 0);
      let type = route.query.id;
      data.corpusTypeId = route.query.corpusTypeId;
      if (start.value || type != data.id) {
        data.id = route.query.id;
        infoData(data.id, data.corpusTypeId);
      }
    });
    let contentbox = computed(() => item => {
      //替换
      if (item != undefined) {
        return item.replace(/\n/g, "<br>");
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_swipers = _resolveComponent("swipers");
      const _component_botton = _resolveComponent("botton");
      const _component_advertisement = _resolveComponent("advertisement");
      const _component_major = _resolveComponent("major");
      const _component_majorBack = _resolveComponent("majorBack");
      const _component_wecharList = _resolveComponent("wecharList");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "详情"
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(majorMore) && _unref(majorMore)(...args))
        }, _cache[7] || (_cache[7] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_swipers, {
        swiperImg: _unref(data).adList
      }, null, 8, ["swiperImg"])]), (_openBlock(), _createBlock(lottery, {
        key: _unref(refreshKey),
        lottery: _unref(acitveIndexLottery)
      }, null, 8, ["lottery"])), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "tk-info-head",
        onClick: _cache[1] || (_cache[1] = $event => _unref(majoruser)(_unref(getUserInfo).id))
      }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
        src: _unref(https) + _unref(websiteName).logo,
        alt: ""
      }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(getUserInfo).nickname) + "管理员", 1), _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(articleInfo).created_at), 1)])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("ul", null, [_createElementVNode("li", null, [_createElementVNode("span", _hoisted_11, _toDisplayString(_unref(getUserInfo).likes), 1), _cache[8] || (_cache[8] = _createElementVNode("span", {
        class: "name"
      }, "获赞", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_12, _toDisplayString(_unref(getUserInfo).fans), 1), _cache[9] || (_cache[9] = _createElementVNode("span", {
        class: "name"
      }, "粉丝", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_13, _toDisplayString(_unref(getUserInfo).follows), 1), _cache[10] || (_cache[10] = _createElementVNode("span", {
        class: "name"
      }, "关注", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_14, _toDisplayString(_unref(getUserInfo).growth_score), 1), _cache[11] || (_cache[11] = _createElementVNode("span", {
        class: "name"
      }, "成长值", -1))])])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_botton, {
        onSubmits: _unref(guanzhu),
        name: _unref(guanzhuname),
        bgcolor: _unref(guanzhuicolor),
        guanzhu: _unref(guanzhuicno)
      }, null, 8, ["onSubmits", "name", "bgcolor", "guanzhu"])]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_botton, {
        onSubmits: _unref(lahe),
        name: "历史帖子"
      }, null, 8, ["onSubmits"])])]), _createElementVNode("div", _hoisted_18, [_cache[13] || (_cache[13] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_19, [_createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$filters.format(_unref(articleInfo).clickCount)), 1), _cache[12] || (_cache[12] = _createElementVNode("p", {
        class: "name"
      }, "阅读数", -1))])])]), _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(articleInfo).title), 1), _createElementVNode("div", {
        class: "tk-deails-content",
        innerHTML: _unref(contentbox)(_unref(articleInfo).content)
      }, null, 8, _hoisted_22)]), _unref(articleInfo).prev != null ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "up_page tk-next",
        onClick: _cache[2] || (_cache[2] = $event => _unref(handleNext)(_unref(articleInfo).prev.id))
      }, "上一篇：" + _toDisplayString(_unref(articleInfo).prev.title), 1)) : _createCommentVNode("", true), _unref(articleInfo).next != null ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "next_page tk-next",
        onClick: _cache[3] || (_cache[3] = $event => _unref(handleNext)(_unref(articleInfo).next.id))
      }, "下一篇：" + _toDisplayString(_unref(articleInfo).next.title), 1)) : _createCommentVNode("", true)]), _createVNode(_component_advertisement), _createVNode(_component_major, {
        onMajorTab: _unref(majorTab),
        listData: _unref(listData)
      }, null, 8, ["onMajorTab", "listData"]), _createVNode(_component_majorBack, {
        x: "11d",
        r: "10px",
        y: '72%'
      }), _createVNode(comment, {
        dataInfo: _unref(articleInfo),
        typeId: "4",
        corpusTypeId: _unref(data).corpusTypeId,
        onMajoFun: _unref(majoFun)
      }, null, 8, ["dataInfo", "corpusTypeId", "onMajoFun"]), (_openBlock(), _createBlock(comment_list, {
        type: "4",
        corpusTypeId: _unref(data).corpusTypeId,
        id: _unref(id),
        key: _unref(refreshKeys)
      }, null, 8, ["corpusTypeId", "id"])), _createVNode(more, {
        show: _unref(show),
        "onUpdate:show": _cache[4] || (_cache[4] = $event => _isRef(show) ? show.value = $event : show = $event),
        onMajorMore: _unref(funcMore),
        datalist: _unref(datalist)
      }, null, 8, ["show", "onMajorMore", "datalist"]), _createVNode(share, {
        shows: _unref(shows),
        "onUpdate:shows": _cache[5] || (_cache[5] = $event => _isRef(shows) ? shows.value = $event : shows = $event)
      }, null, 8, ["shows"]), _createVNode(_component_wecharList, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[6] || (_cache[6] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        onSelecRoom: _unref(selecRoom)
      }, null, 8, ["show", "onSelecRoom"])], 64);
    };
  }
};