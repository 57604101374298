import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/onreturn.png';
import _imports_1 from '../../../assets/image/baisearch.png';
import _imports_2 from '../../../assets/image/logo.jpg';
import _imports_3 from '../../../assets/image/weigui.png';
const _hoisted_1 = {
  class: "tk-main-head-nav",
  ref: "scrollTabs"
};
const _hoisted_2 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_3 = {
  class: "tk-head-info"
};
const _hoisted_4 = {
  class: "tk-main-room"
};
const _hoisted_5 = {
  class: "tk-room-user"
};
const _hoisted_6 = {
  class: "tk-user-head"
};
const _hoisted_7 = {
  class: "tk-user-avater"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "tk-user-name"
};
const _hoisted_10 = {
  class: "tk-user-records"
};
const _hoisted_11 = {
  class: "staus"
};
const _hoisted_12 = {
  key: 1
};
const _hoisted_13 = {
  class: "tk-user-head"
};
const _hoisted_14 = {
  class: "tk-user-avater"
};
const _hoisted_15 = {
  class: "tk-user-name"
};
const _hoisted_16 = {
  class: "name"
};
const _hoisted_17 = {
  class: "tiems"
};
const _hoisted_18 = {
  class: "tk-user-records"
};
const _hoisted_19 = {
  class: "tk-weigui"
};
const _hoisted_20 = {
  class: "tk-weigui-l"
};
const _hoisted_21 = {
  key: 0
};
const _hoisted_22 = {
  key: 1
};
const _hoisted_23 = {
  key: 0
};
import { getBlackHouse, getUserBlackHouse } from '@/utils/https/me';
import { computed, onBeforeMount, reactive, toRefs, getCurrentInstance } from 'vue';

import { useRouter } from 'vue-router';
import { ref } from 'vue';
export default {
  __name: 'room',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    let data = reactive({
      blackRoom: [],
      page: 1,
      getUserInfo: {},
      message: ''
    });
    let {
      blackRoom,
      getUserInfo,
      message
    } = toRefs(data);
    onBeforeMount(() => {
      getUserBlackHouses();
      data.getUserInfo = JSON.parse(localStorage.getItem('usetInfo'));
    });
    //接口请求
    let getBlackHouses = page => {
      getBlackHouse({
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.blackRoom.push(...datas);
        }
      });
    };
    let getUserBlackHouses = () => {
      getUserBlackHouse().then(res => {
        if (res.data.status != 20000) {
          data.message = res.data.message;
        } else {
          let datas = res.data.data;
          data.message = datas.mushin.name;
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getBlackHouses(data.page);
      data.page++;
    };
    const avatarCompent = computed(() => item => {
      var str = new RegExp("http");
      if (str.test(item)) {
        return item;
      } else {
        return proxy.$httpsUrl + item;
      }
    });
    let returnUp = () => {
      router.back();
    };
    let handleRouter = () => {
      router.push({
        path: '/me/room/search'
      });
    };
    return (_ctx, _cache) => {
      const _component_empty = _resolveComponent("empty");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "小黑屋", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        src: _imports_1,
        alt: "",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(handleRouter) && _unref(handleRouter)(...args))
      })])])], 512), _cache[13] || (_cache[13] = _createElementVNode("div", {
        class: "h"
      }, null, -1)), _createElementVNode("div", _hoisted_4, [_cache[12] || (_cache[12] = _createElementVNode("div", {
        class: "tk-room-tips"
      }, "温馨提示：违反社区规则的用户将进入小黑屋进行公示，直到解封时间。", -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
        src: _unref(getUserInfo).avatar == '' ? require('@/assets/image/logo.jpg') : _ctx.$httpsUrl + _unref(getUserInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_8)]), _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(getUserInfo).nickname), 1), _createElementVNode("div", _hoisted_10, [_cache[5] || (_cache[5] = _createElementVNode("span", null, "个人违规记录：", -1)), _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(message)), 1)])]), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "tk-user-tips"
      }, " 和谐社区靠大家，您是遵守社区规则的好彩民，暂无禁言记录继续保持哦！ ", -1))]), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[2] || (_cache[2] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(blackRoom).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blackRoom), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createVNode(_component_van_image, {
            src: avatarCompent.value(item.user.avatar)
          }, {
            error: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("img", {
              src: _imports_2
            }, null, -1)])),
            _: 2
          }, 1032, ["src"])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, _toDisplayString(item.user.nickname), 1), _createElementVNode("div", _hoisted_17, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", _hoisted_18, [_createElementVNode("span", null, "共" + _toDisplayString(item.count) + "次违规", 1)])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("p", null, [_cache[8] || (_cache[8] = _createTextVNode("违规理由： ")), _createElementVNode("span", null, _toDisplayString(item.mushin.name), 1)]), _createElementVNode("p", null, [_cache[9] || (_cache[9] = _createTextVNode("处理结果： ")), item.is_forever ? (_openBlock(), _createElementBlock("span", _hoisted_21, "永久禁言")) : (_openBlock(), _createElementBlock("span", _hoisted_22, "禁言" + _toDisplayString(item.mushin_days), 1))]), !item.is_forever ? (_openBlock(), _createElementBlock("p", _hoisted_23, [_cache[10] || (_cache[10] = _createTextVNode("解封时间： ")), _createElementVNode("span", null, _toDisplayString(item.mushin_end_date), 1)])) : _createCommentVNode("", true)]), _cache[11] || (_cache[11] = _createElementVNode("div", {
            class: "tk-weigui-r"
          }, [_createElementVNode("img", {
            src: _imports_3,
            alt: ""
          })], -1))])]);
        }), 128))]))]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};