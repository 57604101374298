import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, isRef as _isRef, KeepAlive as _KeepAlive, Fragment as _Fragment } from "vue";
import _imports_0 from '../../../assets/image/error.png';
import _imports_1 from '../../../assets/image/play.png';
const _hoisted_1 = {
  class: "tk-main-guess"
};
const _hoisted_2 = {
  key: 0,
  class: "tk-lootery"
};
const _hoisted_3 = {
  class: "tk-artivles"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "tk-artivles-title"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  class: "tk-artivle-img"
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  class: "tk-artivles-title"
};
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = {
  key: 0,
  class: "tk-video"
};
const _hoisted_12 = {
  class: "popo"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  key: 1,
  class: "video"
};
const _hoisted_15 = ["src"];
const _hoisted_16 = {
  class: "tk-swiper",
  style: {
    "margin-top": "10px"
  }
};
import lottery from '@/components/page/lottery.vue';
import year from '@/components/page/guess/year.vue';
import articlesvote from '@/components/page/guess/articlesvote.vue';
import comment from '@/components/page/comment.vue';
import comment_list from '@/components/page/comment_list.vue';
import Dialog from '@/components/mode/message';
import { useStore } from 'vuex';
import { guess, guessDetail, guessFollow, guessCollect, guessVote } from '@/utils/https/guess';
import { numbers } from '@/utils/https/lottery';

import { useRouter, useRoute } from 'vue-router';
import { onBeforeMount, reactive, toRefs, ref, onDeactivated, onActivated } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const $store = useStore(); //获取vuex
    onBeforeMount(() => {
      data.adList = JSON.parse(localStorage.getItem('avList')).adlist;
      data.daDeac = JSON.parse(localStorage.getItem('avDesc'));
      // if(route.query.id != undefined){//判断返回过来的值更多
      //     data.guessId = route.query.id;
      //     data.year = route.query.year;
      //     data.lottery = route.query.lottery;
      //     data.oneissue = route.query.issue;
      //     guessissue(data.year,data.lottery);
      // }else{
      //     guessissue(data.year,data.lottery);
      // }
    });
    let data = reactive({
      activeType: 'article',
      issue: [],
      year: localStorage.getItem('yearone'),
      //年份
      lottery: localStorage.getItem('lotteryType'),
      //彩票类型
      galleryData: [],
      voteList: [],
      shows: false,
      show: false,
      showVideo: false,
      more: '',
      //更多
      numarray: {
        year: "2023",
        issue: "000",
        lotteryType: 1,
        lotteryTime: "2020-12-29",
        number_attr: [{
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }, {
          color: 0,
          number: "0",
          shengXiao: "-",
          wuXing: "-"
        }]
      },
      //开奖号码
      oneissue: '',
      guessId: '',
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {} : JSON.parse(localStorage.getItem('config')),
      lotteryShow: false,
      adList: [],
      daDeac: []
    });
    let {
      activeType,
      issue,
      galleryData,
      voteList,
      shows,
      showVideo,
      show,
      numarray,
      oneissue,
      websiteName,
      lotteryShow
    } = toRefs(data);
    //接口请求地址
    let guessissue = async (year, lottery) => {
      //获取期数
      let guessId;
      await guess({
        year: year,
        lotteryType: lottery
      }).then(res => {
        if (res == undefined) return false;
        data.issue = res.data.data;
        if (data.issue.length == 0) {
          Dialog('没有这一年的历史数据哦！', '#07c160');
        } else {
          guessId = res.data.data[0].id;
        }
      });
      if (data.guessId == '') {
        guessDetails(guessId);
      } else {
        guessDetails(data.guessId);
      }
    };
    let guessDetails = id => {
      //获取详情
      guessDetail({
        id: id
      }).then(res => {
        if (res == undefined) return false;
        if (res.data.status == 40000) {
          Dialog('没有这一年的历史数据哦！', '#07c160');
          return false;
        }
        let datas = res.data.data.HumorousDetailData;
        number(datas.year, datas.lotteryType, datas.issue); //详情获取每一期的期数 
        datas.isFollow = datas.follow;
        datas.isCollect = datas.collect;
        data.galleryData = datas;
        data.voteList = res.data.data.voteList;
        data.voteList.forEach(item => {
          let c = sum(data.voteList);
          if (c == 0) {
            item.total = 0 + '%';
          } else {
            item.total = 100 / c * item.vote_num + '%';
          }
        });
      });
    };
    let follow = id => {
      //点赞
      guessFollow({
        id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let collect = id => {
      //收藏
      guessCollect({
        id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let guessVotes = (id, sx) => {
      guessVote({
        id: id,
        vote_zodiac: sx
      }).then(res => {
        Dialog(res.data.message, '#07c160');
        if (res.data.message != '您已经投票了') {
          data.voteList.forEach(item => {
            if (item.name == sx) {
              item.vote_num += 1;
            }
            let c = sum(data.voteList);
            if (c == 0) {
              item.percentage = 0;
            } else {
              item.percentage = 100 / c * item.vote_num;
            }
          });
        }
      });
    };
    function sum(arr) {
      //获得投票总数
      var s = 0;
      arr.forEach(function (val) {
        s += val.vote_num;
      }, 0);
      return s;
    }
    ;
    let number = (year, lotteryType, issue) => {
      //每一期开奖结果
      numbers({
        year: year,
        lotteryType: lotteryType,
        issue: issue
      }).then(res => {
        if (res.data.status == 40000) {
          data.lotteryShow = false;
        } else {
          let datas = res.data.data;
          data.numarray = datas;
          data.lotteryShow = true;
        }
      });
    };
    //功能区域

    let handleUp = () => {
      let issue = data.oneissue == '' ? data.issue[0].issue : data.oneissue;
      data.issue.forEach((item, index) => {
        if (item.issue == issue) {
          if (index - 1 >= 0) {
            data.oneissue = data.issue[index - 1].issue;
            guessDetails(data.issue[index - 1].id);
          }
        }
      });
    };
    let handleNext = () => {
      let issue = data.oneissue == '' ? data.issue[0].issue : data.oneissue;
      data.issue.forEach((item, index) => {
        if (item.issue == issue) {
          if (index + 1 <= data.issue.length) {
            data.oneissue = data.issue[index + 1].issue;
            guessDetails(data.issue[index + 1].id);
          }
        }
      });
    };
    let majoissue = () => {
      //显示更多期数
      router.push({
        path: '/guess/period',
        query: {
          data: JSON.stringify(data.issue)
        }
      });
    };
    const handleThumbUpCount = () => {
      if (!data.galleryData.isFollow) {
        //判断是否点赞
        data.galleryData.isFollow = 1;
        Dialog('点赞成功', '#07c160');
        data.galleryData.thumbUpCount += 1;
      } else {
        data.galleryData.isFollow = 0;
        data.galleryData.thumbUpCount -= 1;
        Dialog('已取消点赞', '#07c160');
      }
      follow(data.galleryData.id);
    };
    const handleCollect = () => {
      if (!data.galleryData.isCollect) {
        //判断是收藏
        data.galleryData.isCollect = 1;
        Dialog('收藏成功', '#07c160');
        data.galleryData.collectCount += 1;
      } else {
        data.galleryData.isCollect = 0;
        data.galleryData.collectCount -= 1;
        Dialog('已取消收藏', '#07c160');
      }
      collect(data.galleryData.id);
    };
    let majoFun = index => {
      // comment组件点击 0 是点赞 3是收藏 2是分享
      if (index == 3) {
        if (!data.galleryData.isCollect) {
          //判断是收藏
          data.galleryData.isCollect = 1;
          Dialog('收藏成功', '#07c160');
          data.galleryData.collectCount += 1;
        } else {
          data.galleryData.isCollect = 0;
          data.galleryData.collectCount -= 1;
          Dialog('已取消收藏', '#07c160');
        }
        collect(data.galleryData.id);
      } else if (index == 0) {
        if (!data.galleryData.isFollow) {
          //判断是否点赞
          data.galleryData.isFollow = 1;
          Dialog('点赞成功', '#07c160');
          data.galleryData.thumbUpCount += 1;
        } else {
          data.galleryData.isFollow = 0;
          data.galleryData.thumbUpCount -= 1;
          Dialog('已取消点赞', '#07c160');
        }
        follow(data.galleryData.id);
      } else if (index == 2) {
        data.show = true;
      }
    };
    let issueFun = item => {
      //切换期数
      data.galleryData = [];
      data.oneissue = item.issue;
      guessDetails(item.id);
    };
    let vote = async sx => {
      //投票
      guessVotes(data.galleryData.id, sx);
      data.shows = false;
    };
    let majoYear = item => {
      data.year = item;
      data.more = '';
      data.oneissue == '';
      data.galleryData = [];
      guessissue(item, data.lottery);
    };
    let principalTans = item => {
      //切换tab

      data.showVideo = false;
      if (item == '0') {
        data.activeType = 'article';
      } else {
        data.activeType = 'video';
      }
    };
    let video_pay = () => {
      data.showVideo = true;
    };
    let start = ref(true);
    onDeactivated(() => {
      start.value = !start.value;
    });
    onActivated(() => {
      window.scrollTo(0, 0);
      let lottery = route.query.lottery;
      let year = localStorage.getItem('yearone');
      data.lottery = lottery;
      if (route.query.id != undefined) {
        //判断返回过来的值更多
        data.guessId = route.query.id;
        data.year = route.query.year;
        data.lottery = route.query.lottery;
        data.oneissue = route.query.issue;
        guessissue(data.year, data.lottery);
      } else {
        data.guessId = '';
        guessissue(year, lottery);
      }
    });
    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_image = _resolveComponent("van-image");
      const _component_swipers = _resolveComponent("swipers");
      const _component_majorBack = _resolveComponent("majorBack");
      const _component_share = _resolveComponent("share");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "幽默猜测"
      }, {
        tkCont: _withCtx(() => [_createVNode(_component_navTabsVue, {
          nameLeft: "图片猜测",
          onPrincipalTans: _unref(principalTans),
          nameRight: "视频猜测"
        }, null, 8, ["onPrincipalTans"]), _createVNode(year, {
          issue: _unref(issue),
          onMajoissue: _unref(majoissue),
          onIssueFun: _unref(issueFun),
          onMajoYear: _unref(majoYear),
          onissue: _unref(oneissue),
          year: _unref(data).year
        }, null, 8, ["issue", "onMajoissue", "onIssueFun", "onMajoYear", "onissue", "year"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_unref(lotteryShow) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(lottery, {
        types: false,
        data: _unref(numarray)
      }, null, 8, ["data"])])) : _createCommentVNode("", true), _createVNode(articlesvote, {
        onHandleThumbUpCount: handleThumbUpCount,
        onHandleCollect: handleCollect,
        galleryData: _unref(galleryData),
        onVote: _unref(vote),
        voteList: _unref(voteList),
        onHandleUp: _unref(handleUp),
        onHandleNext: _unref(handleNext),
        type: "guess",
        show: _unref(shows),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(shows) ? shows.value = $event : shows = $event)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_unref(activeType) != 'video' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(galleryData).pictureTitle), 1), _createElementVNode("div", {
          class: "tk-artivle-content",
          innerHTML: _unref(galleryData).pictureContent
        }, null, 8, _hoisted_6), _createElementVNode("div", _hoisted_7, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
          src: _unref(galleryData).imageUrl
        }, {
          error: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)])),
          _: 1
        }, 8, ["src"])), [[_directive_lazy, _unref(galleryData).imageUrl]])])])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_unref(galleryData).videoTitle), 1), _createElementVNode("div", {
          class: "tk-artivle-content",
          innerHTML: _unref(galleryData).videoContent
        }, null, 8, _hoisted_10), !_unref(showVideo) ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("img", {
          src: _ctx.$httpsUrl + _unref(websiteName).logo,
          alt: ""
        }, null, 8, _hoisted_13)]), _createElementVNode("div", {
          class: "video_pay",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(video_pay) && _unref(video_pay)(...args))
        }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1)]))])) : (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("video", {
          autoplay: "autoplay",
          controls: "",
          src: _unref(galleryData).videoUrl,
          style: {
            "width": "100%",
            "height": "100%"
          }
        }, null, 8, _hoisted_15)]))]))])]),
        _: 1
      }, 8, ["galleryData", "onVote", "voteList", "onHandleUp", "onHandleNext", "show"]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_swipers, {
        swiperImg: _unref(data).adList,
        listImg: _unref(data).daDeac
      }, null, 8, ["swiperImg", "listImg"])])]), _createVNode(_component_majorBack), (_openBlock(), _createBlock(_KeepAlive, null, [_unref(galleryData).length != 0 ? (_openBlock(), _createBlock(comment_list, {
        key: 0,
        type: "11",
        id: _unref(galleryData).id,
        "onUpdate:id": _cache[2] || (_cache[2] = $event => _unref(galleryData).id = $event)
      }, null, 8, ["id"])) : _createCommentVNode("", true)], 1024)), _createVNode(comment, {
        dataInfo: _unref(galleryData),
        typeId: "11",
        onMajoFun: _unref(majoFun)
      }, null, 8, ["dataInfo", "onMajoFun"]), _createVNode(_component_share, {
        shows: _unref(show),
        "onUpdate:shows": _cache[3] || (_cache[3] = $event => _isRef(show) ? show.value = $event : show = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};