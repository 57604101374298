import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../assets/image/moneySelect.png';
import _imports_1 from '../../../assets/image/money.png';
import _imports_2 from '../../../assets/image/sright.png';
import _imports_3 from '../../../assets/image/convertselect.png';
const _hoisted_1 = {
  class: "tk-main-purse-exchange"
};
const _hoisted_2 = {
  class: "tk-purse-exchange-money"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  class: "select"
};
const _hoisted_5 = {
  class: "tk-exchange-money"
};
const _hoisted_6 = {
  class: "num"
};
const _hoisted_7 = {
  class: "tk-exchange-select-userlottey"
};
const _hoisted_8 = {
  class: "tk-platform"
};
const _hoisted_9 = {
  class: "platform"
};
const _hoisted_10 = {
  class: "info"
};
const _hoisted_11 = {
  class: "platform"
};
const _hoisted_12 = {
  class: "info"
};
const _hoisted_13 = {
  class: "platform"
};
const _hoisted_14 = {
  class: "info"
};
const _hoisted_15 = {
  class: "tk-botton"
};
const _hoisted_16 = {
  class: "tk-tisp"
};
const _hoisted_17 = {
  class: "bottomSelect"
};
const _hoisted_18 = {
  class: "tk-platform"
};
const _hoisted_19 = ["onClick"];
const _hoisted_20 = {
  class: "platform"
};
const _hoisted_21 = {
  class: "info"
};
const _hoisted_22 = {
  class: "select"
};
const _hoisted_23 = {
  key: 0,
  src: _imports_3,
  alt: ""
};
import { platQuotas, platUserPlat, platRecharge } from '@/utils/https/me';
import Dialog from '@/components/mode/message.js';
import { ref } from 'vue';

import { useRouter } from 'vue-router';
import { reactive, toRefs, onBeforeMount } from 'vue';
export default {
  __name: 'exchange',
  setup(__props) {
    let route = useRouter();
    let data = reactive({
      activeIndex: 100,
      selectMoney: 0,
      purseList: [],
      id: '',
      showBottom: false,
      selectplt: {
        plats: {
          name: '图库'
        },
        plat_user_account: '*****',
        plat_user_name: '*****'
      }
    });
    let {
      activeIndex,
      selectMoney,
      purseList,
      showBottom,
      selectplt
    } = toRefs(data);
    onBeforeMount(() => {
      platQuotass();
      platUserPlats();
    });
    let money = ref([{
      quota: "*"
    }, {
      quota: "*"
    }, {
      quota: "*"
    }, {
      quota: "*"
    }, {
      quota: "*"
    }, {
      quota: "*"
    }]); //金额

    let platUserPlats = () => {
      //获取列表
      platUserPlat().then(res => {
        if (res.data.status == 40000) {
          Dialog(res.data.message);
        } else {
          let datas = res.data.data;
          data.selectplt = datas[0];
          data.id = datas[0].id;
          datas.forEach((element, index) => {
            if (index == 0) {
              element.select = true;
            } else {
              element.select = false;
            }
          });
          data.purseList.push(...datas);
        }
      });
    };
    let platQuotass = () => {
      //获取金鹅
      platQuotas().then(res => {
        let datas = res.data.data;
        money.value = datas;
      });
    };
    let platRecharges = (id, money) => {
      platRecharge({
        user_plat_id: id,
        quota: money
      }).then(res => {
        if (res.data.status == 40000) {
          Dialog(res.data.message);
          route.push({
            path: '/me'
          });
        } else {
          Dialog(res.data.message);
        }
      });
    };
    let handleMoney = (item, index) => {
      data.activeIndex = index;
      data.selectMoney = item.quota;
    };
    let selectLottry = index => {
      //选择平台
      data.purseList.forEach(element => {
        if (element.id == index) {
          element.select = true;
          data.id = '';
          data.selectplt = element;
        } else {
          element.select = false;
        }
        if (element.select == true) {
          data.id = element.id;
        }
      });
      data.showBottom = false;
    };
    let handleSumite = () => {
      if (data.id == '') {
        Dialog('请选择充值平台');
      } else if (data.selectMoney == '0') {
        Dialog('请选择充值金额');
      } else {
        platRecharges(data.id, data.selectMoney);
      }
    };
    let handleMore = () => {
      data.showBottom = true;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "额度兑换"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(money), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(_unref(activeIndex) == index ? 'active' : ''),
          onClick: $event => _unref(handleMoney)(item, index)
        }, [_unref(activeIndex) == index ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))) : _createCommentVNode("", true), _cache[3] || (_cache[3] = _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, "¥ " + _toDisplayString(item.quota), 1)], 10, _hoisted_3);
      }), 128))]), _createElementVNode("div", _hoisted_5, [_cache[4] || (_cache[4] = _createElementVNode("span", null, "获得金币：", -1)), _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(selectMoney)), 1)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
        class: "tk-select-title",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(handleMore) && _unref(handleMore)(...args))
      }, _cache[5] || (_cache[5] = [_createElementVNode("p", null, " 请选择扣除额度的彩票余额账号", -1), _createElementVNode("p", null, [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      })], -1)])), _createElementVNode("div", _hoisted_8, [_createElementVNode("ul", null, [_createElementVNode("li", null, [_createElementVNode("div", _hoisted_9, [_cache[6] || (_cache[6] = _createElementVNode("span", {
        class: "name"
      }, "平台：", -1)), _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(selectplt).plats.name), 1)]), _createElementVNode("div", _hoisted_11, [_cache[7] || (_cache[7] = _createElementVNode("span", {
        class: "name"
      }, "账号：", -1)), _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(selectplt).plat_user_account), 1)]), _createElementVNode("div", _hoisted_13, [_cache[8] || (_cache[8] = _createElementVNode("span", {
        class: "name"
      }, "姓名：", -1)), _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(selectplt).plat_user_name), 1)]), _cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "select"
      }, [_createElementVNode("img", {
        src: _imports_3,
        alt: ""
      })], -1))])])]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_botton, {
        name: "充值",
        bgcolor: _unref(data).id == '' ? 'rgba(3, 159, 76, 0.4)' : '#07c160',
        onClick: _unref(handleSumite)
      }, null, 8, ["bgcolor", "onClick"])])]), _createElementVNode("div", _hoisted_16, [_cache[10] || (_cache[10] = _createTextVNode(" 温馨提示：")), _createElementVNode("span", null, " 请选择您想额度转换" + _toDisplayString(_unref(selectplt).plats.name) + "，选择好之后，", 1), _createTextVNode("务必保持" + _toDisplayString(_unref(selectplt).plats.name) + "账号有充足余额， ", 1), _cache[11] || (_cache[11] = _createElementVNode("span", null, "务必按照提示充值。", -1)), _createTextVNode(" 目前只支持" + _toDisplayString(_unref(selectplt).plats.name) + "平台余额兑换至图库金币，请根据需求充值。 ", 1), _cache[12] || (_cache[12] = _createElementVNode("p", null, [_createElementVNode("span", null, "工作时间11:00-23:00")], -1))])]), _createVNode(_component_van_popup, {
        show: _unref(showBottom),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(showBottom) ? showBottom.value = $event : showBottom = $event),
        round: "",
        position: "bottom",
        style: {
          height: '30%'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_17, [_cache[13] || (_cache[13] = _createElementVNode("div", {
          class: "title"
        }, "选择平台账号", -1)), _createElementVNode("div", _hoisted_18, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(purseList), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(selectLottry)(item.id)
          }, [_createElementVNode("div", _hoisted_20, [_createElementVNode("span", _hoisted_21, _toDisplayString(item.plats.name), 1)]), _createElementVNode("div", _hoisted_22, [item.select ? (_openBlock(), _createElementBlock("img", _hoisted_23)) : _createCommentVNode("", true)])], 8, _hoisted_19);
        }), 128))])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};