import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '../../assets/image/close.png';

import { useRouter } from 'vue-router';
const _hoisted_1 = {
  class: "tk-main-QEcode"
};
const _hoisted_2 = {
  class: "tk-exit-name"
};
const _hoisted_3 = {
  class: "tk-exit-from"
};
const _hoisted_4 = {
  class: "tk-botton"
};
export default {
  __name: 'contactUser',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    let props = __props;
    let emits = __emit;
    let colse = () => {
      emits('update:show', false);
    };
    let overlayPop = () => {
      emits('update:show', false);
    };
    let addUser = () => {
      router.push({
        path: '/me/kf'
      });
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: __props.show
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(colse) && _unref(colse)(...args))
        }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("div", {
          class: "tk-exit-title"
        }, "温馨提示", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(__props.title), 1)])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_botton, {
          name: "立即联系",
          onClick: _unref(addUser)
        }, null, 8, ["onClick"])])])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};