import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../../../assets/image/onreturn.png';
import _imports_1 from '../../../assets/image/ranking/dj.png';
const _hoisted_1 = {
  class: "tk-main-head-nav",
  ref: "scrollTabs"
};
const _hoisted_2 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_3 = {
  class: "tk-ranking"
};
const _hoisted_4 = {
  class: "tk-ranking"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "img"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "userInfo"
};
const _hoisted_9 = {
  class: "username"
};
const _hoisted_10 = {
  class: "num"
};
const _hoisted_11 = {
  class: "tk-ranking-list"
};
const _hoisted_12 = {
  class: "tk-ranking-s"
};
const _hoisted_13 = {
  key: 0,
  class: "tk-ranking-me"
};
const _hoisted_14 = {
  class: "tk-rankings"
};
const _hoisted_15 = {
  class: "tk-ranking-me-avater"
};
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  class: "tk-ranking-me-user"
};
const _hoisted_18 = {
  class: "tk-name"
};
const _hoisted_19 = {
  class: "ranking"
};
const _hoisted_20 = {
  class: "num"
};
const _hoisted_21 = {
  class: "tk-ranking-other"
};
const _hoisted_22 = {
  key: 0
};
const _hoisted_23 = {
  class: "tk-ranking"
};
const _hoisted_24 = {
  class: "tk-user"
};
const _hoisted_25 = {
  class: "avater"
};
const _hoisted_26 = ["src"];
const _hoisted_27 = {
  class: "num"
};
import { rankList } from '@/utils/https/me';
import { onBeforeMount, reactive, toRefs } from 'vue';

import { useRouter } from 'vue-router';
export default {
  __name: 'grade',
  setup(__props) {
    let data = reactive({
      userInfo: {
        nickname: '图库',
        rank: 1,
        fans: 48
      },
      rankingList: [],
      three: [{
        bg: require('../../../assets/image/ranking/one.png'),
        class: 'tk-ranking-one',
        avatar: '',
        nickname: '',
        growth_score: ''
      }, {
        bg: require('../../../assets/image/ranking/three.png'),
        class: 'tk-ranking-tow',
        avatar: '',
        nickname: '',
        growth_score: ''
      }, {
        bg: require('../../../assets/image/ranking/tow.png'),
        class: 'tk-ranking-three',
        avatar: '',
        nickname: '',
        growth_score: ''
      }]
    });
    let {
      userInfo,
      rankingList,
      three
    } = toRefs(data);
    onBeforeMount(() => {
      if (getQueryString('token') != null) {
        localStorage.setItem('Authorization', getQueryString('token_type') + ' ' + getQueryString('token'));
        data.ios = true;
      }
      fanLists();
    });
    let getQueryString = name => {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      ;
      return null;
    };
    let fanLists = () => {
      rankList().then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        let user = res.data.data.info;
        datas.forEach((item, index) => {
          if (index < 3) {
            data.three[index].avatar = item.avatar;
            data.three[index].nickname = item.nickname;
            data.three[index].growth_score = item.growth_score;
          }
        });
        data.rankingList = datas;
        data.userInfo = user;
      });
    };
    const router = useRouter();
    let returnUp = () => {
      router.back();
      callNativeApp('back');
    };
    let callNativeApp = back => {
      try {
        // 從JS傳送資訊到App裡設定的callbackHandler。
        webkit.messageHandlers.callbackHandler.postMessage(back);
      } catch (err) {
        // 如果找不到定義的callbackHandler則回傳錯誤訊息。
      }
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "等级排行榜", -1)), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-head-info"
      }, null, -1))]), _cache[4] || (_cache[4] = _createElementVNode("img", {
        class: "bg",
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(three), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(item.class + ' b'),
          key: index
        }, [_createElementVNode("img", {
          class: "hg",
          src: item.bg,
          alt: ""
        }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
          src: item.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.avatar,
          alt: ""
        }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(item.nickname), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(item.growth_score), 1)])], 2);
      }), 128))])])], 512), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_unref(userInfo).length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("img", {
        src: _unref(userInfo).avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + _unref(userInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_16)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, _toDisplayString(_unref(userInfo).nickname), 1), _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(userInfo).rank == 0 ? '暂无排名' : '第' + _unref(userInfo).rank + '名'), 1)])]), _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(userInfo).growth_score), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_21, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "tk-ranking-title flex-between"
      }, [_createElementVNode("div", {
        class: "ranking"
      }, "排名"), _createElementVNode("div", {
        class: "num"
      }, "展示次数")], -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rankingList), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [index >= 3 ? (_openBlock(), _createElementBlock("li", _hoisted_22, [_createElementVNode("div", _hoisted_23, _toDisplayString(index + 1), 1), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_createElementVNode("img", {
          src: item.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.avatar,
          alt: ""
        }, null, 8, _hoisted_26)]), _createElementVNode("span", null, _toDisplayString(item.nickname), 1)]), _createElementVNode("div", _hoisted_27, _toDisplayString(item.growth_score), 1)])) : _createCommentVNode("", true)]);
      }), 128))])])])])], 64);
    };
  }
};