import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "tk-bonus"
};
const _hoisted_3 = {
  class: "tk-bonus-list"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "titlesc"
};
const _hoisted_6 = {
  class: "name"
};
const _hoisted_7 = {
  key: 0,
  class: "image"
};
const _hoisted_8 = {
  key: 1,
  class: "image"
};
const _hoisted_9 = {
  key: 2,
  class: "image"
};
const _hoisted_10 = {
  key: 3,
  class: "image"
};
const _hoisted_11 = {
  key: 4,
  class: "image"
};
const _hoisted_12 = {
  class: "info",
  style: {
    "margin-left": "10px"
  }
};
const _hoisted_13 = {
  key: 0,
  class: "name"
};
const _hoisted_14 = {
  key: 1,
  class: "name"
};
const _hoisted_15 = {
  class: "tiems"
};
const _hoisted_16 = {
  class: "money"
};
const _hoisted_17 = {
  key: 0,
  style: {
    "font-size": "18px",
    "color": "red"
  }
};
const _hoisted_18 = {
  key: 1,
  style: {
    "font-size": "18px"
  }
};
const _hoisted_19 = {
  key: 2,
  class: "start"
};
const _hoisted_20 = {
  key: 3,
  class: "error"
};
const _hoisted_21 = {
  key: 4,
  class: "start"
};
const _hoisted_22 = {
  class: "content"
};
const _hoisted_23 = {
  class: "titlesb"
};
const _hoisted_24 = {
  class: "record_type"
};
const _hoisted_25 = ["onClick"];
const _hoisted_26 = {
  class: "tiems_title"
};
const _hoisted_27 = {
  class: "record_type record_tiemss"
};
const _hoisted_28 = ["onClick"];
import Dialog from '@/components/mode/message';
import { records } from '@/utils/https/me';
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import { ref, reactive, toRefs } from 'vue';
export default {
  __name: 'record',
  setup(__props) {
    let show = ref(false);
    let shows = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    let recordType = reactive([{
      id: 1,
      name: "提现"
    }, {
      id: 2,
      name: "充值"
    }, {
      id: 3,
      name: "福利"
    }, {
      id: 5,
      name: "转账"
    }, {
      id: 4,
      name: "其它"
    }]);
    let recordTiems = reactive([{
      index: 1,
      id: "-1",
      name: "昨日"
    }, {
      index: 2,
      id: 1,
      name: "今日"
    }, {
      index: 3,
      id: 7,
      name: "近7日"
    }, {
      index: 4,
      id: 30,
      name: "近30日"
    }]);
    let activeIndexType = ref(1);
    let activeIndexTime = ref(2);
    let activeId = ref(1);
    let activeName = ref('提现');
    const handletixian = () => {
      show.value = !show.value;
    };
    const handlejintian = () => {
      shows.value = !shows.value;
    };
    const selectType = (id, name) => {
      activeName.value = name;
      show.value = false;
      datas.bonusInfo.length = 0;
      activeIndexType.value = id;
      finished.value = false;
      loading.value = false;
      datas.page = 1;
      onLoad();
    };
    const selectTimes = (id, index) => {
      shows.value = false;
      datas.bonusInfo.length = 0;
      activeIndexTime.value = index;
      activeId.value = id;
      loading.value = false;
      finished.value = false;
      datas.page = 1;
      onLoad();
    };
    let datas = reactive({
      bonusInfo: [],
      page: 1,
      total: '0',
      sum: '0'
    });
    const router = useRouter();
    let {
      bonusInfo,
      http,
      total,
      sum
    } = toRefs(datas);
    let getFansx = async (page, type, date) => {
      let {
        data
      } = await records({
        page,
        type,
        date,
        limit: 20
      });
      if (data.status == 20000) {
        if (data.data.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          datas.bonusInfo.push(...data.data);
        }
      } else {
        Dialog(data.message, '#07c160');
      }
    };
    const onLoad = () => {
      getFansx(datas.page, activeIndexType.value, activeId.value);
      datas.page++;
    };
    const handleNext = item => {
      router.push({
        path: "/me/bonus/record/details",
        query: {
          data: JSON.stringify(item)
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_empty = _resolveComponent("empty");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "交易记录"
      }), _createElementVNode("div", null, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "bg"
      }, null, -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "type_item",
        onClick: handletixian
      }, [_createElementVNode("span", null, _toDisplayString(_unref(activeName)), 1), _createVNode(_component_van_icon, {
        class: "icon",
        name: "play"
      })]), _createElementVNode("div", {
        class: "type_item",
        onClick: handlejintian
      }, [_createElementVNode("span", null, _toDisplayString(_unref(recordTiems)[_unref(activeIndexTime) - 1].name), 1), _createVNode(_component_van_icon, {
        class: "icon",
        name: "play"
      })])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        "loading-text": " ",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_unref(bonusInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bonusInfo), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index,
            onClick: $event => handleNext(item)
          }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [item.name == '提现' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_van_image, {
            width: "31px",
            height: "31px",
            fit: "cover",
            src: require('../../assets/image/me/tx.png')
          }, null, 8, ["src"])])) : _createCommentVNode("", true), item.name == '充值' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_van_image, {
            width: "31px",
            height: "31px",
            fit: "cover",
            src: require('../../assets/image/me/cz.png')
          }, null, 8, ["src"])])) : _createCommentVNode("", true), item.name == '福利' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_van_image, {
            width: "31px",
            height: "31px",
            fit: "cover",
            src: require('../../assets/image/me/fl.png')
          }, null, 8, ["src"])])) : _createCommentVNode("", true), item.name == '其他' ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_van_image, {
            width: "31px",
            height: "31px",
            fit: "cover",
            src: require('../../assets/image/me/qd.png')
          }, null, 8, ["src"])])) : _createCommentVNode("", true), item.name == '转账' ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_van_image, {
            width: "31px",
            height: "31px",
            fit: "cover",
            src: require('../../assets/image/me/qd.png')
          }, null, 8, ["src"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_12, [item.sub_name ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(item.sub_name), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(item.name), 1)), _createElementVNode("div", _hoisted_15, _toDisplayString(item.created_at), 1)])]), _createElementVNode("div", _hoisted_16, [item.symbol == '-' ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(item.symbol) + _toDisplayString(item.money), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(item.symbol) + _toDisplayString(item.money), 1)), item.status == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_19, "审核中")) : _createCommentVNode("", true), item.status == '-1' ? (_openBlock(), _createElementBlock("span", _hoisted_20, "失败")) : _createCommentVNode("", true), item.status == 1 ? (_openBlock(), _createElementBlock("span", _hoisted_21, "成功")) : _createCommentVNode("", true), _createVNode(_component_van_icon, {
            size: "12",
            name: "arrow"
          })])])], 8, _hoisted_4);
        }), 128))])]),
        _: 1
      }, 8, ["loading", "finished"])]), _createVNode(_component_van_action_sheet, {
        show: _unref(show),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(show) ? show.value = $event : show = $event)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_createVNode(_component_van_icon, {
          class: "icons",
          name: "play"
        })]), _createElementVNode("div", _hoisted_24, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(recordType), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_unref(activeIndexType) == item.id ? 'item active' : 'item'),
            key: index,
            onClick: $event => selectType(item.id, item.name)
          }, _toDisplayString(item.name), 11, _hoisted_25);
        }), 128))])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_action_sheet, {
        show: _unref(shows),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(shows) ? shows.value = $event : shows = $event)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_26, [_createVNode(_component_van_icon, {
          class: "icons",
          name: "play"
        }), _cache[3] || (_cache[3] = _createElementVNode("p", null, "当前系统支持查询最近30日交易记录", -1))]), _createElementVNode("div", _hoisted_27, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(recordTiems), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_unref(activeIndexTime) == item.index ? 'item active' : 'item'),
            key: index,
            onClick: $event => selectTimes(item.id, item.index)
          }, _toDisplayString(item.name), 11, _hoisted_28);
        }), 128))])]),
        _: 1
      }, 8, ["show"])])], 64);
    };
  }
};