import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../../../assets/image/sright.png';
import _imports_1 from '../../../assets/image/del.png';
import _imports_2 from '../../../assets/image/return.png';
const _hoisted_1 = {
  class: "tk-main-row"
};
const _hoisted_2 = {
  class: "tk-row-type"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-more"
};
const _hoisted_5 = {
  key: 0,
  class: "tk-row-conent"
};
const _hoisted_6 = {
  style: {
    "margin-top": "5px"
  }
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "tk-row-conent tk-row-reference"
};
const _hoisted_9 = {
  class: "tk-row-o"
};
const _hoisted_10 = {
  style: {
    "margin-top": "0px"
  }
};
const _hoisted_11 = {
  class: "num"
};
const _hoisted_12 = {
  class: "tk-comment-botton"
};
const _hoisted_13 = {
  class: "tk-row-reference"
};
const _hoisted_14 = ["onClick"];
const _hoisted_15 = {
  class: "num"
};
const _hoisted_16 = {
  key: 0,
  class: "propIssue"
};
const _hoisted_17 = {
  class: "tk-main-head-nav",
  ref: "scrollTabs"
};
const _hoisted_18 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_19 = {
  class: "tk-head-title"
};
const _hoisted_20 = {
  class: "tk-main-materials"
};
const _hoisted_21 = ["onClick"];
import Dialog from '@/components/mode/message';
import { forecastJoin, forecastCreate } from '@/utils/https/gallery';
import { nextTick, reactive } from 'vue';

import { toRefs, ref, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'guessing',
  setup(__props) {
    let data = reactive({
      codeType: [
        // {name:'特肖'},{name:'特码'},{name:'单双'},{name:'一肖'},{name:'大小'},{name:'波色'},{name:'连肖'},{name:'杀特肖'},
        //     {name:'一尾'},{name:'杀肖'},{name:'杀特尾'},{name:'杀尾'},{name:'不中'},{name:'天地生肖'},{name:'家禽野兽'},{name:'左右生肖'},
        //     {name:'杀一头'},{name:'稳禁一段'},{name:'合数单双'},{name:'杀一和'},{name:'三行中特'},
      ],
      child: [{
        name: '正一',
        id: 1
      }, {
        name: '正二',
        id: 2
      }, {
        name: '正三',
        id: 3
      }, {
        name: '正四',
        id: 4
      }, {
        name: '正五',
        id: 5
      }, {
        name: '正六',
        id: 6
      }, {
        name: '特码',
        id: 7
      }],
      codeData: [],
      //号码显示
      select: false,
      activeIndex: 0,
      activeIndexs: 0,
      selectnum: 0,
      //获取sub_list的长度判断可以选择几个
      name: '',
      //用来判断是否显示 child 等于 单双，大小，波色显示
      sub_list: [],
      //存储接口返回的sub_list方便取值
      propCont: false,
      minCount: 1,
      maxCount: 36,
      positionName: ''
    });
    let from = reactive({
      position: 1,
      //位置用来上传
      body: '',
      //存储名字用来上传
      content: [],
      //显示选择的号码
      pic_detail_id: 1,
      forecast_type_id: 1
    });
    let {
      content
    } = toRefs(from);
    let majorqi = ref(null);
    const route = useRoute();
    const router = useRouter();
    let {
      codeType,
      child,
      select,
      activeIndex,
      codeData,
      name,
      activeIndexs,
      propCont
    } = toRefs(data);
    onBeforeMount(() => {
      codenum();
      from.pic_detail_id = route.query.id;
    });
    let codenum = () => {
      //请求数据
      forecastJoin().then(res => {
        if (res == undefined) return false;
        let datas = res.data.data;
        data.codeType = datas;
        nextTick(() => {
          majorSelect('', data.codeType[0], 0); //初始化号码
        });
      });
    };
    let forecastCreates = from => {
      forecastCreate(from).then(res => {
        if (res.status != '400') {
          router.back();
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let clientWidth = 71;
    let tabWidht = ref(null);
    //功能区域
    let majorSelect = (e, item, index) => {
      //点击显示号码
      if (e != '') {
        clientWidth = e.target.clientWidth;
      }
      clientWidth = tabWidht.value[index].clientWidth;
      data.propCont = false;
      majorqi.value.scrollLeft = clientWidth * index + 11 * index;
      data.activeIndex = index;
      data.name = item.name;
      from.body = item.name;
      from.forecast_type_id = item.id;
      data.sub_list = item.sub_list;
      data.minCount = item.minCount;
      data.maxCount = item.maxCount;
      data.selectnum = item.maxCount; //能选择多少个
      let array = [];
      item.show_list.forEach((element, index) => {
        array.push({
          name: element,
          select: ''
        });
      });
      from.content.length = 0;
      data.codeData = array;
    };
    let selectCode = (item, index) => {
      //选择号码

      if (item.select != 1 && from.content.length < data.selectnum) {
        from.content.push(item.name);
        data.codeData[index].select = 1;
      } else {
        data.codeData[index].select = '';
        from.content.forEach((items, i) => {
          if (items == item.name) {
            from.content.splice(i, 1);
          }
        });
      }

      // data.codeData.forEach(items=>{
      //     if(items.select==1){
      //         from.content.push(items.name)
      //     }
      // })
    };
    let selectAddress = (index, name) => {
      //选择位置
      data.activeIndexs = index;
      data.positionName = name;
      from.position = index + 1;
    };
    let clear = () => {
      data.codeData.forEach(item => {
        item.select = '';
      });
      from.content.length = 0;
    };
    let onSubmit = () => {
      if (from.content.length == 0) {
        return false;
      }
      // if(data.sub_list.length!=0){
      if (from.position == 1) {
        if (data.name == '大小' || data.name == '单双' || data.name == '波色') {
          data.positionName = '正一';
        }
      }
      if (data.minCount <= from.content.length && from.content.length <= data.maxCount) {
        let leng = from.content.length - data.minCount;
        if (data.sub_list.length == 0) {
          from.body = from.body + '-' + data.positionName;
        } else {
          from.body = from.body + '-' + data.sub_list[leng].name + ' ' + data.positionName;
        }
        forecastCreates(from);
      } else {
        Dialog(`请选择${data.minCount}～${data.maxCount}号码`, '#07c160');
      }

      // }
    };
    let more = () => {
      data.propCont = true;
    };
    let returnUp = () => {
      data.propCont = false;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "参与竞猜"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", {
        ref_key: "majorqi",
        ref: majorqi
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeType), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(activeIndex) == index ? 'active' : ''),
          key: index,
          ref_for: true,
          ref_key: "tabWidht",
          ref: tabWidht,
          onClick: $event => _unref(majorSelect)($event, item, index)
        }, _toDisplayString(item.name), 11, _hoisted_3);
      }), 128))], 512), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "li",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(more) && _unref(more)(...args))
      }, _cache[3] || (_cache[3] = [_createElementVNode("span", null, "更多", -1), _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]))])]), _unref(name) == '大小' || _unref(name) == '单双' || _unref(name) == '波色' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-row-title"
      }, "位置", -1)), _createElementVNode("ul", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(child), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(activeIndexs) == index ? 'active' : ''),
          key: index,
          onClick: $event => _unref(selectAddress)(index, item.name)
        }, _toDisplayString(item.name), 11, _hoisted_7);
      }), 128))])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_8, [_cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "tk-row-title"
      }, "推荐内容", -1)), _createElementVNode("div", _hoisted_9, [_createElementVNode("ul", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(content), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(item), 1)])]);
      }), 128))]), _createElementVNode("div", {
        class: "del",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(clear) && _unref(clear)(...args))
      }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)]))])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_botton, {
        name: "发布",
        onClick: _unref(onSubmit),
        bgcolor: _unref(from).content.length == 0 ? 'rgba(3, 159, 76, 0.4' : '#07c160'
      }, null, 8, ["onClick", "bgcolor"])]), _createElementVNode("div", _hoisted_13, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "tk-reference-title"
      }, " 参考内容 ", -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(item.select == 1 ? 'active' : ''),
          key: index,
          onClick: $event => _unref(selectCode)(item, index)
        }, [_createElementVNode("div", _hoisted_15, [_createElementVNode("span", null, _toDisplayString(item.name), 1)])], 10, _hoisted_14);
      }), 128))])])]), _unref(propCont) ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[8] || (_cache[8] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)])), _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(name)), 1), _cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "tk-head-info"
      }, null, -1))])], 512), _cache[10] || (_cache[10] = _createElementVNode("div", {
        style: {
          "height": "44px"
        }
      }, null, -1)), _createElementVNode("div", _hoisted_20, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeType), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(activeIndex) == index ? 'active' : ''),
          onClick: $event => _unref(majorSelect)('', item, index),
          key: index
        }, _toDisplayString(item.name), 11, _hoisted_21);
      }), 128))])])])) : _createCommentVNode("", true)], 64);
    };
  }
};