import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../../../assets/image/right-t.png';
const _hoisted_1 = {
  class: "tk-main-love"
};
const _hoisted_2 = {
  class: "tk-love-description"
};
const _hoisted_3 = {
  class: "tk-prompt-c"
};
const _hoisted_4 = {
  class: "tk-turntable-box"
};
const _hoisted_5 = {
  class: "tk-box"
};
const _hoisted_6 = {
  class: "tk-tiems-box"
};
const _hoisted_7 = {
  class: "tk-tiems-calendar"
};
const _hoisted_8 = {
  class: "tk-calendar-header"
};
const _hoisted_9 = ["data-index"];
const _hoisted_10 = {
  class: "tk-calendar-date"
};
const _hoisted_11 = ["onClick"];
import { onBeforeMount } from "vue";
import { open_date } from '@/utils/https/lottery';

import { reactive, toRefs, computed } from 'vue';
export default {
  __name: 'lotteryData',
  setup(__props) {
    let tiems = reactive({
      //开奖日期
      year: '',
      month: '',
      dates: '',
      days: [],
      opendate: [],
      type: localStorage.getItem('lotteryType') //彩票类型
    });
    let {
      year,
      month,
      dates,
      days,
      opendate
    } = toRefs(tiems);
    let datas = () => {
      var date = new Date();
      tiems.year = date.getFullYear(); //获取完整的年份(4位)
      tiems.month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      tiems.dates = date.getDate(); //获取当前日(1-31)
      var d = new Date(tiems.year, tiems.month, 0);
      var days = d.getDate();
      for (let i = 1; i <= days; i++) {
        tiems.days.push({
          num: i,
          select: 0
        });
      }
    };
    let open_dates = lotteryType => {
      open_date({
        lotteryType: lotteryType
      }).then(res => {
        if (res == undefined) return false;
        datas();
        let datass = res.data.data.dayList;
        tiems.days.forEach(element => {
          datass.forEach(item => {
            if (element < 10) {
              element = '0' + element;
            }
            if (item.split('-')[2] == element.num) {
              element.select = 1;
            }
          });
        });
        tiems.opendate.push(...datass);
      });
    };
    let fullName = computed(() => (item, c) => {
      //计算属性传递参数
      let lottery = 0;
      item.forEach(element => {
        if (element.select == 1 && element.num == c) {
          lottery = 1;
        }
      });
      return lottery;
    });
    let handleTiem = item => {
      //选择日期
      tiems.dates = item.num;
      // fullName(tiems.days,item.num)
    };
    onBeforeMount(() => {
      open_dates(tiems.type);
    });
    //功能区域

    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "搅珠日期"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "tk-love-title"
      }, "简介", -1)), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "tk-desc"
      }, "搅珠日期对照表，可查看当月及下一个月的搅珠开奖日期", -1)), _createElementVNode("div", _hoisted_3, "今天: " + _toDisplayString(_unref(year)) + "-" + _toDisplayString(_unref(month)) + "-" + _toDisplayString(_unref(dates)) + " " + _toDisplayString(_unref(fullName)(_unref(days), _unref(dates)) ? '是' : '不是') + "开奖日期", 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_unref(year)) + "-" + _toDisplayString(_unref(month)), 1), _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1))]), _createElementVNode("div", {
        class: "tk-calendar-day",
        "data-index": _unref(fullName)(_unref(days), _unref(dates))
      }, "今天：" + _toDisplayString(_unref(year)) + "-" + _toDisplayString(_unref(month)) + "-" + _toDisplayString(_unref(dates)) + " " + _toDisplayString(_unref(fullName)(_unref(days), _unref(dates)) ? '是' : '不是') + "开奖日期", 9, _hoisted_9), _createElementVNode("div", _hoisted_10, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(days), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          onClick: $event => _unref(handleTiem)(item),
          class: _normalizeClass(item.select ? 'active' : ''),
          style: _normalizeStyle({
            'background': _unref(dates) == item.num ? '#A1B1C8 !important' : ''
          }),
          key: index
        }, _toDisplayString(item.num), 15, _hoisted_11);
      }), 128))])])])])])])])], 64);
    };
  }
};