import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "tk-main-comment"
};
const _hoisted_2 = {
  class: "tk-comment"
};
const _hoisted_3 = {
  class: "tk-comment"
};
const _hoisted_4 = {
  class: "tk-textarea"
};
const _hoisted_5 = {
  class: "tk-comment-botton"
};
import { diagramCreate } from "@/utils/https/gallery";
import Dialog from '@/components/mode/message';
import { onBeforeMount } from "vue";

import { useRoute, useRouter } from 'vue-router';
import { reactive, toRefs } from 'vue';
export default {
  __name: 'comment',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    let from = reactive({
      id: '',
      title: '',
      content: '',
      issue: '',
      lotteryType: localStorage.getItem('lotteryType')
    });
    onBeforeMount(() => {
      from.id = route.query.id;
      from.issue = route.query.issue;
    });
    let {
      content,
      title
    } = toRefs(from);
    let diagramCreates = from => {
      diagramCreate(from).then(res => {
        if (res.status != '400') {
          router.back();
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let submits = () => {
      diagramCreates(from);
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "发布评论"
      }), _createElementVNode("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-title"
      }, "标题", -1)), _createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(title) ? title.value = $event : title = $event),
        type: "text",
        placeholder: "请输入标题"
      }, null, 512), [[_vModelText, _unref(title)]])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(content) ? content.value = $event : content = $event),
        placeholder: "想说点什么...",
        rows: "10"
      }, null, 512), [[_vModelText, _unref(content)]])])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_botton, {
        onClick: _unref(submits),
        bgcolor: _unref(content).length == 0 ? 'rgba(3, 159, 76, 0.4' : '#07c160',
        name: "发布"
      }, null, 8, ["onClick", "bgcolor"])])])], 64);
    };
  }
};