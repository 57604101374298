import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../assets/image/error.png';
import _imports_1 from '../../assets/image/zan.png';
import _imports_2 from '../../assets/image/article.png';
const _hoisted_1 = {
  class: "tk-discover-box"
};
const _hoisted_2 = {
  class: "discover_b"
};
const _hoisted_3 = {
  key: 1,
  class: "tk-image"
};
const _hoisted_4 = {
  class: "tk-img-wrapper j_wrapper",
  ref: "wrapper"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "name"
};
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = {
  class: "num"
};
const _hoisted_9 = {
  class: "tk-exit"
};
import { discoveryList } from '@/utils/https/discover';
import { connect } from 'echarts';
import { onBeforeMount, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { Waterfall } from '@/utils/Waterfall';
import loadings from '@/assets/image/error.png';
import error from '@/assets/image/error.png';
import { useStore } from 'vuex';

import { ref, onDeactivated, onActivated } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const $store = useStore(); //获取vuex
    let data = reactive({
      page: 1,
      lottery: 2,
      pictureData: [],
      is_rec: 2,
      content: '',
      //搜索内容
      type: '1' //类型1是图片2是视频
    });
    let {
      pictureData
    } = toRefs(data);
    let router = useRouter();
    onBeforeMount(() => {
      if (localStorage.getItem('lotteryType') == null) {
        localStorage.setItem('lotteryType', '2');
      } else {
        data.lottery = localStorage.getItem('lotteryType');
      }
    });
    let discovery = (lottery, page, rec, type, content = '') => {
      discoveryList({
        lotteryType: lottery,
        page: page,
        is_rec: rec,
        type: type,
        keyword: content
      }).then(res => {
        if (res.data.status == 40000) {
          finished.value = true;
          return false;
        }
        let datas = res.data.data.data;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          datas.forEach(item => {
            item.comment = [];
            if (item.images.length == 0) {
              item.img_url = '';
            } else {
              item.img_url = item.images[0].img_url;
            }
          });
          data.pictureData.push(...datas);
          $store.commit('upvideoCont', data.pictureData);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      if (data.lottery == 5) {
        finished.value = true;
      } else {
        discovery(data.lottery, data.page, data.is_rec, data.type, data.content);
        data.page++;
      }
    };
    let onSubmit = item => {
      data.page = 1;
      data.pictureData.length = 0;
      data.content = item;
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let principalTans = item => {
      //切换
      finished.value = false;
      data.type = item + 1;
      data.page = 1;
      data.pictureData.length = 0;
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let pictureJump = (item, index) => {
      //跳转详情

      if (data.type == 2) {
        // let array = [];
        // array.push(...data.pictureData)
        // array.forEach((item,indexs)=>{
        //     item.comment = [];
        //     if(index==indexs){
        //         array.splice(0,index)
        //     }
        // })

        localStorage.setItem('videoInfo', JSON.stringify(data.pictureData));
        router.push({
          path: '/discover/video',
          query: {
            page: data.page,
            index: index
          }
        });
      } else {
        router.push({
          path: '/discover/details',
          query: {
            id: item.id
          }
        });
      }
    };
    let majorArticle = () => {
      //跳转发布
      router.push({
        path: '/discover/article'
      });
    };
    let getImg = () => {
      new Waterfall({
        el: 'j_wrapper',
        colmun: 2,
        gap: 10
      });
    };
    onDeactivated(() => {});
    onActivated(() => {
      window.scrollTo(0, 0);
      let discoverys = localStorage.getItem('discovery');
      if (discoverys == 'true') {
        data.page = 1;
        data.lottery = $store.state.lotteryType;
        data.pictureData.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
        localStorage.setItem('discovery', false);
        // discovery(data.lottery,data.page,data.is_rec,data.type,data.content);
      }
    });
    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_headNav = _resolveComponent("headNav");
      const _component_search = _resolveComponent("search");
      const _component_empty = _resolveComponent("empty");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        type: false
      }, {
        tkCont: _withCtx(() => [_createVNode(_component_navTabsVue, {
          nameLeft: "图文资料",
          onPrincipalTans: _unref(principalTans),
          nameRight: "视频资料"
        }, null, 8, ["onPrincipalTans"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_search, {
        returns: false,
        onOnSubmit: _unref(onSubmit)
      }, null, 8, ["onOnSubmit"])]), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(pictureData).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pictureData), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [_createElementVNode("div", {
            class: "card wrapper",
            onClick: $event => _unref(pictureJump)(item, index)
          }, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
            src: item.img_url,
            onLoad: _cache[0] || (_cache[0] = $event => _unref(getImg)())
          }, {
            error: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)])),
            _: 2
          }, 1032, ["src"])), [[_directive_lazy, item.img_url]]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1), _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)), _createElementVNode("div", _hoisted_8, _toDisplayString(item.thumbUpCount), 1)])], 8, _hoisted_5)]);
        }), 128))], 512)]))]),
        _: 1
      }, 8, ["loading", "finished"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "tk-icon",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(majorArticle) && _unref(majorArticle)(...args))
      }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)]))])], 64);
    };
  }
};