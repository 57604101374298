import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, vModelText as _vModelText } from "vue";
import _imports_0 from '../../assets/image/passeyesopn.png';
import _imports_1 from '../../assets/image/passeyes.png';
import _imports_2 from '../../assets/image/close.png';
const _hoisted_1 = {
  class: "from"
};
const _hoisted_2 = {
  class: "icon"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_8 = {
  key: 0,
  id: "captcha",
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_9 = {
  style: {
    "margin": "16px 0"
  }
};
const _hoisted_10 = {
  class: "wrapper"
};
const _hoisted_11 = {
  class: "tk-main-QEcode"
};
const _hoisted_12 = {
  class: "codeEr"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  class: "tk-from-field flex-between"
};
const _hoisted_15 = {
  class: "tk-field-icon"
};
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  class: "tk-field-input"
};
const _hoisted_18 = {
  class: "tk-botton"
};
import { onBeforeMount, reactive, ref } from "vue";
import { getUserInfo } from '@/utils/https/me';
import { register, captcha, loginChat, mobileSend, mobileLogin } from '@/utils/https/register';
import { config } from '@/utils/https/page';
import Dialog from '@/components/mode/message.js';
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';

import { computed, watch } from 'vue';
export default {
  __name: 'register',
  setup(__props) {
    const $store = useStore(); //获取vuex
    const router = useRouter();
    const route = useRoute();
    const show = ref(false); //显示弹窗
    const froms = ref({}); //提交的表表单
    const yzm = ref("发送验证码");
    const status = reactive({
      login_send_sms: false,
      //是否手机注册
      wydun: null,
      //是否是验证状态
      code: '' //行为验证号码
    });
    const codeImage = ref('');
    const tableForm = reactive([{
      type: "text",
      model: "account_name",
      name: "用户名",
      placeholder: "请输入用户名",
      message: "请填写用户名",
      required: true,
      Lefticon: require('@/assets/image/user.png'),
      status: true
    }, {
      type: "text",
      model: "mobile",
      name: "手机号",
      placeholder: "请输入手机号",
      message: "请填写手机号",
      required: true,
      Lefticon: require('@/assets/image/iphone.png'),
      status: false,
      max: 11
    }, {
      type: "text",
      model: "sms_code",
      name: "手机证码",
      placeholder: "请输入手机验证码",
      message: "请填写手机验证码",
      required: true,
      Lefticon: require('@/assets/image/anquan.png'),
      yzm: true,
      status: false
    }, {
      type: "password",
      model: "password",
      name: "密码",
      placeholder: "请输入密码",
      message: "请填写密码",
      required: true,
      Lefticon: require('@/assets/image/password.png'),
      status: true,
      look: true,
      looks: false
    }, {
      type: "password",
      model: "password_confirmation",
      name: "再次密码",
      placeholder: "请再次输入密码",
      message: "请再次填写密码",
      required: true,
      Lefticon: require('@/assets/image/password.png'),
      status: true,
      look: true,
      looks: false
    }, {
      type: "text",
      model: "captcha",
      name: "图型验证码",
      placeholder: "请输入图型验证码",
      message: "请填写图型验证码",
      required: true,
      Lefticon: require('@/assets/image/code.png'),
      image: true,
      status: true
    }, {
      type: "text",
      model: "invite_code",
      name: "邀请码",
      placeholder: "邀请码 选填",
      message: "请输入邀请码",
      required: false,
      Lefticon: require('@/assets/image/code.png'),
      status: true
    }]);
    const handleLook = item => {
      tableForm.forEach(element => {
        if (element == item) {
          if (item.looks) {
            element.looks = false;
            element.type = 'password';
          } else {
            element.looks = true;
            element.type = 'text';
          }
        }
      });
    };
    const configInfo = computed(() => {
      return $store.state.config;
    });
    watch(() => configInfo.value, (val, old) => {
      if (JSON.stringify(val) != '{}') {
        let datas = configInfo.value;
        status.login_send_sms = datas.register_send_sms;
        status.wydun = datas.wydun;
        status.code = datas.wydun_captcha_id;
        if (datas.register_send_sms && status.wydun == 1) {
          wydunStatus(datas.wydun);
        }
        if (datas.register_send_sms) {
          mobleStatus(datas.register_send_sms);
        }
        if (!datas.register_send_sms && status.wydun == 1) {
          tableForm.forEach((item, index) => {
            if (item.model == "captcha") {
              item.status = false;
            }
          });
        }
        if (status.wydun == 1) {
          mobileIniteNeCaptcha(status.code);
          if (!status.login_send_sms) {
            initNECaptchas(datas.wydun_captcha_id);
          }
        }
      }
    });
    onBeforeMount(() => {
      captchas();
      if (JSON.stringify(configInfo.value) != '{}') {
        let datas = configInfo.value;
        status.login_send_sms = datas.register_send_sms;
        status.wydun = datas.wydun;
        status.code = datas.wydun_captcha_id;
        if (datas.register_send_sms && status.wydun == 1) {
          wydunStatus(datas.wydun);
        }
        if (datas.register_send_sms) {
          mobleStatus(datas.register_send_sms);
        }
        if (!datas.register_send_sms && status.wydun == 1) {
          tableForm.forEach((item, index) => {
            if (item.model == "captcha") {
              item.status = false;
            }
          });
        }
        if (status.wydun == 1) {
          mobileIniteNeCaptcha(status.code);
          if (!status.login_send_sms) {
            initNECaptchas(datas.wydun_captcha_id);
          }
        }
      }
      // 传人邀请码
      let invite_code = route.query.invite;
      if (invite_code) {
        froms.value.invite_code = invite_code;
      }
      //传人设备码
      let device = JSON.parse(localStorage.getItem('device'));
      if (device && device.system) {
        froms.value.device = device.system;
        froms.value.device_code = device.device;
      } else {
        froms.value.device = 'h5';
      }
    });
    //是否开启行为验证
    let wydunStatus = wydun => {
      tableForm.forEach((item, index) => {
        if (wydun == 1 && (item.model == "captcha" || item.model == "mobile" || item.model == "sms_code")) {
          item.status = false;
        } else {
          item.status = true;
        }
      });
    };
    //是否开启手机注册
    let mobleStatus = sms => {
      tableForm.forEach((item, index) => {
        if (sms && item.model == "captcha") {
          item.status = false;
        } else {
          item.status = true;
        }
      });
    };
    const handleEndCode = () => {
      //发送验证按钮
      if (yzm.value != "发送验证码") return false;
      var myreg = /^1[3-9]\d{9}$/;
      if (froms.value.mobile == '') {
        Dialog('手机号不能为空');
        return;
      } else if (!myreg.test(froms.value.mobile)) {
        Dialog('手机号格式有误');
        return;
      }
      if (status.wydun == null) {
        Dialog('验证加载中请稍后...');
        return false;
      }
      if (status.wydun == 1) {
        captchaIns.value && captchaIns.value.verify();
      } else {
        show.value = true;
        // mobileSends();
      }
    };
    const handleSubmit = () => {
      if (froms.value.captcha && froms.value.captcha != '') {
        mobileSends();
      } else {
        Dialog('请输入图形验证码');
      }
    };
    // 发送手机验证验证码
    let mobileSends = async () => {
      let res = await mobileSend({
        mobile: froms.value.mobile,
        scene: 'register',
        captcha: froms.value.captcha,
        key: froms.value.key,
        NECaptchaValidate: froms.value.NECaptchaValidate
      });
      if (res.data.status == 20000) {
        show.value = false;
        Dialog(res.data.message);
        djs();
        if (status.wydun == 1) {
          captchaIns.value.refresh();
        }
      } else {
        Dialog(res.data.message);
        captchas();
        if (status.wydun == 1) {
          captchaIns.value.refresh();
        }
      }
    };
    //倒计时
    const djs = () => {
      let clear;
      let num = 60;
      clear = setInterval(() => {
        num -= 1;
        yzm.value = num;
        if (num == 0) {
          yzm.value = "发送验证码";
          clearInterval(clear);
        }
      }, 1000);
    };
    //手机号登陆
    let mobileLogins = async from => {
      let {
        data
      } = await mobileLogin(from);
      if (data.status == 20000) {
        let datas = data.data;
        Dialog(data.message);
        localStorage.setItem('Authorization', `${datas.token_type} ${datas.token}`);
        loginChats();
        getUserInfos();
        router.push({
          path: '/index'
        });
      } else {
        captchas();
        Dialog(data.message);
      }
    };
    const NECaptchaValidates = ref(null);
    const onSubmit = from => {
      //提交表单
      if (status.wydun == 1 && status.login_send_sms) {
        delete froms.value.NECaptchaValidate;
      }
      if (status.login_send_sms) {
        delete froms.value.captcha;
        delete froms.value.key;
      }
      if (status.wydun == null) {
        Dialog('验证加载中请稍后...');
        return false;
      }
      if (Math.floor(localStorage.getItem('verifyNum')) > 3) {
        froms.value.NECaptchaValidates = NECaptchaValidates.value;
      }
      logins(froms.value);
    };
    const regverify = () => {
      if (localStorage.getItem('verifyNum') == null) {
        localStorage.setItem('verifyNum', 1);
      } else {
        let num = Math.floor(localStorage.getItem('verifyNum')) + 1;
        localStorage.setItem('verifyNum', num);
      }
    };
    let logins = datas => {
      //请求登陆接口
      register(datas).then(res => {
        if (res.data.status == 40000) {
          captchas();
          Dialog(res.data.message);
          if (status.wydun == 1) {
            captchaIns.value.refresh();
          }
          return false;
        }
        if (res.status != '400') {
          let data = res.data.data;
          Dialog(res.data.message);
          localStorage.setItem('Authorization', `${data.token_type} ${data.token}`);
          loginChats();
          getUserInfos();
          router.push({
            path: '/index'
          });
          regverify();
        } else {
          captchas();
          Dialog(res.data.message);
        }
      });
    };
    let loginChats = () => {
      //登陆聊天室
      loginChat().then(res => {
        if (res.data.status == 20000) {
          let chat = res.data.data.token;
          localStorage.setItem('chatToken', chat);
        }
      });
    };
    let captchas = () => {
      //请求验证码
      captcha().then(res => {
        let datas = res.data.data;
        froms.value.key = datas.key;
        codeImage.value = datas.img;
      });
    };
    let getUserInfos = () => {
      //获取用户信息
      getUserInfo().then(res => {
        if (res.status != 500) {
          let datas = res.data.data;
          localStorage.setItem('usetInfo', JSON.stringify(datas));
        } else {
          data.userInfo = '';
        }
      });
    };

    //行为验证
    const initNECaptchas = code => {
      initNECaptcha({
        captchaId: code,
        element: '#captcha',
        mode: 'popup',
        width: '18.75rem',
        onVerify: function (err, data) {
          froms.value.NECaptchaValidate = data.validate;
          NECaptchaValidates.value = data.validate;
        }
      }, function onload(instance) {
        console.log(instance);
      }, function onerror(err) {
        console.log(err);
      });
    };
    const captchaIns = ref(null);
    const mobileIniteNeCaptcha = code => {
      // 若使用降级方案接入
      // initNECaptcha 替换成 initNECaptchaWithFallback
      initNECaptcha({
        captchaId: code,
        element: '#captchass',
        mode: 'popup',
        width: '320px',
        apiVersion: 2,
        popupStyles: {
          capBarTextAlign: 'center',
          capBarBorderColor: '#fff',
          radius: 16,
          capBarHeight: 50,
          capPaddingTop: 0,
          capBarTextWeight: 500,
          capBarTextColor: '#333'
        },
        customStyles: {
          imagePanel: {
            borderRadius: 12
          },
          controlBar: {
            textColor: '#1a2233',
            borderColor: '#e6e4e4',
            borderColorMoving: '#ffca42',
            borderColorSuccess: '#61cc80',
            borderColorError: '#ff4242',
            background: '#E6E4E4',
            backgroundMoving: '#fff8e8',
            backgroundSuccess: '#b5e6c3',
            backgroundError: '#f6caca',
            height: 40,
            borderRadius: 20,
            slideIcon: 'https://yidunfe.nos-jd.163yun.com/origin1669028716517.png',
            slideIconMoving: 'https://yidunfe.nos-jd.163yun.com/moving1669028716557.png',
            slideIconSuccess: 'https://yidunfe.nos-jd.163yun.com/success1669028716496.png',
            slideIconError: 'https://yidunfe.nos-jd.163yun.com/error1669028716489.png'
          }
        },
        onVerify: (err, data) => {
          // 当验证失败时, 内部会自动 refresh 方法, 无需手动再调用一次
          if (err) return;
          if (data) {
            froms.value.NECaptchaValidate = data.validate;
            NECaptchaValidates.value = data.validate;
            mobileSends();
          }
          // 以下为业务侧逻辑
        }
      }, function onload(instance) {
        captchaIns.value = instance;
      }, function onerror(err) {
        // 初始化失败后触发该函数, err对象描述当前错误信息
        console.log(err);
      });
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableForm, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: index
            }, [item.status ? (_openBlock(), _createBlock(_component_van_field, {
              key: 0,
              modelValue: froms.value[item.model],
              "onUpdate:modelValue": $event => froms.value[item.model] = $event,
              type: item.type,
              name: item.name,
              label: "",
              maxlength: item.max,
              placeholder: item.placeholder,
              "label-width": "0px",
              rules: [{
                required: item.required,
                message: item.message
              }]
            }, {
              "left-icon": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
                src: item.Lefticon,
                alt: ""
              }, null, 8, _hoisted_3)])]),
              "right-icon": _withCtx(() => [_withDirectives(_createElementVNode("div", {
                class: "icon j-login",
                onClick: handleEndCode
              }, _toDisplayString(yzm.value), 513), [[_vShow, item.yzm]]), item.image ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "image",
                onClick: _cache[0] || (_cache[0] = (...args) => _unref(captchas) && _unref(captchas)(...args))
              }, [_createElementVNode("img", {
                src: codeImage.value,
                alt: ""
              }, null, 8, _hoisted_4)])) : _createCommentVNode("", true), item.look ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "look",
                onClick: $event => handleLook(item, index)
              }, [item.looks ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : (_openBlock(), _createElementBlock("img", _hoisted_7))], 8, _hoisted_5)) : _createCommentVNode("", true)]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "type", "name", "maxlength", "placeholder", "rules"])) : _createCommentVNode("", true)]);
          }), 128))]),
          _: 1
        }), status.wydun == 1 && !status.login_send_sms ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("", true), _cache[5] || (_cache[5] = _createElementVNode("div", {
          id: "captchass",
          style: {
            "margin-top": "20px"
          }
        }, null, -1)), _createElementVNode("div", _hoisted_9, [_createVNode(_component_van_button, {
          color: "#07c160",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" 注册 ")])),
          _: 1
        })])]),
        _: 1
      })]), _createVNode(_component_van_overlay, {
        show: show.value,
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => show.value = false, ["stop"]))
        }, _cache[6] || (_cache[6] = [_createElementVNode("img", {
          src: _imports_2,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_12, [_cache[7] || (_cache[7] = _createElementVNode("div", {
          class: "text"
        }, "验证码", -1)), _createElementVNode("img", {
          onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => _unref(captchas) && _unref(captchas)(...args), ["stop"])),
          src: codeImage.value,
          alt: ""
        }, null, 8, _hoisted_13)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("img", {
          src: require('@/assets/image/anquan.png'),
          alt: ""
        }, null, 8, _hoisted_16)]), _createElementVNode("div", _hoisted_17, [_withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => froms.value.captcha = $event),
          placeholder: "请输入验证码"
        }, null, 512), [[_vModelText, froms.value.captcha]])])]), _cache[8] || (_cache[8] = _createElementVNode("div", {
          class: ""
        }, null, -1)), _createElementVNode("div", _hoisted_18, [_createVNode(_component_botton, {
          name: "确定",
          onClick: handleSubmit
        })])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};