import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, isRef as _isRef, vModelText as _vModelText, withCtx as _withCtx } from "vue";
import _imports_0 from '../../assets/image/passeyes.png';
import _imports_1 from '../../assets/image/passeyesopn.png';
import _imports_2 from '../../assets/image/close.png';
const _hoisted_1 = {
  class: "tk-main-comment"
};
const _hoisted_2 = {
  class: "tk-field-icon"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "tk-field-input"
};
const _hoisted_5 = ["type", "onUpdate:modelValue", "placeholder"];
const _hoisted_6 = ["type", "onUpdate:modelValue", "placeholder"];
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_9 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_10 = ["src"];
const _hoisted_11 = {
  class: "wrapper"
};
const _hoisted_12 = {
  class: "tk-main-QEcode"
};
const _hoisted_13 = {
  class: "codeEr"
};
const _hoisted_14 = ["src"];
const _hoisted_15 = {
  class: "tk-from-field flex-between"
};
const _hoisted_16 = {
  class: "tk-field-icon"
};
const _hoisted_17 = ["src"];
const _hoisted_18 = {
  class: "tk-field-input"
};
const _hoisted_19 = {
  class: "tk-botton"
};
import { mobileSend, captchaTow, captcha, mobileVerify } from '@/utils/https/register';
import { onBeforeMount, reactive, ref, toRefs } from "vue";
import Dialog from '@/components/mode/message';
import { useRouter } from 'vue-router';
export default {
  __name: 'mobile',
  setup(__props) {
    const router = useRouter();
    let yzm = ref("发送验证码");
    let shows = ref(false);
    let codeNum = ref('');
    let data = reactive({
      from: [{
        type: 'text',
        name: 'mobile',
        placeholder: '请输入手机号',
        value: '',
        icon: require('@/assets/image/iphone.png')
      }, {
        type: 'text',
        name: 'sms_code',
        placeholder: '请输入验证码',
        value: '',
        icon: require('@/assets/image/anquan.png'),
        type_c: 'yzm'
      }],
      froms: {
        scene: 'bind'
      },
      codeImg: '',
      userInfo: {}
    });
    let {
      from,
      codeImg
    } = toRefs(data);
    const wydun = ref();
    onBeforeMount(() => {
      captchas();
      data.userInfo = JSON.parse(localStorage.getItem('usetInfo'));
      let datas = JSON.parse(localStorage.getItem('config'));
      wydun.value = datas.wydun;
      if (datas.wydun == 1) {
        mobileIniteNeCaptcha(datas.wydun_captcha_id);
      }
    });
    const djs = () => {
      let clear;
      let num = 60;
      clear = setInterval(() => {
        num -= 1;
        yzm.value = num;
        if (num == 0) {
          yzm.value = "发送验证码";
          clearInterval(clear);
        }
      }, 1000);
    };
    let mobileSends = async () => {
      if (wydun.value == 1) {
        var res = await mobileSend({
          mobile: data.from[0].value,
          scene: 'bind',
          NECaptchaValidate: keyvalidate.value
        });
        if (res.data.status == 20000) {
          shows.value = false;
          Dialog(res.data.message);
          djs();
        } else {
          Dialog(res.data.message);
          captchas();
        }
        captchaIns.value.refresh();
      } else {
        var res = await mobileSend({
          mobile: data.from[0].value,
          scene: 'bind',
          captcha: codeNum.value,
          key: data.froms.key
        });
        if (res.data.status == 20000) {
          shows.value = false;
          Dialog(res.data.message);
          djs();
        } else {
          Dialog(res.data.message);
          captchas();
        }
        captchaIns.value.refresh();
      }
    };
    let onSubmit = async e => {
      e.preventDefault();
      data.from.forEach(item => {
        if (item.name == 'mobile') {
          data.froms.mobile = item.value;
        } else if (item.name == 'sms_code') {
          data.froms.sms_code = item.value;
        }
      });
      if (data.froms.sms_code == '') {
        Dialog('手机验证码不能为空');
      } else {
        let res = await mobileVerify(data.froms);
        if (res.data.status == 20000) {
          Dialog(res.data.message);
          data.userInfo.mobile = data.froms.mobile;
          localStorage.setItem('usetInfo', JSON.stringify(data.userInfo));
          router.back();
        } else {
          Dialog(res.data.message);
        }
      }
    };
    let lookPassword = index => {
      data.lookeyes = !data.lookeyes;
      if (data.lookeyes) {
        data.from[index].type = 'password';
      } else {
        data.from[index].type = 'text';
      }
    };
    let captchas = () => {
      //请求验证码
      captcha().then(res => {
        let datas = res.data.data;
        data.froms.key = datas.key;
        data.codeImg = datas.img;
      });
    };
    const colse = () => {
      shows.value = false;
    };
    const showDjs = () => {
      if (yzm.value != "发送验证码") return false;
      var myreg = /^1[3-9]\d{9}$/;
      if (data.from[0].value == '') {
        Dialog('手机号不能为空');
      } else if (!myreg.test(data.from[0].value)) {
        Dialog('手机号格式不正确');
      } else {
        if (wydun.value == 1) {
          captchaIns.value && captchaIns.value.verify();
        } else {
          shows.value = true;
          captchas();
        }
      }
    };
    let handleSubmit = async () => {
      //请求验证码
      if (codeNum.value == '') {
        Dialog('验证码不能为空');
      } else {
        mobileSends();
      }
    };
    //手机登陆行为验证
    const captchaIns = ref(null);
    const keyvalidate = ref('');
    const mobileIniteNeCaptcha = code => {
      // 若使用降级方案接入
      // initNECaptcha 替换成 initNECaptchaWithFallback
      initNECaptcha({
        captchaId: code,
        element: '#captchass',
        mode: 'popup',
        width: '320px',
        apiVersion: 2,
        onVerify: (err, data) => {
          // 当验证失败时, 内部会自动 refresh 方法, 无需手动再调用一次
          if (err) return;
          // 以下为业务侧逻辑
          if (data) {
            keyvalidate.value = data.validate;
            mobileSends();
          }
        }
      }, function onload(instance) {
        captchaIns.value = instance;
      }, function onerror(err) {
        // 初始化失败后触发该函数, err对象描述当前错误信息
        console.log(err);
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "填写手机号"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = (...args) => _unref(onSubmit) && _unref(onSubmit)(...args))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(from), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tk-from-field flex-between",
          key: index
        }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          src: item.icon,
          alt: ""
        }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [item.name == 'mobile' ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          maxlength: "11",
          type: item.type,
          "onUpdate:modelValue": $event => item.value = $event,
          placeholder: item.placeholder
        }, null, 8, _hoisted_5)), [[_vModelDynamic, item.value]]) : _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          type: item.type,
          "onUpdate:modelValue": $event => item.value = $event,
          placeholder: item.placeholder
        }, null, 8, _hoisted_6)), [[_vModelDynamic, item.value]])]), item.type_c == 'password' ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-field-tips",
          onClick: $event => _unref(lookPassword)(index)
        }, [_unref(data).lookeyes ? (_openBlock(), _createElementBlock("img", _hoisted_8)) : (_openBlock(), _createElementBlock("img", _hoisted_9))], 8, _hoisted_7)) : _createCommentVNode("", true), item.type_c == 'code' ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tk-field-code",
          onClick: _cache[0] || (_cache[0] = (...args) => _ctx.codeImgFun && _ctx.codeImgFun(...args))
        }, [_createElementVNode("img", {
          src: _unref(codeImg),
          alt: ""
        }, null, 8, _hoisted_10)])) : _createCommentVNode("", true), item.type_c == 'yzm' ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          style: {
            "color": "#039F4C"
          },
          onClick: showDjs
        }, _toDisplayString(_unref(yzm)), 1)) : _createCommentVNode("", true)]);
      }), 128)), _cache[4] || (_cache[4] = _createElementVNode("div", {
        id: "captchass"
      }, null, -1)), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "tk-from-field bottom"
      }, [_createElementVNode("button", {
        type: "submit"
      }, "绑定")], -1))], 32)]), _createVNode(_component_van_overlay, {
        show: _unref(shows),
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", {
          class: "colse",
          onClick: _withModifiers(colse, ["stop"])
        }, _cache[6] || (_cache[6] = [_createElementVNode("img", {
          src: _imports_2,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_13, [_cache[7] || (_cache[7] = _createElementVNode("div", {
          class: "text"
        }, "验证码", -1)), _createElementVNode("img", {
          onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => _unref(captchas) && _unref(captchas)(...args), ["stop"])),
          src: _unref(codeImg),
          alt: ""
        }, null, 8, _hoisted_14)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("img", {
          src: require('@/assets/image/anquan.png'),
          alt: ""
        }, null, 8, _hoisted_17)]), _createElementVNode("div", _hoisted_18, [_withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(codeNum) ? codeNum.value = $event : codeNum = $event),
          placeholder: "请输入验证码"
        }, null, 512), [[_vModelText, _unref(codeNum)]])])]), _cache[8] || (_cache[8] = _createElementVNode("div", {
          class: ""
        }, null, -1)), _createElementVNode("div", _hoisted_19, [_createVNode(_component_botton, {
          name: "确定",
          onClick: _unref(handleSubmit)
        }, null, 8, ["onClick"])])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};