import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '../assets/image/upload.png';
import _imports_1 from '../assets/image/add.png';
const _hoisted_1 = {
  class: "upload-oss"
};
const _hoisted_2 = {
  key: 0,
  class: "tk-upload-icon"
};
const _hoisted_3 = {
  key: 1,
  class: "tk-upload-avatar"
};
import { reactive } from 'vue';
export default {
  __name: 'uploadImage',
  props: {
    icon: {
      type: Boolean,
      default: true
    }
  },
  emits: ['uploadFun'],
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let emits = __emit;
    const fileList = reactive([]); //显示图片列表
    const uploadList = reactive([]); //图片上传列表

    const beforeRead = file => {
      if (Array.isArray(file)) {
        file.forEach(item => {
          if (item.type !== 'image/jpeg' || item.type !== 'image/png' || item.type !== 'image/jpg' || item.type !== 'image/gif') {
            return false;
          }
        });
        if (file.type !== 'image/jpeg' || file.type !== 'image/png' || file.type !== 'image/jpg' || file.type !== 'image/gif') {
          return false;
        }
      }
      return true;
    };
    let deleteImg = flie => {
      fileList.forEach((item, i) => {
        if (flie.file.lastModified == item.file.lastModified) {
          fileList.splice(0, i);
          uploadList.splice(0, i);
        }
      });
      emits('uploadFun', uploadList);
    };
    let afterRead = file => {
      let content = file;
      if (props.icon) {
        //上传一张图片并切不展示用于头像上传
        // 判断当前上传几张图，一张以上则为数组结构
        if (Array.isArray(content)) {
          content.forEach(item => {
            fileList.push(item);
            uploadList.push(item);
          });
        } else {
          uploadList.push(content);
          fileList.push(content);
        }
        emits('uploadFun', uploadList);
      } else {
        fileList.length = 0;
        uploadList.push(content);
        emits('uploadFun', uploadList);
      }
    };

    // // 文件大小超过限制时触发
    // function onOversize(file){
    //     console.log("请上传小于10M的图片")
    // }

    // // 上传前置处理
    // function beforeRead (file) {
    //   if(Array.isArray(file)) {
    //     file.forEach(item => {
    //       if (item.type !== 'image/jpeg') {
    //           console.log("请上传 image 格式图片")
    //           return false
    //         }
    //     })
    //     if (file.type !== 'image/jpeg') {
    // 		console.log("请上传 image 格式图片")
    //       return false
    //     }
    //   }
    //   return true
    // }
    // async function onRead(file){
    //   let content = file
    //   let forms = new FormData()
    //   // 判断当前上传几张图，一张以上则为数组结构
    //   if(Array.isArray(content)) {
    //         content.forEach(item => {
    //           forms.append("file",item.file)

    //         })
    //       }else{
    //         forms.append("file", content.file)

    //   }
    //   let res = await uploadImage(forms)
    //   console.log(res)
    // //   if (res) {
    // //     emit("update:fileList", res.data) 
    // //   }
    // }

    return (_ctx, _cache) => {
      const _component_van_uploader = _resolveComponent("van-uploader");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_uploader, {
        "after-read": _unref(afterRead),
        "before-delete": _unref(deleteImg),
        "before-read": beforeRead,
        modelValue: fileList,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => fileList = $event),
        "max-count": __props.icon ? 8 : 1,
        "preview-size": "1.20773rem"
      }, {
        default: _withCtx(() => [__props.icon ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "",
          srcset: ""
        }, null, -1), _createElementVNode("img", {
          class: "add",
          src: _imports_1,
          alt: ""
        }, null, -1)]))) : (_openBlock(), _createElementBlock("div", _hoisted_3, " 更换头像 "))]),
        _: 1
      }, 8, ["after-read", "before-delete", "modelValue", "max-count"])]);
    };
  }
};