import { createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '../../assets/image/close.png';
const _hoisted_1 = {
  class: "tk-main-QEcode"
};
const _hoisted_2 = {
  class: "tk-exit-from"
};
const _hoisted_3 = {
  class: "exit-input"
};
const _hoisted_4 = {
  class: "tk-botton"
};
import { reactive, toRefs } from "vue";

import { ref } from 'vue';
export default {
  __name: 'exitUserName',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    nickname: {
      type: String,
      default: ''
    }
  },
  emits: ['update:show', ''],
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let comment = ref('');
    comment.value = props.nickname;
    let emits = __emit;
    let submit = () => {
      emits('submit', comment.value);
    };
    let colse = () => {
      emits('update:show', false);
    };
    let overlayPop = () => {
      emits('update:show', false);
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: __props.show,
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => _unref(colse) && _unref(colse)(...args), ["stop"]))
        }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)])), _createElementVNode("div", {
          class: "tk-exit-name",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [_cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "tk-exit-title"
        }, "修改昵称", -1)), _createElementVNode("div", _hoisted_2, [_cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "tk-text"
        }, "昵称", -1)), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(comment) ? comment.value = $event : comment = $event),
          maxlength: "16",
          placeholder: "请输入要修改的昵称"
        }, null, 512), [[_vModelText, _unref(comment)]])])])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_botton, {
          name: "修改",
          onClick: _unref(submit)
        }, null, 8, ["onClick"])])])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};