import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../assets/image/major/provice/2031.png';
import _imports_1 from '../../assets/image/major/provice/2017.png';
import _imports_2 from '../../assets/image/major/provice/2018.png';
import _imports_3 from '../../assets/image/major/provice/2032.png';
const _hoisted_1 = {
  class: "tk-main-comment"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-comment-item-head"
};
const _hoisted_5 = {
  class: "tk-head-avater"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = ["src"];
const _hoisted_8 = ["src"];
const _hoisted_9 = ["src"];
const _hoisted_10 = ["src"];
const _hoisted_11 = {
  class: "tk-head-user"
};
const _hoisted_12 = {
  key: 0,
  class: "username"
};
const _hoisted_13 = {
  key: 1,
  class: "username"
};
const _hoisted_14 = {
  key: 2,
  class: "username"
};
const _hoisted_15 = {
  key: 3,
  class: "username"
};
const _hoisted_16 = {
  key: 4,
  class: "username"
};
const _hoisted_17 = {
  class: "tiems"
};
const _hoisted_18 = {
  key: 0,
  class: "tk-lottery"
};
const _hoisted_19 = {
  key: 1,
  class: "tk-lottery"
};
const _hoisted_20 = {
  key: 2,
  class: "tk-lottery"
};
const _hoisted_21 = {
  key: 3,
  class: "tk-lottery"
};
const _hoisted_22 = {
  key: 4,
  class: "tk-lottery"
};
const _hoisted_23 = {
  key: 5,
  class: "tk-lottery"
};
const _hoisted_24 = {
  key: 6,
  class: "tk-lottery"
};
const _hoisted_25 = {
  key: 0,
  class: "tk-comment-title"
};
const _hoisted_26 = {
  key: 1,
  class: "tk-comment-title"
};
const _hoisted_27 = {
  key: 2,
  class: "tk-comment-title"
};
const _hoisted_28 = {
  key: 3,
  class: "tk-comment-title"
};
const _hoisted_29 = {
  key: 4,
  class: "tk-comment-title"
};
import genre from '@/components/page/genre.vue';
import { getFollows } from '@/utils/https/me';

import { reactive, toRefs, ref, onActivated } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'upvote',
  setup(__props) {
    let data = reactive({
      dataNav: [{
        name: '发现',
        id: 1
      }, {
        name: '幽默猜测',
        id: 2
      }, {
        name: '高手论坛',
        id: 3
      }, {
        name: '资料大全',
        id: 4
      }, {
        name: '六合图库',
        id: 5
      }],
      page: 1,
      followsInfo: [],
      type: '1',
      refs: 1
    });
    let {
      dataNav,
      followsInfo,
      type
    } = toRefs(data);
    const router = useRouter();
    let getFollowss = (type, page) => {
      getFollows({
        type: type,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          if (type == 1) {
            datas.forEach(element => {
              element.lotteryType = element.user_discovery.lotteryType;
            });
          }
          if (type == 2) {
            datas.forEach(element => {
              element.lotteryType = element.humorou.lotteryType;
            });
          }
          if (type == 3) {
            datas.forEach(element => {
              element.lotteryType = element.discuss.lotteryType;
            });
          }
          if (type == 5) {
            datas.forEach(element => {
              element.lotteryType = element.pic_detail.lotteryType;
            });
          }
          loading.value = false;
          data.followsInfo.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getFollowss(data.type, data.page);
      data.page++;
    };
    let majorNav = item => {
      data.page = 1;
      data.type = item;
      data.followsInfo = [];
      // getFollowss(data.type,data.page);
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let returnUp = () => {
      sessionStorage.setItem("collect", true);
    };
    let majorDesc = item => {
      sessionStorage.setItem("collect", false);
      if (data.type == 1) {
        if (item.user_discovery.type == 2) {
          router.push({
            path: '/discover/video',
            query: {
              data: item.user_discovery.id,
              page: item.user_discovery.id
            }
          });
        } else {
          router.push({
            path: '/discover/details',
            query: {
              id: item.followable_id
            }
          });
        }
      }
      if (data.type == 2) {
        router.push({
          path: `/guess`,
          query: {
            id: item.humorou.id,
            year: item.humorou.year,
            lottery: item.humorou.lotteryType,
            issue: item.humorou.issue
          }
        });
      }
      if (data.type == 3) {
        router.push({
          path: '/master/details',
          query: {
            id: item.discuss.id
          }
        });
      }
      if (data.type == 4) {
        router.push({
          path: `/materials/list/details`,
          query: {
            id: item.id,
            corpusTypeId: item.corpusTypeId
          }
        });
      }
      if (data.type == 5) {
        router.push({
          path: `/gallery`,
          query: {
            color: item.pic_detail.color,
            year: item.pic_detail.year,
            id: item.pic_detail.pictureTypeId,
            title: item.pic_detail.pictureName,
            issue: item.pic_detail.issue,
            pictureId: item.pic_detail.pictureId,
            lottery: item.pic_detail.lotteryType
          }
        });
      }
    };
    onActivated(() => {
      window.scrollTo(0, 0);
      let collect = sessionStorage.getItem('collect');
      if (collect == 'collect') {
        data.page = 1;
        data.followsInfo.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
      if (collect == 'true') {
        data.refs += 1;
        data.type = 1;
        data.followsInfo.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的点赞",
        onReturnUp: _unref(returnUp)
      }, {
        tkCont: _withCtx(() => [(_openBlock(), _createBlock(genre, {
          dataNav: _unref(dataNav),
          onMajorNav: _unref(majorNav),
          key: _unref(data).refs
        }, null, 8, ["dataNav", "onMajorNav"]))]),
        _: 1
      }, 8, ["onReturnUp"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_unref(followsInfo).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(followsInfo), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(majorDesc)(item)
          }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_unref(type) == 1 ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: item.user_discovery.user.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.user_discovery.user.avatar,
            alt: ""
          }, null, 8, _hoisted_6)) : _createCommentVNode("", true), _unref(type) == 2 ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: item.humorou.imageUrl == '' ? require('@/assets/image/logo.png') : item.humorou.imageUrl,
            alt: ""
          }, null, 8, _hoisted_7)) : _createCommentVNode("", true), _unref(type) == 3 ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            src: item.discuss.user.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.discuss.user.avatar,
            alt: ""
          }, null, 8, _hoisted_8)) : _createCommentVNode("", true), _unref(type) == 4 ? (_openBlock(), _createElementBlock("img", {
            key: 3,
            src: item.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.avatar,
            alt: ""
          }, null, 8, _hoisted_9)) : _createCommentVNode("", true), _unref(type) == 5 ? (_openBlock(), _createElementBlock("img", {
            key: 4,
            src: item.pic_detail.picurl == '' ? require('@/assets/image/logo.png') : item.pic_detail.picurl,
            alt: ""
          }, null, 8, _hoisted_10)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_11, [_unref(type) == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(item.user_discovery.user.nickname), 1)) : _createCommentVNode("", true), _unref(type) == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(item.humorou.title), 1)) : _createCommentVNode("", true), _unref(type) == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(item.discuss.user.nickname), 1)) : _createCommentVNode("", true), _unref(type) == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(item.nickname), 1)) : _createCommentVNode("", true), _unref(type) == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(item.pic_detail.pictureName), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_17, _toDisplayString(item.created_at), 1)]), item.lotteryType == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[1] || (_cache[1] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "澳门", -1)]))) : _createCommentVNode("", true), item.lotteryType == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[2] || (_cache[2] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "新澳门", -1)]))) : _createCommentVNode("", true), item.lotteryType == 6 ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[3] || (_cache[3] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "快乐八", -1)]))) : _createCommentVNode("", true), item.lotteryType == 7 ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[4] || (_cache[4] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "老澳", -1)]))) : _createCommentVNode("", true), item.lotteryType == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[5] || (_cache[5] = [_createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "香港", -1)]))) : _createCommentVNode("", true), item.lotteryType == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[6] || (_cache[6] = [_createElementVNode("img", {
            src: _imports_2,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "台湾", -1)]))) : _createCommentVNode("", true), item.lotteryType == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[7] || (_cache[7] = [_createElementVNode("img", {
            src: _imports_3,
            alt: ""
          }, null, -1), _createElementVNode("span", null, "新加坡", -1)]))) : _createCommentVNode("", true)]), _unref(type) == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(item.user_discovery.title), 1)) : _createCommentVNode("", true), _unref(type) == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(item.humorou.title), 1)) : _createCommentVNode("", true), _unref(type) == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(item.discuss.title), 1)) : _createCommentVNode("", true), _unref(type) == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(item.title), 1)) : _createCommentVNode("", true), _unref(type) == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(item.pic_detail.pictureName), 1)) : _createCommentVNode("", true)], 8, _hoisted_3);
        }), 128))]))]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};