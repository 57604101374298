import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "icon-tk"
};
const _hoisted_2 = {
  key: 0,
  class: "tk-main-statistics"
};
const _hoisted_3 = {
  class: "tk-container-wrap"
};
const _hoisted_4 = {
  class: "tk-wrap-title"
};
const _hoisted_5 = {
  class: "tk-wrap"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "tk-container sx"
};
const _hoisted_8 = {
  class: "tk-warp-sx-title"
};
const _hoisted_9 = {
  class: "tk-warp-cont"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  class: "tk-container bosei"
};
const _hoisted_12 = {
  class: "tk-warp-sx-title"
};
const _hoisted_13 = {
  class: "tk-warp-cont"
};
const _hoisted_14 = ["onClick"];
const _hoisted_15 = {
  key: 0,
  style: {
    "color": "#4487E2 !important"
  }
};
const _hoisted_16 = {
  key: 1,
  style: {
    "color": "#389B67 !important"
  }
};
const _hoisted_17 = {
  key: 2,
  style: {
    "color": "#FF1A00 !important"
  }
};
const _hoisted_18 = {
  class: "tk-container weishu"
};
const _hoisted_19 = {
  class: "tk-warp-sx-title"
};
const _hoisted_20 = {
  class: "tk-warp-cont"
};
const _hoisted_21 = ["onClick"];
const _hoisted_22 = {
  class: "tk-main-QEcode"
};
const _hoisted_23 = {
  class: "tk-exit-name"
};
const _hoisted_24 = {
  class: "tk-exit-title"
};
const _hoisted_25 = {
  class: "tk-exit-from"
};
const _hoisted_26 = {
  class: "tk-botton"
};
import attribute from '@/views/page/statistics/attribute.vue';
import { onBeforeMount } from 'vue';
import { statistics, number_attr, number_attr2 } from "@/utils/https/statistics";
import { useRoute } from 'vue-router';

import { reactive, toRefs, computed } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    let data = reactive({
      codeData: [{
        title: '特码出现期数最多的号码',
        data: []
      }, {
        title: '特码当前遗漏期数最多的号码',
        data: []
      }, {
        title: '正码出现期数最多的号码',
        data: []
      }, {
        title: '正码当前遗漏期数最多的号码',
        data: []
      }],
      sxData: [{
        title: '特码出现期数最多的生肖',
        data: []
      }, {
        title: '特码当前遗漏期数最多的生肖',
        data: []
      }, {
        title: '正码出现期数最多的生肖',
        data: []
      }, {
        title: '正码当前遗漏期数最多的生肖',
        data: []
      }],
      boseiData: [{
        title: '特码出现期数最多的波色',
        data: [{
          count: 39,
          key: 3,
          value: "绿波"
        }, {
          count: 36,
          key: 1,
          value: "红波"
        }, {
          count: 25,
          key: 2,
          value: "蓝波"
        }]
      }, {
        title: '特码当前遗漏期数最多的波色',
        data: []
      }, {
        title: '正码出现期数最多的波色',
        data: []
      }, {
        title: '正码当前遗漏期数最多的波色',
        data: []
      }],
      weishuData: [{
        title: '特码出现期数最多的尾数',
        data: []
      }, {
        title: '特码当前遗漏期数最多的尾数',
        data: []
      }],
      show: true,
      shows: false,
      lotteryType: localStorage.getItem('lotteryType'),
      issue: 100,
      numBer: '',
      title: '',
      values: ''
    });
    let {
      codeData,
      sxData,
      boseiData,
      weishuData,
      show,
      issue,
      shows,
      numBer,
      title,
      values
    } = toRefs(data);

    ///
    let number_attrs = () => {
      number_attr().then(res => {
        if (res.data.status == '40000') {
          return false;
        }
        let datas = res.data.data;
        localStorage.setItem('number_attr', JSON.stringify(datas));
      });
    };
    let number_attrss = () => {
      number_attr2().then(res => {
        if (res.data.status == '40000') {
          return false;
        }
        let datas = res.data.data;
        localStorage.setItem('number_attr2', JSON.stringify(datas));
      });
    };
    let statisticss = (lotteryType, issue) => {
      statistics({
        lotteryType: lotteryType,
        issue: issue
      }).then(res => {
        if (res.data.status == '40000') {
          return false;
        }
        let datas = res.data.data;
        data.sxData[0].data = datas.specialHotAnimalList;
        data.sxData[1].data = datas.specialColdAnimalList;
        data.sxData[3].data = datas.normalColdAnimalList;
        data.sxData[2].data = datas.normalHotAnimalList;
        data.weishuData[0].data = datas.specialHotTailList;
        data.weishuData[1].data = datas.specialColdTailList;
        data.boseiData[0].data = datas.specialColdColorList;
        data.boseiData[1].data = datas.specialColdColorList;
        data.boseiData[2].data = datas.normalHotColorList;
        data.boseiData[3].data = datas.normalColdColorList;
        data.codeData[0].data = datas.specialHotNumberList;
        data.codeData[1].data = datas.specialColdNumberList;
        data.codeData[2].data = datas.normalHotNumberList;
        data.codeData[3].data = datas.normalColdNumberList;
      });
    };
    const route = useRoute();
    onBeforeMount(() => {
      data.lotteryType = route.query.lottery;
      statisticss(data.lotteryType, data.issue);
      number_attrs();
      number_attrss();
    });
    let handleDown = e => {
      statisticss(data.lotteryType, data.issue);
    };
    let styles = computed(() => item => {
      //计算属性传递参数
      return colorarray[cons(item, numarrays)];
    });
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['red', 'blue', 'green'];
    //功能区域
    let handleDesc = (title, count, num) => {
      data.title = title;
      data.numBer = num;
      data.values = count;
      data.shows = !data.shows;
    };
    let principalTans = datas => {
      data.show = !datas;
    };
    let close = () => {
      data.shows = !data.shows;
    };
    return (_ctx, _cache) => {
      const _component_navTabsVue = _resolveComponent("navTabsVue");
      const _component_headNav = _resolveComponent("headNav");
      const _component_majorBack = _resolveComponent("majorBack");
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "资讯统计"
      }, _createSlots({
        tkCont: _withCtx(() => [_createVNode(_component_navTabsVue, {
          nameLeft: "六合统计",
          onPrincipalTans: _unref(principalTans),
          nameRight: "属性参照"
        }, null, 8, ["onPrincipalTans"])]),
        _: 2
      }, [_unref(show) ? {
        name: "iocn",
        fn: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[3] || (_cache[3] = _createElementVNode("span", null, "期号", -1)), _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(issue) ? issue.value = $event : issue = $event),
          onKeyup: _cache[1] || (_cache[1] = _withKeys((...args) => _unref(handleDown) && _unref(handleDown)(...args), ["enter"])),
          type: "text"
        }, null, 544), [[_vModelText, _unref(issue)]])])]),
        key: "0"
      } : undefined]), 1024), _unref(show) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "tk-top-wrap",
          key: index
        }, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (items, indexs) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass('color-wrap ' + _unref(styles)(items.number)),
            key: indexs
          }, [_createElementVNode("div", {
            class: "num",
            onClick: $event => _unref(handleDesc)(item.title, items.count, items.number)
          }, _toDisplayString(items.number), 9, _hoisted_6)], 2);
        }), 128))])]);
      }), 128))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sxData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "tk-warp-sx flex-between",
          key: index
        }, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (items, indexs) => {
          return _openBlock(), _createElementBlock("div", {
            class: "tk-sx-item",
            onClick: $event => _unref(handleDesc)(item.title, items.count, items.name),
            key: indexs
          }, _toDisplayString(items.name), 9, _hoisted_10);
        }), 128))])]);
      }), 128))])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(boseiData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "tk-warp-sx flex-between",
          key: index
        }, [_createElementVNode("div", _hoisted_12, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_13, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (items, indexs) => {
          return _openBlock(), _createElementBlock("div", {
            class: "tk-sx-item",
            key: indexs,
            onClick: $event => _unref(handleDesc)(item.title, items.count, items.value)
          }, [items.value == '蓝波' ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(items.value), 1)) : _createCommentVNode("", true), items.value == '绿波' ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(items.value), 1)) : _createCommentVNode("", true), items.value == '红波' ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(items.value), 1)) : _createCommentVNode("", true)], 8, _hoisted_14);
        }), 128))])]);
      }), 128))])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(weishuData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "tk-warp-sx flex-between",
          key: index
        }, [_createElementVNode("div", _hoisted_19, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_20, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (items, indexs) => {
          return _openBlock(), _createElementBlock("div", {
            class: "tk-sx-item",
            key: indexs,
            onClick: $event => _unref(handleDesc)(item.title, items.count, items.name)
          }, _toDisplayString(items.name + '尾'), 9, _hoisted_21);
        }), 128))])]);
      }), 128))])])])) : (_openBlock(), _createBlock(attribute, {
        key: 1
      })), _createVNode(_component_majorBack), _createVNode(_component_van_overlay, {
        show: _unref(shows),
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => _ctx.overlayPop && _ctx.overlayPop(...args), ["stop"]))
        }, [_createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, _toDisplayString(_unref(title)), 1), _createElementVNode("div", _hoisted_25, " 【" + _toDisplayString(_unref(numBer)) + "】统计的次数：" + _toDisplayString(_unref(values)) + "次 ", 1)]), _createElementVNode("div", _hoisted_26, [_createVNode(_component_botton, {
          name: "关闭",
          onClick: _unref(close)
        }, null, 8, ["onClick"])])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};