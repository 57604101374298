import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
import _imports_0 from '../../assets/image/me/copy.png';
const _hoisted_1 = {
  class: "details"
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "money"
};
const _hoisted_5 = {
  class: "cont"
};
const _hoisted_6 = {
  class: "item"
};
const _hoisted_7 = {
  key: 0,
  class: "name"
};
const _hoisted_8 = {
  key: 1,
  class: "name"
};
const _hoisted_9 = {
  key: 0,
  class: "item"
};
const _hoisted_10 = {
  class: "text"
};
const _hoisted_11 = {
  class: "item"
};
const _hoisted_12 = {
  key: 0,
  class: "text",
  style: {
    "color": "red"
  }
};
const _hoisted_13 = {
  key: 1,
  class: "text"
};
const _hoisted_14 = {
  key: 2,
  class: "text"
};
const _hoisted_15 = {
  class: "item"
};
const _hoisted_16 = {
  class: "text"
};
const _hoisted_17 = {
  class: "item"
};
const _hoisted_18 = {
  class: "text",
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
import { onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import Dialog from '@/components/mode/message';

import { ref } from 'vue';
export default {
  __name: 'recordDesc',
  setup(__props) {
    const route = useRoute();
    const datas = ref({});
    onBeforeMount(() => {
      let data = JSON.parse(route.query.data);
      datas.value = data;
    });
    const copyText = async val => {
      if (navigator.clipboard && navigator.permissions) {
        await navigator.clipboard.writeText(val);
        Dialog("复制成功！", '#07c160');
      } else {
        const textArea = document.createElement("textArea");
        textArea.value = val;
        textArea.style.width = 0;
        textArea.style.position = "fixed";
        textArea.style.left = "-999px";
        textArea.style.top = "10px";
        textArea.setAttribute("readonly", "readonly");
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        Dialog("复制成功！", '#07c160');
      }
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "交易详情"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_unref(datas).symbol) + _toDisplayString(_unref(datas).money), 1), _cache[1] || (_cache[1] = _createElementVNode("p", {
        class: "jinb"
      }, "金币", -1))]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "name"
      }, "交易类型", -1)), _unref(datas).name == '其他' && _unref(datas).sub_name ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(datas).sub_name), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(datas).name), 1))]), _unref(datas).plats ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "name"
      }, "平台", -1)), _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(datas).plats.name), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_11, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "name"
      }, "交易状态", -1)), _unref(datas).status == -1 ? (_openBlock(), _createElementBlock("div", _hoisted_12, "失败")) : _createCommentVNode("", true), _unref(datas).status == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_13, "成功")) : _createCommentVNode("", true), _unref(datas).status == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_14, "未审核")) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_15, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "name"
      }, "交易时间", -1)), _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(datas).created_at), 1)]), _createElementVNode("div", _hoisted_17, [_cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "name"
      }, "订单号码", -1)), _createElementVNode("div", _hoisted_18, [_createElementVNode("span", null, _toDisplayString(_unref(datas).trade_no), 1), _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = $event => copyText(_unref(datas).trade_no)),
        src: _imports_0,
        alt: ""
      })])])])])])], 64);
    };
  }
};