import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue";
import _imports_0 from '../../assets/image/onreturn.png';
import _imports_1 from '../../assets/image/guanzhu.png';
import _imports_2 from '../../assets/image/zanon.png';
import _imports_3 from '../../assets/image/bdz.png';
import _imports_4 from '../../assets/image/bpl.png';
import _imports_5 from '../../assets/image/colleon.png';
import _imports_6 from '../../assets/image/bsc.png';
import _imports_7 from '../../assets/image/bfx.png';
const _hoisted_1 = {
  class: "ti-main-video"
};
const _hoisted_2 = {
  class: "tk-main-head-nav"
};
const _hoisted_3 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_4 = {
  class: "tk-head-return"
};
const _hoisted_5 = {
  class: "tk-nav-icon"
};
const _hoisted_6 = {
  class: "tk-nav-title"
};
const _hoisted_7 = {
  class: "tk-right-userInfo"
};
const _hoisted_8 = {
  class: "user-head"
};
const _hoisted_9 = ["src", "onClick"];
const _hoisted_10 = {
  key: 1,
  class: "user-fosu"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = ["onClick"];
const _hoisted_13 = {
  key: 0,
  src: _imports_2,
  alt: ""
};
const _hoisted_14 = {
  key: 1,
  src: _imports_3,
  alt: ""
};
const _hoisted_15 = ["onClick"];
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  key: 0,
  src: _imports_5,
  alt: ""
};
const _hoisted_18 = {
  key: 1,
  src: _imports_6,
  alt: ""
};
const _hoisted_19 = ["onClick"];
const _hoisted_20 = {
  class: "tk-bottom-content"
};
const _hoisted_21 = {
  key: 0,
  class: "user"
};
const _hoisted_22 = {
  class: "content"
};
const _hoisted_23 = {
  class: "video"
};
const _hoisted_24 = ["src"];
const _hoisted_25 = {
  class: "tk-main-comment"
};
const _hoisted_26 = {
  class: "tk-comment"
};
const _hoisted_27 = {
  class: "tk-textarea"
};
const _hoisted_28 = {
  class: "tk-file"
};
const _hoisted_29 = {
  class: "file-shape"
};
const _hoisted_30 = {
  class: "file-upload"
};
const _hoisted_31 = {
  class: "link"
};
const _hoisted_32 = {
  class: "tk-comment-botton"
};
import { useStore } from 'vuex';
import commentFs from '@/components/page/comment.vue';
import videoComment from '@/components/page/videoComment.vue';
import { discoveryList, discoveryFollow, discoveryCollect, discoveryDetail } from '@/utils/https/discover';
import { setFocus } from '@/utils/https/https';
import { useRouter } from "vue-router";
import uploadImage from '@/components/uploadImage.vue';
import { uploadFile, createComment } from '@/utils/https/https';
import Dialog from '@/components/mode/message';
import { comment, sonComment, commentList } from '@/utils/https/materials';
import { follow } from '@/utils/https/https';

import { reactive, toRefs, ref, onBeforeMount, nextTick } from 'vue';
import { useRoute } from 'vue-router';
export default {
  __name: 'video',
  setup(__props) {
    const $store = useStore(); //获取vuex
    let data = reactive({
      page: 1,
      lottery: 2,
      pictureData: [],
      is_rec: 2,
      content: '',
      //搜索内容
      type: '2',
      //类型1是图片2是视频
      touchable: true,
      https: configAddBase.main_url + '/',
      show: false,
      articleInfo: {},
      ids: '',
      showshare: false,
      //分享
      videoUrl: '',
      //视频地址
      commentCount: '',
      //评论总条数
      http: 'http://45.61.244.147:9000',
      videoNum: 0,
      c: 0
    });
    let {
      http,
      https,
      show,
      articleInfo,
      ids,
      commentCount,
      showshare,
      videoUrl,
      videoNum
    } = toRefs(data);
    let focus = id => {
      //关注
      setFocus({
        id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let handleFocu = item => {
      //关注
      focus(item.id);
      if (item.focus) {
        data.pictureData.forEach(items => {
          if (items.id == item.id) {
            items.focus = false;
          }
        });
      } else {
        data.pictureData.forEach(items => {
          if (items.id == item.id) {
            items.focus = true;
          }
        });
      }
    };
    let onecollect = id => {
      //点赞
      discoveryCollect({
        id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let onefollow = id => {
      //点赞
      discoveryFollow({
        id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let discovery = (lottery, page, rec, type, content = '') => {
      discoveryList({
        lotteryType: lottery,
        page: page,
        is_rec: rec,
        type: type,
        keyword: content
      }).then(res => {
        if (res.data.status == 40000) {
          return false;
        }
        let datas = res.data.data.data;
        if (datas.length == 0) {} else {
          data.pictureData.push(...datas);
          $store.commit('upvideoCont', data.pictureData);
        }
      });
    };
    let infoData = id => {
      discoveryDetail({
        id: id
      }).then(res => {
        //帖子详情
        if (res.status != '400') {
          let datas = res.data.data;
          data.pictureData = [datas];
        }
      });
    };
    let handleInfo = id => {
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    let videoPlayer = ref(null);
    let router = useRouter();
    let route = useRoute();
    onBeforeMount(async () => {
      window.scrollTo(0, 1);
      if (localStorage.getItem('config') == null) {
        data.http = route.query.video_url;
      } else {
        data.http = JSON.parse(localStorage.getItem('config')).video_url;
      }
      if (JSON.parse(localStorage.getItem('videoInfo')) == null) {
        data.pictureData = JSON.parse(route.query.data);
      } else {
        // data.pictureData = JSON.parse(localStorage.getItem('videoInfo'));
        data.pictureData = $store.state.videoList;
        data.videoNum = route.query.index;
        data.c = route.query.index;
      }
      if (route.query.data != undefined) {
        infoData(route.query.data);
      }
      data.page = route.query.page;
      await nextTick();
      setTimeout(() => {
        videoPlayer.value[data.videoNum].play();
        data.videoUrl = data.pictureData[data.videoNum].videos;
      }, 2000);
    });
    let change = e => {
      //滑动屏幕的时候
      videoPlayer.value[e].play();
      videoPlayer.value[data.c].pause();
      data.videoUrl = data.pictureData[e].videos;
      dataComment.oneComment.length = 0;
      data.c = e;
      if (e == data.pictureData.length - 1) {
        data.page++;
        discovery(data.lottery, data.page, data.is_rec, data.type, data.content);
      }
    };
    let returns = () => {
      //返回上一页

      localStorage.removeItem('videoInfo');
      router.back();
    };
    let handleFun = (e, index, item) => {
      if (e == 0) {
        //点赞
        onefollow(item.id);
        if (item.follow) {
          data.pictureData.forEach(items => {
            if (items.id == item.id) {
              items.follow = false;
              items.thumbUpCount -= 1;
            }
          });
          $store.commit('upvideoCont', data.pictureData);
        } else {
          data.pictureData.forEach(items => {
            if (items.id == item.id) {
              items.follow = true;
              items.thumbUpCount += 1;
            }
          });
          $store.commit('upvideoCont', data.pictureData);
        }
      }
      if (e == 1) {
        //评论
        data.show = true;
        data.ids = item.id;
        data.articleInfo = item;
        datas.from.target_id = item.id;
        dataComment.oneComment.length = 0;
        data.commentCount = item.commentCount;
        dataComment.page = 1;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
      if (e == 3) {
        //收藏
        onecollect(item.id);
        if (item.collect) {
          data.pictureData.forEach(items => {
            if (items.id == item.id) {
              items.collect = false;
              items.collectCount -= 1;
            }
          });
          $store.commit('upvideoCont', data.pictureData);
        } else {
          data.pictureData.forEach(items => {
            if (items.id == item.id) {
              items.collect = true;
              items.collectCount += 1;
            }
          });
          $store.commit('upvideoCont', data.pictureData);
        }
      }
      if (e == 2) {
        //收藏
        data.showshare = true;
      }
    };
    let datas = reactive({
      fileList: [],
      content: '',
      image: [],
      typeId: '6',
      showComment: false,
      from: {
        content: '',
        target_id: '',
        images: [],
        type: '6',
        corpusTypeId: ''
      }
    });
    let {
      content,
      showComment
    } = toRefs(datas);
    let hanladClick = () => {
      //评论视频
      datas.showComment = true;
      data.show = false;
      datas.from.type = 6;
    };
    let handleReturn = () => {
      datas.showComment = false;
    };
    let addcomment = from => {
      //添加评论
      createComment(from).then(res => {
        if (res.status != '400') {
          datas.showComment = false;
          from.content = '';
          from.images.length = 0;
          dataComment.page = 1;
          finished.value = true;
          dataComment.oneComment.length = 0;
          data.pictureData.forEach(items => {
            if (items.id == data.ids) {
              items.commentCount += 1;
            }
          });
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let uploadFun = array => {
      //获取图片
      datas.fileList = array;
    };
    let submits = async () => {
      //提交要添加的评论
      if (datas.content.length == 0) return false;
      if (datas.fileList.length != 0) {
        let res = uploadFile(datas.fileList); //图片上传接口 先上传 在提交
        await res.then(datac => {
          if (datac.data.status == 40000) {
            Dialog(datac.data.message, '#07c160');
            return false;
          }
          let datasc = datac.data.data.url;
          datas.from.images = datasc;
        });
      }
      datas.from.content = datas.content;
      addcomment(datas.from);
    };
    let changeFile = e => {};

    //评论页面展示

    let dataComment = reactive({
      type: 6,
      id: 1,
      page: 1,
      corpusTypeId: '',
      oneComment: []
    });
    let {
      oneComment
    } = toRefs(dataComment);
    let oneCommentList = (type, id, page, corpusTypeId) => {
      //获取一级评论

      commentList({
        type: type,
        target_id: id,
        page: page,
        corpusTypeId: corpusTypeId
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.data;
        if (res.data.data.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          datas.forEach(item => {
            item.page = 1; //添加一个分页字段
            item.childcomment = []; //添加一个子评论字段
          });
          dataComment.oneComment.push(...datas);
          // data.pictureData[data.videoNum].comment.push(...datas)   
          // $store.commit('upvideoCont',data.pictureData);
        }
      });
    };
    //下拉刷新
    const loading = ref(false);
    const finished = ref(true);
    const onLoad = () => {
      oneCommentList(dataComment.type, data.ids, dataComment.page, dataComment.corpusTypeId);
      dataComment.page++;
    };
    let oneReply = id => {
      //回复评论
      datas.from.target_id = id;
      datas.from.type = 5;
      data.show = false;
      datas.showComment = true;
    };
    let childrenComments = async (id, page, index, close) => {
      //展示评论
      if (close == 'close') {
        //收起评论，直接清空子评论s
        dataComment.oneComment.forEach((item, i) => {
          if (i == index) {
            item.page = 1;
            item.childcomment = [];
          }
        });
        return false;
      }
      let res = await sonComment({
        commentId: id,
        page: page
      });
      if (res == undefined) return false;
      if (res.data.data.length == 0) {
        Dialog('没有评论了');
      } else {
        let datas = res.data.data.data;
        dataComment.oneComment.forEach((item, i) => {
          if (i == index) {
            item.page++;
            item.childcomment.push(...datas);
          }
        });
      }
    };
    let follows = id => {
      follow({
        commentId: id
      }).then(res => {
        if (res.status != '400') {
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let thumbUpCount = item => {
      follows(item.id);
      dataComment.oneComment.forEach(element => {
        if (element.id == item.id) {
          element.follow ? element.thumbUpCount -= 1 : element.thumbUpCount += 1;
          element.follow = !element.follow;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      const _component_share = _resolveComponent("share");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_swipe, {
        style: {
          "height": "100vh"
        },
        vertical: "",
        autoplay: 0,
        onChange: _unref(change),
        "show-indicators": false,
        loop: false,
        "initial-swipe": _unref(videoNum)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).pictureData, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
              src: _imports_0,
              alt: "",
              srcset: "",
              onClick: _cache[0] || (_cache[0] = (...args) => _unref(returns) && _unref(returns)(...args))
            })])]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1), _cache[7] || (_cache[7] = _createElementVNode("div", {
              class: "tk-nav-right"
            }, null, -1))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("ul", null, [_createElementVNode("li", null, [_createElementVNode("div", _hoisted_8, [item.user_info ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: item.user_info.avatar == '' ? require('@/assets/image/logo.png') : _unref(https) + item.user_info.avatar,
              alt: "",
              onClick: $event => _unref(handleInfo)(item.user_id)
            }, null, 8, _hoisted_9)) : _createCommentVNode("", true), !item.focus ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("img", {
              src: _imports_1,
              alt: "",
              onClick: $event => _unref(handleFocu)(item)
            }, null, 8, _hoisted_11)])) : _createCommentVNode("", true)])]), _createElementVNode("li", null, [_createElementVNode("div", {
              class: "tk-info-item",
              onClick: $event => _unref(handleFun)(0, index, item)
            }, [item.follow ? (_openBlock(), _createElementBlock("img", _hoisted_13)) : (_openBlock(), _createElementBlock("img", _hoisted_14)), _createElementVNode("span", null, _toDisplayString(item.thumbUpCount), 1)], 8, _hoisted_12), _createElementVNode("div", {
              class: "tk-info-item",
              onClick: $event => _unref(handleFun)(1, index, item)
            }, [_cache[8] || (_cache[8] = _createElementVNode("img", {
              src: _imports_4,
              alt: ""
            }, null, -1)), _createElementVNode("span", null, _toDisplayString(item.commentCount), 1)], 8, _hoisted_15), _createElementVNode("div", {
              class: "tk-info-item",
              onClick: $event => _unref(handleFun)(3, index, item)
            }, [item.collect ? (_openBlock(), _createElementBlock("img", _hoisted_17)) : (_openBlock(), _createElementBlock("img", _hoisted_18)), _createElementVNode("span", null, _toDisplayString(item.collectCount), 1)], 8, _hoisted_16), _createElementVNode("div", {
              class: "tk-info-item",
              onClick: $event => _unref(handleFun)(2, index)
            }, _cache[9] || (_cache[9] = [_createElementVNode("img", {
              src: _imports_7,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "分享", -1)]), 8, _hoisted_19)])])]), _createElementVNode("div", _hoisted_20, [item.user_info ? (_openBlock(), _createElementBlock("div", _hoisted_21, "@" + _toDisplayString(item.user_info.nickname), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_22, _toDisplayString(item.content), 1)]), _createElementVNode("div", _hoisted_23, [_createElementVNode("video", {
              ref_for: true,
              ref_key: "videoPlayer",
              ref: videoPlayer,
              playsinline: "false",
              "webkit-playsinline": "false",
              loop: "",
              preload: "metadata",
              src: _unref(http) + item.videos,
              style: {
                "width": "100%",
                "height": "100%"
              }
            }, null, 8, _hoisted_24)])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["onChange", "initial-swipe"])]), _createVNode(_component_van_popup, {
        show: _unref(show),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(show) ? show.value = $event : show = $event),
        round: "",
        position: "bottom",
        style: {
          height: '60%'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: _unref(loading),
          "onUpdate:loading": _cache[1] || (_cache[1] = $event => _isRef(loading) ? loading.value = $event : null),
          finished: _unref(finished),
          "finished-text": " ",
          onLoad: onLoad
        }, {
          default: _withCtx(() => [_createVNode(videoComment, {
            oneComment: _unref(oneComment),
            commentNum: _unref(commentCount),
            onOneReply: _unref(oneReply),
            onChildrenComments: _unref(childrenComments),
            onThumbUpCount: _unref(thumbUpCount)
          }, null, 8, ["oneComment", "commentNum", "onOneReply", "onChildrenComments", "onThumbUpCount"])]),
          _: 1
        }, 8, ["loading", "finished"]), _createVNode(commentFs, {
          dataInfo: _unref(articleInfo),
          onHanladClick: _unref(hanladClick)
        }, null, 8, ["dataInfo", "onHanladClick"])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: _unref(showComment),
        onClick: _cache[5] || (_cache[5] = $event => _isRef(show) ? show.value = false : show = false)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
        }, [_createVNode(_component_headNav, {
          title: "发布评论",
          video: "video",
          onReturnUp: _unref(handleReturn)
        }, null, 8, ["onReturnUp"]), _createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(content) ? content.value = $event : content = $event),
          placeholder: "想说点什么...",
          rows: "10"
        }, null, 512), [[_vModelText, _unref(content)]])]), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, [_createVNode(uploadImage, {
          onUploadFun: _unref(uploadFun)
        }, null, 8, ["onUploadFun"])])]), _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(content).length) + "/2000", 1)])]), _createElementVNode("div", _hoisted_32, [_createVNode(_component_botton, {
          onClick: _unref(submits),
          bgcolor: _unref(content).length == 0 ? 'rgba(3, 159, 76, 0.4' : '#07c160',
          name: "发布"
        }, null, 8, ["onClick", "bgcolor"])])])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_share, {
        shows: _unref(showshare),
        "onUpdate:shows": _cache[6] || (_cache[6] = $event => _isRef(showshare) ? showshare.value = $event : showshare = $event),
        video: _unref(videoUrl)
      }, null, 8, ["shows", "video"])], 64);
    };
  }
};