import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
import _imports_0 from '../../assets/image/return.png';
import _imports_1 from '../../assets/image/bottom.png';
import _imports_2 from '../../assets/image/up.png';
import _imports_3 from '../../assets/image/serachTow.png';
import _imports_4 from '../../assets/image/close.png';
const _hoisted_1 = {
  class: "tk-serach"
};
const _hoisted_2 = {
  key: 0,
  src: _imports_1,
  alt: ""
};
const _hoisted_3 = {
  key: 1,
  src: _imports_2,
  alt: ""
};
const _hoisted_4 = {
  key: 2,
  class: "tk-select"
};
const _hoisted_5 = {
  key: 1,
  class: "tk-icon-serch"
};
const _hoisted_6 = {
  class: "tk-from-serach flex-between"
};
const _hoisted_7 = {
  class: "tk-from-input"
};
const _hoisted_8 = ["placeholder"];
const _hoisted_9 = {
  class: "tk-from-botton"
};
const _hoisted_10 = {
  key: 0,
  class: "station"
};
import { toRefs, watch } from "vue";

import { useRouter } from 'vue-router';
import { reactive, ref, onActivated } from 'vue';
export default {
  __name: 'search',
  props: {
    returns: {
      type: Boolean,
      default: true
    },
    tuku: {
      type: Boolean,
      default: true
    },
    colors: {
      type: String,
      default: '1'
    },
    placeholder: {
      type: String,
      default: '请输入'
    }
  },
  emits: ['onSubmit', 'colors', 'handelChange', 'close'],
  setup(__props, {
    emit: __emit
  }) {
    const route = useRouter();
    let emits = __emit;
    let props = __props;
    watch(() => props.colors, (val, old) => {
      data.activeIndex = val; //父组件传过来的值
    });
    let data = reactive({
      select: false,
      activeIndex: 1
    });
    let searchCont = ref(''); //输入内容
    let onSubmit = () => {
      emits('onSubmit', searchCont.value);
    };
    let handelChange = () => {
      emits('handelChange', searchCont.value);
    };
    let close = () => {
      //清除内容
      searchCont.value = '';
      emits('close', searchCont.value);
    };
    let {
      activeIndex
    } = toRefs(data);
    let selectColor = () => {
      //显示弹窗
      data.select = !data.select;
    };
    let colors = index => {
      data.activeIndex = index;
      emits('colors', index);
    };
    let returnBlack = () => {
      route.back();
    };
    onActivated(() => {
      searchCont.value = '';
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: _normalizeClass(__props.returns && __props.tuku ? 'tk-mian-serach flex-between' : 'tk-mian-serachs flex-between')
      }, [__props.returns && __props.tuku ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-return-up",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnBlack) && _unref(returnBlack)(...args))
      }, _cache[8] || (_cache[8] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]))) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [__props.returns ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-icon",
        onClick: _cache[3] || (_cache[3] = (...args) => _unref(selectColor) && _unref(selectColor)(...args))
      }, [_createElementVNode("span", null, _toDisplayString(_unref(activeIndex) == 1 ? '彩色' : '黑白'), 1), !_unref(data).select ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3)), _unref(data).select ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("ul", null, [_createElementVNode("li", {
        class: _normalizeClass(_unref(activeIndex) == 1 ? 'flex-between active' : 'flex-between'),
        onClick: _cache[1] || (_cache[1] = $event => _unref(colors)(1))
      }, _cache[9] || (_cache[9] = [_createElementVNode("div", null, "彩色", -1), _createElementVNode("div", {
        class: "select"
      }, [_createElementVNode("div")], -1)]), 2), _createElementVNode("li", {
        class: _normalizeClass(_unref(activeIndex) == 2 ? 'flex-between active' : 'flex-between'),
        onClick: _cache[2] || (_cache[2] = $event => _unref(colors)(2))
      }, _cache[10] || (_cache[10] = [_createElementVNode("div", null, "黑白", -1), _createElementVNode("div", null, null, -1)]), 2)])])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[11] || (_cache[11] = [_createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)]))), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(searchCont) ? searchCont.value = $event : searchCont = $event),
        onInput: _cache[5] || (_cache[5] = $event => _unref(handelChange)($event)),
        placeholder: __props.placeholder
      }, null, 40, _hoisted_8), [[_vModelText, _unref(searchCont)]]), _unref(searchCont).length != 0 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "icon",
        onClick: _cache[6] || (_cache[6] = (...args) => _unref(close) && _unref(close)(...args))
      }, _cache[12] || (_cache[12] = [_createElementVNode("img", {
        src: _imports_4,
        alt: ""
      }, null, -1)]))) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "botton",
        onClick: _cache[7] || (_cache[7] = (...args) => _unref(onSubmit) && _unref(onSubmit)(...args))
      }, "搜索")])])])], 2), __props.returns && __props.tuku ? (_openBlock(), _createElementBlock("div", _hoisted_10)) : _createCommentVNode("", true)], 64);
    };
  }
};