import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/searchblack.png';
import _imports_1 from '@/assets/image/errorr.png';
import _imports_2 from '../../../assets/image/major/provice/2031.png';
import _imports_3 from '../../../assets/image/major/provice/2017.png';
import _imports_4 from '../../../assets/image/major/provice/2018.png';
import _imports_5 from '../../../assets/image/major/provice/2032.png';
import _imports_6 from '../../../assets/image/sright.png';
const _hoisted_1 = {
  class: "tk-main-collect"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-img"
};
const _hoisted_5 = {
  class: "tk-title"
};
const _hoisted_6 = {
  class: "info"
};
const _hoisted_7 = {
  key: 0,
  class: "tk-lottery"
};
const _hoisted_8 = {
  key: 1,
  class: "tk-lottery"
};
const _hoisted_9 = {
  key: 2,
  class: "tk-lottery"
};
const _hoisted_10 = {
  key: 3,
  class: "tk-lottery"
};
const _hoisted_11 = {
  key: 4,
  class: "tk-lottery"
};
const _hoisted_12 = {
  key: 5,
  class: "tk-lottery"
};
import genre from "@/components/page/genre.vue";
import { onActivated, onBeforeMount, reactive } from "vue";
import { getCollect } from "@/utils/https/me";

import { useRouter } from 'vue-router';
import { toRefs, ref } from 'vue';
export default {
  __name: 'collect',
  setup(__props) {
    const router = useRouter();
    let data = reactive({
      dataNav: [{
        name: "六合图库",
        id: 1
      }, {
        name: "幽默猜测",
        id: 2
      }, {
        name: "发现视频",
        id: 3
      }],
      provinces: [{
        name: "新澳彩",
        lottery: 2
      }, {
        name: "港彩",
        lottery: 1
      }, {
        name: "快乐八",
        lottery: 6
      }, {
        name: "老澳",
        lottery: 7
      }
      // { name: "台彩", lottery: 3 },
      // { name: "新彩", lottery: 4 },
      ],
      collectData: [],
      type: "1",
      lotteryType: "2",
      page: 1,
      refs: 1
    });
    let {
      dataNav,
      collectData,
      provinces
    } = toRefs(data);
    let search = () => {
      router.push({
        path: "/me/collect/search",
        query: {
          type: data.type,
          lotteryType: data.lotteryType
        }
      });
    };
    onBeforeMount(() => {
      // getCollects(data.type,data.lotteryType,'',data.page);
      sessionStorage.setItem("collect", false);
    });

    //请求接口
    let getCollects = (type, lotteryType, name, page) => {
      getCollect({
        type: type,
        lotteryType: lotteryType,
        keyword: name,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (type == 2) {
          datas.forEach(element => {
            element.pic_detail = element.humorou;
            element.pic_detail.picurl = element.humorou.imageUrl;
            element.pic_detail.pictureName = element.humorou.title;
          });
        }
        if (type == 3) {
          datas.forEach(element => {
            element.pic_detail = element.user_discovery;
            element.pic_detail.picurl = element.user_discovery.images[0].img_url;
            element.pic_detail.pictureName = element.user_discovery.title;
          });
        }
        if (datas == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.collectData.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getCollects(data.type, data.lotteryType, "", data.page);
      data.page++;
    };
    let tabLottery = item => {
      //切换彩种
      data.page = 1;
      data.collectData.length = 0;
      data.lotteryType = item;
      // getCollects(data.type,data.lotteryType,'',data.page);
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let majorNav = item => {
      //切换类型
      data.page = 1;
      data.collectData.length = 0;
      data.type = item;
      // getCollects(data.type,data.lotteryType,'',data.page);
      loading.value = true;
      finished.value = false;
      onLoad();
    };
    let returnUp = () => {
      sessionStorage.setItem("collect", true);
    };
    let majorDesc = item => {
      sessionStorage.setItem("collect", false);
      if (data.type == 1) {
        router.push({
          path: `/gallery`,
          query: {
            color: item.pic_detail.color,
            year: localStorage.getItem("yearone"),
            id: item.pic_detail.pictureTypeId,
            title: item.pic_detail.pictureName,
            lottery: item.pic_detail.lotteryType
          }
        });
      }
      if (data.type == 2) {
        router.push({
          path: `/guess`,
          query: {
            id: item.humorou.id,
            year: item.humorou.year,
            lottery: item.humorou.lotteryType,
            issue: item.humorou.issue
          }
        });
      }
      if (data.type == 3) {
        router.push({
          path: "/discover/video",
          query: {
            data: item.user_discovery.id,
            page: item.id
          }
        });
      }
    };
    onActivated(() => {
      let collect = sessionStorage.getItem("collect");
      if (collect == "collect") {
        data.page = 1;
        data.collectData.length = 0;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
      if (collect == "true") {
        data.refs += 1;
        data.type = 1;
        data.collectData.length = 0;
        data.lotteryType = 6;
        loading.value = true;
        finished.value = false;
        onLoad();
      }
    });
    return (_ctx, _cache) => {
      const _component_lotteyTabs = _resolveComponent("lotteyTabs");
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_image = _resolveComponent("van-image");
      const _component_di = _resolveComponent("di");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的收藏",
        onReturnUp: _unref(returnUp)
      }, {
        tkCont: _withCtx(() => [(_openBlock(), _createBlock(_component_lotteyTabs, {
          province: _unref(provinces),
          onTabLottery: _unref(tabLottery),
          key: _unref(data).refs
        }, null, 8, ["province", "onTabLottery"])), (_openBlock(), _createBlock(genre, {
          dataNav: _unref(dataNav),
          onMajorNav: _unref(majorNav),
          key: _unref(data).refs
        }, null, 8, ["dataNav", "onMajorNav"]))]),
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(search) && _unref(search)(...args))
        }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }, 8, ["onReturnUp"]), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_di, {
          class: "tk-collect-item"
        }, {
          default: _withCtx(() => [_unref(collectData).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
            key: 0
          })) : (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collectData), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              key: index,
              onClick: $event => _unref(majorDesc)(item)
            }, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_image, {
              src: item.pic_detail.picurl
            }, {
              error: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
                src: _imports_1,
                alt: ""
              }, null, -1)])),
              _: 2
            }, 1032, ["src"])]), _createElementVNode("div", _hoisted_5, _toDisplayString(item.pic_detail.pictureName), 1), _createElementVNode("div", _hoisted_6, [item.pic_detail.lotteryType == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [_createElementVNode("img", {
              src: _imports_2,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "澳门", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [_createElementVNode("img", {
              src: _imports_2,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "新澳门", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[6] || (_cache[6] = [_createElementVNode("img", {
              src: _imports_3,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "香港", -1)]))) : _createCommentVNode("", true), item.lotteryType == 7 ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[7] || (_cache[7] = [_createElementVNode("img", {
              src: _imports_2,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "老澳", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[8] || (_cache[8] = [_createElementVNode("img", {
              src: _imports_4,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "台湾", -1)]))) : _createCommentVNode("", true), item.pic_detail.lotteryType == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[9] || (_cache[9] = [_createElementVNode("img", {
              src: _imports_5,
              alt: ""
            }, null, -1), _createElementVNode("span", null, "新加坡", -1)]))) : _createCommentVNode("", true), _cache[10] || (_cache[10] = _createElementVNode("div", {
              class: "tk-desc"
            }, [_createElementVNode("img", {
              src: _imports_6,
              alt: ""
            })], -1))])], 8, _hoisted_3);
          }), 128))]))]),
          _: 1
        })])]),
        _: 1
      }, 8, ["loading", "finished"])], 64);
    };
  }
};