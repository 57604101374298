import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref } from "vue";
import _imports_0 from '../../assets/image/zanon.png';
import _imports_1 from '../../assets/image/zan.png';
import _imports_2 from '../../assets/image/right.png';
import _imports_3 from '../../assets/image/bottom.png';
import _imports_4 from '../../assets/image/up.png';
const _hoisted_1 = {
  class: "tk-main-comment-list"
};
const _hoisted_2 = {
  class: "tk-comment-list"
};
const _hoisted_3 = {
  class: "tk-comment-list-hot"
};
const _hoisted_4 = {
  class: "tk-comment-head"
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "tk-comment-user"
};
const _hoisted_9 = {
  class: "tk-comment-name"
};
const _hoisted_10 = {
  class: "tk-name"
};
const _hoisted_11 = {
  class: "tk-floor"
};
const _hoisted_12 = {
  class: "tk-tiems"
};
const _hoisted_13 = {
  class: "tk-comment"
};
const _hoisted_14 = {
  class: "tk-content"
};
const _hoisted_15 = {
  key: 0,
  class: "tk-comment-img"
};
const _hoisted_16 = ["src", "onClick"];
const _hoisted_17 = {
  class: "tk-comment-info"
};
const _hoisted_18 = ["onClick"];
const _hoisted_19 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_20 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_21 = ["onClick"];
const _hoisted_22 = {
  class: "tk-comment-other"
};
const _hoisted_23 = {
  class: "tk-comment-user-head"
};
const _hoisted_24 = ["src"];
const _hoisted_25 = {
  class: "tk-comment-user"
};
const _hoisted_26 = {
  class: "tk-comment-name"
};
const _hoisted_27 = {
  class: "tk-name"
};
const _hoisted_28 = {
  key: 0,
  src: _imports_2,
  alt: ""
};
const _hoisted_29 = {
  class: "tk-tiems"
};
const _hoisted_30 = {
  class: "tk-comment"
};
const _hoisted_31 = {
  class: "tk-content"
};
const _hoisted_32 = {
  key: 0,
  class: "tk-comment-img"
};
const _hoisted_33 = ["src", "onClick"];
const _hoisted_34 = {
  class: "tk-comment-info"
};
const _hoisted_35 = ["onClick"];
const _hoisted_36 = {
  class: "tk-comment-more"
};
const _hoisted_37 = ["onClick"];
const _hoisted_38 = {
  key: 0
};
const _hoisted_39 = {
  key: 1
};
const _hoisted_40 = ["onClick"];
import Dialog from '@/components/mode/message';
// import {comment,sonComment,commentList} from '@/utils/https/materials';
// import {follow} from '@/utils/https/https'
import { showImagePreview } from 'vant'; 
import { useRouter } from 'vue-router';
import { reactive, toRefs } from 'vue';
//图片预览

export default {
  __name: 'videoComment',
  props: {
    type: {
      //显示哪里的评论
      type: String,
      default: ''
    },
    id: '',
    //文章ID
    corpusTypeId: {
      type: String,
      default: ''
    },
    oneComment: {
      type: Array,
      default: []
    },
    commentNum: {
      //显示哪里的评论
      type: String,
      default: ''
    }
  },
  emits: ['thumbUpCount', 'oneReply', 'childrenComments'],
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    let props = __props;
    let emits = __emit;
    let data = reactive({
      // hotData:[ ],
      // oneComment:[],
      // page:1,
      https: configAddBase.main_url + '/'
    });
    let {
      hotData,
      https
    } = toRefs(data);
    let childrenComments = async (id, page, index, close) => {
      //请求子评论
      emits('childrenComments', id, page, index, close);
    };
    let thumbUpCount = (item, type) => {
      //点赞
      emits('thumbUpCount', item);
    };
    let oneReply = id => {
      //会回复一级评论
      emits('oneReply', id);
    };
    let preview = (img, index) => {
      //预览图片
      let arr = [];
      img.forEach(item => {
        arr.push(item.img_url);
      });
      showImagePreview({
        images: arr,
        startPosition: index
      });
    };
    let userInfo = id => {
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_empty = _resolveComponent("empty");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", null, _toDisplayString(__props.commentNum) + "条评论", 1)]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-heads"
      }, null, -1)), __props.oneComment.length == 0 ? (_openBlock(), _createBlock(_component_empty, {
        key: 0
      })) : (_openBlock(), _createElementBlock("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.oneComment, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", {
          class: "tk-comment-user-head",
          onClick: $event => _unref(userInfo)(item.user.id)
        }, [_createElementVNode("img", {
          src: item.user.avatar == '' ? require('@/assets/image/logo.png') : _unref(https) + item.user.avatar,
          alt: ""
        }, null, 8, _hoisted_7)], 8, _hoisted_6), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("span", _hoisted_10, _toDisplayString(item.user.account_name), 1), _createElementVNode("span", _hoisted_11, _toDisplayString(index + 1) + "楼", 1), _createElementVNode("span", _hoisted_12, _toDisplayString(item.time_str), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(item.content), 1), item.images.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.images, (img, indexs) => {
          return _openBlock(), _createElementBlock("div", {
            class: "tk-img-item",
            key: indexs
          }, [_createElementVNode("img", {
            src: img.img_url,
            alt: "",
            onClick: $event => _unref(preview)(item.images, indexs)
          }, null, 8, _hoisted_16)]);
        }), 128))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", {
          class: "tk-info-zan",
          onClick: $event => _unref(thumbUpCount)(item, '1')
        }, [item.follow ? (_openBlock(), _createElementBlock("img", _hoisted_19)) : (_openBlock(), _createElementBlock("img", _hoisted_20)), _createElementVNode("span", null, _toDisplayString(item.thumbUpCount), 1)], 8, _hoisted_18), _createElementVNode("div", {
          class: "info-reply",
          onClick: $event => _unref(oneReply)(item.id)
        }, "回复", 8, _hoisted_21)]), _createElementVNode("div", _hoisted_22, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childcomment, (items, i) => {
          return _openBlock(), _createElementBlock("li", {
            key: i
          }, [_createElementVNode("div", _hoisted_23, [_createElementVNode("img", {
            src: items.user.avatar == '' ? require('@/assets/image/logo.png') : _unref(https) + items.user.avatar,
            alt: ""
          }, null, 8, _hoisted_24)]), _createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [_createElementVNode("span", _hoisted_27, [_createElementVNode("span", null, _toDisplayString(items.up_user.account_name), 1), items.up_user.account_name != undefined ? (_openBlock(), _createElementBlock("img", _hoisted_28)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(items.user.account_name), 1)]), _createElementVNode("span", _hoisted_29, _toDisplayString(items.time_str), 1)]), _createElementVNode("div", _hoisted_30, [_createElementVNode("div", _hoisted_31, _toDisplayString(items.content), 1), items.images.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_32, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.images, (imgs, indexs) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tk-img-item",
              key: indexs
            }, [_createElementVNode("img", {
              src: imgs.img_url,
              alt: "",
              onClick: $event => _unref(preview)(items.images, indexs)
            }, null, 8, _hoisted_33)]);
          }), 128))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_34, [_createElementVNode("div", {
            class: "info-reply",
            onClick: $event => _unref(oneReply)(items.id)
          }, "回复", 8, _hoisted_35)])])]);
        }), 128))])]), _createElementVNode("div", _hoisted_36, [item.subCommentCount != 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-more other",
          onClick: $event => _unref(childrenComments)(item.id, item.page, index, '')
        }, [item.page != 1 ? (_openBlock(), _createElementBlock("span", _hoisted_38, "—— 展开更多回复")) : (_openBlock(), _createElementBlock("span", _hoisted_39, "—— 展开" + _toDisplayString(item.subCommentCount) + "条回复", 1)), _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_3,
          alt: ""
        }, null, -1))], 8, _hoisted_37)) : _createCommentVNode("", true), item.page != 1 ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tk-retract other",
          onClick: $event => _unref(childrenComments)(item.id, item.page, index, 'close')
        }, _cache[1] || (_cache[1] = [_createElementVNode("span", null, "收起", -1), _createElementVNode("img", {
          src: _imports_4,
          alt: ""
        }, null, -1)]), 8, _hoisted_40)) : _createCommentVNode("", true)])])]);
      }), 128))]))]), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-comment-list-normal"
      }, null, -1))])]), _cache[4] || (_cache[4] = _createElementVNode("div", {
        style: {
          "height": "65px"
        }
      }, null, -1))], 64);
    };
  }
};