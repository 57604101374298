import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../../assets/image/close.png';
const _hoisted_1 = {
  class: "tk-more"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "icon"
};
const _hoisted_4 = ["src"];
export default {
  __name: 'more',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    datalist: {
      type: Array,
      default: [{
        name: '用户主页',
        icon: require('../../assets/image/meuser.png')
      },
      // {name:'历史帖子',icon:require('../../assets/image/history.png')},
      {
        name: '举报',
        icon: require('../../assets/image/warning.png')
      }]
    }
  },
  emits: ['majorMore'],
  setup(__props, {
    emit: __emit
  }) {
    // let data = reactive({
    //     datalist:[
    //         {name:'用户主页',icon:require('../../assets/image/meuser.png')},
    //         {name:'历史帖子',icon:require('../../assets/image/history.png')},
    //         {name:'举报',icon:require('../../assets/image/warning.png')},
    //     ],
    // })
    let emits = __emit;
    let props = __props;
    // let {datalist} = toRefs(data);

    let majorMore = index => {
      emits('majorMore', index);
    };
    let majorClose = () => {
      emits('update:show', false);
    };
    return (_ctx, _cache) => {
      return __props.show ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-main-more",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(majorClose) && _unref(majorClose)(...args))
      }, [_createElementVNode("div", {
        class: "tk-more-botton",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("ul", {
        style: _normalizeStyle({
          'grid-template-columns': `repeat(${__props.datalist.length},1fr)`
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.datalist, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(majorMore)(index)
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
          src: item.icon,
          alt: ""
        }, null, 8, _hoisted_4)]), _createElementVNode("span", null, _toDisplayString(item.name), 1)], 8, _hoisted_2);
      }), 128))], 4)]), _createElementVNode("div", {
        class: "tk-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => _unref(majorClose) && _unref(majorClose)(...args), ["stop"]))
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]))])])) : _createCommentVNode("", true);
    };
  }
};