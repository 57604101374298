import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../assets/image/jiashow.png';
import _imports_1 from '../assets/image/jianshow.png';
const _hoisted_1 = {
  class: "tk-major-botton"
};
const _hoisted_2 = {
  class: "tk-function"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["src"];
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

import { ref, onActivated } from 'vue';
export default {
  __name: 'majorBotton',
  props: {
    listData: {
      type: Array,
      default: [{
        name: '开奖现场',
        icon: require('../assets/image/major/2057.png'),
        path: '/lottery'
      }, {
        name: '高手论坛',
        icon: require('../assets/image/major/2127.png'),
        path: '/master'
      }, {
        name: '工具宝箱',
        icon: require('../assets/image/major/2130.png'),
        path: '/tool'
      }, {
        name: '聊天室',
        icon: require('../assets/image/major/2129.png'),
        path: '1'
      }, {
        name: '资料大全',
        icon: require('../assets/image/major/2131.png'),
        path: '/materials'
      }]
    }
  },
  emits: ['majorTab'],
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let lottery = ref(null);
    const router = useRouter();
    const emits = __emit;
    let data = reactive({
      show: false
    });
    let {
      show
    } = toRefs(data);
    let closed = () => {
      data.show = false;
    };
    let majorTab = item => {
      data.show = false;
      emits('majorTab', item);
      if (item.path != '1') {
        router.push({
          path: item.path,
          query: {
            lottery: lottery.value
          }
        });
      }
    };
    onActivated(() => {
      lottery.value = localStorage.getItem("lotteryType");
      data.show = false;
    });
    return (_ctx, _cache) => {
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(show) ? (_openBlock(), _createBlock(_component_van_overlay, {
        key: 0,
        show: _unref(show),
        "z-index": "999",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => _isRef(show) ? show.value = false : show = false, ["stop"]))
      }, null, 8, ["show"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "tk-major-image",
        onClick: _cache[1] || (_cache[1] = $event => _isRef(show) ? show.value = true : show = true)
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass(_unref(show) ? 'tk-major-closed' : 'tk-major-closed acitves'),
        onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => _unref(closed) && _unref(closed)(...args), ["stop"]))
      }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)]), 2)]), _createElementVNode("ul", {
        style: _normalizeStyle({
          'z-index': _unref(show) ? 1000 : 0
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.listData, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(show) ? '' : 'acitve'),
          key: index,
          onClick: $event => _unref(majorTab)(item)
        }, [_createElementVNode("span", null, _toDisplayString(item.name), 1), _createElementVNode("img", {
          src: item.icon,
          alt: ""
        }, null, 8, _hoisted_4)], 10, _hoisted_3);
      }), 128))], 4)])], 64);
    };
  }
};