import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, isRef as _isRef, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../assets/image/shares.png';
import _imports_1 from '../../assets/image/invite.png';
import _imports_2 from '../../assets/image/me/copy.png';
import _imports_3 from '../../assets/image/holp.png';
import _imports_4 from '../../assets/image/close.png';
const _hoisted_1 = {
  class: "tk-invite-box"
};
const _hoisted_2 = {
  class: "tk-invite-code"
};
const _hoisted_3 = {
  class: "img",
  id: "qrcode"
};
const _hoisted_4 = {
  class: "desc"
};
const _hoisted_5 = {
  class: "tk-QEcode"
};
const _hoisted_6 = {
  class: "tk-QEcode-title"
};
const _hoisted_7 = {
  class: "prompt"
};
import { reactive, toRefs } from "vue";
import useClipboard from "vue-clipboard3";
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import Dialog from '@/components/mode/message';

import { onBeforeMount } from 'vue';
export default {
  __name: 'Invite',
  setup(__props) {
    const {
      toClipboard
    } = useClipboard();
    let data = reactive({
      logoSrc: JSON.parse(localStorage.getItem('config')).logo,
      appSrc: 'http://www.baidu.com',
      code: '1902788',
      shows: false,
      shareShow: false,
      https: process.env.VUE_APP_BASE_API,
      url: JSON.parse(localStorage.getItem('config')).main_domain
    });
    onBeforeMount(() => {
      let domain = window.location.host;
      console.log(domain);
      let code = JSON.parse(localStorage.getItem('usetInfo')).invite_code;
      data.code = code;
      data.appSrc = `http://${domain}/register?invite=${code}`;
    });
    let {
      logoSrc,
      appSrc,
      code,
      shows,
      shareShow,
      https
    } = toRefs(data);
    let copyContent = async url => {
      try {
        await toClipboard(url); //实现复制
        Dialog('复制成功，赶快去给好友分享吧！', '#07c160');
        console.log("Success");
      } catch (e) {
        console.error(e);
      }
    };
    let promptColse = () => {
      data.shows = false;
    };
    let rule = () => {
      data.shows = true;
    };
    let search = () => {
      data.shareShow = true;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_overlay = _resolveComponent("van-overlay");
      const _component_share = _resolveComponent("share");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "邀请好友"
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(search) && _unref(search)(...args))
        }, _cache[7] || (_cache[7] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_cache[10] || (_cache[10] = _createElementVNode("div", {
        class: "tk-bg-invite"
      }, [_createElementVNode("div", {
        class: "title"
      }, "邀请好友 领取金币"), _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(vueQr, {
        margin: 0,
        logoScale: 0.3,
        correctLevel: 3,
        "auto-color": true,
        "dot-scale": 1,
        text: _unref(appSrc),
        colorDark: "#000"
      }, null, 8, ["text"])]), _cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "desc"
      }, "扫码下载APP，填写邀请码注册哟！", -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(data).url), 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, "我的邀请码：" + _toDisplayString(_unref(code)), 1), _createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = $event => _unref(copyContent)(_unref(appSrc))),
        src: _imports_2,
        alt: ""
      })])])]), _createElementVNode("div", {
        class: "tk-other",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(rule) && _unref(rule)(...args))
      }, _cache[9] || (_cache[9] = [_createElementVNode("div", {
        class: "text"
      }, "邀请规则", -1), _createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)]))]), _createVNode(_component_van_overlay, {
        show: _unref(shows),
        "z-index": "999",
        onClick: _cache[5] || (_cache[5] = $event => _isRef(shows) ? shows.value = false : shows = false)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrappers",
          onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
          class: "close",
          onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => _unref(promptColse) && _unref(promptColse)(...args), ["stop"]))
        }, _cache[11] || (_cache[11] = [_createElementVNode("img", {
          src: _imports_4,
          alt: ""
        }, null, -1)])), _cache[12] || (_cache[12] = _createElementVNode("div", {
          class: "title"
        }, "邀请规则", -1)), _cache[13] || (_cache[13] = _createElementVNode("div", {
          class: "content"
        }, [_createTextVNode(" 1.分享好友注册填写邀请码，邀请人获得一次性5.0元拉新奖励(好友注册起24小时内填写有效), 被邀请人可获得2.0元新人奖励"), _createElementVNode("br"), _createElementVNode("br"), _createTextVNode(" 2 分享好友注册必须在24小时内填写邀请码，邀请人才可获得拉新奖励，过期无效哦～"), _createElementVNode("br"), _createElementVNode("br"), _createTextVNode(" 邀请码填写步骤：我的-活动中心-新手任务-填写邀请码 ")], -1))])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_share, {
        shows: _unref(shareShow),
        "onUpdate:shows": _cache[6] || (_cache[6] = $event => _isRef(shareShow) ? shareShow.value = $event : shareShow = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};