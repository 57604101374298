import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/date.png';
import _imports_1 from '../../../assets/image/searchblack.png';
import _imports_2 from '../../../assets/image/zan.png';
import _imports_3 from '../../../assets/image/comment.png';
const _hoisted_1 = {
  class: "icon-tk"
};
const _hoisted_2 = {
  class: "tk-main-materals-list"
};
const _hoisted_3 = {
  class: "tk-materals-list"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tk-list-title"
};
const _hoisted_6 = {
  class: "tk-title-picture"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "tk-title-info"
};
const _hoisted_9 = {
  class: "tk-name"
};
const _hoisted_10 = {
  class: "tk-tiems"
};
const _hoisted_11 = {
  class: "tk-list-content"
};
const _hoisted_12 = {
  class: "tk-list-commten-info"
};
const _hoisted_13 = {
  class: "tk-like"
};
const _hoisted_14 = {
  class: "num"
};
const _hoisted_15 = {
  class: "tk-commten"
};
const _hoisted_16 = {
  class: "num"
};
import picker from '@/components/picker.vue';
import { listArticle } from '@/utils/https/materials';

import { useRouter, useRoute } from 'vue-router';
import { reactive, toRefs, onBeforeMount, ref, onDeactivated, onActivated } from 'vue';
export default {
  __name: 'list',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    let data = reactive({
      columns: [{
        text: '2025',
        value: '2025'
      }, {
        text: '2024',
        value: '2024'
      }, {
        text: '2023',
        value: '2023'
      }, {
        text: '2022',
        value: '2022'
      }, {
        text: '2021',
        value: '2021'
      }, {
        text: '2020',
        value: '2020'
      }],
      show: false,
      lotteryType: 1,
      //彩票类型
      title: '',
      //文章标题
      id: '',
      //文章ID
      page: 1,
      //分页
      articleData: [],
      //帖子数据，
      year: '',
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {
        logo: ''
      } : JSON.parse(localStorage.getItem('config')),
      ids: ''
    });
    let {
      title,
      columns,
      show,
      articleData,
      websiteName,
      https
    } = toRefs(data);
    onBeforeMount(() => {
      // data.title = route.query.title;
      data.id = route.query.id;
    });

    //分页请求
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const onLoad = () => {
      article(data.id, data.year, data.page);
      data.page++;
    };
    const onRefresh = () => {
      // 清空列表数据
      data.page = 1;
      data.articleData.length = 0;
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    //页面请求区域
    let article = (id, year, page) => {
      //请求列表

      // let articleData = JSON.parse(sessionStorage.getItem(`profile_${data.lotteryType}_id${id}_year_${year}_p${page}`));

      // if(articleData == null){
      listArticle({
        corpusTypeId: id,
        year: year,
        page: page
      }).then(res => {
        let datas = res.data.data.list;
        refreshing.value = false;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          // sessionStorage.setItem(`profile_${data.lotteryType}_id${id}_year_${year}_p${page}`,JSON.stringify(datas))
          data.articleData.push(...datas);
        }
      });

      // }else{
      //     loading.value = false;
      //     data.articleData.push(...articleData);   
      //     refreshing.value = false;

      // }
    };

    //页面功能区域
    let onConfirm = item => {
      //获取prompt选择的值
      data.articleData.length = 0;
      data.year = item;
      article(data.id, item, data.page);
    };
    let showYear = () => {
      //
      data.show = !data.show;
    };

    //页面跳转区域
    let majorDesc = id => {
      router.push({
        path: `/materials/list/details`,
        query: {
          id: id,
          corpusTypeId: data.id,
          type: data.lotteryType
        }
      });
    };
    let majorSearch = () => {
      router.push({
        path: `/materials/list/search`,
        query: {
          id: data.id,
          title: data.title,
          type: data.lotteryType
        }
      });
    };
    let start = ref(true);
    onDeactivated(() => {
      start.value = false;
    });
    onActivated(() => {
      data.lotteryType = route.query.type;
      let id = route.query.id;
      if (start.value || id != data.id) {
        data.id = route.query.id;
        data.title = route.query.title;
        data.page = 1;
        data.articleData.length = 0;
        finished.value = false;
        loading.value = true;
        onLoad();
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_majorBack = _resolveComponent("majorBack");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(title)
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
          src: _imports_0,
          alt: "",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(showYear) && _unref(showYear)(...args))
        }), _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          onClick: _cache[1] || (_cache[1] = (...args) => _unref(majorSearch) && _unref(majorSearch)(...args))
        })])]),
        _: 1
      }, 8, ["title"]), _createVNode(_component_van_pull_refresh, {
        modelValue: _unref(refreshing),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(refreshing) ? refreshing.value = $event : null),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: _unref(loading),
          "onUpdate:loading": _cache[2] || (_cache[2] = $event => _isRef(loading) ? loading.value = $event : null),
          finished: _unref(finished),
          "finished-text": "",
          onLoad: onLoad
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_cache[7] || (_cache[7] = _createElementVNode("div", {
            class: "tk-swiper"
          }, null, -1)), _createElementVNode("div", _hoisted_3, [_unref(articleData).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
            key: 0
          })) : _createCommentVNode("", true), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(articleData), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              onClick: $event => _unref(majorDesc)(item.id),
              key: index
            }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
              src: _ctx.$httpsUrl + _unref(websiteName).logo,
              alt: ""
            }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_unref(title) + '管理员'), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(item.created_at), 1)])]), _createElementVNode("div", _hoisted_11, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_cache[5] || (_cache[5] = _createElementVNode("img", {
              src: _imports_2,
              alt: ""
            }, null, -1)), _createElementVNode("span", _hoisted_14, _toDisplayString(item.thumbUpCount), 1)]), _createElementVNode("div", _hoisted_15, [_cache[6] || (_cache[6] = _createElementVNode("img", {
              src: _imports_3,
              alt: ""
            }, null, -1)), _createElementVNode("span", _hoisted_16, _toDisplayString(item.commentCount), 1)])])], 8, _hoisted_4);
          }), 128))])])])]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(picker, {
        show: _unref(show),
        "onUpdate:show": _cache[4] || (_cache[4] = $event => _isRef(show) ? show.value = $event : show = $event),
        onOnConfirm: _unref(onConfirm)
      }, null, 8, ["show", "onOnConfirm"]), _createVNode(_component_majorBack)], 64);
    };
  }
};