import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/image/logo.jpg';
const _hoisted_1 = {
  key: 0,
  class: "tk-red-bag"
};
const _hoisted_2 = {
  class: "head"
};
const _hoisted_3 = {
  class: "box"
};
const _hoisted_4 = {
  class: "money"
};
const _hoisted_5 = {
  class: "num"
};
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  __name: 'record',
  props: {
    moeny: 0
  },
  setup(__props, {
    expose: __expose
  }) {
    const router = useRouter();
    const recordShow = ref(false);
    const handleShow = () => {
      recordShow.value = false;
    };
    const props = __props;
    onBeforeMount(() => {});
    const handelGoInfo = () => {
      router.push({
        path: "/me"
      });
    };
    __expose({
      recordShow
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return recordShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_icon, {
        onClick: handleShow,
        name: "arrow-left"
      })]), _createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "title"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }), _createElementVNode("span", null, "平台发出的红包")], -1)), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "f"
      }, "恭喜发财 大吉大利", -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString(__props.moeny), 1), _cache[0] || (_cache[0] = _createElementVNode("span", {
        class: "tips"
      }, "金币", -1))]), _createElementVNode("div", {
        class: "tips_s",
        onClick: handelGoInfo
      }, [_cache[1] || (_cache[1] = _createTextVNode(" 已存入账户 ")), _createVNode(_component_van_icon, {
        name: "arrow"
      })])])])) : _createCommentVNode("", true);
    };
  }
};