import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "tk-container-attribute"
};
const _hoisted_2 = {
  class: "tk-major-nav"
};
const _hoisted_3 = {
  ref: "majornav"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tk-link"
};
import bosei from '@/views/page/statistics/bosei.vue';
import sx from '@/views/page/statistics/sx.vue';
import wuxin from '@/views/page/statistics/wuxin.vue';
import jiaqin from '@/views/page/statistics/jiaqin.vue';

import { reactive, toRefs } from 'vue';
export default {
  __name: 'attribute',
  setup(__props) {
    let data = reactive({
      dataNav: [{
        name: '波色'
      }, {
        name: '生肖'
      }, {
        name: '五行'
      }, {
        name: '家禽野兽'
      }],
      left: 0,
      indexActive: 0
    });
    let {
      dataNav,
      left,
      indexActive
    } = toRefs(data);
    let majoNav = index => {
      data.left = 25 * index;
      data.indexActive = index;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataNav), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(majoNav)(index)
        }, _toDisplayString(item.name), 9, _hoisted_4);
      }), 128))], 512), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: "link",
        style: _normalizeStyle({
          'left': _unref(left) + '%'
        })
      }, null, 4)])]), _unref(indexActive) == 0 ? (_openBlock(), _createBlock(bosei, {
        key: 0
      })) : _createCommentVNode("", true), _unref(indexActive) == 1 ? (_openBlock(), _createBlock(sx, {
        key: 1
      })) : _createCommentVNode("", true), _unref(indexActive) == 2 ? (_openBlock(), _createBlock(wuxin, {
        key: 2
      })) : _createCommentVNode("", true), _unref(indexActive) == 3 ? (_openBlock(), _createBlock(jiaqin, {
        key: 3
      })) : _createCommentVNode("", true)]);
    };
  }
};