import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, isRef as _isRef, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx } from "vue";
import _imports_0 from '../../assets/image/me/info/code.png';
import _imports_1 from '../../assets/image/me/info/comment.png';
import _imports_2 from '../../assets/image/me/info/sz.png';
import _imports_3 from '../../assets/image/me/info/exit.png';
import _imports_4 from '../../assets/image/me/info/dj.png';
import _imports_5 from '../../assets/image/sright.png';
import _imports_6 from '../../assets/image/me/info/cz.png';
import _imports_7 from '../../assets/image/me/info/tx.png';
import _imports_8 from '../../assets/image/me/jl.png';
import _imports_9 from '../../assets/image/me/huodong.png';
const _hoisted_1 = {
  class: "tk-main-me"
};
const _hoisted_2 = {
  class: "tk-me-head"
};
const _hoisted_3 = {
  key: 0,
  class: "tk-me-NotLogin"
};
const _hoisted_4 = {
  class: "tk-logo"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "tk-login-title"
};
const _hoisted_7 = {
  class: "tk-me-login"
};
const _hoisted_8 = {
  key: 1,
  class: "tk-me-login"
};
const _hoisted_9 = {
  class: "tk-me-login-head flex-between"
};
const _hoisted_10 = {
  class: "tk-head-info"
};
const _hoisted_11 = {
  key: 0,
  class: "tips"
};
const _hoisted_12 = {
  class: "tk-login-info flex-between"
};
const _hoisted_13 = {
  class: "tk-info"
};
const _hoisted_14 = ["src"];
const _hoisted_15 = {
  class: "tk-data"
};
const _hoisted_16 = {
  class: "tk-data-name"
};
const _hoisted_17 = {
  class: "tk-grade flex-between"
};
const _hoisted_18 = {
  class: "tk-login-money flex-between"
};
const _hoisted_19 = {
  class: "tk-money"
};
const _hoisted_20 = {
  class: "tk-money-num"
};
const _hoisted_21 = {
  class: "tk-money-icon"
};
const _hoisted_22 = {
  class: "tk-me-member-box"
};
const _hoisted_23 = {
  class: "bootom"
};
const _hoisted_24 = {
  class: "tk-menber-title"
};
const _hoisted_25 = ["onClick"];
const _hoisted_26 = {
  class: "icon"
};
const _hoisted_27 = ["src"];
const _hoisted_28 = {
  class: "name"
};
const _hoisted_29 = {
  class: "prompt"
};
const _hoisted_30 = ["onClick"];
const _hoisted_31 = {
  class: "lottery-title"
};
const _hoisted_32 = ["src"];
const _hoisted_33 = {
  class: "loottery-tiems"
};
const _hoisted_34 = {
  class: "i"
};
const _hoisted_35 = {
  class: "tk-botton"
};
import { onBeforeMount, reactive, toRefs } from 'vue';
import addGameUser from '@/components/me/addGameUser.vue';
import QRcode from '@/components/me/QRcode.vue';
import Dialog from '@/components/mode/message';
import { useStore } from 'vuex';
import { getUserInfo } from '@/utils/https/me';
import { nextLottery, getMessageBadge } from '@/utils/https/https';
import { roomList } from '@/utils/https/wechar';
import { getCurrentInstance } from 'vue';

import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    let data = reactive({
      box: [{
        title: '必备工具',
        data: [{
          name: '活动中心',
          image: require('@/assets/image/me/hdzxs.png'),
          path: '/me/activity'
        }, {
          name: '邀请好友',
          image: require('@/assets/image/me/yqhys.png'),
          path: '/me/invite'
        }, {
          name: '聊天室',
          image: require('@/assets/image/me/ltss.png'),
          path: 'wechat'
        }, {
          name: '用户等级',
          image: require('@/assets/image/me/yhdjs.png'),
          path: '/me/grade'
        }]
      }, {
        title: '我的频道',
        data: [{
          name: '我的推广',
          image: require('@/assets/image/me/tgs.png'),
          path: 'c'
        }, {
          name: '我的收藏',
          image: require('@/assets/image/me/scs.png'),
          path: '/me/collect'
        }, {
          name: '我的评论',
          image: require('@/assets/image/me/pls.png'),
          path: '/me/comment'
        }, {
          name: '我的关注',
          image: require('@/assets/image/me/gzs.png'),
          path: '/me/follow'
        }, {
          name: '我的粉丝',
          image: require('@/assets/image/me/fss.png'),
          path: '/me/fans'
        }, {
          name: '我的点赞',
          image: require('@/assets/image/me/dzs.png'),
          path: '/me/upvote'
        }, {
          name: '我的小黑屋',
          image: require('@/assets/image/me/xhws.png'),
          path: '/me/room'
        }, {
          name: '我的黑名单',
          image: require('@/assets/image/me/hmds.png'),
          path: '/me/blacklist'
        }]
      }, {
        title: '排行榜',
        data: [{
          name: '粉丝排行榜',
          image: require('@/assets/image/me/fsphbs.png'),
          path: '/me/ranking/fans'
        }, {
          name: '等级排行榜',
          image: require('@/assets/image/me/djphbs.png'),
          path: '/me/ranking/grade'
        }, {
          name: '分享排行榜',
          image: require('@/assets/image/me/fxphbs.png'),
          path: '/me/ranking/share'
        }, {
          name: '金币排行榜',
          image: require('@/assets/image/me/lwphbs.png'),
          path: '/me/ranking/gift'
        }]
      }, {
        title: '平台信息',
        data: [{
          name: '联系客服',
          image: require('@/assets/image/me/lxkfs.png'),
          path: '/me/kf'
        }, {
          name: '站内消息',
          image: require('@/assets/image/me/zlxx.png'),
          path: '/notice'
        }, {
          name: '关于我们',
          image: require('@/assets/image/me/gywm.png'),
          path: '/me/setup/about'
        }, {
          name: '意见反馈',
          image: require('@/assets/image/me/yjfk.png'),
          path: '/me/setup/feedback/idea'
        }, {
          name: '清除缓存',
          image: require('@/assets/image/me/qlhcs.png'),
          path: 'b'
        }, {
          name: '下载App',
          image: require('@/assets/image/me/app.png'),
          path: 'dow'
        }, {
          name: '举报记录',
          image: require('@/assets/image/me/jbzls.png'),
          path: '/me/report'
        }, {
          name: '平台设置',
          image: require('@/assets/image/me/ptszs.png'),
          path: 'sz'
        }]
      }],
      lotteryType: [{
        icon: require('../../assets/image/major/provice/2017.png'),
        name: '港彩',
        id: '1',
        times: '2023年06月07日  21点30分'
      }, {
        icon: require('../../assets/image/major/provice/2031.png'),
        name: '新澳彩',
        id: '2',
        times: '2023年06月07日  21点30分'
      },
      // {icon:require('../../assets/image/major/provice/2018.png'),name:'台彩',id:'3',times:'2023年06月07日  21点30分'},
      {
        icon: require('../../assets/image/major/provice/2032.png'),
        name: '新彩',
        id: '4',
        times: '2023年06月07日  21点30分'
      },
      // {icon:require('../../assets/image/major/provice/2031.png'),name:'天澳彩',id:'5',times:'2023年06月07日  21点30分'},
      {
        icon: require('../../assets/image/major/provice/2031.png'),
        name: '快乐八',
        id: '6',
        times: '2023年06月07日  21点30分'
      }],
      codeShow: false,
      gameShow: false,
      userInfo: '',
      //用户数据
      shows: false,
      lotteryTypes: localStorage.getItem('lotteryType'),
      roomShow: false,
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {
        name: '图库中心',
        login: require('../../assets/image/logo.png')
      } : JSON.parse(localStorage.getItem('config')),
      gameTitle: '',
      msg: 0,
      list: []
    });
    let {
      box,
      codeShow,
      gameShow,
      userInfo,
      shows,
      lotteryType,
      lotteryTypes,
      roomShow,
      websiteName,
      gameTitle
    } = toRefs(data);
    const router = useRouter();
    const store = useStore(); //获取vuex
    onMounted(() => {});
    onMounted(() => {
      //
      proxy.$sayHello();
      data.msg = store.state.msg;
      window.scrollTo(0, 0);
      if (localStorage.getItem('usetInfo') == null || localStorage.getItem('usetInfo') == undefined) {
        // getUserInfos()
      } else {
        data.userInfo = JSON.parse(localStorage.getItem('usetInfo'));
      }
      if (localStorage.getItem('Authorization') != null) {
        getUserInfos();
      }
      nextLotterys();
      if (localStorage.getItem('Authorization') != null) {
        //获取消息 
        getMessageBadge().then(res => {
          if (res.status == 500) {
            return false;
          }
          if (res.data.status != 40000) {
            let msg = res.data.data.msg;
            store.commit('msgadd', msg);
          }
        });
      }
    });
    // 接口请求 
    let list = reactive([{
      name: '香港六合聊天室',
      id: '1',
      selectIcon: require('@/assets/image/major/provice/2028.png')
    }, {
      name: '新澳门六合聊天室',
      id: '2',
      selectIcon: require('@/assets/image/major/provice/2015.png')
    }, {
      name: '台湾六合聊天室',
      id: '3',
      selectIcon: require('@/assets/image/major/provice/2029.png')
    }, {
      name: '新加坡六合聊天室',
      id: '4',
      selectIcon: require('@/assets/image/major/provice/2019.png')
    }, {
      name: '澳门六合聊天室',
      id: '5',
      selectIcon: require('@/assets/image/major/provice/2015.png')
    }]);
    let roomLists = () => {
      roomList().then(res => {
        list.length = 0;
        let datas = res.data.data;
        data.list = datas;
        if (localStorage.getItem('chatToken') != null) {
          data.list.push({
            name: '旧版六合聊天室',
            id: '9'
          });
        }
        if (datas.length == 1) {
          router.push({
            path: '/wechat',
            query: {
              title: datas[0].name,
              id: datas[0].id
            }
          });
        } else if (datas.length == 0) {
          Dialog('暂无聊天列表');
        } else {
          data.roomShow = true;
        }
        datas.forEach((item, index) => {
          switch (item.id) {
            case 1:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2028.png')
              });
              break;
            case 2:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            case 3:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2029.png')
              });
              break;
            case 4:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2019.png')
              });
              break;
            case 5:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            default:
              break;
          }
        });
        localStorage.setItem('room_info', JSON.stringify(list));
      });
    };
    let getUserInfos = () => {
      getUserInfo().then(res => {
        if (res.data.status == 20000) {
          let datas = res.data.data;
          data.userInfo = datas;
          localStorage.setItem('usetInfo', JSON.stringify(datas));
        } else {
          data.userInfo = '';
        }
      });
    };
    let nextLotterys = () => {
      nextLottery().then(res => {
        if (res.status != 500) {
          let datas = res.data.data;
          data.lotteryType.forEach((item, index) => {
            item.times = `${datas[index].lotteryTime}   ${datas[index].time}`;
            // data.lotteryType[index].times = `${item.lotteryTime}   ${item.time}`
          });
          localStorage.setItem('nextLottery', JSON.stringify(datas));
        }
      });
    };

    //功能区域
    let selectLottery = id => {
      data.lotteryTypes = id;
      localStorage.setItem('lotteryTypes', id);
      localStorage.setItem('lotteryType', id);
    };
    let prompt = () => {
      //平台设置
      data.shows = false;
    };
    let QRcodeFun = () => {
      //显示二维码
      data.codeShow = true;
    };
    let czmoney = () => {
      if (data.userInfo.platforms_count == 0) {
        data.gameTitle = '您还没有平台游戏账号，请前往添加';
        data.gameShow = true;
      } else {
        router.push({
          path: '/me/purse/topup'
        });
      }
    };
    let recordsb = () => {
      router.push({
        path: '/me/bonus/record'
      });
    };
    let txmoney = () => {
      if (data.userInfo.isset_fund_password != 0 && data.userInfo.platforms_count != 0) {
        router.push({
          path: '/me/purse/withdraw'
        });
      } else {
        if (data.userInfo.platforms_count == 0) {
          data.gameTitle = '您还没有平台游戏账号，请前往添加';
        } else {
          data.gameTitle = '您还没有添加平台资金密码，请前往添加';
        }
        data.gameShow = true;
      }
    };
    let setup = () => {
      //跳转设置页面
      router.push({
        path: '/me/setup'
      });
    };
    let login = () => {
      //跳转登陆页面
      router.push({
        path: '/login'
      });
    };
    let homepage = () => {
      router.push({
        path: '/userInfo',
        query: {
          id: data.userInfo.id
        }
      });
    };
    const {
      proxy
    } = getCurrentInstance();
    let navGeTo = path => {
      if (path == 'sz') {
        data.shows = true;
      } else if (path == 'c') {
        Dialog('开发中...');
      } else if (path == 'dow') {
        window.location.href = data.websiteName.download_url;
      } else if (path == 'b') {
        Dialog('清除缓存成功,请刷新页面');
        // localStorage.clear();
        localStorage.removeItem('yearone');
        localStorage.removeItem('yearColor');
        localStorage.removeItem('config');
        localStorage.removeItem('lotteryNumber');
        localStorage.removeItem('notice');
        localStorage.removeItem('swiperImg');
        localStorage.removeItem('number_attr');
        localStorage.removeItem('room_info');
        localStorage.removeItem('materList');
        localStorage.removeItem('nextLottery');
        localStorage.removeItem('ping');
        localStorage.removeItem('configUrlBase');
        sessionStorage.clear();
        localStorage.removeItem('avList');
        // location.reload();
        proxy.$sayHello();
      } else if (path == 'wechat') {
        if (localStorage.getItem('room_info') != null) {
          list = JSON.parse(localStorage.getItem('room_info'));
          data.list = JSON.parse(localStorage.getItem('room_info'));
          if (localStorage.getItem('chatToken') != null) {
            data.list.push({
              name: '旧版六合聊天室',
              id: '9'
            });
          }
          if (data.list.length == 1) {
            router.push({
              path: '/wechat',
              query: {
                title: data.list[0].name,
                id: data.list[0].id
              }
            });
          } else if (data.list.length == 0) {
            Dialog('暂无聊天列表');
          } else {
            roomShow.value = true;
          }
        } else {
          roomLists();
        }
      } else {
        router.push({
          path: path
        });
      }
    };
    let selecRoom = item => {
      //选择聊天室
      router.push({
        path: '/wechat',
        query: {
          title: item.name,
          id: item.id
        }
      });
    };
    let handleMessg = () => {
      router.push({
        path: '/notice'
      });
    };
    let handlebonus = () => {
      router.push({
        path: '/me/bonus'
      });
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_overlay = _resolveComponent("van-overlay");
      const _component_wecharList = _resolveComponent("wecharList");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_unref(userInfo) == '' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        src: _ctx.$httpsUrl + _unref(websiteName).logo,
        alt: ""
      }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", null, "欢迎来到" + _toDisplayString(_unref(websiteName).name), 1), _cache[13] || (_cache[13] = _createElementVNode("span", null, "请登录或者注册后查看", -1))]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_botton, {
        name: "登陆/注册",
        onClick: _unref(login)
      }, null, 8, ["onClick"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "tk-tow-code",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(QRcodeFun) && _unref(QRcodeFun)(...args))
      }, _cache[14] || (_cache[14] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "邀请码", -1)])), _createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
        class: "comment",
        src: _imports_1,
        alt: "",
        onClick: _cache[1] || (_cache[1] = $event => _unref(handleMessg)())
      }), _createElementVNode("img", {
        class: "sz",
        src: _imports_2,
        alt: "",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(setup) && _unref(setup)(...args))
      }), _unref(data).msg > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(store).state.msg), 1)) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("img", {
        src: _unref(userInfo).avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + _unref(userInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_14), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("span", null, _toDisplayString(_unref(userInfo).nickname), 1), _createElementVNode("img", {
        src: _imports_3,
        alt: "",
        onClick: _cache[3] || (_cache[3] = (...args) => _unref(setup) && _unref(setup)(...args))
      })]), _createElementVNode("div", _hoisted_17, [_cache[15] || (_cache[15] = _createElementVNode("img", {
        src: _imports_4,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, "V" + _toDisplayString(_unref(userInfo).level_grade), 1)])])]), _createElementVNode("div", {
        class: "tk-more",
        onClick: _cache[4] || (_cache[4] = (...args) => _unref(homepage) && _unref(homepage)(...args))
      }, _cache[16] || (_cache[16] = [_createElementVNode("span", null, "我的主页", -1), _createElementVNode("img", {
        src: _imports_5,
        alt: ""
      }, null, -1)]))]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_cache[17] || (_cache[17] = _createElementVNode("div", {
        class: "tk-money-name"
      }, "金币", -1)), _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(userInfo).account_balance), 1)]), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", {
        class: "tk-money-item",
        onClick: _cache[5] || (_cache[5] = (...args) => _unref(czmoney) && _unref(czmoney)(...args))
      }, _cache[18] || (_cache[18] = [_createElementVNode("img", {
        src: _imports_6,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "充值", -1)])), _createElementVNode("div", {
        class: "tk-money-item",
        onClick: _cache[6] || (_cache[6] = (...args) => _unref(txmoney) && _unref(txmoney)(...args))
      }, _cache[19] || (_cache[19] = [_createElementVNode("img", {
        src: _imports_7,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "提现", -1)])), _createElementVNode("div", {
        class: "tk-money-item",
        onClick: _cache[7] || (_cache[7] = (...args) => _unref(recordsb) && _unref(recordsb)(...args))
      }, _cache[20] || (_cache[20] = [_createElementVNode("img", {
        src: _imports_8,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "记录", -1)]))])])]))]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", {
        class: "huodong",
        onClick: _cache[8] || (_cache[8] = (...args) => _unref(handlebonus) && _unref(handlebonus)(...args))
      }, [_cache[22] || (_cache[22] = _createElementVNode("div", {
        class: "images"
      }, [_createElementVNode("img", {
        src: _imports_9,
        alt: ""
      })], -1)), _cache[23] || (_cache[23] = _createElementVNode("div", {
        class: "title"
      }, " 福利中心 尽享彩金 ", -1)), _createElementVNode("div", _hoisted_23, [_cache[21] || (_cache[21] = _createTextVNode(" 领取福利 ")), _createVNode(_component_van_icon, {
        name: "arrow"
      })])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(box), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tk-me-box",
          key: index
        }, [_createElementVNode("div", _hoisted_24, _toDisplayString(item.title), 1), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (items, indexs) => {
          return _openBlock(), _createElementBlock("li", {
            key: indexs,
            onClick: $event => _unref(navGeTo)(items.path)
          }, [_createElementVNode("div", _hoisted_26, [_createElementVNode("img", {
            src: items.image,
            alt: ""
          }, null, 8, _hoisted_27)]), _createElementVNode("div", _hoisted_28, _toDisplayString(items.name), 1)], 8, _hoisted_25);
        }), 128))])]);
      }), 128))])]), _createVNode(QRcode, {
        show: _unref(codeShow),
        "onUpdate:show": _cache[9] || (_cache[9] = $event => _isRef(codeShow) ? codeShow.value = $event : codeShow = $event)
      }, null, 8, ["show"]), _createVNode(addGameUser, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[10] || (_cache[10] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        title: _unref(gameTitle)
      }, null, 8, ["show", "title"]), _createVNode(_component_van_overlay, {
        show: _unref(shows),
        "z-index": "999"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrappers",
          onClick: _cache[11] || (_cache[11] = _withModifiers((...args) => _unref(prompt) && _unref(prompt)(...args), ["stop"]))
        }, [_createElementVNode("div", _hoisted_29, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lotteryType), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            class: _normalizeClass(item.id == _unref(lotteryTypes) ? 'select' : ''),
            key: index,
            onClick: $event => _unref(selectLottery)(item.id)
          }, [_createElementVNode("div", _hoisted_31, [_createElementVNode("img", {
            src: item.icon,
            alt: ""
          }, null, 8, _hoisted_32), _createElementVNode("p", null, _toDisplayString(item.name), 1)]), _createElementVNode("div", _hoisted_33, [_cache[24] || (_cache[24] = _createElementVNode("span", null, "下期开奖时间：", -1)), _createElementVNode("span", _hoisted_34, _toDisplayString(item.times), 1)])], 10, _hoisted_30);
        }), 128))]), _createElementVNode("div", _hoisted_35, [_createVNode(_component_botton, {
          name: "确认",
          onClick: _withModifiers(_unref(prompt), ["stop"])
        }, null, 8, ["onClick"])])])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_wecharList, {
        show: _unref(roomShow),
        "onUpdate:show": _cache[12] || (_cache[12] = $event => _isRef(roomShow) ? roomShow.value = $event : roomShow = $event),
        onSelecRoom: _unref(selecRoom),
        list: _unref(data).list
      }, null, 8, ["show", "onSelecRoom", "list"])], 64);
    };
  }
};