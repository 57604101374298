import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
import _imports_0 from '../assets/image/lahe.png';
import _imports_1 from '../assets/image/guanzhu.png';
const _hoisted_1 = {
  class: "tk-botton"
};
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
export default {
  __name: 'botton',
  props: {
    name: {
      default: '关闭',
      type: String
    },
    color: {
      default: '#fff',
      type: String
    },
    bgcolor: {
      default: '#07c160',
      type: String
    },
    guanzhu: {
      default: false,
      type: Boolean
    },
    lahe: {
      default: false,
      type: Boolean
    }
  },
  emits: ['submits'],
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let emits = __emit;
    let TkBottom = () => {
      emits('submits');
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("button", {
        style: _normalizeStyle({
          'color': __props.color,
          'background': __props.bgcolor
        }),
        class: "botton",
        type: "button",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(TkBottom) && _unref(TkBottom)(...args))
      }, [__props.lahe ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true), _cache[1] || (_cache[1] = _createTextVNode()), __props.guanzhu ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), _createTextVNode(_toDisplayString(__props.name), 1)], 4)]);
    };
  }
};