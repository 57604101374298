import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withKeys as _withKeys, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createBlock as _createBlock } from "vue";
import _imports_0 from '../../assets/image/wechatplay.png';
import _imports_1 from '../../assets/redBag/red.png';
import _imports_2 from '../../assets/redBag/redbagon.png';
import _imports_3 from '../../assets/redBag/redbag.png';
import _imports_4 from '../../assets/image/emoje.png';
import _imports_5 from '../../assets/image/other.png';
import _imports_6 from '../../assets/image/fs.png';
import _imports_7 from '../../assets/image/canvas.png';
import _imports_8 from '../../assets/image/images.png';
import _imports_9 from '../../assets/image/bet.svg';
import _imports_10 from '../../assets/image/game.png';
import _imports_11 from '../../assets/image/icon/dowapp.png';
const _hoisted_1 = {
  style: {
    "position": "relative"
  }
};
const _hoisted_2 = {
  style: {
    "min-height": "100%"
  }
};
const _hoisted_3 = {
  key: 0,
  style: {
    "display": "flex",
    "align-items": "center",
    "justify-content": "center",
    "margin-bottom": "10px"
  }
};
const _hoisted_4 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "justify-content": "center",
    "padding": "4px 8px",
    "border-radius": "5px",
    "background": "#F3F7FD"
  }
};
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = {
  key: 1,
  class: "item"
};
const _hoisted_7 = {
  class: "tk-info-head"
};
const _hoisted_8 = ["onTouchstart", "onTouchend"];
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "tk-user"
};
const _hoisted_11 = {
  class: "tk-name"
};
const _hoisted_12 = {
  class: "tk-tiems"
};
const _hoisted_13 = {
  key: 0,
  class: "tk-wechat-conten ta image",
  style: {
    "display": "block"
  }
};
const _hoisted_14 = ["src", "onClick"];
const _hoisted_15 = ["innerHTML"];
const _hoisted_16 = {
  key: 3,
  class: "tk-wechat-conten"
};
const _hoisted_17 = {
  class: "tk-lottery-box"
};
const _hoisted_18 = {
  key: 0
};
const _hoisted_19 = {
  key: 1,
  class: "span"
};
const _hoisted_20 = {
  class: "sx"
};
const _hoisted_21 = {
  key: 4,
  class: "tk-wechat-conten redbag"
};
const _hoisted_22 = {
  key: 0
};
const _hoisted_23 = ["onClick"];
const _hoisted_24 = {
  key: 2,
  class: "item",
  style: {
    "align-items": "flex-end !important"
  }
};
const _hoisted_25 = {
  class: "tk-info-head me"
};
const _hoisted_26 = {
  class: "tk-user"
};
const _hoisted_27 = {
  class: "tk-name"
};
const _hoisted_28 = {
  class: "tk-tiems"
};
const _hoisted_29 = {
  class: "tk-picture"
};
const _hoisted_30 = ["src"];
const _hoisted_31 = {
  key: 0,
  class: "tk-wechat-conten mes image",
  style: {
    "display": "block"
  }
};
const _hoisted_32 = ["src", "onClick"];
const _hoisted_33 = ["innerHTML"];
const _hoisted_34 = {
  action: "",
  class: "flex-between"
};
const _hoisted_35 = {
  class: "label-input"
};
const _hoisted_36 = {
  key: 1,
  class: "speaking"
};
const _hoisted_37 = {
  key: 2,
  class: "speaking"
};
const _hoisted_38 = {
  key: 3,
  class: "speaking"
};
const _hoisted_39 = {
  key: 0,
  class: "label-botton flex-between"
};
const _hoisted_40 = {
  class: "mores"
};
const _hoisted_41 = {
  key: 0,
  class: "emoji"
};
const _hoisted_42 = ["onClick"];
const _hoisted_43 = {
  class: "jinbi"
};
const _hoisted_44 = {
  class: "tk-home"
};
const _hoisted_45 = ["onClick"];
const _hoisted_46 = {
  key: 0
};
const _hoisted_47 = {
  key: 0,
  class: "icon"
};
const _hoisted_48 = ["src"];
const _hoisted_49 = {
  key: 1,
  class: "icon goods"
};
const _hoisted_50 = {
  class: "name"
};
import lotteryCode from "./code.vue";
import redbag from "../redbag/charBag.vue";
import record from "../redbag/record.vue";
import popu from "../redbag/popu.vue";
import lotteryReal from '@/components/me/lotteryReal.vue';
import notive from '@/components/page/notive.vue';
import wecharList from '@/components/me/wecharList.vue';
import { roomList, packetReceive } from '@/utils/https/wechar';
import { showConfirmDialog } from 'vant';
import { showImagePreview } from 'vant'; //图片预览
import { getCurrentInstance } from 'vue';
import { computed, onBeforeMount, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { roomRecord, roomJoin, roomChat } from '@/utils/https/wechar';
import Dialog from '@/components/mode/message';
import { compressImage } from '@/utils/compressImage';
import { uploadFile } from '@/utils/https/https';
import { iMgetLaunchURLHTML } from "@/utils/https/page";

import { toRefs, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    let preview = img => {
      //预览图片
      showImagePreview([img]);
    };
    const handleImagClcikbet = () => {
      window.location.href = JSON.parse(localStorage.getItem('config')).bet_url;
    };
    const {
      proxy
    } = getCurrentInstance();
    const httpsComputed = computed(() => item => {
      if (item) {
        var reg = /(http|https):\/\/\S*/;
        if (item.match(reg)) {
          return item;
        } else {
          return proxy.$httpsUrl + item;
        }
      }
    });
    const gameTop = ref('20%');
    const openLotter = ref(null);
    const chid = ref(null);
    const chids = ref(null);
    const popop = ref(null);
    const redBagInfo = ref(null);
    const redBagMoney = ref(0);
    const listInfothis = ref(null);
    const showPopover = ref(false);
    const showBagss = computed(() => () => {
      if (popu.value != null) {
        return popu.value.redBag.length;
      }
    });
    let tkGame = ref([{
      icon: require('../../assets/image/game/game5.png'),
      name: '金币竞猜',
      path: '/guess/index',
      id: 5,
      status: true
    }]);
    const listNums = ref([]);
    const changNum = e => {
      listNums.value = e;
    };
    //点击聊天室的红包
    const handleRedBag = (item, data) => {
      chid.value.redShow = false;
      chid.value.show = true;
      redBagInfo.value = {
        id: item.redId
      };
      listInfothis.value = redBagInfo.value;
    };
    // 改变红包样式 
    // const 
    //点击打开红包
    const handleOpenRed = () => {
      chid.value.redShow = true;
      chid.value.show = false;
      packetReceives(redBagInfo.value.id);
    };
    // 点击查看剩余的红包
    const handleImagbagClcik = () => {
      popop.value.show = true;
    };
    // 领取红包
    const handleSubmit = item => {
      chid.value.redShow = false;
      chid.value.show = true;
      redBagInfo.value = item;
      popop.value.show = false;
    };
    // 改变数组里面的内容
    const listInfofun = () => {
      wecharRecord.value.forEach(item => {
        if (listInfothis.value == null) return false;
        if (item.data.message.redId == listInfothis.value.id) {
          item.data.message.is_receive = true;
          listInfothis.value = null;
        }
      });
    };
    //领取红包接口
    const packetReceives = async id => {
      let {
        data
      } = await packetReceive({
        id
      });
      if (data.status == 20000) {
        chids.value.recordShow = true;
        popop.value.show = false;
        chid.value.redShow = false;
        chid.value.show = false;
        redBagMoney.value = data.data.money;
        popop.value.redPackes();
        listInfofun();
        //from.message = "已领取了红包";
        //from.style = "red_receive_ok";
        //roomChats(from);
      } else {
        listInfofun();
        Dialog(data.message, '#07c160');
      }
    };
    const msgRed = computed(() => data => {
      if (popop.value != null) {
        let redBag = popop.value.redBag;
        redBag.forEach(element => {
          data.forEach(item => {
            if (item.data.message.redId == element.id) {
              item.data.message.status = element.status;
            }
          });
        });
        return data;
      }
    });
    let data = reactive({
      more: false,
      top: '108px',
      bottom: '0px',
      gameShow: false,
      roomId: '1',
      //房间id
      page: '1',
      wecharRecord: [],
      nowecharRecord: [],
      meId: JSON.parse(localStorage.getItem('usetInfo')).id,
      scrollHeight: 0,
      content: '',
      xl: false,
      emojiShow: false,
      unreadShow: false,
      //是否显示未读消息
      unreadSocrll: false,
      //是否在底部不在底部就开始计数
      unreadNum: 0,
      atString: 0,
      //@的字符串有多长方便删除
      title: '六合聊天室',
      //聊天室名字
      client_id: '',
      //建立websoken 返回的ID
      lotteryShow: false,
      ws_url: 'wss://55.6ctkapi.com/wss',
      emoji: [['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '🥲', '🥹', '☺️', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎'], ['🥸', '🤩', '🥳', '😏', '😒', '😞', '😔', '😟', '😕', '🙁', '☹️', '😣', '😖', '😫', '😩', '🥺', '😢', '😭', '😮‍💨', '😤', '😠', '😡', '🤬', '🤯', '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓'], ['🫣', '🤗', '🫡', '🤔', '🫢', '🤭', '🤫', '🤥', '😶', '😶‍🌫️', '😐', '😑', '😬', '🫨', '🤯', '🙄', '😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', '😵', '😵‍💫', '🫥', '🤐', '🥴', '🤢', '🤮'], ['🤧', '😷', '🤒', '🤕', '🤑', '🤠', '😈', '👿', '👹', '👺', '🤡', '💩', '👻', '💀', '☠️', '👽', '👾', '🤖', '🎃', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾']],
      speaking: true,
      //禁言
      room_open: true //房间关闭
    });
    let wechar = ref(null);
    let sonRef = ref(null);
    const scrollDiv = ref(null);
    let {
      more,
      top,
      bottom,
      gameShow,
      meId,
      content,
      emoji,
      emojiShow,
      title,
      unreadNum,
      unreadShow,
      lotteryShow,
      speaking,
      room_open
    } = toRefs(data);
    const route = useRoute();
    const router = useRouter();
    let appShow = ref(false);
    let list = reactive([]); //列表
    const regigsterShow = ref(true);
    const registerTiem = tiems => {
      if (tiems) {
        var str2 = tiems.replace(/-/g, '/');
        const date = new Date();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        const day = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}:${seconds}`;
        var dates = new Date(day).getTime();
        var regtiem = new Date(str2).getTime() + 3 * 60 * 60 * 1000;
        if (dates - regtiem > 0) {
          regigsterShow.value = true;
        } else {
          regigsterShow.value = false;
        }
        ;
      }

      // if(dates.getTime() - regtiem.getTime())
    };
    const $store = useStore(); //获取vuex
    onBeforeMount(() => {
      registerTiem(JSON.parse(localStorage.getItem('usetInfo')).register_at);
      let is_forbid_speak = JSON.parse(localStorage.getItem('usetInfo')).is_forbid_speak;
      if (is_forbid_speak == '1') {
        data.speaking = false;
      } else {
        data.speaking = true;
      }
      if (localStorage.getItem('room_open') == null) {
        data.room_open = true;
      } else {
        data.room_open = JSON.parse(localStorage.getItem('room_open'));
      }
      if (localStorage.getItem('config') != null) {
        data.ws_url = JSON.parse(localStorage.getItem('config')).ws_url;
      }
      let datas = JSON.parse(localStorage.getItem('room_info'));
      list.push(...datas);
      if (localStorage.getItem('device').device) {
        appShow.value = false;
      } else {
        appShow.value = true;
      }
      if (localStorage.getItem('config') == null) {
        configs();
      } else {
        tkGame.value = [{
          icon: require('../../assets/image/game/game5.png'),
          name: '金币竞猜',
          path: '/guess/index',
          id: 5,
          status: true
        }];
        let gameList = JSON.parse($store.state.config.game_platform_collection);
        gameList.forEach((item, index) => {
          tkGame.value.push(item);
        });
      }
    });
    onMounted(async () => {
      data.roomId = route.query.id;
      data.title = route.query.title;
      from.room_id = route.query.id;
      //     data.roomId = 10;
      // data.title = route.query.title;
      // from.room_id = 10;
      roomRecords(data.roomId, 1);
      // 获取 DOM 元素
      data.bottom = wechar.value?.clientHeight + 'px';
      await nextTick(() => {
        data.top = sonRef.value?.offsetHeight + 'px';
      });
      initWebSocket();
      // document.getElementById('app').style.overflow = 'hidden';
      // document.querySelector('body').style.overflow = 'hidden';
    });
    let handleScroll = e => {
      if (data.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < 10) {
        //显示未读消息
        data.unreadShow = false;
        data.unreadSocrll = false;
      } else {
        data.unreadSocrll = true;
      }
    };
    let wecharRecord = ref([]);
    //ajax 请求区域

    let roomLists = () => {
      roomList().then(res => {
        list.length = 0;
        let datas = res.data.data;
        datas.forEach((item, index) => {
          switch (item.id) {
            case 1:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2028.png')
              });
              break;
            case 2:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            case 3:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2029.png')
              });
              break;
            case 4:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2019.png')
              });
              break;
            case 5:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('@/assets/image/major/provice/2015.png')
              });
              break;
            default:
              break;
          }
        });
        localStorage.setItem('room_info', JSON.stringify(list));
      });
    };
    let roomRecords = (roomId, page) => {
      //获取聊天记录
      roomRecord({
        room_id: roomId,
        page: page
      }).then(res => {
        if (res.status == '500') {
          return false;
        }
        if (res.data.status == '40000' && page == 1) {
          wecharRecord.value = [];
        }
        if (res.data.status == '40000') {
          loading.value = false;
        } else {
          loading.value = false;
          let datas = res.data.data;
          data.nowecharRecord.push(...datas);
          let reversed = [];
          reversed.push(...data.nowecharRecord);
          reversed.reverse();
          wecharRecord.value = reversed;
          if (page == 1) {
            nextTick(() => {
              scrollDiv.value.scrollTop = scrollDiv.value?.scrollHeight;
              data.scrollHeight = scrollDiv.value?.scrollHeight;
            });
          } else {
            nextTick(() => {
              scrollDiv.value.scrollTop = scrollDiv.value?.scrollHeight - data.scrollHeight;
              data.scrollHeight = scrollDiv.value?.scrollHeight;
            });
          }
        }
      });
    };
    let roomJoins = (client_id, room_id) => {
      //加入房间
      roomJoin({
        client_id: client_id,
        room_id: room_id
      }).then(res => {
        if (res.data.status == 40000) {
          Dialog(res.data.message);
        }
      });
    };
    // document.addEventListener('visibilitychange', function () {
    //     if (document.visibilityState == 'hidden') { //状态判断 离开浏览页面时
    //         console.log('hidden');
    //         websock.send(JSON.stringify({"type":"close"}))
    //         websock.onclose();
    //     }
    //     if(document.visibilityState == 'visible') {
    //         console.log('visible');
    //         initWebSocket();
    //     }
    // });
    let from = reactive({
      room_id: '',
      style: 'string',
      //string（字符串）link（超链接）iamge[图片链接]
      type: 'all',
      //all（群发）at@指定人
      message: ''
      // cate:'',//当style=link时 必传 1高手论坛全部主题 2图片图解详情 3资料大全详情
      // corpusTypeId:'',//cate 等于资料大全的时候
      // detail_id:'',//文章IDstyle==link的时候
      // to:[{name:'',id:''}],//@用户的信息
    });
    let roomChats = from => {
      data.content = '';
      roomChat(from).then(res => {
        if (res.data.status != 40000) {} else {
          showConfirmDialog({
            title: '温馨提示',
            message: res.data.message
          }).then(() => {
            location.reload();
          }).catch(() => {
            // on cancel
          });
        }
      });
    };

    //功能
    const loading = ref(false);
    const onRefresh = async () => {
      data.page++;
      roomRecords(data.roomId, data.page);
    };
    let strings = '';
    let keyupDelete = () => {
      //删除输入框内容的时候 这个地方也有问题@的时候不能全部删除
      //   let path =  /(?<=@)(.+?)(?=\s)/g
      let path = new RegExp("(?<=@)(.+?)(?=\s)", 'g');
      let c = path.test(data.content);
      if (!c) {
        // let b =  data.content.slice(0,-data.atString);
        // data.content = b
        data.atString = 0;
        from.type = 'all';
        from.to = {};
      }
    };
    let handleFileChange = async file => {
      let flexs;
      await compressImage(file.content).then(result => {
        //console.log('压缩后的结果', result); // result即为压缩后的结果
        // if (result.size > file.file.size){
        // console.log('上传原图');
        // //压缩后比原来更大，则将原图上传
        // flexs = file.file
        // } else {
        //压缩后比原来小，上传压缩后的
        // console.log(result.size,file.file.size)
        // console.log('上传压缩图');
        let params = new FormData();
        params.append("file", result, file.file.name);
        flexs = params;
        // }
      });
      let res = await uploadFile(file, true);
      from.style = 'image';
      from.type = 'all';
      from.message = res.data.data.url[0];
      roomChats(from);
    };
    let handleInputChange = async () => {
      //输入框发生变化
      data.more = false;
      data.emojiShow = false;
      await nextTick();
      data.bottom = wechar.value?.clientHeight + 'px';
    };
    let fullname = computed(() => item => {
      //计算属性传递参数
      return item.replace(/\n/g, "<br/>");
    });
    let sending = async () => {
      //提交数据
      from.message = data.content;
      from.style = 'string';
      roomChats(from);
      let myFields = document.querySelector('#textarea');
      myFields.blur();
    };
    let handleClickBlur = () => {
      let myFields = document.querySelector('#textarea');
      myFields.blur();
    };
    let insertAtCursor = async myValue => {
      //选择输入框的位置插入文字和表情
      const myField = document.querySelector('#textarea');
      if (myField.selectionStart || myField.selectionStart === 0) {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        data.content = data.content.substring(0, startPos) + myValue + data.content.substring(endPos, data.content.length);
        nextTick(() => {
          myField.focus();
          myField.setSelectionRange(endPos + myValue.length, endPos + myValue.length);
        }); // 这句是重点, 圈起来
      } else {
        data.content += myValue;
      }
    };

    /**
    * @Description: 长按事件
    * @Author: LiuYanQiang
    */
    let timeOut;
    let timeOuts;
    let timesPage = 0;
    let gotouchstart = item => {
      window.isClick = true;
      clearTimeout(timeOut);
      clearTimeout(timeOuts);
      timeOut = setTimeout(function () {
        timesPage++;
        from.type = 'at', insertAtCursor('@' + item.data.from.user_name + ' ');
        data.atString = item.data.from.user_name.length + 1;
        from.to = {
          id: item.data.from.user_id,
          name: item.data.from.user_name
        };
        window.isClick = false;
      }, 500);
    };
    /**
     * @Description: 短按事件————手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
     * @Author: LiuYanQiang
     */
    const handleImagClcik = () => {
      router.push({
        path: "/guess/index"
      });
    };
    const handleImagClcikApp = () => {
      window.location.href = JSON.parse(localStorage.getItem('config')).download_url;
    };
    let gotouchend = id => {
      clearTimeout(timeOut);
      clearTimeout(timeOuts);
      if (timesPage == 0) {
        router.push({
          path: "/userInfo",
          query: {
            id: id
          }
        });
      }
      if (window.isClick) {}
      timesPage = 0;
    };
    /**
     * @Description: 如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
     * @Author: LiuYanQiang
     */
    let gotouchmove = () => {
      // console.log('手指移动了')
      clearTimeout(timeOut);
      clearTimeout(timeOuts);
      window.isClick = false;
    };
    let majorMore = async () => {
      //显示更多相片相机
      data.more = !data.more;
      data.emojiShow = false;
      await nextTick();
      data.bottom = wechar.value?.clientHeight + 'px';
      await nextTick();
      scrollDiv.value.scrollTop = scrollDiv.value.scrollTop + wechar.value?.clientHeight;
    };
    watch(data, (val, oldval) => {
      if (val.emojiShow == false) {
        nextTick(() => {
          val.bottom = wechar.value?.clientHeight + 'px';
        });

        // scrollDiv.value.scrollTop =scrollDiv.value.scrollTop +  wechar.value?.clientHeight
      }
    });
    watch(wecharRecord, (val, oldval) => {
      nextTick(() => {
        if (!data.unreadSocrll && data.xl) {
          //判断是否在底部在底部 不再底部就记录接受的多少条消息提示未读
          data.unreadNum = 0;
          data.xl = false;
          data.unreadShow = false;
          scrollDiv.value.scrollTop = scrollDiv.value?.scrollHeight;
        } else if (data.unreadSocrll && data.xl) {
          data.unreadShow = true;
          data.unreadNum++;
        }
      });
    }, {
      deep: true
    });
    let morelottery = () => {
      //显示聊天室
      let is_forbid_speak = JSON.parse(localStorage.getItem('usetInfo')).is_forbid_speak;
      if (is_forbid_speak == '1') {
        data.speaking = false;
      } else {
        data.speaking = true;
      }
      roomLists();
      data.gameShow = true;
    };
    let selecRoom = item => {
      //选择聊天室
      data.nowecharRecord.length = 0;
      localStorage.removeItem('room_open');
      data.room_open = true;
      data.page = 1;
      router.push({
        path: '/wechat',
        query: {
          title: item.name,
          id: item.id
        }
      });
      data.title = item.name;
      data.roomId = item.id;
      from.room_id = item.id;
      roomJoins(data.client_id, data.roomId); //加入聊天室
      roomRecords(data.roomId, data.page);
    };
    let keyboardHeight = ref(0);
    let selecEmoji = async () => {
      //是否显示emoji 列表
      data.emojiShow = !data.emojiShow;
      if (data.emojiShow) {
        gameTop.value = '35%';
      } else {
        gameTop.value = '20%';
      }
      data.more = false;
      await nextTick();
      data.bottom = wechar.value?.clientHeight + 'px';
      await nextTick();
      scrollDiv.value.scrollTop = scrollDiv.value.scrollTop + wechar.value?.clientHeight;
    };
    let handleInputFocus = async e => {
      data.emojiShow = false;
      data.more = false;
      await nextTick();
      scrollDiv.value.scrollTop = scrollDiv.value.scrollTop + keyboardHeight.value; //移入输入框或取光标位置
      const myField = document.querySelector('#textarea');
      startPoss = myField.selectionStart;
      endPoss = myField.selectionEnd;
      window.addEventListener('resize', async () => {
        await nextTick();
        //键盘高度
        keyboardHeight.value = window.innerHeight;
        await nextTick();
        scrollDiv.value.scrollTop = scrollDiv.value.scrollTop + keyboardHeight.value;
        // alert(sonRef.value.offsetHeight)
      });
    };
    var startPoss = 0;
    var endPoss = 0;
    let handleClick = () => {
      //点击输入框获取光标位置
      const myField = document.querySelector('#textarea');
      startPoss = myField.selectionStart;
      endPoss = myField.selectionEnd;
    };
    let selecEmojiCont = item => {
      //选择emoji 表情

      if (startPoss != 0 || endPoss != 0) {
        data.content = data.content.substring(0, startPoss) + item + data.content.substring(endPoss, data.content.length);
      } else {
        data.content += item;
      }
    };
    let soket = reactive({
      lockReconnect: false,
      //是否真正建立连接
      timeout: 50 * 1000,
      //58秒一次心跳
      timeoutObj: null,
      //心跳心跳倒计时
      serverTimeoutObj: null,
      //心跳倒计时
      timeoutnum: null //断开 重连倒计时
    });
    let websock;
    let initWebSocket = () => {
      //初始化weosocket
      const wsuri = data.ws_url;
      // const wsuri = 'wss://msg.49tkaapi.com/wss';
      websock = new WebSocket(wsuri);
      // 客户端接收服务端数据时触发
      websock.onmessage = websocketonmessage;
      // // 连接建立时触发
      websock.onopen = websocketonopen;
      // // 通信发生错误时触发
      websock.onerror = websocketonerror;
      // // 连接关闭时触发
      websock.onclose = websocketclose;
    };
    let websocketonopen = () => {
      //开启心跳
      // start();
      //连接建立之后执行send方法发送数据
      // let actions = {"room":"007854ce7b93476487c7ca8826d17eba","info":"1121212"};
      // this.websocketsend(JSON.stringify(actions));
    };

    // 通信发生错误时触发
    let websocketonerror = () => {
      console.log("出现错误");
      reconnect();
    };
    let clearTimeoutPong;
    clearTimeoutPong = setInterval(() => {
      websock.send(JSON.stringify({
        "type": "pong"
      }));
    }, 40000);
    // 客户端接收服务端数据时触发
    let websocketonmessage = e => {
      if (soket.timeoutnum) {
        clearInterval(soket.timeoutnum);
        soket.timeoutnum = null;
      }
      let datas = JSON.parse(e.data);
      if (datas.type == 'init') {
        data.client_id = datas.client_id;
        roomJoins(data.client_id, data.roomId);
      }
      if (datas.type == 'chat_ok') {
        //这个地方有点问题数据加载完成后获取元素的scroll 然后吧值赋值给scrollTop 这个地方要报错 刷新页面就没事  没搞懂这个地方  可以了用监听
        wecharRecord.value.push({
          data: datas.data
        });
        data.xl = true;
      }
      if (datas.type == 'close') {
        //    console.log('coles')
      }
      if (datas.type == 'ping') {
        websock.send(JSON.stringify({
          "type": "pong"
        }));
      }
      if (datas.type == 'forbid_speak') {
        //禁言
        let userInfo = JSON.parse(localStorage.getItem('usetInfo')); //如果是禁言就修改userInfo里面的数据
        let user_id = datas.data.user_id;
        if (user_id.includes(userInfo.id)) {
          data.speaking = false;
          userInfo.is_forbid_speak = 1;
          localStorage.setItem('usetInfo', JSON.stringify(userInfo));
        }
      }
      if (datas.type == 'open_speak') {
        //解除禁言
        let userInfo = JSON.parse(localStorage.getItem('usetInfo')); //如果是禁言就修改userInfo里面的数据
        let user_id = datas.data.user_id;
        if (user_id.includes(userInfo.id)) {
          data.speaking = true;
          userInfo.is_forbid_speak = 2;
          localStorage.setItem('usetInfo', JSON.stringify(userInfo));
        }
      }
      if (datas.type == 'delete') {
        //删除聊天
        let box = wecharRecord.value;
        let boxs = data.nowecharRecord;
        let datab = datas.data.uuids;
        datab.forEach(element => {
          box.forEach((item, index) => {
            if (item.data.uuid == element) {
              wecharRecord.value.splice(index, 1);
            }
          });
        });
        datab.forEach(element => {
          boxs.forEach((item, index) => {
            if (item.data.uuid == element) {
              boxs.value.splice(index, 1);
            }
          });
        });
      }
      if (datas.type == 'room_stop') {
        data.room_open = false;
        localStorage.setItem('room_open', JSON.stringify(false));
      }
      if (datas.type == 'room_start') {
        data.room_open = true;
        localStorage.setItem('room_open', JSON.stringify(true));
      }

      //收到服务器信息，心跳重置
      // reset();
    };
    // 连接关闭时触发
    let websocketclose = e => {
      //关闭
      reconnect();
    };
    let reconnect = () => {
      //重新连接

      // soket.lockReconnect = true;
      // //没连接上会一直重连，设置延迟避免请求过多
      // soket.timeoutnum && clearTimeout(soket.timeoutnum);
      soket.timeoutnum = setTimeout(function () {
        //新连接
        initWebSocket();
      }, 5000);
    };
    onBeforeUnmount(() => {
      // document.querySelector('body').style.overflow =  'auto';
      // document.getElementById("app").style.overflow =  'auto';
      clearInterval(clearTimeoutPong);
      websock.send(JSON.stringify({
        "type": "clode"
      }));
      websock.close();
      localStorage.removeItem('room_open');
      window.removeEventListener('resize', async () => {
        await nextTick();
        //键盘高度
        keyboardHeight.value = window.innerHeight;
        await nextTick();
        scrollDiv.value.scrollTop = scrollDiv.value.scrollTop + keyboardHeight.value;
      });
    });
    let handleCode = () => {
      openLotter.value.clickShow();
    };
    let numColor = computed(() => item => {
      //号码的颜色
      if (parseInt(item) < 50 && parseInt(item) > 0 && item != '4' && item != '9') {
        return colorarray[cons(item, numarrays)];
      } else {
        return 0;
      }
    });
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['1', '2', '3'];
    // 游戏跳转
    const gameTogo = (id, name, status) => {
      if (status) {
        if (id == 5) {
          router.push({
            path: "/guess/index"
          });
        } else if (id == 2) {
          getLaunchURLHTMLs();
        } else {
          router.push({
            path: "/game/list",
            query: {
              id: id,
              title: name
            }
          });
        }
      } else {
        Dialog('暂未开通,尽请期待', '#07c160');
      }

      // window.location.replace("https://m.pg-staging.com/1492288/index.html?ot=I-d2ad95e58fe14303a615b4b7a6d604a8&btt=1&ops=ee2ead6f4e2629c2");
    };
    let getLaunchURLHTMLs = async () => {
      let {
        data
      } = await iMgetLaunchURLHTML();
      if (data.status == 20000) {
        window.location.href = data.data.data;
      } else {
        Dialog(data.message, '#07c160');
      }
      // htmls.value = data.data.data;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_van_uploader = _resolveComponent("van-uploader");
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_grid = _resolveComponent("van-grid");
      const _component_van_popover = _resolveComponent("van-popover");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        ref_key: "sonRef",
        ref: sonRef
      }, [_createVNode(_component_headNav, {
        title: _unref(title),
        top: true,
        onMorelottery: _unref(morelottery)
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(handleCode) && _unref(handleCode)(...args))
        }, _cache[17] || (_cache[17] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        tkCont: _withCtx(() => [_createVNode(notive, {
          notiveInfo: ""
        })]),
        _: 1
      }, 8, ["title", "onMorelottery"])], 512), _unref(unreadShow) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-unread",
        style: _normalizeStyle({
          'top': _unref(top)
        })
      }, _toDisplayString(_unref(unreadNum)) + "条新消息 ", 5)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "tk-wechat-box",
        style: _normalizeStyle({
          'height': `calc(100vh - ${_unref(top)})`
        })
      }, [_createElementVNode("div", {
        class: "tk-wechat-content",
        style: _normalizeStyle({
          'height': `calc(100vh - ${_unref(top)} - ${_unref(bottom)} )`
        }),
        ref_key: "scrollDiv",
        ref: scrollDiv,
        onScroll: _cache[3] || (_cache[3] = $event => _unref(handleScroll)($event)),
        onClick: _cache[4] || (_cache[4] = (...args) => _unref(handleClickBlur) && _unref(handleClickBlur)(...args))
      }, [_createVNode(_component_van_pull_refresh, {
        "pulling-text": " ",
        "loosing-text": " ",
        modelValue: loading.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => loading.value = $event),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(msgRed.value(_unref(wecharRecord)), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, [item.data.style == 'red_receive_ok' ? (_openBlock(), _createElementBlock("li", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[18] || (_cache[18] = _createElementVNode("img", {
            style: {
              "width": "13px",
              "height": "17.5px",
              "margin-right": "4px"
            },
            src: _imports_1,
            alt: ""
          }, null, -1)), _createElementVNode("div", _hoisted_5, _toDisplayString(item.data.from.user_id == _unref(meId) ? '您' : item.data.from.user_name) + "已领取了红包", 1)])])) : _createCommentVNode("", true), item.data.from.user_id != _unref(meId) && item.data.style != 'red_receive_ok' ? (_openBlock(), _createElementBlock("li", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
            class: "tk-picture",
            onTouchstart: $event => _unref(gotouchstart)(item),
            onTouchmove: _cache[1] || (_cache[1] = (...args) => _unref(gotouchmove) && _unref(gotouchmove)(...args)),
            onTouchend: $event => _unref(gotouchend)(item.data.from.user_id)
          }, [_createElementVNode("img", {
            src: item.data.from.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.data.from.avatar,
            alt: ""
          }, null, 8, _hoisted_9)], 40, _hoisted_8), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(item.data.from.user_name), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(item.data.time), 1)])]), item.data.style == 'image' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("img", {
            src: httpsComputed.value(item.data.message),
            alt: "",
            onClick: $event => _unref(preview)(httpsComputed.value(item.data.message))
          }, null, 8, _hoisted_14)])) : _createCommentVNode("", true), item.data.style == 'string' ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "tk-wechat-conten",
            innerHTML: _unref(fullname)(item.data.message)
          }, null, 8, _hoisted_15)) : _createCommentVNode("", true), item.data.style == 'sys_report_code' ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass('tk-wechat-conten border' + item.data.message.lotteryType),
            style: {
              "width": "80%"
            }
          }, [_createVNode(lotteryCode, {
            number_attr: item.data.message
          }, null, 8, ["number_attr"])], 2)) : _createCommentVNode("", true), item.data.style == 'lottery' ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(item.data.message), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              key: index
            }, [_createElementVNode("div", {
              class: _normalizeClass('num class' + _unref(numColor)(item.number))
            }, [_unref(numColor)(item.number) == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(item.number), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(item.number), 1))], 2), _createElementVNode("div", _hoisted_20, _toDisplayString(item.shengXiao + '/' + item.wuXing), 1)]);
          }), 128))])])])) : _createCommentVNode("", true), item.data.style == 'red_envelope' ? (_openBlock(), _createElementBlock("div", _hoisted_21, [item.data.message.is_receive || item.data.message.status != 1 ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[19] || (_cache[19] = [_createElementVNode("img", {
            src: _imports_2,
            alt: ""
          }, null, -1), _createElementVNode("div", {
            class: "title"
          }, "红包已抢完", -1), _createElementVNode("div", {
            class: "tips"
          }, "平台红包", -1)]))) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: $event => handleRedBag(item.data.message, item.data)
          }, _cache[20] || (_cache[20] = [_createElementVNode("img", {
            src: _imports_3,
            alt: ""
          }, null, -1), _createElementVNode("div", {
            class: "title"
          }, "恭喜发财 大吉大利", -1), _createElementVNode("div", {
            class: "tips"
          }, "平台红包", -1)]), 8, _hoisted_23))])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), item.data.from.user_id == _unref(meId) && item.data.style != 'red_receive_ok' ? (_openBlock(), _createElementBlock("li", _hoisted_24, [_createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, _toDisplayString(item.data.from.user_name), 1), _createElementVNode("div", _hoisted_28, _toDisplayString(item.data.time), 1)]), _createElementVNode("div", _hoisted_29, [_createElementVNode("img", {
            src: item.data.from.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.data.from.avatar,
            alt: ""
          }, null, 8, _hoisted_30)])]), item.data.style == 'image' ? (_openBlock(), _createElementBlock("div", _hoisted_31, [_createElementVNode("img", {
            src: _ctx.$httpsUrl + item.data.message,
            alt: "",
            onClick: $event => _unref(preview)(_ctx.$httpsUrl + item.data.message)
          }, null, 8, _hoisted_32)])) : _createCommentVNode("", true), item.data.style == 'string' ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "tk-wechat-conten mes",
            innerHTML: _unref(fullname)(item.data.message)
          }, null, 8, _hoisted_33)) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
        }), 128))])]),
        _: 1
      }, 8, ["modelValue"])], 36), _createElementVNode("div", {
        class: "tk-wehcat-from",
        ref_key: "wechar",
        ref: wechar
      }, [_createElementVNode("form", _hoisted_34, [_createElementVNode("div", _hoisted_35, [_unref(speaking) && _unref(room_open) && regigsterShow.value ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
        key: 0,
        onInput: _cache[5] || (_cache[5] = (...args) => _unref(handleInputChange) && _unref(handleInputChange)(...args)),
        id: "textarea",
        onClick: _cache[6] || (_cache[6] = (...args) => _unref(handleClick) && _unref(handleClick)(...args)),
        onFocus: _cache[7] || (_cache[7] = $event => _unref(handleInputFocus)($event)),
        onKeyup: [_cache[8] || (_cache[8] = _withKeys((...args) => _unref(keyupDelete) && _unref(keyupDelete)(...args), ["delete"])), _cache[9] || (_cache[9] = _withKeys((...args) => _ctx.keyupEnter && _ctx.keyupEnter(...args), ["enter"]))],
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(content) ? content.value = $event : content = $event),
        placeholder: "想说点什么..."
      }, null, 544)), [[_vModelText, _unref(content)]]) : _createCommentVNode("", true), !_unref(speaking) ? (_openBlock(), _createElementBlock("div", _hoisted_36, "你已被禁言")) : _createCommentVNode("", true), !_unref(room_open) ? (_openBlock(), _createElementBlock("div", _hoisted_37, "房间已经关闭")) : _createCommentVNode("", true), !regigsterShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_38, "新用户注册3小时后才能发言")) : _createCommentVNode("", true)]), _unref(speaking) && _unref(room_open) && regigsterShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_39, [_createElementVNode("div", {
        class: "emojie",
        onClick: _cache[11] || (_cache[11] = (...args) => _unref(selecEmoji) && _unref(selecEmoji)(...args))
      }, _cache[21] || (_cache[21] = [_createElementVNode("img", {
        src: _imports_4,
        alt: ""
      }, null, -1)])), _unref(content) == '' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "more",
        onClick: _cache[12] || (_cache[12] = (...args) => _unref(majorMore) && _unref(majorMore)(...args))
      }, _cache[22] || (_cache[22] = [_createElementVNode("img", {
        src: _imports_5,
        alt: ""
      }, null, -1)]))) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "more",
        onClick: _cache[13] || (_cache[13] = (...args) => _unref(sending) && _unref(sending)(...args))
      }, _cache[23] || (_cache[23] = [_createElementVNode("img", {
        src: _imports_6,
        alt: ""
      }, null, -1)])))])) : _createCommentVNode("", true)]), _withDirectives(_createElementVNode("div", _hoisted_40, [_createElementVNode("ul", null, [_createElementVNode("li", null, [_cache[24] || (_cache[24] = _createElementVNode("img", {
        src: _imports_7,
        alt: ""
      }, null, -1)), _cache[25] || (_cache[25] = _createElementVNode("span", null, "拍照", -1)), _createVNode(_component_van_uploader, {
        capture: "camera",
        class: "camera",
        "after-read": _unref(handleFileChange),
        "max-count": 1
      }, null, 8, ["after-read"])]), _createElementVNode("li", null, [_cache[26] || (_cache[26] = _createElementVNode("img", {
        src: _imports_8,
        alt: ""
      }, null, -1)), _cache[27] || (_cache[27] = _createElementVNode("span", null, "相册", -1)), _createVNode(_component_van_uploader, {
        class: "camera",
        "after-read": _unref(handleFileChange),
        "max-count": 1
      }, null, 8, ["after-read"])])])], 512), [[_vShow, _unref(more)]]), _unref(emojiShow) ? (_openBlock(), _createElementBlock("div", _hoisted_41, [_createVNode(_component_van_swipe, {
        class: "my-swipe",
        autoplay: 0,
        "indicator-color": "#07c160"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(emoji), items => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: items
          }, {
            default: _withCtx(() => [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, index) => {
              return _openBlock(), _createElementBlock("li", {
                key: index,
                onClick: $event => _unref(selecEmojiCont)(item)
              }, _toDisplayString(item), 9, _hoisted_42);
            }), 128))])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })])) : _createCommentVNode("", true)], 512)], 4), _createElementVNode("div", {
        class: "tk-info-other",
        style: _normalizeStyle({
          'bottom': gameTop.value
        })
      }, [_unref(appShow) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "jinbi app",
        onClick: handleImagClcikbet
      }, _cache[28] || (_cache[28] = [_createElementVNode("img", {
        src: _imports_9,
        alt: ""
      }, null, -1)]))) : _createCommentVNode("", true), listNums.value.length != 0 ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "redb",
        onClick: handleImagbagClcik
      }, _cache[29] || (_cache[29] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)]))) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_43, [_createVNode(_component_van_popover, {
        show: showPopover.value,
        "onUpdate:show": _cache[14] || (_cache[14] = $event => showPopover.value = $event),
        placement: "left"
      }, {
        reference: _withCtx(() => _cache[31] || (_cache[31] = [_createElementVNode("img", {
          src: _imports_10,
          alt: ""
        }, null, -1)])),
        default: _withCtx(() => [_createVNode(_component_van_grid, {
          square: "",
          clickable: "",
          border: false,
          "column-num": "4",
          style: {
            "width": "240px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_44, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tkGame), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              key: index,
              class: "flex-between",
              onClick: $event => gameTogo(item.id, item.name, item.status)
            }, [item.status ? (_openBlock(), _createElementBlock("div", _hoisted_46, [index != 3 ? (_openBlock(), _createElementBlock("div", _hoisted_47, [_createElementVNode("img", {
              src: './game/game' + item.id + '.png',
              alt: ""
            }, null, 8, _hoisted_48)])) : (_openBlock(), _createElementBlock("div", _hoisted_49, _cache[30] || (_cache[30] = [_createElementVNode("div", {
              class: "goods_f"
            }, null, -1)]))), _createElementVNode("span", _hoisted_50, _toDisplayString(item.name), 1)])) : _createCommentVNode("", true)], 8, _hoisted_45);
          }), 128))])])]),
          _: 1
        })]),
        _: 1
      }, 8, ["show"])]), _unref(appShow) ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: "jinbi app",
        onClick: handleImagClcikApp
      }, _cache[32] || (_cache[32] = [_createElementVNode("img", {
        src: _imports_11,
        alt: ""
      }, null, -1)]))) : _createCommentVNode("", true)], 4), _createVNode(wecharList, {
        show: _unref(gameShow),
        "onUpdate:show": _cache[15] || (_cache[15] = $event => _isRef(gameShow) ? gameShow.value = $event : gameShow = $event),
        onSelecRoom: _unref(selecRoom),
        list: _unref(list)
      }, null, 8, ["show", "onSelecRoom", "list"]), _createVNode(lotteryReal, {
        ref_key: "openLotter",
        ref: openLotter,
        show: _unref(lotteryShow),
        "onUpdate:show": _cache[16] || (_cache[16] = $event => _isRef(lotteryShow) ? lotteryShow.value = $event : lotteryShow = $event),
        lotterys: _unref(data).roomId
      }, null, 8, ["show", "lotterys"]), _createVNode(redbag, {
        ref_key: "chid",
        ref: chid,
        onOpenRed: handleOpenRed
      }, null, 512), _createVNode(record, {
        moeny: redBagMoney.value,
        ref_key: "chids",
        ref: chids
      }, null, 8, ["moeny"]), _createVNode(popu, {
        ref_key: "popop",
        ref: popop,
        onChangNum: changNum,
        onHandleSubmit: handleSubmit
      }, null, 512)]);
    };
  }
};