import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "lottery-code"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "tk-lottery-box"
};
const _hoisted_4 = {
  class: "sx"
};
import { reactive } from "vue";

import { computed } from 'vue';
export default {
  __name: 'code',
  props: {
    number_attr: {
      type: Object,
      default: {}
    }
  },
  setup(__props) {
    const props = __props;
    const data = reactive({
      number_attr: [{
        color: 3,
        number: "11",
        shengXiao: "虎",
        wuXing: "木"
      }, {
        color: 3,
        number: "39",
        shengXiao: "狗",
        wuXing: "水"
      }, {
        color: 2,
        number: "10",
        shengXiao: "兔",
        wuXing: "木"
      }, {
        color: 1,
        number: "24",
        shengXiao: "牛",
        wuXing: "水"
      }, {
        color: 2,
        number: "41",
        shengXiao: "猴",
        wuXing: "木"
      }, {
        color: 2,
        number: "42",
        shengXiao: "羊",
        wuXing: "火"
      }, {
        color: 3,
        number: "22",
        shengXiao: "兔",
        wuXing: "土"
      }]
    });
    let numColor = computed(() => item => {
      //号码的颜色
      if (parseInt(item) < 50 && parseInt(item) > 0 && item != '4' && item != '9') {
        return colorarray[cons(item, numarrays)];
      } else {
        return 0;
      }
    });
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['1', '2', '3'];
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.number_attr.name), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.number_attr.list, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", {
          class: _normalizeClass('num class' + _unref(numColor)(item.number))
        }, [_createElementVNode("span", null, _toDisplayString(item.number), 1)], 2), _createElementVNode("div", _hoisted_4, _toDisplayString(item.sx) + "/" + _toDisplayString(item.nim), 1)]);
      }), 128))])])]);
    };
  }
};