import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '../../assets/image/close.png';
import _imports_1 from '../../assets/image/link_r_b.png';
const _hoisted_1 = {
  class: "tk-main-QEcode"
};
const _hoisted_2 = {
  class: "tk-exit-name"
};
const _hoisted_3 = {
  class: "tk-exit-from"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0,
  class: "icon"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "name"
};
import { roomList } from '@/utils/https/wechar';
import { onBeforeMount, reactive } from 'vue';

import { useRouter } from 'vue-router';
export default {
  __name: 'wecharList',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: []
    }
  },
  emits: ['selecRoom', 'update:show'],
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    let props = __props;
    // let list = reactive([
    //     // {name:'香港六合聊天室',id:'1',selectIcon:require('../../assets/image/major/provice/2028.png')},
    //     // {name:'新澳门六合聊天室',id:'2',selectIcon:require('../../assets/image/major/provice/2015.png')},
    //     // {name:'台湾六合聊天室',id:'3',selectIcon:require('../../assets/image/major/provice/2029.png')},
    //     // {name:'新加坡六合聊天室',id:'4',selectIcon:require('../../assets/image/major/provice/2019.png')},
    //     // {name:'澳门六合聊天室',id:'5',selectIcon:require('../../assets/image/major/provice/2015.png')},
    // ])
    let emits = __emit;
    let colse = () => {
      emits('update:show', false);
    };
    let overlayPop = () => {
      emits('update:show', false);
    };
    onBeforeMount(() => {
      // if(localStorage.getItem('room_info')==null){
      //     roomLists();
      // }else{
      //     let datas = JSON.parse(localStorage.getItem('room_info'));
      //     datas.forEach((item,index) => {
      //         switch (item.id) {
      //             case 1:
      //                list.push( {name:item.name,id:item.id,selectIcon:require('../../assets/image/major/provice/2028.png')})
      //                 break;
      //                 case 2:
      //                list.push( {name:item.name,id:item.id,selectIcon:require('../../assets/image/major/provice/2015.png')})
      //                 break;
      //                 case 3:
      //                list.push( {name:item.name,id:item.id,selectIcon:require('../../assets/image/major/provice/2029.png')})
      //                 break;
      //                 case 4:
      //                list.push( {name:item.name,id:item.id,selectIcon:require('../../assets/image/major/provice/2019.png')})
      //                 break;
      //                 case 5:
      //                 list.push( {name:item.name,id:item.id,selectIcon:require('../../assets/image/major/provice/2015.png')})
      //                 break;
      //             default:
      //                 break;
      //         }
      //     });
      // }
    });
    let roomLists = () => {
      roomList().then(res => {
        if (res == undefined) return false;
        let datas = res.data.data;
        datas.forEach((item, index) => {
          switch (item.id) {
            case 1:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('../../assets/image/major/provice/2028.png')
              });
              break;
            case 2:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('../../assets/image/major/provice/2015.png')
              });
              break;
            case 3:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('../../assets/image/major/provice/2029.png')
              });
              break;
            case 4:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('../../assets/image/major/provice/2019.png')
              });
              break;
            case 5:
              list.push({
                name: item.name,
                id: item.id,
                selectIcon: require('../../assets/image/major/provice/2015.png')
              });
              break;
            default:
              break;
          }
        });
        localStorage.setItem('room_info', JSON.stringify(datas));
      });
    };
    let selecRoom = item => {
      if (localStorage.getItem('usetInfo') == null) {
        router.push({
          path: '/login'
        });
      } else {
        if (item.id == 9) {
          router.push({
            path: '/wechat/liuhe'
          });
        } else {
          emits('selecRoom', item);
        }
      }
    };
    return (_ctx, _cache) => {
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: __props.show,
        "z-index": "1000"
      }, {
        default: _withCtx(() => [__props.list.length != 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "wrapper",
          onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(colse) && _unref(colse)(...args))
        }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_2, [_cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "tk-exit-title"
        }, "选择聊天室", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(selecRoom)(item)
          }, [item.selectIcon ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
            src: item.selectIcon,
            alt: ""
          }, null, 8, _hoisted_6)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, _toDisplayString(item.name), 1), _cache[3] || (_cache[3] = _createElementVNode("div", {
            class: "more"
          }, [_createElementVNode("img", {
            src: _imports_1,
            alt: ""
          })], -1))], 8, _hoisted_4);
        }), 128))])])])])])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["show"]);
    };
  }
};