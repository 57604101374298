import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx } from "vue";
import _imports_0 from '../../assets/image/onreturn.png';
import _imports_1 from '../../assets/image/moneyadd.png';
import _imports_2 from '../../assets/image/colseNew.png';
const _hoisted_1 = {
  class: "tk-user-head"
};
const _hoisted_2 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_3 = {
  class: "tk-head-money"
};
const _hoisted_4 = {
  class: "tk-activity-info flex-between"
};
const _hoisted_5 = {
  class: "tk-item"
};
const _hoisted_6 = {
  class: "tk-num"
};
const _hoisted_7 = {
  class: "tk-item"
};
const _hoisted_8 = {
  class: "tk-num"
};
const _hoisted_9 = {
  class: "tk-item"
};
const _hoisted_10 = {
  class: "tk-num"
};
const _hoisted_11 = {
  class: "tk-activity-plan"
};
const _hoisted_12 = {
  class: "tk-plan-title flex-between"
};
const _hoisted_13 = {
  class: "tk-title-l"
};
const _hoisted_14 = {
  class: "tk-title-c"
};
const _hoisted_15 = {
  class: "tk-title-l"
};
const _hoisted_16 = {
  class: "tk-plan-link"
};
const _hoisted_17 = {
  class: "tk-plan-title flex-between"
};
const _hoisted_18 = {
  class: "tk-desc"
};
const _hoisted_19 = {
  class: "tk-desc"
};
const _hoisted_20 = {
  class: "tk-clock-in"
};
const _hoisted_21 = {
  class: "tk-clock-title flex-between"
};
const _hoisted_22 = {
  class: "tk-title"
};
const _hoisted_23 = {
  class: "tk-clock-cont"
};
const _hoisted_24 = ["src"];
const _hoisted_25 = ["src"];
const _hoisted_26 = {
  class: "null"
};
const _hoisted_27 = {
  class: "tk-bottom"
};
const _hoisted_28 = {
  class: "tk-quest"
};
const _hoisted_29 = {
  class: "tk-quest-list"
};
const _hoisted_30 = {
  key: 0
};
const _hoisted_31 = {
  class: "tk-quert-l"
};
const _hoisted_32 = {
  class: "tk-quert-titlt"
};
const _hoisted_33 = {
  class: "tk-plan-link"
};
const _hoisted_34 = {
  class: "tk-plan-title flex-between"
};
const _hoisted_35 = {
  class: "tk-title-l"
};
const _hoisted_36 = {
  class: "tk-title-l"
};
const _hoisted_37 = {
  class: "tk-plan-link-s"
};
const _hoisted_38 = {
  key: 0,
  class: "tk-link",
  style: {
    'width': 100 + '%'
  }
};
const _hoisted_39 = {
  class: "tk-quert-accomplish"
};
const _hoisted_40 = ["onClick"];
const _hoisted_41 = ["onClick"];
const _hoisted_42 = {
  key: 2,
  class: "tk-quert-r",
  style: {
    "background": "#A1B1C8"
  }
};
const _hoisted_43 = {
  key: 1
};
const _hoisted_44 = {
  class: "tk-quert-l"
};
const _hoisted_45 = {
  class: "tk-quert-titlt"
};
const _hoisted_46 = {
  class: "tk-quert-accomplish",
  style: {
    "margin-top": "10px"
  }
};
const _hoisted_47 = ["onClick"];
const _hoisted_48 = {
  key: 1,
  class: "tk-quert-r",
  style: {
    "background": "#A1B1C8"
  }
};
const _hoisted_49 = {
  class: "wrappers"
};
const _hoisted_50 = {
  class: "prompt"
};
const _hoisted_51 = {
  class: "content"
};
const _hoisted_52 = {
  class: "wrappers showMoney"
};
const _hoisted_53 = {
  class: "title"
};
const _hoisted_54 = {
  class: "num"
};
import genre from '@/components/page/genre.vue';
import Dialog from '@/components/mode/message';
import { signIn, getActivityInformation, activityReceive } from '@/utils/https/me';
import { onBeforeMount } from 'vue';

import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'activity',
  setup(__props) {
    let data = reactive({
      clockIn: [
        // {name:'第一天',img:require('@/assets/image/day/day.png'),imgs:require('@/assets/image/day/aday.png'),select:0,num:''},
        // {name:'第二天',img:require('@/assets/image/day/day.png'),imgs:require('@/assets/image/day/aday.png'),select:0,num:''},
        // {name:'第三天',img:require('@/assets/image/day/day.png'),imgs:require('@/assets/image/day/aday.png'),select:0,num:''},
        // {name:'第四天',img:require('@/assets/image/day/day.png'),imgs:require('@/assets/image/day/aday.png'),select:0,num:''},
        // {name:'第五天',img:require('@/assets/image/day/day.png'),imgs:require('@/assets/image/day/aday.png'),select:0,num:''},
        // {name:'第六天',img:require('@/assets/image/day/day.png'),imgs:require('@/assets/image/day/aday.png'),select:0,num:''},
      ],
      genreData: [{
        name: '今日任务',
        id: 0
      }, {
        name: '新手任务',
        id: 1
      }],
      signInInfo: {
        nextScores: 0,
        growth_score: 0
      },
      shows: false,
      questData: [{
        title: '点赞10次不同的帖子',
        type: 'user_activity_follow',
        finish: 0,
        total: 10,
        receive: false
      }, {
        title: '评论5次不同的帖子',
        type: 'user_activity_comment',
        finish: 0,
        total: 5,
        receive: false
      },
      // {title:'转发3次帖子',type:'user_activity_forward',finish:0,total:3,receive:false},
      {
        title: '在线15分钟以上，退出登录无效',
        type: 'online_15',
        finish: 0,
        total: 0
      }],
      beginner: [{
        title: '补填邀请码',
        desc: '新用户补贴邀请码可领取一次性红包奖励',
        type: 'filling_gift'
      }
      // {title:'新手注册',desc:'新用户注册下载可领取一次注册红包',type:'register_gift',register_gift:''},
      // {title:'分享好友',desc:'新用户只能分享一次，完成后领取红包奖励',type:'share_gift',share_gift:''},
      ],
      navGet: 0,
      money: '',
      work_doing: '',
      work_receive: '',
      showMoney: false,
      ios: false,
      websiteName: localStorage.getItem('config') == null ? {
        name: '图库中心'
      } : JSON.parse(localStorage.getItem('config')),
      getUserInfo: '',
      gold: 0
    });
    let {
      clockIn,
      genreData,
      signInInfo,
      shows,
      questData,
      beginner,
      navGet,
      money,
      work_doing,
      work_receive,
      showMoney,
      websiteName,
      gold
    } = toRefs(data);
    const router = useRouter();
    onBeforeMount(() => {
      if (localStorage.getItem('Authorization') == null && getQueryString('token') == null) {
        router.push({
          path: '/login'
        });
      }
      data.getUserInfo = JSON.parse(localStorage.getItem('usetInfo'));
      getActivityInformations();
      if (getQueryString('token') != null) {
        localStorage.setItem('Authorization', getQueryString('token_type') + ' ' + getQueryString('token'));
        data.ios = true;
      }
    });
    let getQueryString = name => {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      ;
      return null;
    };
    //接口请求
    let majorNav = e => {
      data.navGet = e;
    };
    let getActivityInformations = () => {
      //获取任务

      getActivityInformation().then(res => {
        let datas = res.data.data.user_info;
        let today = res.data.data.today_works;
        let novice_works = res.data.data.novice_works;
        data.money = res.data.data.account_balance;
        data.work_receive = res.data.data.work_receive;
        data.work_doing = res.data.data.work_doing;
        data.questData[0] = {
          ...data.questData[0],
          ...today.follows
        };
        data.questData[1] = {
          ...data.questData[1],
          ...today.comments
        };
        // data.questData[2] = {...data.questData[2],...today.forwards}
        data.questData[2] = {
          ...today.online_15,
          ...data.questData[2]
        };
        data.beginner[0] = {
          ...novice_works.filling_invite_code,
          ...data.beginner[0]
        };
        // data.beginner[1].register_gift = novice_works.register_gift
        // data.beginner[2].share_gift = novice_works.share_gift

        datas.signInDaySpecies.forEach((item, index) => {
          if (item == 0) {
            item = '签';
          }
          data.clockIn.push({
            name: `第${index + 1}天`,
            img: require('@/assets/image/day/day.png'),
            imgs: require('@/assets/image/day/aday.png'),
            select: 0,
            num: item
          });
        });
        data.clockIn.forEach((item, index) => {
          if (datas.signCount >= index + 1) {
            item.select = 1;
          }
        });
        data.signInInfo = datas;
      });
    };
    let activityReceives = type => {
      //完成
      var money_gift_num = 0;
      activityReceive({
        type: type
      }).then(res => {
        data.questData.forEach(item => {
          if (item.type == type) {
            money_gift_num = item.gift_num;
            item.receive = false;
          }
        });
        let money = data.money;
        data.gold = money_gift_num;
        data.money = Math.floor(money.split('.')[0]) + Math.floor(money_gift_num) + '.' + money.split('.')[1];
        data.getUserInfo.account_balance = data.money;
        localStorage.setItem('usetInfo', JSON.stringify(data.getUserInfo));
        data.showMoney = true;
      });
    };
    let activityReceivesc = type => {
      //完成
      activityReceive({
        type: type
      }).then(res => {
        if (res.data.status == 40000) {
          handleToget();
          return false;
        }
        ;
        data.beginner.forEach(item => {
          if (type == 'filling_gift') {
            item.status = 1;
            item.is_exist = false;
            return false;
          }
        });
        let money = data.money;
        data.gold = 1;
        data.money = Math.floor(money.split('.')[0]) + 1 + '.' + money.split('.')[1];
        data.getUserInfo.account_balance = data.money;
        localStorage.setItem('usetInfo', JSON.stringify(data.getUserInfo));
        data.showMoney = true;
      });
    };
    let signIns = () => {
      signIn().then(res => {
        let datas = data.signInInfo;
        datas.signCount += 1;
        datas.isSign = 1;
        data.clockIn.forEach((item, index) => {
          if (datas.signCount >= index + 1) {
            item.select = 1;
          }
        });
        var jinbi = 1;
        switch (datas.signCount) {
          case 1:
            jinbi = data.clockIn[0].num == '签' ? 0 : data.clockIn[0].num;
            break;
          case 2:
            jinbi = data.clockIn[1].num == '签' ? 0 : data.clockIn[1].num;
            break;
          case 3:
            jinbi = data.clockIn[2].num == '签' ? 0 : data.clockIn[2].num;
            break;
          case 4:
            jinbi = data.clockIn[3].num == '签' ? 0 : data.clockIn[3].num;
            break;
          case 5:
            jinbi = data.clockIn[4].num == '签' ? 0 : data.clockIn[4].num;
            break;
          case 6:
            jinbi = data.clockIn[5].num == '签' ? 0 : data.clockIn[5].num;
            break;
          case 7:
            jinbi = data.clockIn[7].num == '签' ? 0 : data.clockIn[6].num;
            break;
          default:
            data.clockIn.forEach((item, index) => {
              if (index == 0) {
                item.select = 1;
              } else {
                item.select = 0;
              }
            });
            jinbi = data.clockIn[0].num == '签' ? 0 : data.clockIn[0].num;
        }
        let money = data.money;
        data.gold = jinbi;
        data.money = Math.floor(money.split('.')[0]) + jinbi + '.' + money.split('.')[1];
        data.getUserInfo.account_balance = data.money;
        localStorage.setItem('usetInfo', JSON.stringify(data.getUserInfo));
        data.signInInfo = datas;
        if (jinbi == 0) {
          Dialog(res.data.message);
        } else {
          data.showMoney = true;
        }
        Dialog(res.data.message);
      });
    };

    //功能区域
    let handleNavTo = item => {
      if (data.ios) {
        callNativeApp('user');
      } else {
        router.push({
          path: '/master'
        });
      }
    };
    let handleToget = () => {
      router.push({
        path: '/me/addinvite'
      });
    };
    let handleSing = () => {
      signIns();
    };
    let returnUp = () => {
      callNativeApp('back');
      router.back();
    };
    let callNativeApp = back => {
      try {
        // 從JS傳送資訊到App裡設定的callbackHandler。
        webkit.messageHandlers.callbackHandler.postMessage(back);
      } catch (err) {
        // 如果找不到定義的callbackHandler則回傳錯誤訊息。
      }
    };

    // let returnUp = ()=>{
    //     router.back()
    // }
    let handleRule = () => {
      data.shows = true;
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "活动中心", -1)), _cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "tk-head-info"
      }, null, -1))]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_unref(work_doing)), 1), _cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "tk-text"
      }, "进行中", -1))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(work_receive)), 1), _cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "tk-text"
      }, "未领取", -1))]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(_unref(money)), 1), _cache[10] || (_cache[10] = _createElementVNode("div", {
        class: "tk-text"
      }, "我的金币", -1))])])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(_unref(signInInfo).level_name), 1), _createElementVNode("div", _hoisted_14, "成长值：" + _toDisplayString(_unref(signInInfo).growth_score), 1), _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(signInInfo).nextLevelName), 1)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", {
        class: "tk-link",
        style: _normalizeStyle({
          'width': 100 / _unref(signInInfo).nextScores * _unref(signInInfo).growth_score + '%'
        })
      }, null, 4)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, "距离下一级还差：" + _toDisplayString(_unref(signInInfo).nextScores - _unref(signInInfo).growth_score) + "成长值", 1), _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(signInInfo).nextScores), 1)])])]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("p", _hoisted_22, "已经连续签到" + _toDisplayString(_unref(signInInfo).signCount) + "天", 1), _createElementVNode("p", {
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(handleRule) && _unref(handleRule)(...args))
      }, "领取规则")]), _createElementVNode("div", _hoisted_23, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(clockIn), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [item.select == 0 ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: item.img,
          alt: ""
        }, null, 8, _hoisted_24)) : _createCommentVNode("", true), item.select == 1 ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: item.imgs,
          alt: ""
        }, null, 8, _hoisted_25)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_26, _toDisplayString(item.num), 1), _createElementVNode("p", null, _toDisplayString(item.name), 1)]);
      }), 128))]), _createElementVNode("div", _hoisted_27, [!_unref(signInInfo).isSign ? (_openBlock(), _createBlock(_component_botton, {
        key: 0,
        name: "立即签到",
        onClick: _unref(handleSing)
      }, null, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_botton, {
        key: 1,
        name: "已签到",
        bgcolor: "rgba(3, 159, 76, 0.4)"
      }))])])]), _createElementVNode("div", _hoisted_28, [_createVNode(genre, {
        dataNav: _unref(genreData),
        onMajorNav: _unref(majorNav)
      }, null, 8, ["dataNav", "onMajorNav"]), _createElementVNode("div", _hoisted_29, [_createElementVNode("ul", null, [!_unref(navGet) ? (_openBlock(), _createElementBlock("div", _hoisted_30, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(questData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "flex-between",
          key: index
        }, [_createElementVNode("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_33, [_createElementVNode("div", _hoisted_34, [_createElementVNode("div", _hoisted_35, _toDisplayString(item.finish), 1), _createElementVNode("div", _hoisted_36, _toDisplayString(item.total), 1)]), _createElementVNode("div", _hoisted_37, [item.status ? (_openBlock(), _createElementBlock("div", _hoisted_38)) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tk-link",
          style: _normalizeStyle({
            'width': 100 / item.total * item.finish + '%'
          })
        }, null, 4))])]), _createElementVNode("div", _hoisted_39, " 完成任务+" + _toDisplayString(item.gift_num) + "金币 ", 1)]), item.receive == false && item.finish != item.total ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-quert-r",
          onClick: $event => _unref(handleNavTo)(item.type)
        }, " 去完成 ", 8, _hoisted_40)) : _createCommentVNode("", true), item.receive == true ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tk-quert-r",
          style: {
            "background": "#FF1A00"
          },
          onClick: $event => _unref(activityReceives)(item.type)
        }, " 领取 ", 8, _hoisted_41)) : _createCommentVNode("", true), item.receive == false && item.finish == item.total ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(!item.receive && !item.status && item.finish == 0 ? '未完成' : '完成'), 1)) : _createCommentVNode("", true)]);
      }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_43, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(beginner), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "flex-between",
          key: index
        }, [_createElementVNode("div", _hoisted_44, [_createElementVNode("div", _hoisted_45, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_46, _toDisplayString(item.desc), 1)]), item.status == 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-quert-r",
          onClick: $event => _unref(activityReceivesc)(item.type),
          style: {
            "background": "#FF1A00"
          }
        }, " 立即领取 ", 8, _hoisted_47)) : (_openBlock(), _createElementBlock("div", _hoisted_48, " 已完成 "))]);
      }), 128))]))])])]), _createVNode(_component_van_overlay, {
        show: _unref(shows),
        "z-index": "999",
        onClick: _cache[3] || (_cache[3] = $event => _isRef(shows) ? shows.value = false : shows = false)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_49, [_createElementVNode("div", _hoisted_50, [_cache[17] || (_cache[17] = _createElementVNode("div", {
          class: "title"
        }, "领取规则", -1)), _createElementVNode("div", _hoisted_51, [_createTextVNode(" 1.用户下载" + _toDisplayString(_unref(websiteName).name) + "APP并登录后，每日首次打开并登录，进入活动中心，点击“签到”即完成一次签到。", 1), _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)), _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)), _cache[13] || (_cache[13] = _createTextVNode(" 2.连续签到，首次完成签到的开始累计，签到中断则重新开始计算天数，签到7天为一周期。")), _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)), _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)), _cache[16] || (_cache[16] = _createTextVNode(" 3.每日同一个注册用户仅可参与一次 "))]), _createVNode(_component_botton, {
          onClick: _cache[2] || (_cache[2] = $event => _isRef(shows) ? shows.value = false : shows = false)
        })])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: _unref(showMoney),
        "z-index": "999",
        onClick: _cache[4] || (_cache[4] = $event => _isRef(showMoney) ? showMoney.value = false : showMoney = false)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_52, [_cache[18] || (_cache[18] = _createElementVNode("div", {
          class: "money_img"
        }, [_createElementVNode("img", {
          src: _imports_1,
          alt: ""
        })], -1)), _createElementVNode("div", _hoisted_53, "恭喜获得" + _toDisplayString(_unref(gold)) + "枚金币", 1), _cache[19] || (_cache[19] = _createElementVNode("div", {
          class: "desc"
        }, "金币已存入您的账户中，请注意查看", -1)), _createElementVNode("div", _hoisted_54, _toDisplayString(_unref(gold)) + "枚", 1), _cache[20] || (_cache[20] = _createElementVNode("div", {
          class: "colse"
        }, [_createElementVNode("img", {
          src: _imports_2,
          alt: ""
        })], -1))])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};