import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";

import { useRouter } from 'vue-router';
import { reactive, toRefs } from 'vue';
const _hoisted_1 = {
  class: "tk-main-tool"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "icon"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "name"
};
export default {
  __name: 'index',
  setup(__props) {
    let router = useRouter();
    let skipNav = item => {
      router.push({
        path: `${item}`
      });
    };
    let data = reactive({
      title: '全年资料',
      tabsData: [{
        image: require('@/assets/image/tool/love.png'),
        name: '恋人特码',
        path: '/tool/love'
      }, {
        image: require('@/assets/image/tool/card.png'),
        name: '生肖卡牌',
        path: '/tool/sx'
      }, {
        image: require('@/assets/image/tool/moble.png'),
        name: '摇一摇',
        path: '/tool/shake'
      }, {
        image: require('@/assets/image/tool/bags.png'),
        name: '玄机锦囊',
        path: '/tool/bags'
      }, {
        image: require('@/assets/image/tool/lottery.png'),
        name: '幸运摇奖',
        path: '/tool/rotation'
      }, {
        image: require('@/assets/image/tool/turntable.png'),
        name: '波肖转盘',
        path: '/tool/turntable'
      }, {
        image: require('@/assets/image/tool/tiems.png'),
        name: '揽珠日期',
        path: '/tool/lottery/data'
      }, {
        image: require('@/assets/image/tool/test.png'),
        name: '天机测算',
        path: '/tool/secret'
      }, {
        image: require('@/assets/image/tool/hl.png'),
        name: '老黄历',
        path: '/tool/calendar'
      }, {
        image: require('@/assets/image/tool/tiems.png'),
        name: '挑码助手',
        path: '/tool/assistant'
      }]
    });
    let {
      title,
      tabsData
    } = toRefs(data);
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "工具宝箱"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabsData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(skipNav)(item.path)
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
          src: item.image,
          alt: ""
        }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1)], 8, _hoisted_2);
      }), 128))])])], 64);
    };
  }
};