import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, isRef as _isRef, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from '../../../assets/image/more/jia.png';
import _imports_1 from '../../../assets/image/more/deng.png';
import _imports_2 from '../../../assets/image/more/nan.png';
import _imports_3 from '../../../assets/image/more/lv.png';
import _imports_4 from '../../../assets/image/more/tiemsgreed.png';
const _hoisted_1 = {
  class: "tk-main-love"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "tk-select-age"
};
const _hoisted_4 = {
  class: "tk-select-ul"
};
const _hoisted_5 = {
  class: "tk-birthday flex-between"
};
const _hoisted_6 = {
  class: "tk-birthady-me"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1,
  class: "tiems"
};
const _hoisted_9 = {
  class: "birthday"
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  key: 1,
  class: "tiems"
};
const _hoisted_12 = {
  class: "botton"
};
const _hoisted_13 = {
  key: 1,
  class: "tk-match-content"
};
const _hoisted_14 = {
  class: "code"
};
const _hoisted_15 = {
  class: "tk-num"
};
import Dialog from '@/components/mode/message';
import { onBeforeMount } from 'vue';

import { reactive, toRefs } from 'vue';
export default {
  __name: 'love',
  setup(__props) {
    let data = reactive({
      show: false,
      currentDate: ['2021', '01', '01'],
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2040, 10, 1),
      select: 0,
      //判断点击谁的生日方便赋值
      meBirthady: '',
      //我的生日
      herBirthady: '',
      //她的生日
      activeIndex: '',
      //性别
      code: [{
        number: 0,
        style: 'red'
      }, {
        number: 0,
        style: 'red'
      }, {
        number: 0,
        style: 'red'
      }, {
        number: 0,
        style: 'red'
      }, {
        number: 0,
        style: 'red'
      }, {
        number: 0,
        style: 'red'
      }],
      loveCode: true
      // valueFromiOS: new vue({ value : 1})
    });
    let {
      show,
      code,
      currentDate,
      minDate,
      maxDate,
      meBirthady,
      herBirthady,
      activeIndex,
      loveCode
    } = toRefs(data);

    //功能区域

    let selectMe = () => {
      //选择我的时间
      data.select = 0;
      data.show = true;
    };
    let selectHer = () => {
      data.select = 1;
      data.show = true;
    };
    let confirm = value => {
      //选择日期判断谁是她谁是我
      let tiems = value.selectedValues;
      if (data.select) {
        data.herBirthady = tiems[0] + '-' + tiems[1] + '-' + tiems[2];
      } else {
        data.meBirthady = tiems[0] + '-' + tiems[1] + '-' + tiems[2];
      }
      data.show = false;
    };
    let cancel = () => {
      //取消选择日期
      data.show = false;
    };
    let selectAge = index => {
      //选择性别0是男1是女
      data.activeIndex = index;
    };
    let submit = () => {
      if (data.meBirthady == '') {
        Dialog('请选择性别', '#07c160');
      }
      if (data.herBirthady == '') {
        Dialog('请选择他/她的生日', '#07c160');
      } else if (data.meBirthady == '') {
        Dialog('请选择您的生日', '#07c160');
      } else {
        data.loveCode = false;
        let b = [];
        for (let i = 1; i <= 49; i++) {
          b.push(i);
        }
        var arr1 = b.sort(function () {
          return .5 - Math.random();
        });
        var arr2 = arr1.slice(0, 6);
        data.loveCode = false;
        data.code.forEach((element, index) => {
          element.number = arr2[index];
          element.style = colorarray[cons(element.number, numarrays)];
        });
        let datas = {
          value: data.code,
          loveCode: data.loveCode,
          expires: Date.now() + 1000 * 60 * 60 // 1 hour from now
        };
        localStorage.setItem('love', JSON.stringify(datas));
      }
    };
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['red', 'blue', 'green'];
    onBeforeMount(() => {
      if (localStorage.getItem('love') != null) {
        let datas = JSON.parse(localStorage.getItem('love'));
        if (datas && datas.expires > Date.now()) {
          data.loveCode = false;
          data.code = datas.value;
        } else {}
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      const _component_van_date_picker = _resolveComponent("van-date-picker");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "恋人特码"
      }), _createElementVNode("div", _hoisted_1, [_cache[14] || (_cache[14] = _createElementVNode("div", {
        class: "tk-love-description"
      }, [_createElementVNode("div", {
        class: "tk-love-title"
      }, "简介"), _createElementVNode("div", {
        class: "tk-desc"
      }, "请输入您和她/他的生日，计算本期特码，赶紧来试试！"), _createElementVNode("div", {
        class: "tk-prompt-c"
      }, "温馨提示：每天只能进行一次匹配")], -1)), _unref(loveCode) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_cache[13] || (_cache[13] = _createStaticVNode("<div class=\"tk-love-code\" data-v-7ff6cac0><div class=\"tk-code-nan code\" data-v-7ff6cac0><div class=\"tk-code greed\" data-v-7ff6cac0><div class=\"tk-num\" data-v-7ff6cac0>男</div></div></div><div class=\"tk-code-icon\" data-v-7ff6cac0><img src=\"" + _imports_0 + "\" alt=\"\" data-v-7ff6cac0></div><div class=\"k-code-nv code\" data-v-7ff6cac0><div class=\"tk-code red\" data-v-7ff6cac0><div class=\"tk-num\" data-v-7ff6cac0>女</div></div></div><div class=\"tk-code-icon\" data-v-7ff6cac0><img src=\"" + _imports_1 + "\" alt=\"\" data-v-7ff6cac0></div><div class=\"tk-code-wenhao code\" data-v-7ff6cac0><div class=\"tk-code yellow\" data-v-7ff6cac0><div class=\"tk-num\" data-v-7ff6cac0>?</div></div></div></div>", 1)), _createElementVNode("div", _hoisted_3, [_cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "tk-select-title"
      }, " 请选择性别 ", -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: _normalizeClass(_unref(activeIndex) == 0 ? 'tk-age-item active' : 'tk-age-item'),
        onClick: _cache[0] || (_cache[0] = $event => _unref(selectAge)(0))
      }, _cache[6] || (_cache[6] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)]), 2), _createElementVNode("div", {
        class: _normalizeClass(_unref(activeIndex) == 1 ? 'tk-age-item active' : 'tk-age-item'),
        onClick: _cache[1] || (_cache[1] = $event => _unref(selectAge)(1))
      }, _cache[7] || (_cache[7] = [_createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)]), 2)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_cache[10] || (_cache[10] = _createElementVNode("div", {
        class: "title"
      }, "您的生日", -1)), _createElementVNode("div", {
        class: "birthday",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(selectMe) && _unref(selectMe)(...args))
      }, [_cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "birthday-img"
      }, [_createElementVNode("img", {
        class: "imgs",
        src: _imports_4,
        alt: ""
      })], -1)), _unref(meBirthady) == '' ? (_openBlock(), _createElementBlock("span", _hoisted_7, "请选择您的日期")) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(meBirthady)), 1))])]), _createElementVNode("div", {
        class: "tk-birthady-me",
        onClick: _cache[3] || (_cache[3] = (...args) => _unref(selectHer) && _unref(selectHer)(...args))
      }, [_cache[12] || (_cache[12] = _createElementVNode("div", {
        class: "title"
      }, "她的生日", -1)), _createElementVNode("div", _hoisted_9, [_cache[11] || (_cache[11] = _createElementVNode("div", {
        class: "birthday-img"
      }, [_createElementVNode("img", {
        class: "imgs",
        src: _imports_4,
        alt: ""
      })], -1)), _unref(herBirthady) == '' ? (_openBlock(), _createElementBlock("span", _hoisted_10, "请选择您的日期")) : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(herBirthady)), 1))])])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_botton, {
        name: "匹配一下",
        onSubmits: _unref(submit)
      }, null, 8, ["onSubmits"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(code), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", {
          class: _normalizeClass('tk-code ' + item.style)
        }, [_createElementVNode("div", _hoisted_15, _toDisplayString(item.number), 1)], 2)])]);
      }), 128))])]))]), _createVNode(_component_van_popup, {
        show: _unref(show),
        "onUpdate:show": _cache[5] || (_cache[5] = $event => _isRef(show) ? show.value = $event : show = $event),
        round: "",
        position: "bottom",
        style: {
          height: '40%'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_van_date_picker, {
          modelValue: _unref(currentDate),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(currentDate) ? currentDate.value = $event : currentDate = $event),
          title: "选择年月份",
          "min-date": _unref(minDate),
          "max-date": _unref(maxDate),
          onConfirm: _unref(confirm),
          onCancel: _unref(cancel)
        }, null, 8, ["modelValue", "min-date", "max-date", "onConfirm", "onCancel"])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};