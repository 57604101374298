import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "tk-main-account"
};
const _hoisted_2 = {
  class: "tk-mian-account-cont"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "platform"
};
const _hoisted_5 = {
  class: "info"
};
const _hoisted_6 = {
  class: "platform"
};
const _hoisted_7 = {
  class: "info"
};
const _hoisted_8 = {
  class: "platform"
};
const _hoisted_9 = {
  class: "info"
};
const _hoisted_10 = {
  class: "tk-bottom"
};
import { platUserPlat } from '@/utils/https/me';
import { onBeforeMount, reactive } from 'vue';
import Dialog from '@/components/mode/message.js';

import { useRouter } from 'vue-router';
export default {
  __name: 'account',
  setup(__props) {
    let data = reactive([]);
    onBeforeMount(() => {
      window.scrollTo(0, 0);
      platUserPlats();
    });
    let platUserPlats = () => {
      platUserPlat().then(res => {
        if (res.data.status == 40000) {
          // Dialog(res.data.message);
        } else {
          let datas = res.data.data;
          data.push(...datas);
        }
      });
    };
    let router = useRouter();
    let handleTab = () => {
      router.push({
        path: '/me/setup/BindingAccount'
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "已绑定平台账号"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_unref(data).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
        key: 0
      })) : (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", _hoisted_4, [_cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "name"
        }, "平台：", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(item.plats.name), 1)]), _createElementVNode("div", _hoisted_6, [_cache[1] || (_cache[1] = _createElementVNode("span", {
          class: "name"
        }, "账号：", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString(item.plat_user_account), 1)]), _createElementVNode("div", _hoisted_8, [_cache[2] || (_cache[2] = _createElementVNode("span", {
          class: "name"
        }, "姓名：", -1)), _createElementVNode("span", _hoisted_9, _toDisplayString(item.plat_user_name), 1)])]);
      }), 128))]))]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_botton, {
        name: "添加彩票平台",
        onClick: _unref(handleTab)
      }, null, 8, ["onClick"])])])], 64);
    };
  }
};