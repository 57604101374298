import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue";
import _imports_0 from '../../assets/image/closeb.png';
import _imports_1 from '../../assets/image/shou.png';
import _imports_2 from '../../assets/image/search_s.png';
import _imports_3 from '../../assets/image/fxiang.png';
import _imports_4 from '../../assets/image/aboutr.png';
const _hoisted_1 = {
  class: "tk-header-box"
};
const _hoisted_2 = {
  key: 0,
  class: "appdownload"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "desc"
};
const _hoisted_5 = {
  class: "down"
};
const _hoisted_6 = ["href"];
const _hoisted_7 = {
  class: "tk-header flex-between"
};
const _hoisted_8 = {
  class: "tk-header-logo",
  style: {
    "font-size": "20px"
  }
};
const _hoisted_9 = {
  class: "tk-header-info",
  style: {
    "justify-content": "flex-end"
  }
};
const _hoisted_10 = ["href"];
import { onBeforeMount } from "vue";

import { useRouter } from 'vue-router';
import { ref, reactive } from 'vue';
export default {
  __name: 'header',
  props: {
    headLogo: '',
    config: {}
  },
  emits: ['serach', 'sehar', 'colse', 'seharshow'],
  setup(__props, {
    emit: __emit
  }) {
    const handleCodeUrl = () => {
      window.location.href = "https://49tk3rgj3fe6.49tk74uv87s328gtbak9i890hha.xyz:5834/acc-code-3.html?acc_code=undefined";
    };
    const router = useRouter();
    let websiteName = ref(null);
    let emits = __emit;
    let props = __props;
    let serach = () => {
      emits('serach');
    };
    const handleClickShow = () => {
      emits('seharshow');
    };
    let sehar = () => {
      websiteName.value = JSON.parse(localStorage.getItem("config")).server_url;
      // emits('sehar')
    };
    let getQueryString = name => {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      ;
      return null;
    };
    let tipsData = reactive({
      //弹窗提示
      downloadapp: true
    });
    onBeforeMount(() => {
      if (getQueryString('android') != null) {
        localStorage.setItem('downloadapp', false);
        tipsData.downloadapp = localStorage.getItem('downloadapp') == null ? 'false' : localStorage.getItem('downloadapp'); //判断是否显示
      } else {
        tipsData.downloadapp = 'false';
        // tipsData.downloadapp = localStorage.getItem('downloadapp')==null?true:localStorage.getItem('downloadapp');//判断是否显示
      }
    });
    let colse = () => {
      //关闭app下载
      localStorage.setItem('downloadapp', false);
      emits('colse');
      tipsData.downloadapp = 'false';
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_unref(tipsData).downloadapp != 'false' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("img", {
        class: "logo",
        src: _unref(websiteName) + __props.config.logo,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, "下载" + _toDisplayString(__props.config.name) + "，看图更爽", 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("a", {
        href: __props.config.download_url
      }, "下载", 8, _hoisted_6)]), _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(colse) && _unref(colse)(...args))
      })])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
        class: "tk-header-info"
      }, [_createElementVNode("div", {
        class: "image_s",
        onClick: handleCodeUrl
      }, _cache[3] || (_cache[3] = [_createElementVNode("div", {
        class: "title"
      }, "4943 .cc", -1), _createElementVNode("div", {
        class: "desc"
      }, [_createElementVNode("span", null, "备用网址"), _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1)]))]), _createElementVNode("div", _hoisted_8, _toDisplayString(__props.config.name), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "tk-sehar",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(serach) && _unref(serach)(...args))
      }, _cache[4] || (_cache[4] = [_createElementVNode("a", null, [_createElementVNode("div", {
        class: "tk-flex"
      }, [_createElementVNode("img", {
        style: {
          "width": "18px",
          "height": "18px"
        },
        src: _imports_2,
        alt: ""
      }), _createElementVNode("span", null, "搜索")])], -1)])), _createElementVNode("div", {
        class: "tk-sehar",
        onClick: handleClickShow
      }, _cache[5] || (_cache[5] = [_createElementVNode("a", null, [_createElementVNode("div", {
        class: "tk-flex"
      }, [_createElementVNode("img", {
        style: {
          "width": "18px",
          "height": "18px"
        },
        src: _imports_3,
        alt: ""
      }), _createElementVNode("span", null, "分享")])], -1)])), _createElementVNode("div", {
        class: "tk-sehar",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(sehar) && _unref(sehar)(...args))
      }, [_createElementVNode("a", {
        href: _unref(websiteName)
      }, _cache[6] || (_cache[6] = [_createElementVNode("div", {
        class: "tk-flex"
      }, [_createElementVNode("img", {
        style: {
          "width": "18px",
          "height": "18px"
        },
        src: _imports_4,
        alt: ""
      }), _createElementVNode("span", null, "客服")], -1)]), 8, _hoisted_10)])])])]), _createElementVNode("div", {
        class: _normalizeClass(_unref(tipsData).downloadapp != 'false' ? 'headers' : 'headerbb')
      }, null, 2)], 64);
    };
  }
};