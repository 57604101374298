import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/more.png';
import _imports_1 from '../../../assets/image/tisp.png';
import _imports_2 from '../../../assets/image/error.png';
const _hoisted_1 = {
  class: "tk-main-details"
};
const _hoisted_2 = {
  class: "tk-swiper",
  style: {
    "margin-bottom": "10px"
  }
};
const _hoisted_3 = {
  class: "tk-details-content"
};
const _hoisted_4 = {
  class: "tk-details-info"
};
const _hoisted_5 = {
  class: "tk-picture"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "tk-user"
};
const _hoisted_8 = {
  class: "tk-name"
};
const _hoisted_9 = {
  class: "tk-tiems"
};
const _hoisted_10 = {
  class: "tk-info-like"
};
const _hoisted_11 = {
  class: "num"
};
const _hoisted_12 = {
  class: "num"
};
const _hoisted_13 = {
  class: "num"
};
const _hoisted_14 = {
  class: "num"
};
const _hoisted_15 = {
  class: "tk-info-button"
};
const _hoisted_16 = {
  class: "tk-l",
  style: {
    "width": "100%"
  }
};
const _hoisted_17 = {
  class: "tk-tips"
};
const _hoisted_18 = {
  class: "tk-read"
};
const _hoisted_19 = {
  class: "name"
};
const _hoisted_20 = {
  class: "tk-deails-title"
};
const _hoisted_21 = ["innerHTML"];
const _hoisted_22 = {
  class: "tk-deails-img"
};
import lottery from '@/components/page/lottery.vue';
import comment from '@/components/page/comment.vue';
import comment_list from '@/components/page/comment_list.vue';
import more from '@/components/page/more.vue';
import share from '@/components/page/share.vue';
import Dialog from '@/components/mode/message';
import { showImagePreview } from 'vant'; //图片预览
import { useStore } from 'vuex';
import { discussDetail, discussFollow } from '@/utils/https/master';
import { getUserIndex } from '@/utils/https/materials';
import { setFocus } from '@/utils/https/https';

import { reactive, toRefs, onBeforeMount, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'details',
  setup(__props) {
    let preview = (img, index) => {
      //预览图片
      let arr = [];
      img.forEach(item => {
        arr.push(item.img_url);
      });
      showImagePreview({
        images: arr,
        startPosition: index
      });
    };
    let data = reactive({
      show: false,
      id: '',
      //文章id
      articleInfo: {},
      getUserInfo: {},
      guanzhuicno: true,
      //关注
      guanzhuicolor: '#FF1A00',
      //关注
      guanzhuname: '关注',
      shows: false,
      //显示分享页面,
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {
        name: '图库中心',
        login: require('../../../assets/image/logo.png')
      } : JSON.parse(localStorage.getItem('config')),
      adList: '',
      acitveIndexLottery: 5
    });
    let {
      show,
      shows,
      articleInfo,
      user,
      guanzhuicno,
      guanzhuicolor,
      guanzhuname,
      getUserInfo,
      id,
      websiteName,
      acitveIndexLottery
    } = toRefs(data);
    let router = useRouter();
    let route = useRoute();
    const $store = useStore(); //获取vuex
    onBeforeMount(() => {
      data.adList = JSON.parse(localStorage.getItem('avList')).adlist;
      window.scrollTo(0, 0);
      data.id = route.query.id;
      infoData(data.id);
      if (route.query.lottery == undefined) {
        data.acitveIndexLottery = localStorage.getItem('lotteryType');
      } else {
        data.acitveIndexLottery = route.query.lottery;
      }
    });
    //数据请求区域
    // import {discoveryDetail,discoveryFollow} from '@/utils/https/discover'
    let infoData = id => {
      discussDetail({
        id: id
      }).then(res => {
        //帖子详情
        if (res.status != '400') {
          let datas = res.data.data;
          datas.isFollow = datas.follow;
          datas.isCollect = datas.collect;
          data.articleInfo = datas;
          getUser(datas.user_id);
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let getUser = id => {
      //用户数据
      getUserIndex({
        id: id
      }).then(res => {
        if (res.status != '400') {
          let datas = res.data.data;
          data.getUserInfo = datas;
          if (!datas.isFocuson) {
            data.guanzhuicolor = '#FF1A00';
            data.guanzhuname = '关注';
          } else {
            data.guanzhuicolor = '#A1B1C8';
            data.guanzhuname = '已关注';
            data.guanzhuicno = false;
          }
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let focus = id => {
      //关注
      setFocus({
        id: id
      }).then(res => {
        if (res.status != '400') {} else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let onefollow = id => {
      //点赞
      discussFollow({
        id: id
      }).then(res => {
        if (res.data.status == '40000') {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    //功能区域
    let majoFun = index => {
      //comment组件的信息数据
      if (index == 0) {
        //点赞
        if (!data.articleInfo.isFollow) {
          //判断是否点赞
          data.articleInfo.isFollow = 1;
          Dialog('点赞成功', '#07c160');
          data.articleInfo.thumbUpCount += 1;
          data.getUserInfo.likes += 1;
        } else {
          data.articleInfo.isFollow = 0;
          data.articleInfo.thumbUpCount -= 1;
          data.getUserInfo.likes -= 1;
          Dialog('已取消点赞', '#07c160');
        }
        onefollow(data.articleInfo.id);
      } else if (index == 2) {
        data.shows = true;
      }
    };
    let guanzhu = () => {
      //关注
      focus(data.articleInfo.user_id);
      if (data.guanzhuname == '关注') {
        data.guanzhuicno = false;
        data.guanzhuicolor = '#A1B1C8';
        data.guanzhuname = '已关注';
        data.getUserInfo.follows += 1;
        Dialog('已关注', '#07c160');
      } else {
        data.guanzhuicno = true;
        data.guanzhuicolor = '#FF1A00';
        data.getUserInfo.follows -= 1;
        data.guanzhuname = '关注';
        Dialog('已取消关注', '#07c160');
      }
    };
    let lahe = () => {
      //跳转历史记录
      router.push({
        path: `/materials/list/history`,
        query: {
          id: data.articleInfo.id,
          title: data.getUserInfo.nickname,
          userId: data.articleInfo.user_id
        }
      });
    };
    let majorMore = () => {
      //显示更多
      data.show = true;
    };
    let funcMore = index => {
      //更多弹窗里面的信息 0 是用户主页，1是历史记录  2是举报
      if (index == '') {
        router.push({
          path: `/materials/list/history`,
          query: {
            id: data.articleInfo.id,
            title: data.getUserInfo.nickname,
            userId: data.articleInfo.user_id
          }
        });
      } else if (index == 0) {
        router.push({
          path: '/userInfo'
        });
      } else if (index == 1) {
        router.push({
          path: '/user/report',
          query: {
            typeId: 9,
            id: data.articleInfo.id,
            corpusTypeId: ''
          }
        });
      }
    };
    let majoruser = id => {
      //跳转用户中心
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    let contentbox = computed(() => item => {
      //替换
      if (item != undefined) {
        return item.replace(/\n/g, "<br>");
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_swipers = _resolveComponent("swipers");
      const _component_botton = _resolveComponent("botton");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_advertisement = _resolveComponent("advertisement");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(articleInfo).title
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(majorMore) && _unref(majorMore)(...args))
        }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_swipers, {
        swiperImg: _unref(data).adList
      }, null, 8, ["swiperImg"])]), _createVNode(lottery, {
        lottery: _unref(acitveIndexLottery)
      }, null, 8, ["lottery"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "tk-info-head",
        onClick: _cache[1] || (_cache[1] = $event => _unref(majoruser)(_unref(getUserInfo).id))
      }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
        src: _unref(getUserInfo).avatar == '' ? _ctx.$httpsUrl + _unref(websiteName).logo : _ctx.$httpsUrl + _unref(getUserInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(getUserInfo).nickname), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(articleInfo).created_at), 1)])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("ul", null, [_createElementVNode("li", null, [_createElementVNode("span", _hoisted_11, _toDisplayString(_unref(getUserInfo).likes), 1), _cache[5] || (_cache[5] = _createElementVNode("span", {
        class: "name"
      }, "获赞", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_12, _toDisplayString(_unref(getUserInfo).fans), 1), _cache[6] || (_cache[6] = _createElementVNode("span", {
        class: "name"
      }, "粉丝", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_13, _toDisplayString(_unref(getUserInfo).follows), 1), _cache[7] || (_cache[7] = _createElementVNode("span", {
        class: "name"
      }, "关注", -1))]), _createElementVNode("li", null, [_createElementVNode("span", _hoisted_14, _toDisplayString(_unref(getUserInfo).growth_score), 1), _cache[8] || (_cache[8] = _createElementVNode("span", {
        class: "name"
      }, "成长值", -1))]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_botton, {
        onSubmits: _unref(guanzhu),
        name: _unref(guanzhuname),
        bgcolor: _unref(guanzhuicolor),
        guanzhu: _unref(guanzhuicno)
      }, null, 8, ["onSubmits", "name", "bgcolor", "guanzhu"])])])])]), _createElementVNode("div", _hoisted_17, [_cache[10] || (_cache[10] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_18, [_createElementVNode("p", _hoisted_19, _toDisplayString(_unref(articleInfo).views), 1), _cache[9] || (_cache[9] = _createElementVNode("p", {
        class: "name"
      }, "阅读数", -1))])])]), _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(articleInfo).title), 1), _createElementVNode("div", {
        class: "tk-deails-content",
        innerHTML: _unref(contentbox)(_unref(articleInfo).content),
        style: _normalizeStyle({
          'color': _unref(articleInfo).word_color
        })
      }, null, 12, _hoisted_21), _createElementVNode("div", _hoisted_22, [_createVNode(_component_van_swipe, {
        autoplay: 0
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(articleInfo).images, (image, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
              src: image.img_url,
              fit: "cover",
              onClick: $event => _unref(preview)(_unref(articleInfo).images, index)
            }, {
              error: _withCtx(() => _cache[11] || (_cache[11] = [_createElementVNode("img", {
                src: _imports_2,
                alt: ""
              }, null, -1)])),
              _: 2
            }, 1032, ["src", "onClick"])), [[_directive_lazy, image.img_url]])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })])])]), _createVNode(_component_advertisement), _createVNode(comment_list, {
        type: "9",
        id: _unref(id)
      }, null, 8, ["id"]), _createVNode(comment, {
        dataInfo: _unref(articleInfo),
        typeId: "9",
        onMajoFun: _unref(majoFun),
        type: "2",
        userInfo: _unref(getUserInfo)
      }, null, 8, ["dataInfo", "onMajoFun", "userInfo"]), _createVNode(more, {
        show: _unref(show),
        "onUpdate:show": _cache[2] || (_cache[2] = $event => _isRef(show) ? show.value = $event : show = $event),
        onMajorMore: _unref(funcMore)
      }, null, 8, ["show", "onMajorMore"]), _createVNode(share, {
        shows: _unref(shows),
        "onUpdate:shows": _cache[3] || (_cache[3] = $event => _isRef(shows) ? shows.value = $event : shows = $event)
      }, null, 8, ["shows"])], 64);
    };
  }
};