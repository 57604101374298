import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
import { loadingProps } from "vant";

import { useRouter } from 'vue-router';
import { ref, onBeforeMount } from 'vue';
export default {
  __name: 'liuhe',
  setup(__props) {
    let router = useRouter();
    let url = ref('');
    onBeforeMount(() => {
      let urls = JSON.parse(localStorage.getItem('config')).old_ws_url;
      if (localStorage.getItem('chatToken') == null) {
        router.push({
          path: '/login'
        });
      } else {
        let token = localStorage.getItem('chatToken');
        url.value = `${urls}sp/amzl.html?t=${token}`;
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "聊天"
      }), _createElementVNode("iframe", {
        src: _unref(url),
        frameborder: "0"
      }, null, 8, _hoisted_1)], 64);
    };
  }
};