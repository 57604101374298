import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '../../../assets/image/return.png';
const _hoisted_1 = {
  class: "tk-major-nav"
};
const _hoisted_2 = {
  ref: "majornav"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-link"
};
const _hoisted_5 = {
  class: "tk-major-qi"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "tk-more"
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  key: 1
};
const _hoisted_10 = {
  key: 0,
  class: "propIssue"
};
const _hoisted_11 = {
  class: "tk-main-head-nav",
  ref: "scrollTabs"
};
const _hoisted_12 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_13 = {
  class: "tk-main-materials"
};
const _hoisted_14 = ["onClick"];
import { nextTick, onActivated, onBeforeMount, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

import { reactive, watch } from 'vue';
export default {
  __name: 'year',
  props: {
    issue: {
      type: Array,
      default: ['1', '2', '3']
    },
    onissue: '',
    year: ''
  },
  emits: ['issueFun', 'majoissue', 'majoYear'],
  setup(__props, {
    emit: __emit
  }) {
    let data = reactive({
      dataNav: [],
      left: 0,
      activeIndex: 0,
      propIssue: false
    });
    onActivated(() => {
      nextTick(() => {
        let yearList = JSON.parse(sessionStorage.getItem("yearList"));
        if (yearList != null) {
          yearList.forEach(item => {
            if (item.lotteryType == localStorage.getItem("lotteryType")) {
              data.dataNav = item.years;
            }
          });
        } else {
          data.dataNav = [2024, 2023, 2022, 2021];
        }
        // if(localStorage.getItem("lotteryType") == 3 || localStorage.getItem("lotteryType") == 4) {
        //     data.dataNav = [2024,2023,2022,2021]
        // } else {
        //     data.dataNav = JSON.parse(localStorage.getItem('year'))
        // }
      });
    });
    let {
      activeIndex,
      propIssue
    } = toRefs(data);
    let emits = __emit;
    let props = __props;
    let clientWidth;
    watch(props, (val, oldval) => {
      nextTick(() => {
        if (majorqi.value?.children.length == 0) {
          return false;
        }
        clientWidth = majorqi.value?.children[0].offsetWidth;
        if (props.year == '') {
          data.left = 0;
        } else {
          data.dataNav.forEach((item, index) => {
            if (item == props.year) {
              data.left = 100 / 4 * index;
            }
          });
        }
        data.activeIndex = props.onissue == '' ? props.issue[0].issue : props.onissue;
        props.issue.forEach((item, index) => {
          if (data.activeIndex == item.issue) {
            majorqi.value.scrollLeft = clientWidth * index + 10 * index;
          }
        });
      });
    });
    data.dataNav.forEach((item, index) => {
      //日期联动，通过传过来的日期和取出来的日期对比，获得第几个然后高亮显示
      if (item == props.year) {
        data.left = 100 / data.dataNav.length * index;
      }
    });
    let majorqi = ref(null);
    let {
      dataNav,
      left
    } = toRefs(data);
    let issueFun = (e, datas, index) => {
      //点击期数跑到最前面
      clientWidth = e.target.offsetWidth;
      majorqi.value.scrollLeft = clientWidth * index + 10 * index - clientWidth - 10;
      data.activeIndex = datas.issue;
      issueOne.value = datas.issue;
      data.propIssue = false;
      emits('issueFun', datas);
    };
    const issueOne = ref(0);
    let issueFunTow = (datas, index) => {
      //点击更多里面的
      majorqi.value.scrollLeft = clientWidth * index + 10 * index - clientWidth - 10;
      data.activeIndex = datas.issue;
      issueOne.value = datas.issue;
      data.propIssue = false;
      emits('issueFun', datas);
    };
    let majoissue = () => {
      //显示更能多期数
      data.propIssue = true;
    };
    let returnUp = () => {
      //关闭更多期数
      data.propIssue = false;
    };
    let majoNav = (index, year) => {
      data.left = 100 / data.dataNav.length * index;
      if (props.issue.length == 0) {
        data.activeIndex = 0;
      } else {
        data.activeIndex = props.issue[0].issue;
      }
      emits('majoYear', year);
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataNav), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(majoNav)(index, item)
        }, _toDisplayString(item) + "年", 9, _hoisted_3);
      }), 128))], 512), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "link",
        style: _normalizeStyle({
          'left': _unref(left) + '%'
        })
      }, null, 4)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("ul", {
        ref_key: "majorqi",
        ref: majorqi
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.issue, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(activeIndex) == item.issue ? 'active' : ''),
          key: index,
          onClick: $event => _unref(issueFun)($event, item, index)
        }, "第" + _toDisplayString(item.issue) + "期", 11, _hoisted_6);
      }), 128))], 512), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
        class: "more",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(majoissue) && _unref(majoissue)(...args))
      }, [_createTextVNode(_toDisplayString(__props.onissue) + " ", 1), issueOne.value != 0 ? (_openBlock(), _createElementBlock("span", _hoisted_8, "第" + _toDisplayString(_unref(activeIndex)) + "期", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_9, "请选择")), _createVNode(_component_van_icon, {
        name: "arrow"
      })])])]), _unref(propIssue) ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "期数", -1)), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-head-info"
      }, null, -1))])], 512), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "dc"
      }, null, -1)), _createElementVNode("div", _hoisted_13, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.issue, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(activeIndex) == item.issue ? 'active' : ''),
          onClick: $event => _unref(issueFunTow)(item, index),
          key: index
        }, "第" + _toDisplayString(item.issue) + "期", 11, _hoisted_14);
      }), 128))])])])) : _createCommentVNode("", true)], 64);
    };
  }
};