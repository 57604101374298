import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "tk-button-info",
  style: {
    "margin-top": "50px"
  }
};
const _hoisted_2 = {
  class: "tk-button"
};
import Dialog from '@/components/mode/message';
import router from '@/router';

import { reactive, onBeforeMount, toRefs } from 'vue';
export default {
  __name: 'exitLogin',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let data = reactive({});
    onBeforeMount(() => {});
    let {
      logoSrc,
      appSrc,
      code
    } = toRefs(data);
    let emits = __emit;
    let colse = () => {
      emits('update:show', false);
    };
    let overlayPop = () => {
      emits('update:show', false);
    };
    let copyContent = () => {
      localStorage.removeItem('Authorization');
      localStorage.removeItem('usetInfo');
      router.push({
        path: '/login'
      });
      // location.reload()
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: __props.show,
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createElementVNode("div", {
          class: "tk-main-QEcode",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [_cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "tk-QEcode"
        }, [_createElementVNode("div", {
          class: "tk-QEcode-title"
        }, [_createElementVNode("span", {
          style: {
            "color": "red !important"
          }
        }, "温馨提示")]), _createElementVNode("div", {
          class: "cont",
          style: {
            "margin-top": "20px"
          }
        }, [_createElementVNode("span", null, "您是否要退出登录")])], -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
          class: "tk-button",
          onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createVNode(_component_botton, {
          bgcolor: "#A1B1C8",
          name: "在考虑一下"
        })]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_botton, {
          name: "立即退出",
          onClick: _cache[1] || (_cache[1] = $event => _unref(copyContent)())
        })])])])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};