import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
import _imports_0 from '../../../assets/image/del.png';
const _hoisted_1 = {
  class: "tk-notepad-box"
};
const _hoisted_2 = {
  key: 1,
  class: "tk-notepad"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "tiems"
};
const _hoisted_5 = {
  class: "code"
};
const _hoisted_6 = ["onClick"];
import { onBeforeMount } from "vue";

import { ref } from 'vue';
export default {
  __name: 'notepad',
  setup(__props) {
    let data = ref([]);
    onBeforeMount(() => {
      if (localStorage.getItem('notepad') == null) {} else {
        data.value = JSON.parse(localStorage.getItem('notepad'));
        data.value.forEach(item => {
          item.timestamp = getLocalTime(item.timestamp);
        });
      }
    });
    const handledel = index => {
      data.value.splice(index, 1);
      localStorage.setItem('notepad', JSON.stringify(data.value));
    };
    let getLocalTime = nS => {
      return new Date(parseInt(nS)).toLocaleString().replace(/:\d  {1,2}$/, ' ').replace(/\//g, '-');
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "记事本"
      }), _createElementVNode("div", _hoisted_1, [_unref(data).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "text"
        }, "挑码结果：", -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(item.timestamp), 1)]), _createElementVNode("div", _hoisted_5, _toDisplayString(item.codeString) + "共" + _toDisplayString(item.num) + "码", 1), _createElementVNode("div", {
          class: "del",
          onClick: $event => handledel(index)
        }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]), 8, _hoisted_6)]);
      }), 128))])]))])], 64);
    };
  }
};