import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../assets/redBag/charhome.png';
import _imports_1 from '../../assets/redBag/charopen.png';
import _imports_2 from '../../assets/redBag/charbottom.png';
const _hoisted_1 = {
  class: "redbag"
};
import { onBeforeMount, ref } from "vue";
export default {
  __name: 'charBag',
  emits: ['openRed'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const show = ref(false);
    const redShow = ref(false);
    const emits = __emit;
    const hanldeOpneRad = () => {
      emits('openRed');
    };
    __expose({
      show,
      redShow
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_overlay, {
        show: show.value,
        onClick: _cache[2] || (_cache[2] = $event => show.value = false),
        "z-index": "9999"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", _hoisted_1, [_cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "head"
        }, [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })], -1)), _createElementVNode("div", {
          class: _normalizeClass(redShow.value ? 'open clos' : 'open'),
          onClick: hanldeOpneRad
        }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1)]), 2), _cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "bottom"
        }, [_createElementVNode("img", {
          src: _imports_2,
          alt: ""
        })], -1)), _createVNode(_component_van_icon, {
          onClick: _cache[0] || (_cache[0] = $event => show.value = false),
          class: "cols",
          name: "cross"
        })])])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};