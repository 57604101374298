import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/onreturn.png';
const _hoisted_1 = {
  class: "tk-user-head"
};
const _hoisted_2 = {
  class: "tk-head-nav flex-between"
};
const _hoisted_3 = {
  class: "tk-head-info"
};
const _hoisted_4 = {
  class: "tk-head-money"
};
const _hoisted_5 = {
  class: "userInfo"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "tk-activity-plan"
};
const _hoisted_8 = {
  class: "tk-plan-title flex-between"
};
const _hoisted_9 = {
  class: "tk-title-l"
};
const _hoisted_10 = {
  class: "tk-title-c"
};
const _hoisted_11 = {
  class: "tk-title-l"
};
const _hoisted_12 = {
  class: "tk-plan-link"
};
const _hoisted_13 = {
  class: "tk-plan-title flex-between"
};
const _hoisted_14 = {
  class: "tk-desc"
};
const _hoisted_15 = {
  class: "tk-desc"
};
const _hoisted_16 = {
  class: "tk-grade-box"
};
const _hoisted_17 = {
  class: "tk-grade-list"
};
const _hoisted_18 = ["src"];
const _hoisted_19 = {
  style: {
    "text-align": "right"
  }
};
import genre from '@/components/page/genre.vue';
import { getLevel } from '@/utils/https/me';
import { onBeforeMount } from 'vue';

import { ref, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'grade',
  setup(__props) {
    const activeNames = ref(['1']);
    let data = reactive({
      clockIn: [
        // {name:'新手上路',img:require('@/assets/image/grade/grade1.png'),num:'0'},
        // {name:'六合游民',img:require('@/assets/image/grade/grade2.png'),num:'50'},
        // {name:'六合游侠',img:require('@/assets/image/grade/grade3.png'),num:'150'},
        // {name:'普通彩民',img:require('@/assets/image/grade/grade4.png'),num:'450'},
        // {name:'专业彩民',img:require('@/assets/image/grade/grade5.png'),num:'1350'},
        // {name:'彩民专家',img:require('@/assets/image/grade/grade6.png'),num:'4050'},
        // {name:'彩民精英',img:require('@/assets/image/grade/grade7.png'),num:'12150'},
        // {name:'彩民大师',img:require('@/assets/image/grade/grade8.png'),num:'36450'},
        // {name:'彩民至尊',img:require('@/assets/image/grade/grade9.png'),num:'46450'},
      ],
      genreData: [{
        name: '今日任务',
        id: 0
      }, {
        name: '新手任务',
        id: 1
      }, {
        name: '日常任务',
        id: 2
      }],
      grade: {
        Nextlevel_scores: 0,
        growth_score: 0
      },
      userInfo: JSON.parse(localStorage.getItem('usetInfo')) == null ? {} : JSON.parse(localStorage.getItem('usetInfo')),
      ios: false
    });
    let {
      clockIn,
      genreData,
      userInfo,
      https,
      grade
    } = toRefs(data);
    const router = useRouter();
    onBeforeMount(() => {
      window.scrollTo(0, 0);
      if (localStorage.getItem('Authorization') == null && getQueryString('token') == null) {
        router.push({
          path: '/login'
        });
      }
      getLevels();
      if (getQueryString('token') != null) {
        localStorage.setItem('Authorization', getQueryString('token_type') + ' ' + getQueryString('token'));
        data.userInfo.avatar = getQueryString('avatar');
        data.userInfo.nickname = getQueryString('nickname');
        data.ios = true;
      }
    });
    let getQueryString = name => {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      ;
      return null;
    };
    //接口请求

    let getLevels = () => {
      getLevel().then(res => {
        if (res == undefined) return false;
        let datas = res.data.data[1];
        data.grade = res.data.data[0];
        datas.forEach((item, index) => {
          data.clockIn.push(item);
          if (item.level_name == data.grade.level_name) {
            data.grade.Nextlevel_name = datas[index + 1].level_name;
            data.grade.Nextlevel_scores = datas[index + 1].scores;
          }
        });
      });
    };
    //功能区域

    let integral = () => {
      router.push({
        path: '/me/grade/integral'
      });
    };
    let returnUp = () => {
      if (data.ios) {
        callNativeApp('back');
      }
      router.back();
    };
    let callNativeApp = back => {
      try {
        // 從JS傳送資訊到App裡設定的callbackHandler。
        webkit.messageHandlers.callbackHandler.postMessage(back);
      } catch (err) {
        // 如果找不到定義的callbackHandler則回傳錯誤訊息。
      }
    };
    return (_ctx, _cache) => {
      const _component_van_collapse_item = _resolveComponent("van-collapse-item");
      const _component_van_collapse = _resolveComponent("van-collapse");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-head-return",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returnUp) && _unref(returnUp)(...args))
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-head-title"
      }, "用户等级", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(integral) && _unref(integral)(...args))
      }, "我的成长值")])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
        src: _unref(userInfo).avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + _unref(userInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_6), _createElementVNode("p", null, _toDisplayString(_unref(userInfo).nickname), 1)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_unref(grade).level_name), 1), _createElementVNode("div", _hoisted_10, "成长值：" + _toDisplayString(_unref(grade).growth_score), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(grade).Nextlevel_name), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", {
        class: "tk-link",
        style: _normalizeStyle({
          'width': 100 / _unref(grade).Nextlevel_scores * _unref(grade).growth_score + '%'
        })
      }, null, 4)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, "距离下一级还差：" + _toDisplayString(_unref(grade).Nextlevel_scores - _unref(grade).growth_score) + "成长值", 1), _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(grade).Nextlevel_scores), 1)])])]), _createElementVNode("div", _hoisted_16, [_cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "tk-grade-title"
      }, "用户等级列表", -1)), _createElementVNode("div", _hoisted_17, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "tk-grade-list-title"
      }, [_createElementVNode("div", {
        class: "tk-grade-item"
      }, "用户等级"), _createElementVNode("div", {
        class: "tk-grade-item"
      }, "等级勋章"), _createElementVNode("div", {
        class: "tk-grade-item"
      }, "所需成长值")], -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(clockIn), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", null, _toDisplayString(item.level_name), 1), _createElementVNode("img", {
          src: _ctx.$httpsUrl + item.img_url,
          alt: ""
        }, null, 8, _hoisted_18), _createElementVNode("div", _hoisted_19, _toDisplayString(item.scores), 1)]);
      }), 128))])]), _createVNode(_component_van_collapse, {
        modelValue: _unref(activeNames),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _isRef(activeNames) ? activeNames.value = $event : null)
      }, {
        default: _withCtx(() => [_createVNode(_component_van_collapse_item, {
          title: "如何获取成长值？",
          name: "1"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" 1. 用户每次分享好友，好友通过分享注册并填写邀请码，成功拉新一人可获得一级拉新奖励50点成长值，每天5次封顶；"), _createElementVNode("br", null, null, -1), _createTextVNode(" 2. 用户下级会员分享好友，好友通过分享注册并填写邀请码，成功拉新一人可同时获得二级拉新奖励5点成长值，每天5次封顶；"), _createElementVNode("br", null, null, -1), _createTextVNode(" 3. 用户每发帖一次，可获得10点成长值，每天5次封顶； "), _createElementVNode("br", null, null, -1), _createTextVNode(" 4. 用户每回帖一次，可获得1点成长值，每天100次封顶； "), _createElementVNode("br", null, null, -1), _createTextVNode(" 5. 用户每点赞一次，可获得1点成长值，每天100次封顶。"), _createElementVNode("br", null, null, -1)])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]), _cache[8] || (_cache[8] = _createElementVNode("div", {
        style: {
          "height": "20px"
        }
      }, null, -1))], 64);
    };
  }
};