import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import _imports_0 from '../../assets/image/error.png';
import _imports_1 from '../../assets/image/link_r.png';
const _hoisted_1 = {
  class: "tk-platform-head"
};
const _hoisted_2 = {
  class: "navcol"
};
const _hoisted_3 = {
  class: "discover_b"
};
const _hoisted_4 = {
  key: 0,
  class: "lottery_type"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 1,
  class: "tk-platform-box"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "img"
};
const _hoisted_9 = {
  class: "name"
};
const _hoisted_10 = {
  key: 2,
  class: "tk-platform-box"
};
const _hoisted_11 = {
  class: "withoutImgUrl"
};
const _hoisted_12 = ["onClick"];
const _hoisted_13 = {
  class: "name"
};
const _hoisted_14 = {
  key: 3,
  class: "tk-danbao"
};
const _hoisted_15 = {
  class: "img"
};
const _hoisted_16 = ["onClick"];
const _hoisted_17 = {
  class: "text"
};
import { treasure } from '@/utils/https/https';

import { reactive, toRefs, onBeforeMount } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    let data = reactive({
      platforms: [],
      weburl: [],
      withoutImg: [],
      activeIndex: 4,
      acitveIndexa: 2
    });
    let tkHomeTab = reactive([{
      icon: require('../../assets/image/major/provice/2031.png'),
      name: '新澳彩',
      id: '2',
      selectIcon: require('../../assets/image/major/provice/2015.png'),
      color: '#07c160'
    }, {
      icon: require('../../assets/image/major/provice/2017.png'),
      name: '港彩',
      id: '1',
      selectIcon: require('../../assets/image/major/provice/2028.png'),
      color: '#c10707'
    }, {
      icon: require('../../assets/image/major/provice/2031.png'),
      name: '快乐八',
      id: '6',
      selectIcon: require('../../assets/image/major/provice/2015.png'),
      color: '#07c160'
    }
    // {icon:require('../../assets/image/major/provice/2018.png'),name:'台彩',id:'3',selectIcon:require('../../assets/image/major/provice/2029.png'),color:'#0755c1'},
    //{icon:require('../../assets/image/major/provice/2032.png'),name:'新彩',id:'4',selectIcon:require('../../assets/image/major/provice/2019.png'),color:'#b907c1'},
    ]);
    let {
      platforms,
      weburl,
      activeIndex,
      acitveIndexa,
      withoutImg
    } = toRefs(data);
    onBeforeMount(() => {
      treasures(4, '', data.acitveIndexa);
    });
    //接口请求区域
    let treasures = (type, keyword, lotteryType) => {
      let treasures = JSON.parse(sessionStorage.getItem(`platform_${type}_${lotteryType}`));
      if (treasures == null || keyword != '') {
        data.weburl.length = 0;
        data.withoutImg.length = 0;
        data.platforms.length = 0;
        treasure({
          type,
          lotteryType,
          keyword
        }).then(res => {
          let datas = res.data.data;
          if (res.status == 200) {
            if (type == 4) {
              data.weburl = datas.haveImg;
              data.withoutImg = datas.withoutImg;
            } else {
              data.platforms = datas;
            }
            if (keyword == '') {
              sessionStorage.setItem(`platform_${type}_${lotteryType}`, JSON.stringify(datas));
            }
          }
        });
      } else {
        if (type == 4) {
          data.weburl = treasures.haveImg;
          data.withoutImg = treasures.withoutImg;
        } else {
          data.platforms = treasures;
        }
      }
    };
    let handleActive = item => {
      data.acitveIndexa = item.id;
      treasures(4, '', data.acitveIndexa);
    };
    let onSubmit = e => {
      treasures(data.activeIndex, e, data.acitveIndexa);
    };

    //功能区域
    let principalTans = index => {
      if (index == 5) {
        treasures(index, '', '');
      } else {
        treasures(index, '', '');
      }
      data.activeIndex = index;
    };
    const linkTo = item => {
      let device = JSON.parse(localStorage.getItem("device"));
      if (device && device.system) {
        Android.openWithBrowser(item);
      } else {
        window.location.href = item;
      }
    };
    return (_ctx, _cache) => {
      const _component_search = _resolveComponent("search");
      const _component_van_image = _resolveComponent("van-image");
      const _directive_lazy = _resolveDirective("lazy");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = $event => _unref(principalTans)(4)),
        class: _normalizeClass(_unref(activeIndex) == 4 ? 'item active' : 'item')
      }, "网站大全", 2), _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = $event => _unref(principalTans)(5)),
        class: _normalizeClass(_unref(activeIndex) == 5 ? 'item active' : 'item')
      }, "担保平台", 2)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_search, {
        returns: false,
        onOnSubmit: _unref(onSubmit),
        placeholder: "请输入您想要的内容..."
      }, null, 8, ["onOnSubmit"])])]), _unref(activeIndex) == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tkHomeTab), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(_unref(acitveIndexa) == item.id ? 'active' : ''),
          key: index,
          onClick: $event => _unref(handleActive)(item)
        }, _toDisplayString(item.name), 11, _hoisted_5);
      }), 128))])])) : _createCommentVNode("", true), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "h"
      }, null, -1)), _unref(activeIndex) == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(weburl), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => linkTo(item.ad_url)
        }, [_createElementVNode("div", _hoisted_8, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
          src: item.ad_image
        }, {
          error: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)])),
          _: 2
        }, 1032, ["src"])), [[_directive_lazy, item.ad_image]])]), _createElementVNode("div", _hoisted_9, _toDisplayString(item.title), 1)], 8, _hoisted_7);
      }), 128))])])) : _createCommentVNode("", true), _unref(activeIndex) == 4 ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("ul", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(withoutImg), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: "withoutImg",
          key: index,
          onClick: $event => linkTo(item.ad_url)
        }, [_createElementVNode("div", _hoisted_13, _toDisplayString(item.title), 1)], 8, _hoisted_12);
      }), 128))])])) : (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(platforms), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", _hoisted_15, [_withDirectives((_openBlock(), _createBlock(_component_van_image, {
          src: item.ad_image
        }, {
          error: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)])),
          _: 2
        }, 1032, ["src"])), [[_directive_lazy, item.ad_image]])]), _createElementVNode("div", {
          class: "name",
          onClick: $event => linkTo(item.ad_url)
        }, [_createElementVNode("div", _hoisted_17, _toDisplayString(item.title), 1), _cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "link"
        }, "立即访问", -1)), _cache[5] || (_cache[5] = _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1))], 8, _hoisted_16)]);
      }), 128))])]))], 64);
    };
  }
};