import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/sright.png';
const _hoisted_1 = {
  class: "tk-main-setup"
};
const _hoisted_2 = {
  class: "tk-setup-head"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "updata"
};
const _hoisted_5 = {
  class: "tk-setup-info"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "setup-data"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "setup-tips"
};
const _hoisted_11 = {
  key: 0
};
const _hoisted_12 = {
  key: 1
};
const _hoisted_13 = {
  key: 2
};
const _hoisted_14 = {
  key: 3
};
const _hoisted_15 = {
  key: 4
};
const _hoisted_16 = {
  class: "tk-botton"
};
import exitUserName from '@/components/me/exitUserName.vue';
import exitLogin from '@/components/me/exitLogin.vue';
import { onBeforeMount, toRefs } from 'vue';
import uploadImage from '@/components/uploadImage.vue';
import Dialog from '@/components/mode/message';
import { editUserInfo } from '@/utils/https/me';
import { uploadFile } from '@/utils/https/https';
import router from '@/router';

import { reactive } from 'vue';
//接口请求

export default {
  __name: 'setup',
  setup(__props) {
    let data = reactive({
      dataList: [{
        name: '用户账号',
        image: require('../../../assets/image/user.png'),
        path: '111'
      }, {
        name: '修改昵称',
        image: require('../../../assets/image/me/info/xgnc.png'),
        path: ''
      }, {
        name: '修改密码',
        image: require('../../../assets/image/me/info/xgmm.png'),
        path: '/me/setup/password'
      }, {
        name: '资金密码',
        image: require('../../../assets/image/me/info/xgmm.png'),
        path: '/me/setup/money/password'
      }, {
        name: '已绑定平台账号',
        image: require('../../../assets/image/me/info/bdptzh.png'),
        path: '/me/setup/account'
      }, {
        name: '意见反馈',
        image: require('../../../assets/image/me/info/yjfk.png'),
        path: '/me/setup/feedback/idea'
      }, {
        name: '关于我们',
        image: require('../../../assets/image/me/info/gywm.png'),
        path: '/me/setup/about'
      }, {
        name: '手机号码',
        image: require('../../../assets/image/me/info/yjfk.png'),
        path: '/me/mobile'
      }],
      show: false,
      fileList: [],
      userInfo: {},
      loginShow: false
    });
    let {
      dataList,
      show,
      fileList,
      userInfo,
      loginShow
    } = toRefs(data);
    onBeforeMount(() => {
      data.userInfo = JSON.parse(localStorage.getItem('usetInfo'));
    });
    let uploadFun = async file => {
      //图片信息
      let res = await uploadFile(file);
      if (res.data.status == 20000) {
        let avatar = res.data.data.url[0];
        data.userInfo.avatar = avatar;
        editUserInfos('', avatar);
      } else {
        Dialog(res.data.message, '#07c160');
      }
    };
    let editUserInfos = (nickname = '', avatar = '') => {
      //修改资料
      editUserInfo({
        nickname: nickname,
        avatar: avatar
      }).then(res => {
        if (res == undefined) return false;
        if (res.status != '400') {
          avatar != '' ? data.userInfo.avatar = avatar : ''; //图片修改成功后给页面展示
          nickname != '' ? data.userInfo.nickname = nickname : ''; //昵称修改成功后给页面展示
          localStorage.setItem('usetInfo', JSON.stringify(data.userInfo));
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    //功能区域

    let exitSubmit = data => {
      editUserInfos(data, '');
    };
    let majorTab = item => {
      if (item.path == '') {
        data.show = true;
      }
      if (item.path == '111' || item.path == '222') {} else if (item.name == '资金密码' && data.userInfo.isset_fund_password != 0) {
        router.push({
          path: '/me/setup/money/password/exit'
        });
      } else if (item.name == '手机号码' && data.userInfo.mobile != '') {} else {
        router.push({
          path: item.path
        });
      }
    };
    let handleLogin = () => {
      //退出登陆
      data.loginShow = true;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "设置"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        src: _unref(userInfo).avatar == '' ? '../../../assets/image/logo.png' : _ctx.$httpsUrl + _unref(userInfo).avatar,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createVNode(uploadImage, {
        onUploadFun: _unref(uploadFun),
        icon: false
      }, null, 8, ["onUploadFun"])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => _unref(majorTab)(item)
        }, [_createElementVNode("div", _hoisted_7, [item.path == '111' ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "imgs",
          src: item.image,
          alt: ""
        }, null, 8, _hoisted_8)) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: item.image,
          alt: ""
        }, null, 8, _hoisted_9)), _createElementVNode("span", null, _toDisplayString(item.name), 1)]), _createElementVNode("div", _hoisted_10, [item.path == '111' ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(userInfo).account_name), 1)) : _createCommentVNode("", true), item.path == '/me/mobile' ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(userInfo).mobile), 1)) : _createCommentVNode("", true), item.path == '' ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(userInfo).nickname), 1)) : _createCommentVNode("", true), item.name == '资金密码' ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(userInfo).isset_fund_password ? '修改' : '添加'), 1)) : _createCommentVNode("", true), item.name == '已绑定平台账号' ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_unref(userInfo).platforms_count), 1)) : _createCommentVNode("", true), _cache[2] || (_cache[2] = _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1))])], 8, _hoisted_6);
      }), 128))])]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_botton, {
        name: "退出登陆",
        onClick: _unref(handleLogin)
      }, null, 8, ["onClick"])])]), _createVNode(exitUserName, {
        show: _unref(show),
        "onUpdate:show": _cache[0] || (_cache[0] = $event => _isRef(show) ? show.value = $event : show = $event),
        nickname: _unref(userInfo).nickname,
        onSubmit: _unref(exitSubmit)
      }, null, 8, ["show", "nickname", "onSubmit"]), _createVNode(exitLogin, {
        show: _unref(loginShow),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(loginShow) ? loginShow.value = $event : loginShow = $event)
      }, null, 8, ["show"])], 64);
    };
  }
};