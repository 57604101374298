import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../assets/image/bg-meilv.png';
const _hoisted_1 = {
  class: "tk-mian"
};
const _hoisted_2 = {
  class: "tk-mian-head flex-between"
};
const _hoisted_3 = {
  class: "tk-mian-head-left"
};
const _hoisted_4 = {
  class: "tk-l-info"
};
const _hoisted_5 = {
  class: "tk-info-url"
};
const _hoisted_6 = {
  class: "tk-mian-from"
};
const _hoisted_7 = {
  class: "tk-from-tab"
};
const _hoisted_8 = {
  class: "flex-between"
};
const _hoisted_9 = {
  class: "tk-tips"
};
import NavBar from '@/components/NavBar.vue';
import { useRoute } from 'vue-router';
import { number_attr } from "@/utils/https/statistics";
import { config } from '@/utils/https/page';
import { ref } from 'vue';

import { useRouter } from 'vue-router';
import { onBeforeMount, reactive, toRefs } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    let tkName = ref('图库中心');
    let url = ref('www.48TK.com');
    let route = useRoute();
    let router = useRouter();
    let returns = () => {
      router.push({
        path: '/index'
      });
    };
    onBeforeMount(() => {
      if (route.path == '/register') {
        //判断路由 是登陆还是注册然后显示动画效果
        data.activeIndex = 2;
      } else {
        data.activeIndex = 1;
      }
      number_attrs();
      if (localStorage.getItem('config') == null) {
        configs();
      } else {
        let datas = JSON.parse(localStorage.getItem('config'));
        tkName.value = datas.name;
        url.value = datas.main_domain;
      }
    });
    let configs = () => {
      config().then(res => {
        //获取公共配置
        if (res == undefined) return false;
        let datas = res.data.data;
        tkName.value = datas.name;
        url.value = datas.main_domain;
        localStorage.setItem('config', JSON.stringify(datas));
      });
    };
    let number_attrs = () => {
      number_attr().then(res => {
        if (res.data.status == '40000') {
          return false;
        }
        let datas = res.data.data;
        localStorage.setItem('number_attr', JSON.stringify(datas));
      });
    };
    let data = reactive({
      activeIndex: 1
    });
    let {
      activeIndex
    } = toRefs(data);
    let tkTabFun = index => {
      data.activeIndex = index;
    };
    let returnFun = () => {
      router.push({
        path: '/index'
      });
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(NavBar, {
        colors: "#fff",
        onReturns: _unref(returns)
      }, null, 8, ["onReturns"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[2] || (_cache[2] = _createElementVNode("p", null, "您好，", -1)), _createElementVNode("p", null, "欢迎来到" + _toDisplayString(_unref(tkName)), 1), _createElementVNode("div", _hoisted_5, "记住网址:" + _toDisplayString(_unref(url)), 1)])]), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-mian-head-right"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1))])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("ul", _hoisted_8, [_createVNode(_component_router_link, {
        to: "/login",
        onClick: _cache[0] || (_cache[0] = $event => _unref(tkTabFun)(1)),
        "active-class": "active"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("用户登陆")])),
        _: 1
      }), _createVNode(_component_router_link, {
        to: "/register",
        onClick: _cache[1] || (_cache[1] = $event => _unref(tkTabFun)(2)),
        "active-class": "active"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("用户注册")])),
        _: 1
      })]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "tips",
        style: _normalizeStyle({
          'left': _unref(activeIndex) == 1 ? '0%' : '50%'
        })
      }, null, 4)])]), _createVNode(_component_router_view)])], 64);
    };
  }
};