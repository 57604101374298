import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
import _imports_0 from '../../../assets/image/date.png';
const _hoisted_1 = {
  class: "tk-main-materials"
};
const _hoisted_2 = ["onClick"];
import search from '@/components/page/search.vue';
import picker from '@/components/picker.vue';
import { listCorpusType } from '@/utils/https/materials';

import { useRouter, useRoute } from 'vue-router';
import { reactive, toRefs, ref, onDeactivated, onActivated } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    // onBeforeMount(()=>{

    //     let lottery = localStorage.getItem('lotteryType');//获取彩种
    //     listCorpus(lottery,'');
    // })
    let data = reactive({
      listCorpusArray: [],
      oldListCorpusArray: [],
      show: false,
      lottery: localStorage.getItem('lotteryType')
    });
    let {
      listCorpusArray,
      columns,
      show
    } = toRefs(data);
    let listCorpus = (lottery, year) => {
      //请求页面数据

      let CorpusArray = JSON.parse(sessionStorage.getItem(`profile_${lottery}_year${year}`));
      if (CorpusArray == null) {
        listCorpusType({
          lotteryType: lottery,
          year: year
        }).then(res => {
          let datas = res.data.data;
          data.listCorpusArray.push(...datas);
          data.oldListCorpusArray.push(...datas);
          sessionStorage.setItem(`profile_${lottery}_year${year}`, JSON.stringify(datas));
        });
      } else {
        data.listCorpusArray.push(...CorpusArray);
        data.oldListCorpusArray.push(...CorpusArray);
      }
    };
    let showYear = () => {
      data.show = !data.show;
    };
    let onSubmit = item => {
      if (item.length == 0) data.listCorpusArray = data.oldListCorpusArray;
      data.listCorpusArray = fuzzyQuery(data.listCorpusArray, item);
    };
    let close = item => {
      if (item.length == 0) data.listCorpusArray = data.oldListCorpusArray;
    };
    let handelChanges;
    let handelChange = item => {
      clearTimeout(handelChanges);
      if (item.length == 0) data.listCorpusArray = data.oldListCorpusArray;
      handelChanges = setTimeout(() => {
        data.listCorpusArray = fuzzyQuery(data.listCorpusArray, item);
      }, 1000);
    };
    /**
    * 使用indexof方法实现模糊查询
    * @param  {Array}  list     进行查询的数组
    * @param  {String} keyWord  查询的关键词
    * @return {Array}           查询的结果
    */
    let fuzzyQuery = (list, keyWord) => {
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i].corpusTypeName.indexOf(keyWord) >= 0) {
          arr.push(list[i]);
        }
      }
      return arr;
    };
    let onConfirm = item => {
      //获取prompt选择的值
      data.listCorpusArray.length = 0;
      listCorpus(data.lottery, item);
    };
    let majorDesc = item => {
      //跳转详情
      router.push({
        path: `materials/list`,
        query: {
          id: item.id,
          title: item.corpusTypeName,
          type: item.lotteryType
        }
      });
    };
    let start = ref(true);
    onDeactivated(() => {
      start.value = false;
      data.lottery = route.query.lottery;
    });
    onActivated(() => {
      let lottery = route.query.lottery; //获取彩种
      if (start.value || lottery != data.lottery) {
        data.listCorpusArray.length = 0;
        data.oldListCorpusArray.length = 0;
        listCorpus(lottery, '');
      }
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_majorBack = _resolveComponent("majorBack");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "资料大全"
      }, {
        tkCont: _withCtx(() => [_createVNode(search, {
          returns: false,
          onOnSubmit: _unref(onSubmit),
          onHandelChange: _unref(handelChange),
          onClose: _unref(close)
        }, null, 8, ["onOnSubmit", "onHandelChange", "onClose"])]),
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(showYear) && _unref(showYear)(...args))
        }, _cache[2] || (_cache[2] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }), _createVNode(picker, {
        show: _unref(show),
        "onUpdate:show": _cache[1] || (_cache[1] = $event => _isRef(show) ? show.value = $event : show = $event),
        onOnConfirm: _unref(onConfirm)
      }, null, 8, ["show", "onOnConfirm"]), _createElementVNode("div", _hoisted_1, [_unref(listCorpusArray).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
        key: 0
      })) : _createCommentVNode("", true), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listCorpusArray), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          onClick: $event => _unref(majorDesc)(item),
          key: index
        }, _toDisplayString(item.corpusTypeName), 9, _hoisted_2);
      }), 128))])]), _createVNode(_component_majorBack)], 64);
    };
  }
};