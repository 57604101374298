import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
import _imports_0 from '../../../assets/image/more/bags.png';
const _hoisted_1 = {
  class: "tk-main-love"
};
const _hoisted_2 = {
  class: "tk-bags-content"
};
const _hoisted_3 = {
  class: "tk-bags-title"
};
const _hoisted_4 = {
  key: 0,
  class: "botton"
};
const _hoisted_5 = {
  key: 1,
  class: "tk-bags-text"
};
import { onBeforeMount, reactive, toRefs } from "vue";
import { mystery } from '@/utils/https/https';
import { useStore } from 'vuex';
export default {
  __name: 'bags',
  setup(__props) {
    const $store = useStore(); //获取vuex
    let mysterys = (year, lotteryType) => {
      mystery({
        year: year,
        lotteryType: lotteryType
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data;
        data.title = datas.title;
        data.content = datas.content;
      });
    };
    let data = reactive({
      show: true,
      lotteryType: JSON.parse(localStorage.getItem('lotteryType')),
      year: JSON.parse(localStorage.getItem('yearone')),
      content: [],
      title: ''
    });
    onBeforeMount(() => {
      data.lotteryType = $store.state.lotteryType;
    });
    let {
      show,
      content,
      title
    } = toRefs(data);
    let submit = () => {
      mysterys(data.year, data.lotteryType);
      data.show = !data.show;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "玄机锦囊"
      }, {
        iocn: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
          class: "icon-tk"
        }, [_createElementVNode("a", {
          href: "/tool/bags/search"
        }, "锦囊记录")], -1)])),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-love-description"
      }, [_createElementVNode("div", {
        class: "tk-love-title"
      }, "简介"), _createElementVNode("div", {
        class: "tk-desc"
      }, "10万六合彩用户都说准的谜题，这一期，您猜出了什么玄机?"), _createElementVNode("div", {
        class: "tk-prompt-c"
      }, "温馨提示：打开锦囊是本期的六合彩的一道谜题，参透谜题将获得本期中奖号码，快来打开您的玄机吧！")], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(title)), 1), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "tk-bags-img"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)), _unref(show) ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_botton, {
        name: "打开锦囊",
        onSubmits: _unref(submit)
      }, null, 8, ["onSubmits"])])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", null, _toDisplayString(_unref(content)[0]), 1), _createElementVNode("p", null, _toDisplayString(_unref(content)[1]), 1)]))])])], 64);
    };
  }
};