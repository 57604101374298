import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "tk-main-report"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-report-title"
};
const _hoisted_5 = {
  class: "tk-title"
};
const _hoisted_6 = {
  class: "tk-tips"
};
const _hoisted_7 = {
  class: "tk-report-cotent"
};
const _hoisted_8 = {
  class: "tiems"
};
import { complaintList } from '@/utils/https/me';
import { reactive, toRefs } from 'vue';

import { useRouter } from 'vue-router';
import { ref } from 'vue';
export default {
  __name: 'report',
  setup(__props) {
    const router = useRouter();
    let data = reactive({
      page: 1,
      complaint: []
    });
    let {
      complaint
    } = toRefs(data);
    let complaints = page => {
      complaintList({
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.complaint.push(...datas);
        }
      });
    };
    let ideaFun = item => {
      router.push({
        path: '/me/report/details',
        query: {
          data: JSON.stringify(item)
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      complaints(data.page);
      data.page++;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "举报记录"
      }, {
        iocn: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", {
          class: "icon-tk"
        }, [_createElementVNode("a", {
          href: "/master"
        }, "我要举报")], -1)])),
        _: 1
      }), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(complaint).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(complaint), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(ideaFun)(item)
          }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[2] || (_cache[2] = _createElementVNode("span", null, "帖子标题：", -1)), _createTextVNode(" " + _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.audit == 0 ? '审核中' : '审核通过'), 1)]), _createElementVNode("div", _hoisted_7, [_cache[3] || (_cache[3] = _createElementVNode("span", {
            class: "text"
          }, "举报理由：", -1)), _createTextVNode(_toDisplayString(item.content), 1), _cache[4] || (_cache[4] = _createElementVNode("span", null, null, -1))]), _createElementVNode("div", _hoisted_8, _toDisplayString(item.created_at), 1)], 8, _hoisted_3);
        }), 128))]))])]),
        _: 1
      }, 8, ["loading", "finished"])], 64);
    };
  }
};