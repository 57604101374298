import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '../../assets/image/icon/hot.png';
import _imports_1 from '../../assets/image/zanon.png';
import _imports_2 from '../../assets/image/zan.png';
import _imports_3 from '../../assets/image/right.png';
import _imports_4 from '../../assets/image/loading.gif';
import _imports_5 from '../../assets/image/bottom.png';
import _imports_6 from '../../assets/image/up.png';
const _hoisted_1 = {
  class: "tk-main-comment-list"
};
const _hoisted_2 = {
  class: "tk-comment-list"
};
const _hoisted_3 = {
  class: "tk-comment-list-hot"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "tk-comment-user"
};
const _hoisted_8 = {
  class: "tk-comment-name"
};
const _hoisted_9 = {
  class: "tk-name"
};
const _hoisted_10 = {
  class: "tk-floor"
};
const _hoisted_11 = {
  class: "tk-tiems"
};
const _hoisted_12 = {
  class: "tk-comment"
};
const _hoisted_13 = ["innerHTML"];
const _hoisted_14 = {
  key: 0,
  class: "tk-comment-img"
};
const _hoisted_15 = ["src", "onClick"];
const _hoisted_16 = {
  class: "tk-comment-info"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = {
  key: 0,
  src: _imports_1,
  alt: ""
};
const _hoisted_19 = {
  key: 1,
  src: _imports_2,
  alt: ""
};
const _hoisted_20 = ["onClick"];
const _hoisted_21 = {
  class: "tk-comment-other"
};
const _hoisted_22 = {
  key: 0
};
const _hoisted_23 = ["onClick"];
const _hoisted_24 = ["src"];
const _hoisted_25 = ["src"];
const _hoisted_26 = {
  class: "tk-comment-user"
};
const _hoisted_27 = {
  class: "tk-comment-name"
};
const _hoisted_28 = {
  class: "tk-name"
};
const _hoisted_29 = ["onClick"];
const _hoisted_30 = {
  key: 0,
  src: _imports_3,
  alt: ""
};
const _hoisted_31 = ["onClick"];
const _hoisted_32 = {
  class: "tk-tiems"
};
const _hoisted_33 = {
  class: "tk-comment"
};
const _hoisted_34 = ["innerHTML"];
const _hoisted_35 = {
  key: 0,
  class: "tk-comment-img"
};
const _hoisted_36 = ["src", "onClick"];
const _hoisted_37 = {
  class: "tk-comment-info"
};
const _hoisted_38 = ["onClick"];
const _hoisted_39 = {
  key: 0,
  class: "loding"
};
const _hoisted_40 = {
  class: "tk-comment-more"
};
const _hoisted_41 = ["onClick"];
const _hoisted_42 = {
  key: 0
};
const _hoisted_43 = {
  key: 1
};
const _hoisted_44 = ["onClick"];
const _hoisted_45 = {
  class: "tk-comment-list"
};
const _hoisted_46 = {
  class: "tk-comment-list-hot"
};
const _hoisted_47 = {
  key: 1
};
const _hoisted_48 = ["onClick"];
const _hoisted_49 = ["src"];
const _hoisted_50 = ["src"];
const _hoisted_51 = {
  class: "tk-comment-user"
};
const _hoisted_52 = {
  class: "tk-comment-name"
};
const _hoisted_53 = {
  class: "tk-name"
};
const _hoisted_54 = {
  class: "tk-floor"
};
const _hoisted_55 = {
  class: "tk-tiems"
};
const _hoisted_56 = {
  class: "tk-comment"
};
const _hoisted_57 = ["innerHTML"];
const _hoisted_58 = {
  key: 0,
  class: "tk-comment-img"
};
const _hoisted_59 = ["src", "onClick"];
const _hoisted_60 = {
  class: "tk-comment-info"
};
const _hoisted_61 = ["onClick"];
const _hoisted_62 = {
  key: 0,
  src: _imports_1,
  alt: ""
};
const _hoisted_63 = {
  key: 1,
  src: _imports_2,
  alt: ""
};
const _hoisted_64 = ["onClick"];
const _hoisted_65 = {
  class: "tk-comment-other"
};
const _hoisted_66 = {
  key: 0
};
const _hoisted_67 = ["onClick"];
const _hoisted_68 = ["src"];
const _hoisted_69 = ["src"];
const _hoisted_70 = {
  class: "tk-comment-user"
};
const _hoisted_71 = {
  class: "tk-comment-name"
};
const _hoisted_72 = {
  class: "tk-name"
};
const _hoisted_73 = ["onClick"];
const _hoisted_74 = {
  key: 0,
  src: _imports_3,
  alt: ""
};
const _hoisted_75 = ["onClick"];
const _hoisted_76 = {
  class: "tk-tiems"
};
const _hoisted_77 = {
  class: "tk-comment"
};
const _hoisted_78 = ["innerHTML"];
const _hoisted_79 = {
  key: 0,
  class: "tk-comment-img"
};
const _hoisted_80 = ["src", "onClick"];
const _hoisted_81 = {
  class: "tk-comment-info"
};
const _hoisted_82 = ["onClick"];
const _hoisted_83 = {
  key: 0,
  class: "loding"
};
const _hoisted_84 = {
  class: "tk-comment-more"
};
const _hoisted_85 = ["onClick"];
const _hoisted_86 = {
  key: 0
};
const _hoisted_87 = {
  key: 1
};
const _hoisted_88 = ["onClick"];
import Dialog from '@/components/mode/message';
import { comment, sonComment, commentList } from '@/utils/https/materials';
import { follow } from '@/utils/https/https';
import { computed, onActivated, onBeforeMount, onDeactivated, reactive, toRefs, watch } from 'vue';
import { showImagePreview } from 'vant'; 
import { useRouter } from 'vue-router';
import { ref } from 'vue';
//图片预览

export default {
  __name: 'comment_list',
  props: {
    type: {
      //显示哪里的评论
      type: String,
      default: ''
    },
    id: '',
    //文章ID
    corpusTypeId: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const router = useRouter();
    let props = __props;
    let data = reactive({
      hotData: [],
      oneComment: [],
      page: 1,
      show: false
    });
    let {
      hotData,
      oneComment
    } = toRefs(data);
    onBeforeMount(() => {
      hot();
    });
    watch(props, (val, old) => {
      data.oneComment.length = 0;
      data.page = 1;
      loading.value = false;
      finished.value = false;
      onLoad();
    });
    onDeactivated(() => {
      data.show = true;
      localStorage.setItem('comment', false);
    });
    onActivated(() => {
      let comment = localStorage.getItem('comment');
      if (data.show && comment == 'true') {
        data.oneComment.length = 0;
        data.page = 1;
        loading.value = false;
        finished.value = false;
        onLoad();
      }
    });
    const contents = computed(() => item => {
      let str = item.replace(/\n/g, '<br/>');
      return str;
    });
    // deactivated(()=>{

    // })
    // activated(()=>{
    //     console.log('!!!!!') 
    // })
    // watch(props,(val,oldval)=>{
    //     oneCommentList(props.type,props.id,1);
    // })
    let hot = () => {
      //价值热门评论
      if (isWithinTimeRange()) {
        if (localStorage.getItem('hot')) {
          data.hotData = JSON.parse(localStorage.getItem('hot'));
          console.log(data.hotData);
        } else {
          comment().then(res => {
            if (res == undefined) return false;
            let datas = res.data.data;
            datas.forEach(item => {
              item.page = 1; //添加一个分页字段
              item.childcomment = []; //添加一个子评论字段,
              item.childShow = true;
              item.moreShow = false;
              item.loding = false;
            });
            localStorage.setItem('hot', JSON.stringify(datas));
            data.hotData = datas;
          });
        }
      } else {
        comment().then(res => {
          if (res == undefined) return false;
          let datas = res.data.data;
          datas.forEach(item => {
            item.page = 1; //添加一个分页字段
            item.childcomment = []; //添加一个子评论字段,
            item.childShow = true;
            item.moreShow = false;
            item.loding = false;
          });
          localStorage.setItem('hot', JSON.stringify(datas));
          data.hotData = datas;
        });
      }
    };
    function isWithinTimeRange() {
      const currentTime = new Date();
      const hours = currentTime.getHours(); // 获取当前小时
      const minutes = currentTime.getMinutes(); // 获取当前分钟

      // 判断是否在 21:20 到 21:50 之间
      if (hours === 21 && minutes >= 20 || hours === 21 && minutes <= 50) {
        return true;
      }
      return false;
    }
    let childrenComment = async (id, page, index, close, childShow) => {
      if (close == 'close') {
        //收起评论，直接清空子评论s
        data.hotData.forEach((item, i) => {
          if (i == index) {
            item.childShow = false;
            item.moreShow = false;
          }
        });
        return false;
      }
      if (childShow == false) {
        data.hotData.forEach((item, i) => {
          if (i == index) {
            item.childShow = true;
            item.moreShow = true;
          }
        });
        return false;
      }
      data.hotData.forEach((item, i) => {
        if (i == index) {
          item.loding = true;
        }
      });
      let res = await sonComment({
        commentId: id,
        page: page
      });
      if (res == undefined) return false;
      if (res.data.data.length == 0) {
        Dialog('没有评论了');
        data.hotData.forEach((item, i) => {
          if (i == index) {
            item.loding = false;
          }
        });
      } else {
        let datas = res.data.data.data;
        data.hotData.forEach((item, i) => {
          if (i == index) {
            item.page++;
            item.childcomment.push(...datas);
            item.childShow = true;
            item.moreShow = true;
            item.loding = false;
          }
        });
      }
    };
    let childrenComments = async (id, page, index, close, childShow) => {
      if (close == 'close') {
        //收起评论，直接清空子评论s
        data.oneComment.forEach((item, i) => {
          if (i == index) {
            item.childShow = false;
            item.moreShow = false;
          }
        });
        return false;
      }
      if (childShow == false) {
        data.oneComment.forEach((item, i) => {
          if (i == index) {
            item.childShow = true;
            item.moreShow = true;
          }
        });
        return false;
      }
      data.oneComment.forEach((item, i) => {
        if (i == index) {
          item.loding = true;
        }
      });
      let res = await sonComment({
        commentId: id,
        page: page
      });
      if (res == undefined) return false;
      if (res.data.data.length == 0) {
        Dialog('没有评论了');
        data.oneComment.forEach((item, i) => {
          if (i == index) {
            item.loding = false;
          }
        });
      } else {
        let datas = res.data.data.data;
        data.oneComment.forEach((item, i) => {
          if (i == index) {
            item.page++;
            item.childcomment.push(...datas);
            item.childShow = true;
            item.moreShow = true;
            item.loding = false;
          }
        });
      }
    };
    let oneCommentList = (type, id, page, corpusTypeId) => {
      //一级评论
      commentList({
        type: type,
        target_id: id,
        page: page,
        corpusTypeId: corpusTypeId
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.data;
        if (res.data.data.length == 0) {
          finished.value = true;
          // item.loding = false
        } else {
          loading.value = false;
          datas.forEach(item => {
            item.page = 1; //添加一个分页字段
            item.childcomment = []; //添加一个子评论字段
            item.childShow = true;
            item.moreShow = false;
            item.loding = false;
          });
          data.oneComment.push(...datas);
        }
      });
    };
    let follows = id => {
      follow({
        commentId: id
      }).then(res => {
        if (res.status != '400') {
          Dialog(res.data.message, '#07c160');
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let thumbUpCount = (item, type) => {
      // Dialog('点赞成功','#07c160')
      follows(item.id);
      if (type == 1) {
        data.oneComment.forEach(element => {
          if (element.id == item.id) {
            element.follow ? element.thumbUpCount -= 1 : element.thumbUpCount += 1;
            element.follow = !element.follow;
          }
        });
      } else {
        data.hotData.forEach(element => {
          if (element.id == item.id) {
            element.follow ? element.thumbUpCount -= 1 : element.thumbUpCount += 1;
            element.follow = !element.follow;
          }
        });
      }
    };

    //下拉刷新
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      oneCommentList(props.type, props.id, data.page, props.corpusTypeId);
      data.page++;
    };
    let oneReply = id => {
      //会回复一级评论
      router.push({
        path: '/materials/list/comment',
        query: {
          typeId: '5',
          id: id,
          corpusTypeId: props.corpusTypeId
        }
      });
    };
    let preview = (img, index) => {
      //预览图片
      let arr = [];
      img.forEach(item => {
        arr.push(item.img_url);
      });
      showImagePreview({
        images: arr,
        startPosition: index
      });
    };
    let userInfo = id => {
      router.push({
        path: '/userInfo',
        query: {
          id: id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-comment-head"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }), _createElementVNode("span", null, "热门评论")], -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hotData), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", {
          class: "tk-comment-user-head",
          onClick: $event => _unref(userInfo)(item.user.id)
        }, [item.location == 2 ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: item.user.avatar == '' ? require('@/assets/image/logo.png') : item.user.avatar,
          alt: ""
        }, null, 8, _hoisted_5)) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: item.user.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.user.avatar,
          alt: ""
        }, null, 8, _hoisted_6))], 8, _hoisted_4), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(item.user.nickname), 1), item.web_config ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "tips_tk",
          style: _normalizeStyle({
            'background': item.web_config.color
          })
        }, _toDisplayString(item.web_config.web_sign), 5)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_10, _toDisplayString(index + 1) + "楼", 1), _createElementVNode("span", _hoisted_11, _toDisplayString(item.time_str), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", {
          class: "tk-content",
          innerHTML: contents.value(item.content)
        }, null, 8, _hoisted_13), item.images.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.images, (img, indexs) => {
          return _openBlock(), _createElementBlock("div", {
            class: "tk-img-item",
            key: indexs
          }, [_createElementVNode("img", {
            src: img.img_url,
            alt: "",
            onClick: $event => _unref(preview)(item.images, indexs)
          }, null, 8, _hoisted_15)]);
        }), 128))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", {
          class: "tk-info-zan",
          onClick: $event => _unref(thumbUpCount)(item, '')
        }, [item.follow ? (_openBlock(), _createElementBlock("img", _hoisted_18)) : (_openBlock(), _createElementBlock("img", _hoisted_19)), _createElementVNode("span", null, _toDisplayString(item.thumbUpCount), 1)], 8, _hoisted_17), _createElementVNode("div", {
          class: "info-reply",
          onClick: $event => _unref(oneReply)(item.id)
        }, "回复", 8, _hoisted_20)]), _createElementVNode("div", _hoisted_21, [item.childShow ? (_openBlock(), _createElementBlock("ul", _hoisted_22, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childcomment, (items, i) => {
          return _openBlock(), _createElementBlock("li", {
            key: i
          }, [_createElementVNode("div", {
            class: "tk-comment-user-head",
            onClick: $event => _unref(userInfo)(items.user.id)
          }, [items.location == 2 ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: items.user.avatar == '' ? require('@/assets/image/logo.png') : items.user.avatar,
            alt: ""
          }, null, 8, _hoisted_24)) : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: items.user.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + items.user.avatar,
            alt: ""
          }, null, 8, _hoisted_25))], 8, _hoisted_23), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_createElementVNode("span", _hoisted_28, [_createElementVNode("span", {
            style: {
              "max-width": "100px",
              "overflow": "hidden",
              "text-overflow": "ellipsis",
              "white-space": "nowrap"
            },
            onClick: $event => _unref(userInfo)(items.user.id)
          }, _toDisplayString(items.user.nickname), 9, _hoisted_29), items.up_user.nickname != undefined ? (_openBlock(), _createElementBlock("img", _hoisted_30)) : _createCommentVNode("", true), _createElementVNode("span", {
            style: {
              "max-width": "100px",
              "overflow": "hidden",
              "text-overflow": "ellipsis",
              "white-space": "nowrap"
            },
            onClick: $event => _unref(userInfo)(items.up_user.id)
          }, _toDisplayString(items.up_user.nickname), 9, _hoisted_31)]), _createElementVNode("span", _hoisted_32, _toDisplayString(items.time_str), 1)]), _createElementVNode("div", _hoisted_33, [_createElementVNode("div", {
            class: "tk-content",
            innerHTML: contents.value(items.content)
          }, null, 8, _hoisted_34), items.images.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_35, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.images, (imgs, indexs) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tk-img-item",
              key: indexs
            }, [_createElementVNode("img", {
              src: imgs.img_url,
              alt: "",
              onClick: $event => _unref(preview)(items.images, indexs)
            }, null, 8, _hoisted_36)]);
          }), 128))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_37, [_createElementVNode("div", {
            class: "info-reply",
            onClick: $event => _unref(oneReply)(items.id)
          }, "回复", 8, _hoisted_38)])])]);
        }), 128))])) : _createCommentVNode("", true)]), item.loding ? (_openBlock(), _createElementBlock("div", _hoisted_39, _cache[1] || (_cache[1] = [_createElementVNode("img", {
          src: _imports_4,
          alt: ""
        }, null, -1)]))) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_40, [item.subCommentCount != 0 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-more other",
          onClick: $event => _unref(childrenComment)(item.id, item.page, index, '', item.childShow)
        }, [item.moreShow ? (_openBlock(), _createElementBlock("span", _hoisted_42, "—— 展开更多回复")) : (_openBlock(), _createElementBlock("span", _hoisted_43, "—— 展开" + _toDisplayString(item.subCommentCount) + "条回复", 1)), _cache[2] || (_cache[2] = _createElementVNode("img", {
          src: _imports_5,
          alt: ""
        }, null, -1))], 8, _hoisted_41)) : _createCommentVNode("", true), item.moreShow ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tk-retract other",
          onClick: $event => _unref(childrenComment)(item.id, item.page, index, 'close', item.childShow)
        }, _cache[3] || (_cache[3] = [_createElementVNode("span", null, "收起", -1), _createElementVNode("img", {
          src: _imports_6,
          alt: ""
        }, null, -1)]), 8, _hoisted_44)) : _createCommentVNode("", true)])])]);
      }), 128))])]), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "tk-comment-list-normal"
      }, null, -1))]), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": " ",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_45, [_createElementVNode("div", _hoisted_46, [_cache[9] || (_cache[9] = _createElementVNode("div", {
          class: "tk-comment-head"
        }, [_createElementVNode("span", null, "最新评论")], -1)), _unref(oneComment).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_47, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(oneComment), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", {
            class: "tk-comment-user-head",
            onClick: $event => _unref(userInfo)(item.user.id)
          }, [item.location == 2 ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: item.user.avatar == '' ? require('@/assets/image/logo.png') : item.user.avatar,
            alt: ""
          }, null, 8, _hoisted_49)) : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: item.user.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + item.user.avatar,
            alt: ""
          }, null, 8, _hoisted_50))], 8, _hoisted_48), _createElementVNode("div", _hoisted_51, [_createElementVNode("div", _hoisted_52, [_createElementVNode("span", _hoisted_53, _toDisplayString(item.user.nickname), 1), item.web_config ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "tips_tk",
            style: _normalizeStyle({
              'background': item.web_config.color
            })
          }, _toDisplayString(item.web_config.web_sign), 5)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_54, _toDisplayString(index + 1) + "楼", 1), _createElementVNode("span", _hoisted_55, _toDisplayString(item.time_str), 1)]), _createElementVNode("div", _hoisted_56, [_createElementVNode("div", {
            class: "tk-content",
            innerHTML: contents.value(item.content)
          }, null, 8, _hoisted_57), item.images.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_58, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.images, (img, indexs) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tk-img-item",
              key: indexs
            }, [_createElementVNode("img", {
              src: img.img_url,
              alt: "",
              onClick: $event => _unref(preview)(item.images, indexs)
            }, null, 8, _hoisted_59)]);
          }), 128))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_60, [_createElementVNode("div", {
            class: "tk-info-zan",
            onClick: $event => _unref(thumbUpCount)(item, '1')
          }, [item.follow ? (_openBlock(), _createElementBlock("img", _hoisted_62)) : (_openBlock(), _createElementBlock("img", _hoisted_63)), _createElementVNode("span", null, _toDisplayString(item.thumbUpCount), 1)], 8, _hoisted_61), _createElementVNode("div", {
            class: "info-reply",
            onClick: $event => _unref(oneReply)(item.id)
          }, "回复", 8, _hoisted_64)]), _createElementVNode("div", _hoisted_65, [item.childShow ? (_openBlock(), _createElementBlock("ul", _hoisted_66, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childcomment, (items, i) => {
            return _openBlock(), _createElementBlock("li", {
              key: i
            }, [_createElementVNode("div", {
              class: "tk-comment-user-head",
              onClick: $event => _unref(userInfo)(items.user.id)
            }, [items.location == 2 ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: items.user.avatar == '' ? require('@/assets/image/logo.png') : items.user.avatar,
              alt: ""
            }, null, 8, _hoisted_68)) : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: items.user.avatar == '' ? require('@/assets/image/logo.png') : _ctx.$httpsUrl + items.user.avatar,
              alt: ""
            }, null, 8, _hoisted_69))], 8, _hoisted_67), _createElementVNode("div", _hoisted_70, [_createElementVNode("div", _hoisted_71, [_createElementVNode("span", _hoisted_72, [_createElementVNode("span", {
              style: {
                "max-width": "100px",
                "overflow": "hidden",
                "text-overflow": "ellipsis",
                "white-space": "nowrap"
              },
              onClick: $event => _unref(userInfo)(items.user.id)
            }, _toDisplayString(items.user.nickname), 9, _hoisted_73), items.up_user.nickname != undefined ? (_openBlock(), _createElementBlock("img", _hoisted_74)) : _createCommentVNode("", true), _createElementVNode("span", {
              style: {
                "max-width": "100px",
                "overflow": "hidden",
                "text-overflow": "ellipsis",
                "white-space": "nowrap"
              },
              onClick: $event => _unref(userInfo)(items.up_user.id)
            }, _toDisplayString(items.up_user.nickname), 9, _hoisted_75)]), _createElementVNode("span", _hoisted_76, _toDisplayString(items.time_str), 1)]), _createElementVNode("div", _hoisted_77, [_createElementVNode("div", {
              class: "tk-content",
              innerHTML: contents.value(items.content)
            }, null, 8, _hoisted_78), items.images.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_79, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.images, (imgs, indexs) => {
              return _openBlock(), _createElementBlock("div", {
                class: "tk-img-item",
                key: indexs
              }, [_createElementVNode("img", {
                src: imgs.img_url,
                alt: "",
                onClick: $event => _unref(preview)(items.images, indexs)
              }, null, 8, _hoisted_80)]);
            }), 128))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_81, [_createElementVNode("div", {
              class: "info-reply",
              onClick: $event => _unref(oneReply)(items.id)
            }, "回复", 8, _hoisted_82)])])]);
          }), 128))])) : _createCommentVNode("", true)]), item.loding ? (_openBlock(), _createElementBlock("div", _hoisted_83, _cache[6] || (_cache[6] = [_createElementVNode("img", {
            src: _imports_4,
            alt: ""
          }, null, -1)]))) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_84, [item.subCommentCount != 0 ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "tk-more other",
            onClick: $event => _unref(childrenComments)(item.id, item.page, index, '', item.childShow)
          }, [item.moreShow ? (_openBlock(), _createElementBlock("span", _hoisted_86, "—— 展开更多回复")) : (_openBlock(), _createElementBlock("span", _hoisted_87, "—— 展开" + _toDisplayString(item.subCommentCount) + "条回复", 1)), _cache[7] || (_cache[7] = _createElementVNode("img", {
            src: _imports_5,
            alt: ""
          }, null, -1))], 8, _hoisted_85)) : _createCommentVNode("", true), item.moreShow ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "tk-retract other",
            onClick: $event => _unref(childrenComments)(item.id, item.page, index, 'close', item.childShow)
          }, _cache[8] || (_cache[8] = [_createElementVNode("span", null, "收起", -1), _createElementVNode("img", {
            src: _imports_6,
            alt: ""
          }, null, -1)]), 8, _hoisted_88)) : _createCommentVNode("", true)])])]);
        }), 128))]))]), _cache[10] || (_cache[10] = _createElementVNode("div", {
          class: "tk-comment-list-normal"
        }, null, -1))])]),
        _: 1
      }, 8, ["loading", "finished"])]), _cache[11] || (_cache[11] = _createElementVNode("div", {
        style: {
          "height": "65px"
        }
      }, null, -1))], 64);
    };
  }
};