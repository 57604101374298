import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "tk-pay-list"
};
const _hoisted_2 = {
  class: "videos"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "tk-lottery-code"
};
const _hoisted_5 = {
  class: "tk-lottery-box"
};
const _hoisted_6 = {
  class: "sx"
};
const _hoisted_7 = {
  class: "tk-swiper"
};
import { numbers } from '@/utils/https/lottery';
import { swiperImg } from '@/utils/https/page';

import { reactive, toRefs, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
export default {
  __name: 'playDesc',
  setup(__props) {
    let data = reactive({
      videoUrl: '',
      title: '',
      http: JSON.parse(localStorage.getItem('config')).video_url,
      numarray: {},
      swiperImgsrc: []
    });
    let {
      videoUrl,
      title,
      http,
      numarray,
      swiperImgsrc
    } = toRefs(data);
    let number = (year, lotteryType, issue) => {
      //每一期开奖结果
      numbers({
        year: year,
        lotteryType: lotteryType,
        issue: issue
      }).then(res => {
        if (res.data.status == 40000) return false;
        let datas = res.data.data;
        data.numarray = datas;
      });
    };
    let route = useRoute();
    onBeforeMount(() => {
      data.videoUrl = route.query.videoUrl;
      data.title = route.query.title;
      let lotteryType = route.query.lotteryType;
      let issue = route.query.issue;
      let year = issue.substring(0, 4);
      let issues = issue.slice(4);
      number(year, lotteryType, issues);
      swiperImgs();
    });
    let swiperImgs = () => {
      //轮播图
      swiperImg().then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.ad_list;
        data.swiperImgsrc.push(...datas);
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_swipers = _resolveComponent("swipers");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(title)
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("video", {
        autoplay: "autoplay",
        playsinline: "false",
        "webkit-playsinline": "false",
        loop: "",
        preload: "metadata",
        controls: "",
        src: _unref(http) + _unref(videoUrl),
        style: {
          "width": "100%",
          "height": "100%"
        }
      }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(numarray).number_attr, (items, indexs) => {
        return _openBlock(), _createElementBlock("li", {
          key: indexs
        }, [_createElementVNode("div", {
          class: _normalizeClass('class' + items.color + ' num')
        }, [_createElementVNode("span", null, _toDisplayString(items.number), 1)], 2), _createElementVNode("div", _hoisted_6, _toDisplayString(items.shengXiao) + _toDisplayString(_ctx.sxWx ? '/' + items.wuXing : ''), 1)]);
      }), 128))])])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_swipers, {
        swiperImg: _unref(swiperImgsrc)
      }, null, 8, ["swiperImg"])])])], 64);
    };
  }
};