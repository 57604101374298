import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../../../assets/image/passeyesopn.png';
import _imports_1 from '../../../assets/image/passeyes.png';
const _hoisted_1 = {
  class: "tk-exit-passwrod"
};
const _hoisted_2 = {
  class: "tk-from"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "tk-from-field flex-between"
};
const _hoisted_5 = {
  class: "tk-field-input"
};
const _hoisted_6 = ["type", "onUpdate:modelValue", "placeholder"];
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_9 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
import Dialog from '@/components/mode/message.js';
import router from '@/router';
//接口请求

import { setFundPassword } from '@/utils/https/me';
import { reactive } from 'vue';

import { toRefs } from 'vue';
export default {
  __name: 'money_password',
  setup(__props) {
    let data = reactive({
      from: [
      // {type:'password',name:'password_current',placeholder:'请输入旧密码',value:'',icon:'旧密码',lookeyes:false,type_c:'password'},
      {
        type: 'password',
        name: 'password',
        placeholder: '请输入密码',
        value: '',
        icon: '密码',
        lookeyes: false,
        type_c: 'password'
      }, {
        type: 'password',
        name: 'password_confirmation',
        placeholder: '请再次输入密码',
        value: '',
        icon: '确认密码',
        lookeyes: false,
        type_c: 'password'
      }],
      froms: {
        password: ''
      },
      lookeyes: true,
      show: false,
      codeImg: '',
      message: ''
    });
    let fromx = reactive({
      password: '',
      password_confirmation: ''
    });
    let {
      show,
      from,
      codeImg,
      message,
      froms
    } = toRefs(data);
    let editUserPassword = fromx => {
      setFundPassword(fromx).then(res => {
        if (res.status != '400') {
          let userInfo = JSON.parse(localStorage.getItem('usetInfo')); //如果修改userInfo里面的数据
          userInfo.isset_fund_password = 1;
          localStorage.setItem('usetInfo', JSON.stringify(userInfo));
          Dialog(res.data.message, '#07c160');
          router.back();
        } else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let onSubmit = e => {
      e.preventDefault();
      data.from.forEach(item => {
        if (item.name == 'password') {
          fromx.password = item.value;
        }
        if (item.name == 'password_confirmation') {
          fromx.password_confirmation = item.value;
        }
      });
      if (fromx.password == '') {
        Dialog('密码不能为空', '#07c160');
      } else if (fromx.password_confirmation != fromx.password) {
        Dialog('两次密码不一致', '#07c160');
      } else {
        editUserPassword(fromx);
      }
    };
    let lookPassword = index => {
      if (data.from[index].lookeyes) {
        data.from[index].type = 'password';
        data.from[index].lookeyes = false;
      } else {
        data.from[index].type = 'number';
        data.from[index].lookeyes = true;
      }
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "添加资金密码"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("form", {
        onSubmit: _cache[0] || (_cache[0] = (...args) => _unref(onSubmit) && _unref(onSubmit)(...args))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(from), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "labe",
          key: index
        }, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.icon), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_withDirectives(_createElementVNode("input", {
          type: item.type,
          maxlength: "6",
          "onUpdate:modelValue": $event => item.value = $event,
          placeholder: item.placeholder
        }, null, 8, _hoisted_6), [[_vModelDynamic, item.value]])]), item.type_c == 'password' ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tk-field-tips",
          onClick: $event => _unref(lookPassword)(index)
        }, [item.lookeyes ? (_openBlock(), _createElementBlock("img", _hoisted_8)) : (_openBlock(), _createElementBlock("img", _hoisted_9))], 8, _hoisted_7)) : _createCommentVNode("", true)])]);
      }), 128)), _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "tk-from-field bottom"
      }, [_createElementVNode("button", {
        type: "submit"
      }, "添加")], -1))], 32)])])], 64);
    };
  }
};