import "core-js/modules/es.array.push.js";
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef } from "vue";
import _imports_0 from '../../../assets/image/date.png';
const _hoisted_1 = {
  class: "tk-nav-tabs"
};
const _hoisted_2 = {
  class: "tk-tabs"
};
const _hoisted_3 = {
  class: "tk-main-box"
};
const _hoisted_4 = {
  class: "tk-box-playback"
};
const _hoisted_5 = {
  class: "tk-playback-content"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "tk-play"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "tk-qishu"
};
import { newYear } from "@/utils/dateTiem";
import picker from '@/components/picker.vue';
import { backVideo } from '@/utils/https/lottery';

import { useRouter, useRoute } from 'vue-router';
import { reactive, toRefs, onBeforeMount, ref } from 'vue';
export default {
  __name: 'playback',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    let data = reactive({
      year: newYear,
      videoList: [],
      http: JSON.parse(localStorage.getItem('config')).video_url,
      page: 1,
      lotteryType: 5,
      show: false,
      activeIndex: 4
    });
    let {
      videoList,
      http,
      show,
      activeIndex
    } = toRefs(data);
    onBeforeMount(() => {
      if (Object.keys(route.query).length != 0) {
        data.lotteryType = route.query.lottery;
        data.activeIndex = route.query.index;
      }
      window.scrollTo(0, 0);
    });

    //页面请求区域
    let backVideos = (year, type, page) => {
      backVideo({
        year: year,
        lotteryType: type,
        page: page
      }).then(res => {
        if (res == undefined) return false;
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.videoList.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      backVideos(data.year, data.lotteryType, data.page);
      data.page++;
    };
    let principalTans = index => {
      data.videoList.length = 0;
      data.activeIndex = index;
      data.page = 1;
      data.lotteryType = index + 1;
      backVideos(data.year, data.lotteryType, data.page);
    };
    let showYear = () => {
      data.show = !data.show;
    };
    let hadleGeto = item => {
      router.push({
        path: '/lottery/playback/details',
        query: {
          title: item.videoTitle,
          videoUrl: item.videoUrl,
          issue: item.issue,
          lotteryType: data.lotteryType
        }
      });
    };
    let onConfirm = item => {
      //获取prompt选择的值
      data.videoList.length = 0;
      data.page = 1;
      backVideos(item, data.lotteryType, data.page);
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_list = _resolveComponent("van-list");
      const _component_majorBack = _resolveComponent("majorBack");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "开奖回放"
      }, {
        tkCont: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", null, [_createElementVNode("li", {
          class: _normalizeClass(_unref(activeIndex) == 1 ? 'active' : ''),
          onClick: _cache[0] || (_cache[0] = $event => _unref(principalTans)(1))
        }, "新澳门", 2), _createElementVNode("li", {
          class: _normalizeClass(_unref(activeIndex) == 4 ? 'active' : ''),
          onClick: _cache[1] || (_cache[1] = $event => _unref(principalTans)(4))
        }, "天天澳门", 2), _createElementVNode("li", {
          class: _normalizeClass(_unref(activeIndex) == 0 ? 'active' : ''),
          onClick: _cache[2] || (_cache[2] = $event => _unref(principalTans)(0))
        }, "香港彩", 2), _createElementVNode("li", {
          class: _normalizeClass(_unref(activeIndex) == 5 ? 'active' : ''),
          onClick: _cache[3] || (_cache[3] = $event => _unref(principalTans)(5))
        }, "快乐八", 2), _createElementVNode("li", {
          class: _normalizeClass(_unref(activeIndex) == 6 ? 'active' : ''),
          onClick: _cache[4] || (_cache[4] = $event => _unref(principalTans)(6))
        }, "老澳", 2)])])])]),
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[5] || (_cache[5] = (...args) => _unref(showYear) && _unref(showYear)(...args))
        }, _cache[8] || (_cache[8] = [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)]))]),
        _: 1
      }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[6] || (_cache[6] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(videoList), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(hadleGeto)(item)
          }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
            src: _unref(http) + item.pictureUrl,
            alt: ""
          }, null, 8, _hoisted_8)]), _createElementVNode("div", _hoisted_9, _toDisplayString(item.videoTitle), 1)], 8, _hoisted_6);
        }), 128))])])])]),
        _: 1
      }, 8, ["loading", "finished"])]), _createVNode(_component_majorBack), _createVNode(picker, {
        show: _unref(show),
        "onUpdate:show": _cache[7] || (_cache[7] = $event => _isRef(show) ? show.value = $event : show = $event),
        onOnConfirm: _unref(onConfirm)
      }, null, 8, ["show", "onOnConfirm"])], 64);
    };
  }
};