import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "tk-lottery-Real"
};
const _hoisted_2 = {
  class: "list"
};
const _hoisted_3 = ["onClick"];
import lottery from '@/components/page/lottery.vue';
import { onBeforeMount, onBeforeUnmount, reactive, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

import { useRouter } from 'vue-router';
import { computed } from 'vue';
export default {
  __name: 'lotteryReal',
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const $store = useStore(); //获取vuex
    const router = useRouter();
    const show = ref(false);
    const chid = ref(null);
    const lotteryId = ref(2);
    const lotteryList = ref([{
      name: "澳彩",
      id: 2
    }, {
      name: "港彩",
      id: 1
    }, {
      name: "快乐八",
      id: 6
    }, {
      name: "老澳",
      id: 7
    }, {
      name: "天澳彩",
      id: 5
    }
    // {name:"新彩",id:4},
    // {name:"台彩",id:3},
    ]);
    const numberListTime = reactive([]);
    const configInfo = computed(() => {
      if ($store.state.config != null) {
        return $store.state.config;
      } else {
        let config = localStorage.getItem('config');
        if (config != null) {
          return JSON.parse(config);
        } else {
          return {
            cloud_url: 'https://lty-s1.s3.ap-east-1.amazonaws.com/49_tk/'
          };
        }
      }
      ;
    });
    let tw = configInfo.value.cloud_url + 'open_lottery/v_tw.json';
    let xg = configInfo.value.cloud_url + 'open_lottery/v_xg.json';
    let old_am = configInfo.value.cloud_url + 'open_lottery/v_am_plus.json';
    let xjp = configInfo.value.cloud_url + 'open_lottery/v_xjp.json';
    let am = configInfo.value.cloud_url + 'open_lottery/v_am.json';
    let klb = configInfo.value.cloud_url + 'open_lottery/v_fckl8.json';
    let oldam = configInfo.value.cloud_url + 'open_lottery/v_oldam.json';
    let lotteryGet = (url, lottery) => {
      axios.get(url).then(res => {
        let body = res.data.data;
        body.Time = body.Time.split('点').join(':');
        body.Time = body.Time.substring(0, body.Time.length - 1);
        numberListTime.push({
          stime: new Date(`${body.Year}/${body.Moon}/${body.Day} ${body.Time}`).getTime(),
          id: lottery,
          etime: new Date(`${body.Year}/${body.Moon}/${body.Day} ${body.Time}`).getTime() + 1000 * 4 * 60
        });
      });
    };
    let checked = ref(false);
    let emits = __emit;
    const handleActive = id => {
      lotteryId.value = id;
    };
    const clickShow = () => {
      show.value = !show.value;
      // clearInterval(clearIntervals);
    };
    let page = ref(1);
    watchEffect(async () => {
      // await lotteryGet(tw,4)
      // await lotteryGet(xg,1)
      // await lotteryGet(old_am,2)
      // await lotteryGet(xjp,3)
      // await lotteryGet(oldam,7)
      // await lotteryGet(klb,6)
      // djs();
    });
    const openAutolottery = () => {
      show.value = false;
      // clearInterval(clearIntervals);
    };
    let addUser = () => {
      router.push({
        path: '/me/setup/BindingAccount'
      });
    };
    // let clearIntervals

    // clearIntervals = setInterval(()=>{
    //     numberListTime.forEach(item=>{
    //         if(item.stime <= djs() && item.etime >= djs()) {
    //             show.value = true;
    //         } else {
    //             show.value = false;
    //         }
    //     })
    //     page.value++;
    // },1000)

    onBeforeUnmount(() => {
      page.value = 1;
      // clearInterval(clearIntervals);
    });
    // let djs = ()=>{
    //     var now = new Date();
    //     // 获取本地时区
    //     var localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //     // 设置中国时区
    //     var chinaTimeZone = 'Asia/Shanghai';
    //     // 使用本地时区创建日期对象
    //     var localTime = new Date(now);
    //     // 将本地时间转换为中国时区
    //     var chinaTime = new Date(localTime.toLocaleString('en-US', { timeZone: chinaTimeZone }));
    //     return chinaTime.getTime();  // 参数需要毫秒数，所以这里将秒数乘于 1000

    // }
    __expose({
      clickShow
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return show.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "wrapper wrappersdf",
        onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => _ctx.overlayPop && _ctx.overlayPop(...args), ["stop"]))
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lotteryList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          onClick: _withModifiers($event => handleActive(item.id), ["stop"]),
          class: _normalizeClass(item.id == lotteryId.value ? 'active item' : 'item'),
          key: index
        }, _toDisplayString(item.name), 11, _hoisted_3);
      }), 128))]), _createVNode(lottery, {
        ref_key: "chid",
        ref: chid,
        lottery: lotteryId.value
      }, null, 8, ["lottery"]), _createElementVNode("div", {
        class: "openAutolottery",
        onClick: _withModifiers(openAutolottery, ["stop"])
      }, [_createVNode(_component_van_icon, {
        name: "cross"
      })])])])) : _createCommentVNode("", true);
    };
  }
};