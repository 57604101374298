import "core-js/modules/es.array.push.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../assets/image/triangle.png';
import _imports_1 from '../../../assets/image/ftriangle.png';
import _imports_2 from '../../../assets/image/zan.png';
import _imports_3 from '../../../assets/image/comment.png';
import _imports_4 from '../../../assets/image/colleon.png';
import _imports_5 from '../../../assets/image/colle.png';
import _imports_6 from '../../../assets/image/close.png';
const _hoisted_1 = {
  class: "tk-main-vote"
};
const _hoisted_2 = {
  class: "tk-vote-content"
};
const _hoisted_3 = {
  class: "tk-vote"
};
const _hoisted_4 = {
  key: 0,
  class: "vote-record"
};
const _hoisted_5 = {
  class: "vote-sx"
};
const _hoisted_6 = {
  class: "tk-circle"
};
const _hoisted_7 = {
  class: "bfb"
};
const _hoisted_8 = {
  class: "vot-num"
};
const _hoisted_9 = {
  class: "flex-between"
};
const _hoisted_10 = {
  class: "tk-vote-more"
};
const _hoisted_11 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_12 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_13 = {
  class: "tk-vote-info flex-between"
};
const _hoisted_14 = {
  class: "tk-info"
};
const _hoisted_15 = {
  key: 0,
  src: _imports_4,
  alt: ""
};
const _hoisted_16 = {
  key: 1,
  src: _imports_5,
  alt: ""
};
const _hoisted_17 = {
  class: "tk-look"
};
const _hoisted_18 = {
  class: "tk-main-QEcode"
};
const _hoisted_19 = {
  class: "title"
};
const _hoisted_20 = ["onClick"];
const _hoisted_21 = {
  class: "name"
};
const _hoisted_22 = {
  class: "num"
};
const _hoisted_23 = {
  class: "tk-button-info"
};
const _hoisted_24 = {
  class: "tk-button"
};
import { onBeforeMount, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
export default {
  __name: 'articlesvote',
  props: {
    galleryData: {
      //页面数据
      type: Object,
      default: {}
    },
    voteList: {
      //投票数据
      type: Array,
      default: []
    },
    type: {
      //进入组件的类型
      type: String,
      default: 'image'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['vote', 'handleNext', 'handleUp', 'handleThumbUpCount', 'handleCollect'],
  setup(__props, {
    emit: __emit
  }) {
    let emits = __emit;
    onBeforeMount(() => {});
    let props = __props;
    let router = useRouter();
    let submit = () => {
      //提交投票生肖
      if (data.sx == '') {
        data.sx = props.voteList[0].name;
      }
      emits('vote', data.sx);
    };
    let selectSx = (index, item) => {
      data.activeIndex = index;
      data.sx = item;
    };
    let data = reactive({
      expansions: '展开投票',
      activeIndex: 0,
      //生肖高亮显示
      sx: '' //投票生肖
    });
    let {
      expansions,
      activeIndex
    } = toRefs(data);
    let expansion = () => {
      if (data.expansions == '展开投票') {
        data.expansions = '收起投票';
      } else {
        data.expansions = '展开投票';
      }
    };
    let vote = () => {
      emits('update:show', true);
    };
    let overlayPop = () => {
      emits('update:show', false);
    };
    let colse = () => {
      emits('update:show', false);
    };
    let handleUp = () => {
      emits('handleUp', false);
    };
    let handleNext = () => {
      emits('handleNext', false);
    };
    let handleRecord = () => {
      router.push({
        path: '/gallery/guess/VotRecord'
      });
    };
    const handleThumbUpCount = () => {
      emits('handleThumbUpCount');
    };
    const handleCollect = () => {
      emits('handleCollect');
    };
    return (_ctx, _cache) => {
      const _component_botton = _resolveComponent("botton");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]), _createElementVNode("div", _hoisted_3, [_unref(expansions) == '收起投票' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.voteList, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("span", _hoisted_5, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
          class: "circle",
          style: _normalizeStyle({
            'width': item.percentage + '%'
          })
        }, null, 4), _createElementVNode("div", _hoisted_7, _toDisplayString(item.percentage + '%'), 1)]), _createElementVNode("span", _hoisted_8, _toDisplayString(item.vote_num) + "票", 1)]);
      }), 128))]), _createElementVNode("div", _hoisted_9, [__props.type != 'image' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "vote-botton",
        onClick: _cache[0] || (_cache[0] = $event => _unref(handleUp)())
      }, [_createVNode(_component_botton, {
        name: "下一期",
        bgcolor: "#A1B1C8"
      })])) : _createCommentVNode("", true), __props.type == 'image' ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "vote-botton vote",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(vote) && _unref(vote)(...args))
      }, [_createVNode(_component_botton, {
        name: "投票"
      })])) : _createCommentVNode("", true), __props.type != 'image' ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: "vote-botton vote",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(vote) && _unref(vote)(...args))
      }, [_createVNode(_component_botton, {
        name: "投票"
      })])) : _createCommentVNode("", true), __props.type == 'image' ? (_openBlock(), _createElementBlock("div", {
        key: 3,
        class: "vote-botton vote",
        onClick: _cache[3] || (_cache[3] = (...args) => _unref(handleRecord) && _unref(handleRecord)(...args))
      }, [_createVNode(_component_botton, {
        bgcolor: "#A1B1C8",
        name: "投票记录"
      })])) : _createCommentVNode("", true), __props.type != 'image' ? (_openBlock(), _createElementBlock("div", {
        key: 4,
        class: "vote-botton",
        onClick: _cache[4] || (_cache[4] = $event => _unref(handleNext)())
      }, [_createVNode(_component_botton, {
        name: "上一期",
        bgcolor: "#A1B1C8"
      })])) : _createCommentVNode("", true)])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", {
        onClick: _cache[5] || (_cache[5] = (...args) => _unref(expansion) && _unref(expansion)(...args))
      }, _toDisplayString(_unref(expansions)), 1), _unref(expansions) == '收起投票' ? (_openBlock(), _createElementBlock("img", _hoisted_11)) : (_openBlock(), _createElementBlock("img", _hoisted_12))])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("ul", null, [_createElementVNode("li", {
        onClick: handleThumbUpCount
      }, [_cache[8] || (_cache[8] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$filters.format(__props.galleryData.thumbUpCount)), 1)]), _createElementVNode("li", null, [_cache[9] || (_cache[9] = _createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$filters.format(__props.galleryData.commentCount)), 1)]), _createElementVNode("li", {
        onClick: handleCollect
      }, [__props.galleryData.isCollect ? (_openBlock(), _createElementBlock("img", _hoisted_15)) : (_openBlock(), _createElementBlock("img", _hoisted_16)), _createElementVNode("span", null, _toDisplayString(_ctx.$filters.format(__props.galleryData.collectCount)), 1)])])]), _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$filters.format(__props.galleryData.clickCount)) + " 阅读", 1)])]), _createVNode(_component_van_overlay, {
        show: __props.show
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[7] || (_cache[7] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", {
          class: "colse",
          onClick: _cache[6] || (_cache[6] = _withModifiers((...args) => _unref(colse) && _unref(colse)(...args), ["stop"]))
        }, _cache[10] || (_cache[10] = [_createElementVNode("img", {
          src: _imports_6,
          alt: ""
        }, null, -1)])), _createElementVNode("div", _hoisted_19, _toDisplayString(__props.galleryData.year + '年' + __props.galleryData.issue + '期'), 1), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.voteList, (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(_unref(activeIndex) == index ? 'active' : ''),
            onClick: _withModifiers($event => _unref(selectSx)(index, item.name), ["stop"])
          }, [_createElementVNode("div", _hoisted_21, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_22, _toDisplayString(item.vote_num) + "票", 1)], 10, _hoisted_20);
        }), 128))]), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createVNode(_component_botton, {
          name: "投票",
          onClick: _withModifiers(_unref(submit), ["stop"])
        }, null, 8, ["onClick"])])])])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};