import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "tk-major-province"
};
const _hoisted_2 = ["onClick"];
import { onBeforeMount, watch } from "vue";

import { ref } from 'vue';
export default {
  __name: 'lotteyTabs',
  props: {
    province: {
      type: Array,
      default: [{
        name: '全部',
        lottery: 0
      }, {
        name: '新澳彩',
        lottery: 2
      }, {
        name: '港彩',
        lottery: 1
      }, {
        name: '快乐八',
        lottery: 6
      }, {
        name: '老澳',
        lottery: 7
      }
      // {name:'台彩',lottery:3},
      // {name:'新彩',lottery:4},
      // {name:'天澳彩',lottery:5},
      ]
    },
    id: {
      type: String,
      default: '2'
    }
  },
  emits: ['tabLottery'],
  setup(__props, {
    emit: __emit
  }) {
    let props = __props;
    let activeIndex = ref(6);
    onBeforeMount(() => {
      activeIndex.value = props.id;
    });
    let emits = __emit;
    let tabLottery = (index, lottery) => {
      activeIndex.value = lottery;
      emits('tabLottery', lottery);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", {
        style: _normalizeStyle({
          'grid-template-columns': `repeat(${__props.province.length},1fr )`
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.province, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(item.lottery == _unref(activeIndex) ? 'active' : ''),
          key: index,
          onClick: $event => _unref(tabLottery)(index, item.lottery)
        }, _toDisplayString(item.name), 11, _hoisted_2);
      }), 128))], 4), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "tk-tips"
      }, null, -1))]);
    };
  }
};