import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '../../../assets/image/more/poker.png';
const _hoisted_1 = {
  class: "tk-main-love"
};
const _hoisted_2 = {
  class: "tk-sx-list"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["src"];
import { onBeforeMount, reactive, toRefs } from "vue";
export default {
  __name: 'sx',
  setup(__props) {
    let data = reactive({
      sx: [{
        image: require('@/assets/image/more/1.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/2.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/3.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/4.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/5.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/6.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/7.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/8.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/9.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/10.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/11.png'),
        select: '0'
      }, {
        image: require('@/assets/image/more/12.png'),
        select: '0'
      }],
      acitveIndex: ''
    });
    onBeforeMount(() => {
      arrSort(data.sx);
    });
    let arrSort = array => {
      function randomsort(a, b) {
        return Math.random() > .12 ? -1 : 1;
      }
      data.sx = array.sort(randomsort);
    };
    onBeforeMount(() => {
      let datas = JSON.parse(localStorage.getItem('sx'));
      if (datas && datas.expires > Date.now()) {
        data.sx = datas.value;
      } else {}
    });
    let {
      sx,
      acitveIndex
    } = toRefs(data);
    let rotation = index => {
      data.sx[index].select = 1;
      let i = 0;
      let array = [];
      data.sx.forEach(item => {
        if (item.select == 1) {
          i++;
          array.push(item);
        }
      });
      if (i == 3) {
        data.sx = array;
        let datas = {
          value: array,
          expires: Date.now() + 1000 * 60 * 60 // 1 hour from now
        };
        localStorage.setItem('sx', JSON.stringify(datas));
      }
    };
    //功能区域

    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "生肖卡牌"
      }), _createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "tk-love-description"
      }, [_createElementVNode("div", {
        class: "tk-love-title"
      }, "简介"), _createElementVNode("div", {
        class: "tk-desc"
      }, "每期开奖前通过该工具可以快捷的查看三个隐藏在卡牌中的 生肖，来试试你的财运!"), _createElementVNode("div", {
        class: "tk-prompt-c"
      })], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sx), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          onClick: $event => _unref(rotation)(index),
          key: index
        }, [_createElementVNode("img", {
          class: _normalizeClass(item.select == 1 ? 'zheng activezheng' : 'zheng'),
          src: _imports_0,
          alt: ""
        }, null, 2), _createElementVNode("img", {
          class: _normalizeClass(item.select == 1 ? 'fan acitvefan' : 'fan'),
          src: item.image,
          alt: ""
        }, null, 10, _hoisted_4)], 8, _hoisted_3);
      }), 128))])])])], 64);
    };
  }
};