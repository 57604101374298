import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../assets/image/jiazai.gif';

import { ref, reactive, watch } from 'vue';
const _hoisted_1 = {
  key: 0,
  class: "tk-prompt"
};
const _hoisted_2 = {
  class: "tk-prompt-list"
};
export default {
  __name: 'prompt',
  props: {
    tail: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    let props = __props;
    let shows = ref(false);
    let tailContnet = reactive([]);
    let cler;
    watch(props, (newVal, oldVal) => {
      shows.value = newVal.show;
      tailContnet.push(newVal.tail);
      clearInterval(cler);
      cler = setInterval(() => {
        if (tailContnet.length == 0) {
          shows.value = false;
        } else {
          tailContnet.splice(0, 1);
        }
      }, 3000);
      if (shows.value == false) {
        clearInterval(cler);
      }
    });
    return (_ctx, _cache) => {
      return _unref(shows) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tailContnet), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tk-item flex-between",
          key: index
        }, [_createElementVNode("p", null, _toDisplayString(item), 1), _cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "tail"
        }, [_createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })], -1))]);
      }), 128))])])) : _createCommentVNode("", true);
    };
  }
};