import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "tk-release"
};
const _hoisted_2 = {
  class: "tk-load-up-issue"
};
const _hoisted_3 = {
  class: "tk-issue"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tk-select-platform"
};
const _hoisted_6 = {
  class: "tk-platform"
};
const _hoisted_7 = {
  class: "tk-select-platform"
};
const _hoisted_8 = {
  class: "tk-title-conten"
};
const _hoisted_9 = {
  class: "tk-title-textarea"
};
const _hoisted_10 = {
  class: "tk-file"
};
const _hoisted_11 = {
  class: "file-shape"
};
const _hoisted_12 = {
  class: "file-upload"
};
const _hoisted_13 = {
  class: "tk-select-platform"
};
const _hoisted_14 = {
  class: "tk-color"
};
const _hoisted_15 = ["onClick"];
const _hoisted_16 = {
  class: "tk-platform-botton"
};
import { onBeforeMount, reactive, toRefs } from "vue";
import { discussCreate, discussPrevious } from "@/utils/https/master";
import { uploadFile } from "@/utils/https/https";
import Dialog from "@/components/mode/message";
import { useStore } from "vuex";

import { useRouter } from 'vue-router';
export default {
  __name: 'release',
  setup(__props) {
    const router = useRouter();
    let from = reactive({
      lotteryType: "2",
      year: localStorage.getItem("yearone"),
      title: "",
      content: "",
      word_color: "#50637F",
      images: []
    });
    onBeforeMount(() => {
      discussPreviouss(from.lotteryType);
    });
    let {
      content,
      title
    } = toRefs(from);
    let data = reactive({
      fileList: [],
      lotteryData: [{
        name: "新澳彩",
        lottery: 2
      }, {
        name: "港彩",
        lottery: 1
      }, {
        name: "快乐八",
        lottery: 6
      }, {
        name: "老澳",
        lottery: 7
      }
      // { name: "台彩", lottery: 3 },
      // { name: "新彩", lottery: 4 },
      ],
      color: [{
        color: "#50637F"
      }, {
        color: "#CD6583"
      }, {
        color: "#B54D3E"
      }, {
        color: "#659287"
      }, {
        color: "#6A944D"
      }, {
        color: "#A5BB73"
      }, {
        color: "#4654A3"
      }, {
        color: "#E0A15D"
      }],
      selectIndex: "#50637F",
      upData: {
        content: "",
        title: ""
      },
      upContenTitle: [{
        name: "加载上期内容",
        select: false
      }, {
        name: "加载上期标题",
        select: false
      }, {
        name: "加载上期正文",
        select: false
      }]
    });
    let {
      lotteryData,
      color,
      selectIndex,
      upContenTitle
    } = toRefs(data);
    const $store = useStore(); //获取vuex
    //网络请求
    let discussCreates = from => {
      discussCreate(from).then(res => {
        if (res.status != "400") {
          router.back();
          $store.commit("exidRelease", true);
          Dialog(res.data.message, "#07c160");
        } else {
          Dialog(res.data.message, "#07c160");
        }
      });
    };
    let discussPreviouss = lotteryType => {
      //加载上期数据
      discussPrevious({
        lotteryType: lotteryType
      }).then(res => {
        if (res.data.status != 40000) {
          data.upData = res.data.data;
        }
      });
    };
    let handleUpContent = e => {
      data.upContenTitle.forEach((item, index) => {
        if (e == index) {
          item.select = !item.select;
        } else {
          item.select = false;
        }
      });
      if (e == 0) {
        from.content = data.upData.content;
        from.title = data.upData.title;
      }
      if (e == 1) {
        from.content = "";
        from.title = data.upData.title;
      }
      if (e == 2) {
        from.content = data.upData.content;
        from.title = "";
      }
    };
    let uploadFun = file => {
      data.fileList = file;
    };
    let onSubmit = async () => {
      if (from.content.length == 0) return false;
      if (data.fileList.length != 0) {
        let res = uploadFile(data.fileList); //图片上传接口 先上传 在提交
        await res.then(datac => {
          if (datac.data.status == 40000) {
            Dialog(datac.data.message, "#07c160");
            return false;
          }
          let datas = datac.data.data.url;
          from.images = datas;
        });
      }
      discussCreates(from);
    };
    let tabLottery = lottery => {
      //选择彩🀄️
      from.lotteryType = lottery;
      discussPreviouss(from.lotteryType);
      data.upContenTitle.forEach(item => {
        item.select = false;
      });
      from.title = "";
      from.content = "";
    };
    let selecColor = item => {
      data.selectIndex = item.color;
      from.word_color = item.color;
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_lotteryTabs = _resolveComponent("lotteryTabs");
      const _component_uploadImage = _resolveComponent("uploadImage");
      const _component_botton = _resolveComponent("botton");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "发布论坛贴"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(upContenTitle), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(item.select ? 'issue-item active' : 'issue-item'),
          key: index,
          onClick: $event => _unref(handleUpContent)(index)
        }, _toDisplayString(item.name), 11, _hoisted_4);
      }), 128))])]), _createElementVNode("div", _hoisted_5, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-platform-title"
      }, "选择平台", -1)), _createElementVNode("div", _hoisted_6, [_createVNode(_component_lotteryTabs, {
        province: _unref(lotteryData),
        onTabLottery: _unref(tabLottery)
      }, null, 8, ["province", "onTabLottery"])])]), _createElementVNode("div", _hoisted_7, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-platform-title"
      }, "请输入标题", -1)), _createElementVNode("div", _hoisted_8, [_withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(title) ? title.value = $event : title = $event),
        placeholder: "请输入标题"
      }, null, 512), [[_vModelText, _unref(title)]])]), _createElementVNode("div", _hoisted_9, [_withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(content) ? content.value = $event : content = $event),
        style: _normalizeStyle({
          color: _unref(from).word_color
        }),
        placeholder: "请输入内容"
      }, null, 4), [[_vModelText, _unref(content)]]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_uploadImage, {
        onUploadFun: _unref(uploadFun)
      }, null, 8, ["onUploadFun"])])])])])]), _createElementVNode("div", _hoisted_13, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-platform-title"
      }, "选择文字颜色", -1)), _createElementVNode("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(color), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_unref(selectIndex) == item.color ? 'tk-color-select active' : 'tk-color-select'),
          key: index,
          style: _normalizeStyle({
            background: item.color
          }),
          onClick: $event => _unref(selecColor)(item)
        }, null, 14, _hoisted_15);
      }), 128))])]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_botton, {
        name: "发布",
        onClick: _unref(onSubmit),
        bgcolor: _unref(content).length == 0 ? 'rgba(3, 159, 76, 0.4' : '#07c160'
      }, null, 8, ["onClick", "bgcolor"])])])], 64);
    };
  }
};