import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/image/logo.jpg';
import _imports_1 from '../../assets/redBag/charbagon.png';
import _imports_2 from '../../assets/redBag/charbag.png';
const _hoisted_1 = {
  class: "box"
};
const _hoisted_2 = {
  class: "list"
};
const _hoisted_3 = {
  class: "user"
};
const _hoisted_4 = {
  class: "name"
};
const _hoisted_5 = {
  class: "tiems"
};
const _hoisted_6 = {
  key: 0,
  class: "image"
};
const _hoisted_7 = {
  key: 1,
  class: "image"
};
const _hoisted_8 = ["onClick"];
import { computed, onBeforeMount, ref } from "vue";
import { redPacket } from '@/utils/https/wechar';
export default {
  __name: 'popu',
  emits: ['handleSubmit', 'changNum'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const redBag = ref([]);
    const redPackes = async () => {
      let {
        data
      } = await redPacket();
      redBag.value = data.data;
      emit('changNum', data.data);
    };
    const emit = __emit;
    const ifStaust = computed(() => item => {
      if (item.status != 1 || item.last_nums <= 0 || item.is_receive) {
        return true;
      } else {
        return false;
      }
    });
    const handleSubmit = item => {
      emit('handleSubmit', item);
    };
    onBeforeMount(() => {
      redPackes();
    });
    const show = ref(false);
    __expose({
      show,
      redPackes,
      redBag
    });
    return (_ctx, _cache) => {
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => show.value = $event),
        round: "",
        position: "bottom",
        style: {
          height: '70%'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "title"
        }, "红包列表", -1)), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(redBag.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [_createElementVNode("div", _hoisted_3, [_cache[1] || (_cache[1] = _createElementVNode("div", {
            class: "img"
          }, [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })], -1)), _createElementVNode("div", _hoisted_4, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(item.created_at), 1)]), ifStaust.value(item) ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [_createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1), _createElementVNode("div", {
            class: "img-name"
          }, "红包已抢完", -1), _createElementVNode("div", {
            class: "tips"
          }, "平台红包", -1)]))) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
            src: _imports_2,
            onClick: $event => handleSubmit(item),
            alt: ""
          }, null, 8, _hoisted_8), _cache[3] || (_cache[3] = _createElementVNode("div", {
            class: "img-name"
          }, "恭喜发财 大吉大利", -1)), _cache[4] || (_cache[4] = _createElementVNode("div", {
            class: "tips"
          }, "平台红包", -1))]))]);
        }), 128))])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};