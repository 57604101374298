import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "tk-idea-box"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-title-info flex-between"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "tips"
};
const _hoisted_7 = {
  class: "tk-content"
};
const _hoisted_8 = {
  class: "tiems"
};
import { getAdviceList } from '@/utils/https/me';
import { reactive, toRefs } from 'vue';

import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'idea',
  setup(__props) {
    let data = reactive({
      adviceList: [],
      page: 1
    });
    let {
      adviceList
    } = toRefs(data);
    let getAdviceLists = page => {
      getAdviceList({
        page: page
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.adviceList.push(...datas);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getAdviceLists(data.page);
      data.page++;
    };
    const router = useRouter();
    let idea = () => {
      router.push({
        path: '/me/setup/feedback'
      });
    };
    let ideaFun = item => {
      router.push({
        path: '/me/setup/feedback/details',
        query: {
          data: JSON.stringify(item)
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "反馈列表"
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", {
          class: "icon-tk",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(idea) && _unref(idea)(...args))
        }, _cache[2] || (_cache[2] = [_createElementVNode("span", {
          style: {
            "font-size": "12px !important",
            "color": "var(--main-color) !important"
          }
        }, "我要反馈", -1)]))]),
        _: 1
      }), _createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(adviceList).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(adviceList), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            onClick: $event => _unref(ideaFun)(item),
            key: index
          }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("span", null, "标题：", -1)), _createTextVNode(" " + _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.is_reply == '1' ? '已处理' : '未处理'), 1)]), _createElementVNode("div", _hoisted_7, [_cache[4] || (_cache[4] = _createElementVNode("span", null, "内容：", -1)), _createTextVNode(" " + _toDisplayString(item.content), 1)]), _createElementVNode("div", _hoisted_8, _toDisplayString(item.created_at), 1)], 8, _hoisted_3);
        }), 128))]))])]),
        _: 1
      }, 8, ["loading", "finished"])], 64);
    };
  }
};