import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue";
import _imports_0 from '../../../assets/image/more/imageshake.png';
import _imports_1 from '../../../assets/image/more/imageshakecode.png';
const _hoisted_1 = {
  class: "tk-main-love"
};
const _hoisted_2 = {
  class: "tk-shake-conten"
};
const _hoisted_3 = {
  key: 1,
  class: "tk-shake-code"
};
const _hoisted_4 = {
  class: "shake-code"
};
const _hoisted_5 = {
  class: "tk-num"
};
import { reactive, toRefs } from "vue";

import { onBeforeMount } from 'vue';
export default {
  __name: 'shake',
  setup(__props) {
    let data = reactive({
      show: true,
      acitve: 0,
      code: [{
        number: 0,
        style: 'red'
      }, {
        number: 0,
        style: 'red'
      }, {
        number: 0,
        style: 'red'
      }]
    });
    let {
      show,
      acitve,
      code
    } = toRefs(data);
    //功能区域

    let shake = () => {
      data.acitve = 1;
      let c = setTimeout(() => {
        data.show = false;
        let b = [];
        for (let i = 1; i <= 49; i++) {
          b.push(i);
        }
        var arr1 = b.sort(function () {
          return .5 - Math.random();
        });
        var arr2 = arr1.slice(0, 3);
        data.code.forEach((element, index) => {
          element.number = arr2[index];
          element.style = colorarray[cons(element.number, numarrays)];
        });
        let datas = {
          value: data.code,
          show: false,
          expires: Date.now() + 1000 * 60 * 60 // 1 hour from now
        };
        localStorage.setItem('shake', JSON.stringify(datas));
        clearTimeout(c);
      }, 1000);
    };
    onBeforeMount(() => {
      let datas = JSON.parse(localStorage.getItem('shake'));
      if (datas && datas.expires > Date.now()) {
        data.code = datas.value;
        data.show = datas.show;
      } else {}
    });
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['blue', 'green', 'red'];
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "生肖卡牌"
      }), _createElementVNode("div", _hoisted_1, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "tk-love-description"
      }, [_createElementVNode("div", {
        class: "tk-love-title"
      }, "简介"), _createElementVNode("div", {
        class: "tk-desc"
      }, "试试您的手气到底多准!只要摇一摇，就能算出专属您的本期特码!"), _createElementVNode("div", {
        class: "tk-prompt-c"
      }, "温馨提示：每期只能进行一次摇一摇")], -1)), _createElementVNode("div", _hoisted_2, [_unref(show) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_unref(acitve) == 1 ? 'tk-shake active' : 'tk-shake '),
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(shake) && _unref(shake)(...args))
      }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)]), 2)) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(code), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index
        }, [_createElementVNode("div", {
          class: _normalizeClass('tk-code-s ' + item.style)
        }, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.number), 1)], 2)]);
      }), 128))])])]))])])], 64);
    };
  }
};