import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "tk-main-report"
};
const _hoisted_2 = {
  class: "tk-main-report-box"
};
const _hoisted_3 = {
  class: "tk-report-box-title"
};
const _hoisted_4 = {
  class: "tk-report-box-cotent"
};
const _hoisted_5 = {
  key: 0,
  class: "tk-report-imges"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "tk-report-result"
};
const _hoisted_9 = {
  class: "ke-result"
};
const _hoisted_10 = {
  class: "t"
};
const _hoisted_11 = {
  class: "tiem"
};
import { showImagePreview } from 'vant'; 
import { useRoute } from 'vue-router';
import { ref, onBeforeMount } from 'vue';
//图片预览

export default {
  __name: 'reportDesc',
  setup(__props) {
    let preview = (img, index) => {
      //预览图片
      let arr = [];
      img.forEach(item => {
        arr.push(item.img_url);
      });
      showImagePreview({
        images: arr,
        startPosition: index
      });
    };
    const route = useRoute();
    let dataInfo = ref();
    onBeforeMount(() => {
      dataInfo.value = JSON.parse(route.query.data);
    });
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "举报详情"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(dataInfo).title), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(dataInfo).content), 1), _unref(dataInfo).images.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataInfo).images, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tk-img",
          key: index,
          onClick: $event => _unref(preview)(_unref(dataInfo).images, index)
        }, [_createElementVNode("img", {
          src: item.img_url,
          alt: ""
        }, null, 8, _hoisted_7)], 8, _hoisted_6);
      }), 128))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_cache[0] || (_cache[0] = _createElementVNode("span", null, "处理结果：", -1)), _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(dataInfo).audit == 0 ? '审核中' : '审核通过'), 1)]), _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(dataInfo).created_at), 1)])])])], 64);
    };
  }
};