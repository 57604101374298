import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue";
import _imports_0 from '../../assets/image/icon/sinxiaz.png';
import _imports_1 from '../../assets/image/imagess.png';
import _imports_2 from '../../assets/image/comments.png';
import _imports_3 from '../../assets/image/zanons.png';
import _imports_4 from '../../assets/image/zans.png';
import _imports_5 from '../../assets/image/colleons.png';
import _imports_6 from '../../assets/image/colles.png';
import _imports_7 from '../../assets/image/fencomments.png';

import { useRouter } from 'vue-router';
import { ref, reactive, toRefs, onMounted } from 'vue';
const _hoisted_1 = {
  class: "tk-box-comment"
};
const _hoisted_2 = {
  key: 0,
  class: "tk-box-comment"
};
const _hoisted_3 = {
  class: "tk-info-item"
};
const _hoisted_4 = {
  class: "tk-comment-info"
};
const _hoisted_5 = {
  key: 0,
  src: _imports_3,
  alt: ""
};
const _hoisted_6 = {
  key: 1,
  src: _imports_4,
  alt: ""
};
const _hoisted_7 = {
  key: 0,
  src: _imports_5,
  alt: ""
};
const _hoisted_8 = {
  key: 1,
  src: _imports_6,
  alt: ""
};
const _hoisted_9 = {
  key: 0,
  src: _imports_5,
  alt: ""
};
const _hoisted_10 = {
  key: 1,
  src: _imports_6,
  alt: ""
};
export default {
  __name: 'comments',
  props: {
    dataInfo: {
      type: Object,
      default: {}
    },
    typeId: {
      type: String,
      default: ''
    },
    scrollTop: {
      type: Boolean,
      default: false
    },
    scrollTops: {
      type: Boolean,
      default: false
    },
    corpusTypeId: {
      type: String,
      default: ''
    }
  },
  emits: ['majoFun', 'hanladClick'],
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    const emits = __emit;
    const props = __props;
    let scrollTabs = ref(null);
    let scroolData = reactive({
      offsetTop: 0
    });
    let {
      offsetTop
    } = toRefs(scroolData);
    onMounted(() => {
      // 获取 DOM 元素
      scroolData.offsetTop = scrollTabs.value?.clientHeight;
    });

    //功能区域
    let majoFun = index => {
      sessionStorage.setItem('collect', 'collect');
      emits('majoFun', index);
    };
    let commentOpne = () => {
      if (props.typeId == '') {
        emits('hanladClick');
      } else {
        router.push({
          path: '/materials/list/comment',
          query: {
            typeId: props.typeId,
            id: props.dataInfo.id,
            corpusTypeId: props.corpusTypeId
          }
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "tk-main-comment ccc",
        ref_key: "scrollTabs",
        ref: scrollTabs
      }, [_createElementVNode("div", _hoisted_1, [__props.scrollTop ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
        class: "tk-comment-splik",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(commentOpne) && _unref(commentOpne)(...args))
      }, "发布准确解料，抽送8888元彩金~"), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_icon, {
        name: "chat-o",
        size: "26"
      }), _createElementVNode("span", null, _toDisplayString(__props.dataInfo.commentCount), 1)])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [__props.typeId == 1 && __props.scrollTops ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "tk-info-item",
        onClick: _cache[1] || (_cache[1] = $event => _unref(majoFun)(11))
      }, _cache[9] || (_cache[9] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "竞猜", -1)]))) : _createCommentVNode("", true), __props.typeId == 1 && !__props.scrollTops ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "tk-info-item",
        onClick: _cache[2] || (_cache[2] = $event => _unref(majoFun)(10))
      }, _cache[10] || (_cache[10] = [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "评论", -1)]))) : _createCommentVNode("", true), __props.typeId == 1 ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: "tk-info-item",
        onClick: _cache[3] || (_cache[3] = $event => _unref(majoFun)(4))
      }, _cache[11] || (_cache[11] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "查看图解", -1)]))) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "tk-info-item",
        onClick: _cache[4] || (_cache[4] = $event => _unref(majoFun)(0))
      }, [__props.dataInfo.isFollow || __props.dataInfo.follow ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : (_openBlock(), _createElementBlock("img", _hoisted_6)), _cache[12] || (_cache[12] = _createElementVNode("span", null, "点赞", -1))]), __props.typeId == '11' ? (_openBlock(), _createElementBlock("div", {
        key: 3,
        class: "tk-info-item",
        onClick: _cache[5] || (_cache[5] = $event => _unref(majoFun)(3))
      }, [__props.dataInfo.isCollect ? (_openBlock(), _createElementBlock("img", _hoisted_7)) : (_openBlock(), _createElementBlock("img", _hoisted_8)), _cache[13] || (_cache[13] = _createElementVNode("span", null, "收藏", -1))])) : _createCommentVNode("", true), __props.typeId == '1' ? (_openBlock(), _createElementBlock("div", {
        key: 4,
        class: "tk-info-item",
        onClick: _cache[6] || (_cache[6] = $event => _unref(majoFun)(3))
      }, [__props.dataInfo.isCollect ? (_openBlock(), _createElementBlock("img", _hoisted_9)) : (_openBlock(), _createElementBlock("img", _hoisted_10)), _cache[14] || (_cache[14] = _createElementVNode("span", null, "收藏", -1))])) : (_openBlock(), _createElementBlock("div", {
        key: 5,
        class: "tk-info-item",
        onClick: _cache[7] || (_cache[7] = $event => _unref(majoFun)(1))
      }, [_cache[15] || (_cache[15] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(__props.dataInfo.commentCount), 1)])), _createElementVNode("div", {
        class: "tk-info-item",
        onClick: _cache[8] || (_cache[8] = $event => _unref(majoFun)(2))
      }, _cache[16] || (_cache[16] = [_createElementVNode("img", {
        src: _imports_7,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "分享", -1)]))])])], 512), _createElementVNode("div", {
        style: _normalizeStyle({
          'height': _unref(offsetTop) + 'px'
        })
      }, null, 4)], 64);
    };
  }
};