import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '../../../assets/image/more/text.png';
import _imports_1 from '../../../assets/image/more/zhanshi.png';
import _imports_2 from '../../../assets/image/more/start.png';
import _imports_3 from '../../../assets/image/more/zhuanpan.png';
import _imports_4 from '../../../assets/image/more/di.png';
const _hoisted_1 = {
  class: "tk-main-love tk-main-love-s"
};
const _hoisted_2 = {
  class: "tk-rotation"
};
const _hoisted_3 = {
  class: "tk-rotation-head"
};
const _hoisted_4 = {
  class: "tk-rotation-content"
};
const _hoisted_5 = {
  class: "tk-ul"
};
const _hoisted_6 = {
  class: "tk-num"
};
const _hoisted_7 = {
  class: "tk-trochal-disk"
};
const _hoisted_8 = {
  class: "tk-trochal-rotation"
};
import { onBeforeMount } from "vue";
import Dialog from '@/components/mode/message';

import { reactive, toRefs } from 'vue';
export default {
  __name: 'rotation',
  setup(__props) {
    onBeforeMount(() => {
      cc();
    });
    let cc = () => {
      if (localStorage.getItem('Device') != null) {
        data.showHead = false;
      } else {
        let c = setTimeout(() => {
          if (localStorage.getItem('Device') != null) {
            data.showHead = false;
          }
          clearTimeout(c);
        }, 100);
      }
    };
    let data = reactive({
      codeArray: [],
      rotation: true,
      showHead: true
    });
    let {
      rotation,
      codeArray,
      showHead
    } = toRefs(data);
    let sta = false;
    let start = () => {
      if (sta) return false;
      sta = true;
      let h = setTimeout(() => {
        sta = false;
        data.rotation = !data.rotation;
        clearTimeout(h);
      }, 8000);
      data.codeArray.length = 0;
      let b = [];
      for (let i = 1; i <= 49; i++) {
        b.push(i);
      }
      var arr1 = b.sort(function () {
        return .5 - Math.random();
      });
      var arr2 = arr1.slice(0, 7);
      arr2.forEach(item => {
        data.codeArray.push({
          num: item,
          color: colorarray[cons(item, numarrays)]
        });
      });
      data.rotation = !data.rotation;
      let d = 0;
      let c = setInterval(() => {
        if (d == 7) {
          clearInterval(c);
        } else {
          data.codeArray[d].style = 1;
        }
        d++;
      }, 1000);
    };
    function randNum(min, max, num) {
      var arr = [],
        t;
      function fn(i) {
        for (i; i < num; i++) {
          t = parseInt(Math.random() * (max - min + 1) + min);
          for (var k in arr) {
            if (arr[k] == t) {
              fn(i);
              break;
            }
          }
          arr[i] = t;
        }
      }
      fn(0);
      return arr[0];
    }
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['red', 'blue', 'green'];
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(showHead) ? (_openBlock(), _createBlock(_component_headNav, {
        key: 0,
        title: "幸运摇奖"
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "tk-love-description"
      }, [_createElementVNode("div", {
        class: "tk-prompt-c"
      }, "温馨提示：幸运摇奖用模拟开奖，系统将自动摇出幸运号码")], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-title"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(codeArray), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(item.style == 1 ? 'tk-code-s b ' + item.color : 'tk-code-s ' + item.color),
          key: index
        }, [_createElementVNode("div", _hoisted_6, _toDisplayString(item.num), 1)], 2);
      }), 128))])])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
        class: "tk-start",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(start) && _unref(start)(...args))
      }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1), _createElementVNode("span", null, "点击开始", -1)])), _createElementVNode("img", {
        class: _normalizeClass(_unref(rotation) ? ' ' : 'active'),
        src: _imports_3,
        alt: ""
      }, null, 2)]), _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "tk-disk-bootom"
      }, [_createElementVNode("img", {
        src: _imports_4,
        alt: ""
      })], -1))])])])], 64);
    };
  }
};