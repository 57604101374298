import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "tk-main-empty"
};
const _hoisted_2 = {
  class: "tk-empty"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "tk-name"
};
export default {
  __name: 'empty',
  props: {
    icon: {
      type: String,
      default: require('../../assets/image/empty.png')
    },
    name: {
      type: String,
      default: '暂无数据'
    }
  },
  setup(__props) {
    let props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        src: __props.icon,
        alt: ""
      }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, _toDisplayString(__props.name), 1)]);
    };
  }
};