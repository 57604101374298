import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../assets/image/zan.png';
import _imports_1 from '../../../assets/image/comment.png';
const _hoisted_1 = {
  class: "tk-main-materals-list"
};
const _hoisted_2 = {
  class: "tk-materals-list"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "tk-list-title"
};
const _hoisted_5 = {
  class: "tk-title-picture"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "tk-title-info"
};
const _hoisted_8 = {
  class: "tk-name"
};
const _hoisted_9 = {
  class: "tk-tiems"
};
const _hoisted_10 = {
  class: "tk-list-content"
};
const _hoisted_11 = {
  class: "tk-list-commten-info"
};
const _hoisted_12 = {
  class: "tk-like"
};
const _hoisted_13 = {
  class: "num"
};
const _hoisted_14 = {
  class: "tk-commten"
};
const _hoisted_15 = {
  class: "num"
};
import picker from '@/components/picker.vue';
import { newYear } from '@/utils/dateTiem';
import { listArticle } from '@/utils/https/materials';

import { useRouter, useRoute } from 'vue-router';
import { reactive, toRefs, onBeforeMount, ref } from 'vue';
export default {
  __name: 'history',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    let data = reactive({
      lotteryType: 1,
      //彩票类型
      title: '',
      //文章标题
      id: '',
      //文章ID
      page: 1,
      //分页
      articleData: [],
      //帖子数据，
      year: newYear,
      userId: '',
      //用户id
      websiteName: JSON.parse(localStorage.getItem('config')) == null ? {
        logo: ''
      } : JSON.parse(localStorage.getItem('config'))
    });
    let {
      title,
      articleData,
      websiteName
    } = toRefs(data);
    onBeforeMount(() => {
      data.title = route.query.title;
      data.id = route.query.id;
      data.userId = route.query.userId;
      data.corpusTypeId = route.query.corpusTypeId;
      // article(data.id,data.year,1,data);
    });

    //分页请求
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const onLoad = () => {
      article(data.year, data.page, data.userId, data.corpusTypeId);
      data.page++;
    };
    const onRefresh = () => {
      // 清空列表数据
      data.page = 0;
      data.articleData.length = 0;
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    //页面请求区域
    let article = (year, page, userid, corpusTypeId) => {
      //请求列表
      listArticle({
        year: year,
        page: page,
        userid: userid,
        corpusTypeId: corpusTypeId
      }).then(res => {
        let datas = res.data.data.list;
        refreshing.value = false;
        if (datas.length == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.articleData.push(...datas);
        }
      });
    };

    //页面功能区域

    //页面跳转区域
    let majorDesc = data => {
      router.push({
        path: `/materials/list/details`,
        query: {
          id: data
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: _unref(title) + '管理员'
      }, null, 8, ["title"]), _createVNode(_component_van_pull_refresh, {
        modelValue: _unref(refreshing),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(refreshing) ? refreshing.value = $event : null),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: _unref(loading),
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
          finished: _unref(finished),
          "finished-text": "没有更多了",
          onLoad: onLoad
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_unref(articleData).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
            key: 0
          })) : _createCommentVNode("", true), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(articleData), (item, index) => {
            return _openBlock(), _createElementBlock("li", {
              onClick: $event => _unref(majorDesc)(item.id),
              key: index
            }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
              src: _ctx.$httpsUrl + _unref(websiteName).logo,
              alt: ""
            }, null, 8, _hoisted_6)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(title) + '管理员'), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(item.created_at), 1)])]), _createElementVNode("div", _hoisted_10, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_cache[2] || (_cache[2] = _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)), _createElementVNode("span", _hoisted_13, _toDisplayString(item.thumbUpCount), 1)]), _createElementVNode("div", _hoisted_14, [_cache[3] || (_cache[3] = _createElementVNode("img", {
              src: _imports_1,
              alt: ""
            }, null, -1)), _createElementVNode("span", _hoisted_15, _toDisplayString(item.commentCount), 1)])])], 8, _hoisted_3);
          }), 128))])])])]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};