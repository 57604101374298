import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../assets/image/return.png';
import _imports_1 from '../assets/image/onreturn.png';

import { useRouter } from 'vue-router';
const _hoisted_1 = {
  class: "tk-nav flex-between"
};
const _hoisted_2 = {
  class: "tk-nav-left"
};
const _hoisted_3 = {
  class: "tk-nav-icon"
};
export default {
  __name: 'NavBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    colors: {
      type: String,
      default: '#000'
    }
  },
  emits: 'returns',
  setup(__props, {
    emit: __emit
  }) {
    let emits = __emit;
    let props = __props;
    let route = useRouter();
    let returns = () => {
      // route.back();

      emits('returns');
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [__props.colors == '#000' ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _imports_0,
        alt: "",
        srcset: "",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(returns) && _unref(returns)(...args))
      })) : (_openBlock(), _createElementBlock("img", {
        key: 1,
        src: _imports_1,
        alt: "",
        srcset: "",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(returns) && _unref(returns)(...args))
      }))])]), _createElementVNode("div", {
        style: _normalizeStyle({
          'color': __props.colors
        }),
        class: "tk-nav-title"
      }, _toDisplayString(__props.title), 5), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "tk-nav-right"
      }, null, -1))]);
    };
  }
};